import styled from "styled-components";

export const Form = styled.div`
  width: 100%;

  background-color: #fff;

  padding: 24px;

  > p {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    margin: 24px 0 12px;

    font-weight: 500;

    color: #666666;

    > p {
      margin-left: 6px;

      font-size: 10px;
      font-weight: bold;
    }
  }

  #initial-label,
  #floor-type {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > input {
    width: 100%;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    border-radius: 0;

    padding: 10px;

    margin-bottom: 26px;
  }

  > textarea {
    width: 100%;
    height: 200px;

    resize: none;

    border: 1px solid #b6b6b6;

    border-radius: 0;

    padding: 8px;

    margin-bottom: 26px;
  }

  @media (max-width: 767px) {
    > p {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      flex-direction: column;

      > p {
        margin-left: 0;
      }
    }
  }
`;

export const PropertyTypesContainer = styled.div`
  width: 60%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 16px auto 0;

  padding: 12px;

  background: #f9f9f9;

  @media (max-width: 767px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    width: 100%;
  }
`;

export const PropertyOwnerConsultantWrapper = styled.div`
  width: 100%;

  margin: 48px 0 48px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  font-weight: 500;

  color: #666666;

  .css-2b097c-container {
    width: 100%;

    margin-top: 12px;
  }

  .css-yk16xz-control {
    border: 0;
    border-bottom: 1px solid #b6b6b6;

    border-radius: 0;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const PropertyOwnerWrapper = styled.div`
  width: 45%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  > span {
    font-size: 10px;
  }

  > p {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    > p {
      font-size: 10px;
      font-weight: bold;
    }
  }

  @media (max-width: 767px) {
    width: 100%;

    margin-bottom: 16px;
  }
`;

export const OwnerList = styled.div`
  width: 100%;

  position: relative;



  & input,p {
    width: 100%;

    padding: 14px 12px;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    cursor: default;
  }
  

  & svg {
    width: 24px;
    height: 24px;
    color: #dd6435;
    cursor: pointer;
  }

  & span svg {
    width: 16px;
    height: 16px;
    color: #b6b6b6;
  }

  .css-2b097c-container {
    width: 100%;

    &:last-child {
      margin-bottom: 64px;
    }
  }

  .css-yk16xz-control {
    border-color: #04c5ff;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  #users {
    width: 100%;
    max-height: 350px;

    overflow-y: auto;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    position: absolute;

    left: 50;
    top: 25;
    z-index: 10000;

    background: #ffffff;

    border: 1px solid #b6b6b6;

    > button {
      padding: 8px 16px;

      width: 100%;

      font-size: 14px;

      text-align: start;

      transition: all 0.2s;

      &:hover {
        background: #f9f9f9;
      }
    }
  }

  .MuiAutocomplete-root {
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    > input {
      width: 100%;
    }

    .css-2b097c-container {
      width: 100%;
    }
  }
`;

export const ListAddWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const PropertyConsultantWrapper = styled.div`
  width: 45%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  margin-top: 12px;
  margin-left: auto;

  > p {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    > p {
      font-size: 10px;
      font-weight: bold;
    }
  }

  @media (max-width: 767px) {
    width: 100%;

    margin-left: 0;
  }
`;

export const PropertyAccommodationsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 18px 0;

  @media (max-width: 767px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const PropertyValuesWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 48px 0;

  @media (max-width: 767px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const PropertyPermissionsWrapper = styled.div`
  width: 45%;

  display: flex;
  align-items: center;
  justify-items: center;

  margin: 18px auto 48px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const PermissionsLabelRadiosWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-items: center;

  flex-direction: column;

  & + div {
    margin-left: 16px;
  }

  @media (max-width: 767px) {
    & + div {
      margin-top: 16px;
      margin-left: 0;
    }
  }
`;

export const PermissionRadioWrapper = styled.div`
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 6px 0 18px;

  background: #f9f9f9;

  padding: 12px;

  > p {
    margin: 0 8px;

    cursor: pointer;
  }
`;
