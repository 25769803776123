import { useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import ModelContractSteps from "./ModelContractSteps";
import UploadBeltImage from "../../components/UploadBeltImage";
import { useToast } from "../../hooks/toast";
import {
  MdChevronLeft,
  MdOutlineFileCopy,
  MdOutlineFileUpload,
} from "react-icons/md";
import { ButtonsContainer, Container, Content } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SignersStep from "./components/SignersStep";
import FormStep from "./components/FormStep";
import { CONTRACT_STEPS } from "./constants";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { ModelContractStepperContext } from "../../store/ModelContractStepper/model-contract-stepper";

interface ContractObj {
  [key: string]: string | boolean | number | ContractObj[];
}

interface IProps {
  rent_process_id: number;
  property_id: number;
}
interface IParams {
  type: string;
}

const ICON_SIZE = 24;

function ModelContract({ rent_process_id, property_id }: IProps) {
  const history = useHistory();
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();
  const { access_token } = useAuth();

  const [activeStep, setActiveStep] = useState(
    ModelContractSteps.CONTRACT_DATA_FORM
  );
  const [isGeneratingContract, setIsGeneratingContract] = useState(false);
  const [contractWasGenerated, setContractWasGenerated] = useState(false);

  const { contract_data, signers } = useContext(ModelContractStepperContext);

  function handleGoBack() {
    if (activeStep === ModelContractSteps.CONTRACT_DATA_FORM) {
      return;
    }
    setActiveStep(activeStep - 1);
  }

  function handleGoForward() {
    setActiveStep(activeStep + 1);
  }

  function exitButtonClickHandler() {
    history.push("/clients-without-contract");
  }

  const contractGeneratedHandler = (isInGenerationProcess: boolean) => {
    addToast({
      title: isInGenerationProcess
        ? "O contrato está sendo gerado"
        : "O contrato já foi gerado",
      type: "error",
    });
  };

  const sendContractToApi = async (body: any) => {
    setIsGeneratingContract(true);
    try {
      api.defaults.headers.authorization = `Bearer ${access_token}`;
      const response = await api.post(
        `/api/adm/rent-contract/${property_id}`,
        body
      );
      console.log(response);
      addToast({ title: "Contrato criado com sucesso!", type: "success" });
      setContractWasGenerated(true);
    } catch (err: any) {
      if (err?.response?.status === 403) {
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }
    setIsGeneratingContract(false);
  };

  function isFormValid(data: ContractObj) {
    if (Object.entries(data).length === 0) {
      return false;
    } else {
      for (const key in data) {
        if (Array.isArray(data[key])) {
          const listData = data[key] as ContractObj[];
          for (const listIndex in listData) {
            const innerObj = listData[listIndex];
            for (const objKey in innerObj)
              if (
                innerObj[objKey].toString().trim() === "" ||
                innerObj[objKey] === 0
              )
                return false;
          }
        } else {
          if (data[key].toString().trim() === "" || data[key] === 0)
            return false;
        }
      }
    }
    return true;
  }

  const generateContractHandler = () => {
    let signersIsValid = true;
    let rentProcessIdIsValid = true;
    let contractDataIsValid = true;

    if (signers.length === 0) {
      signersIsValid = false;
      addToast({ title: "Necessário pelo menos um signatário", type: "error" });
    }

    if (!rent_process_id) {
      rentProcessIdIsValid = false;
      addToast({
        title: "Necessário identificador do processo de locação",
        type: "error",
      });
    }

    if (!isFormValid(contract_data)) {
      contractDataIsValid = false;
      addToast({
        title: "Por favor preencha todos os campos!",
        type: "error",
      });
    }

    if (!signersIsValid || !rentProcessIdIsValid || !contractDataIsValid)
      return;

    const body = {
      signers,
      contract_data,
      rent_process_id: rent_process_id,
      model: params.type,
    };
    
    sendContractToApi(body);
  };

  return (
    <>
      <Header />

      <Container>
        <Content>
          <h2>Criação de contrato</h2>
          <p>
            Preencha todos os campos com atenção e confira todas as informações
            antes de finalizar.
          </p>
          <UploadBeltImage currentStep={activeStep} steps={CONTRACT_STEPS} />
          {activeStep === ModelContractSteps.CONTRACT_DATA_FORM ? (
            <FormStep model={params.type} onForward={handleGoForward} />
          ) : (
            <SignersStep />
          )}
          <ButtonsContainer>
            {activeStep === ModelContractSteps.SIGNERS_REGISTER && (
              <button id={"back-btn"} onClick={handleGoBack}>
                <span>
                  <MdChevronLeft size={ICON_SIZE} fontWeight={700} /> Voltar
                </span>
              </button>
            )}
            {activeStep === ModelContractSteps.SIGNERS_REGISTER && (
              <button
                id={
                  contractWasGenerated || isGeneratingContract
                    ? "disable-btn"
                    : "last-btn"
                }
                onClick={
                  contractWasGenerated || isGeneratingContract
                    ? () => contractGeneratedHandler(isGeneratingContract)
                    : generateContractHandler
                }
              >
                {isGeneratingContract ? (
                  "Gerando contrato..."
                ) : (
                  <span className="styled-btn">
                    Gerar Contrato <MdOutlineFileUpload size={ICON_SIZE} />
                  </span>
                )}
              </button>
            )}
            {activeStep === ModelContractSteps.SIGNERS_REGISTER && (
              <button onClick={exitButtonClickHandler}>
                <span className="styled-btn">
                  Todos os Contratos <MdOutlineFileCopy size={ICON_SIZE} />
                </span>
              </button>
            )}
          </ButtonsContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
}

export default ModelContract;
