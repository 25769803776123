import styled from "styled-components";

export const Container = styled.div`
`;

export const Content = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6rem auto;
    >h2 {
        color: #666666;
        font-weight: 700;
        font-size: 2rem;
        text-align: center;
    }

    >label#success-label {
        color: #00bf34;
        font-size: 0.8rem;
        padding-bottom: 0.75em;
    }

`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 4rem auto;

    >label {
        color: #666666;
        font-weight: 500;
        >strong {
            font-size: 0.7rem;
        }
    }
    >input{
        width: 100%;
        
        padding: 0.5rem;
        border: 1px solid #B6B6B6;
        margin: 0.5rem 0 2rem;
    }
    >p {
        font-size: 0.7rem;
    }
    >textarea {
        
        width: 100%;
        height: 7rem;
        padding: 0.5rem;
        border: 1px solid #B6B6B6;
        margin: 0.5rem 0 1.5rem;
    }
    >button {
        font-size: 0.9rem;
        background: #00bf34;
        text-transform: uppercase;
        color: #FFF;
        font-weight: 500;
        width: 100%;
        padding: 0.7rem 0; 
    }
    >button#wait-btn {
        
        color: #666666;
        background: #FFF;
        border: 1px solid #666666;
        cursor: not-allowed;
    }
`;
