import {
  GridContainer,
  Form,
} from "../GeneralStyles/styles";
import { ContentHolder, InfoTitle, RadioContainer, TripleGrid } from "./styles";

const ContractCreation: React.FC = () => {
  return (
    <Form>
      <ContentHolder>
        <InfoTitle>Duração do contrato</InfoTitle>
        <TripleGrid>
          <label>Dia de início:</label>
          <label>Mês de início:</label>
          <label>Ano de início:</label>
          <select></select>
          <select></select>
          <select></select>
        </TripleGrid>
      </ContentHolder>
      <ContentHolder>
        <InfoTitle>Isenção de multa</InfoTitle>
        <GridContainer>
          <label>Meses isenção de multa:</label>
          <label>Meses isenção de multa (por extenso):</label>
          <input />
          <input />
        </GridContainer>
      </ContentHolder>
      <ContentHolder>
        <InfoTitle>Valores da locação</InfoTitle>
        <label>Valor do aluguel (R$):</label>
        <input />
        <label>Valor do aluguel (por extenso):</label>
        <input />
      </ContentHolder>
      <InfoTitle>Informações do contrato</InfoTitle>
      <GridContainer>
        <label>Incluir parágrafo primeiro:</label>
        <label>Encargos:</label>
        <RadioContainer>
          <input
            type="radio"
            value="Sim"
            id="first-paragraph-yes"
            name="first-paragraph-radio-group"
          />
          <label className="radio-lbl">Sim</label>
          <input
            type="radio"
            value="Não"
            id="first-paragraph-no"
            name="first-paragraph-radio-group"
          />
          <label className="radio-lbl">Não</label>
        </RadioContainer>
        <input />
        <label>Incluir parágrafo segundo:</label>
        <label></label>
        <RadioContainer>
          <input
            type="radio"
            value="Sim"
            id="second-paragraph-yes"
            name="second-paragraph-radio-group"
          />
          <label className="radio-lbl">Sim</label>
          <input
            type="radio"
            value="Não"
            id="second-paragraph-no"
            name="second-paragraph-radio-group"
          />
          <label className="radio-lbl">Não</label>
        </RadioContainer>
        <label></label>
        <label>Cidade do imóvel locado:</label>
        <label>UF do imóvel locado:</label>
        <input />
        <input />
      </GridContainer>
      <label>Índice de reajuste:</label>
      <input />
      <TripleGrid>
        <label>Dia atual:</label>
        <label>Mês atual:</label>
        <label>Ano atual:</label>
        <select></select>
        <select></select>
        <select></select>
      </TripleGrid>
    </Form>
  );
};

export default ContractCreation;
