import React, { useEffect } from 'react';

import { isAfter } from 'date-fns';

import AppProvider from './hooks/';

import Routes from './routes/';
import GlobalStyle from './styles/';

import { useClearCache } from 'react-clear-cache';

function App() {
	const { isLatestVersion, emptyCacheStorage } = useClearCache();

  	const access_token = localStorage.getItem('@7Cantos:token');
	const dateToExpires = localStorage.getItem('@7Cantos:dateToExpires');

	useEffect(() => {
		async function loadApi(){
			if(!!dateToExpires){
				const actualDate = new Date(Date.now());
				const dateToExpiresFormated = new Date(dateToExpires);

				if(isAfter(actualDate, dateToExpiresFormated)){
					localStorage.removeItem('@7Cantos:token');
					localStorage.removeItem('@7Cantos:user');
					localStorage.removeItem('@7Cantos:dateToExpires');

					window.location.reload();
				}
			}
		}

		if(!!access_token){
			loadApi();
		}
	}, [access_token, dateToExpires]);

	useEffect(() => {
		if(!isLatestVersion){
			emptyCacheStorage();
		}
	}, [isLatestVersion, emptyCacheStorage]);

  	return (
  	  <>
  	    {!!isLatestVersion ? (
			  <>
				<AppProvider>
					<Routes />
				</AppProvider>
				<GlobalStyle />
			</>
		  ) : (<></>)}
  	  </>
  	);
}

export default App;
