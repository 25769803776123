import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Form, FormDataField, FormDataFields, FormDataWrapper } from "./styles";
import MaskedInput from "react-text-mask";
import { useState } from "react";
import GoogleMaps from "google-map-react";
import Marker from "../../../../components/Marker";
import { ButtonsWrapper, NextButton, ResetButton } from "../../styles";
import { FiChevronRight } from "react-icons/fi";
import api from "../../../../services/api";
import getValidationErrors, {
  Errors,
} from "../../../../utils/getValidationError";

import { geocodeByPlaceId } from "react-google-places-autocomplete";
import GeoCode from "react-geocode";

import * as Yup from "yup";
import { useAuth } from "../../../../hooks/auth";
import refreshToken from "../../../../utils/refreshToken";
import { useToast } from "../../../../hooks/toast";

interface IProps {
  onChangeCurrentForm: () => void;
  onAddressIdReceived: (id: number) => void;
}

function MapForm({ onChangeCurrentForm, onAddressIdReceived }: IProps) {
  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();

  const [address, setAddress] = useState({
    cep: "",
    city: "",
    complement: "",
    floor: "",
    google_id: "",
    latitude: "",
    longitude: "",
    address: "",
    neighborhood: "",
    number: "",
    uf: "",
  });
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [googleMapsSearch, setGoogleMapsSearch] = useState("");

  const [availableAddressInputs, setAvailableAddreInputs] = useState(false);
  const [formErrors, setFormErrors] = useState({} as Errors);

  function handleChange(identifier: string, value: string) {
    setAddress((prevValue) => ({ ...prevValue, [identifier]: value }));
  }

  async function hadleGetMoreInformationsAboutLocal(
    address: string,
    place_id: string
  ) {
    try {
      GeoCode.setApiKey(
        !!"AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc"
          ? "AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc"
          : ""
      );

      const response = await GeoCode.fromAddress(address);

      const request = await geocodeByPlaceId(place_id);

      request[0].address_components.forEach((address) => {
        if (address.types.join().includes("route")) {
          handleChange("address", address.long_name);

          return;
        }

        if (address.types.join().includes("administrative_area_level_2")) {
          handleChange("city", address.long_name);

          return;
        }

        if (address.types.join().includes("sublocality_level_1")) {
          handleChange("neighborhood", address.long_name);

          return;
        }

        if (address.types.join().includes("administrative_area_level_1")) {
          handleChange("uf", address.short_name);

          return;
        }

        if (address.types.join().includes("street_number")) {
          handleChange("number", address.short_name);

          return;
        }

        if (address.types.join().includes("postal_code")) {
          handleChange("cep", address.short_name);

          return;
        }
      });

      handleChange("latitude", response.results[0].geometry.location.lat);
      handleChange("longitude", response.results[0].geometry.location.lng);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAddressSubmit() {
    setIsSubmiting(true);

    try {
      const cepRegex = /^\d{5}-\d{3}$/g;

      const schema = Yup.object().shape({
        address: Yup.string().required("Logradouro obrigatório"),
        cep: Yup.string()
          .required("CEP obrigatório")
          .matches(cepRegex, "CEP inválido"),
        number: Yup.string().required("Número obrigatório"),
        complement: Yup.string(),
        neighborhood: Yup.string().required("Bairro obrigatório"),
        floor: Yup.string(),
        city: Yup.string().required("Cidade obrigatória"),
        uf: Yup.string().required("UF obrigatório"),
      });

      await schema.validate(address, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post("/api/adm/addresses", {
        ...address,
        number: Number(address.number),
      });

      if (!!response.data.success) {
        onAddressIdReceived(response.data.data.id);

        setFormErrors({});

        addToast({
          title: "Endereço cadastrado!",
          type: "success",
        });
        onChangeCurrentForm();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationErrors(err);

        addToast({
          title: error[Object.keys(error)[0]],
          type: "info",
          description: "",
        });

        setFormErrors(error);
      } else if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      console.log(err);
    }

    setIsSubmiting(false);
  }

  return (
    <Form>
      <p>Endereço completo para busca</p>
      <GooglePlacesAutocomplete
        onLoadFailed={(err) => console.log(err)}
        autocompletionRequest={{
          componentRestrictions: { country: "br" },
          types: ["address"],
          location: {
            lat: -17.221666,
            lng: -46.875,
          },
          bounds: [
            {
              lat: -17.221666,
              lng: -46.875,
            },
            {
              lat: -17.221666,
              lng: -46.875,
            },
          ],
        }}
        selectProps={{
          inputValue: googleMapsSearch,
          loadingMessage(obj: { inputValue: string }) {
            return "Carregando...";
          },
          onInputChange(e) {
            setGoogleMapsSearch(e);
          },
          onChange(e) {
            hadleGetMoreInformationsAboutLocal(e?.label, e?.value.place_id);

            setAvailableAddreInputs(true);

            setGoogleMapsSearch(e?.label);
            handleChange("google_id", e?.value.place_id ?? address.google_id);
          },
          noOptionsMessage(obj: { inputValue: string }) {
            return "Sem local";
          },
          placeholder: "",
        }}
      />

      <p className="p-example">
        Ex: Av. Dom Luís, 500 - Aldeota, Fortaleza - CE, Brasil
      </p>

      <FormDataWrapper>
        <label>Confira abaixo Se os dados estão corretos:</label>

        <FormDataFields>
          <FormDataField>
            <label>
              Logradouro: {!!formErrors.address && <p>{formErrors.address}</p>}
            </label>
            <input
              type="text"
              value={address.address}
              readOnly={!availableAddressInputs ? true : false}
              onChange={(e) => handleChange("address", e.target.value)}
            />
          </FormDataField>

          <FormDataField>
            <label>CEP {!!formErrors.cep && <p>{formErrors.cep}</p>}</label>
            <MaskedInput
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
              type="text"
              value={address.cep}
              readOnly={!availableAddressInputs ? true : false}
              onChange={(e) => handleChange("cep", e.target.value)}
            />
          </FormDataField>

          <FormDataField>
            <label>
              Número {!!formErrors.number && <p>{formErrors.number}</p>}
            </label>
            <input
              type="text"
              value={address.number}
              readOnly={!availableAddressInputs ? true : false}
              onChange={(e) => handleChange("number", e.target.value)}
            />
          </FormDataField>

          <FormDataField>
            <label>Complemento (opcional)</label>
            <input
              type="text"
              value={address.complement}
              readOnly={!availableAddressInputs ? true : false}
              onChange={(e) => handleChange("complement", e.target.value)}
            />
          </FormDataField>
        </FormDataFields>

        <FormDataFields>
          <FormDataField>
            <label>
              Bairro{" "}
              {!!formErrors.neighborhood && <p>{formErrors.neighborhood}</p>}
            </label>
            <input
              type="text"
              value={address.neighborhood}
              readOnly={!availableAddressInputs ? true : false}
              onChange={(e) => handleChange("neighborhood", e.target.value)}
            />
          </FormDataField>

          <FormDataField>
            <label>
              Cidade {!!formErrors.city && <p>{formErrors.city}</p>}
            </label>
            <input
              type="text"
              value={address.city}
              readOnly={!availableAddressInputs ? true : false}
              onChange={(e) => handleChange("city", e.target.value)}
            />
          </FormDataField>

          <FormDataField>
            <label>UF {!!formErrors.uf && <p>{formErrors.uf}</p>}</label>
            <input
              type="text"
              value={address.uf}
              readOnly={!availableAddressInputs ? true : false}
              onChange={(e) => handleChange("uf", e.target.value)}
            />
          </FormDataField>

          <FormDataField>
            <label>
              Andar {!!formErrors.floor && <p>{formErrors.floor}</p>}
            </label>
            <input
              type="text"
              value={address.floor}
              readOnly={false}
              onChange={(e) => handleChange("floor", e.target.value)}
            />
          </FormDataField>
        </FormDataFields>
      </FormDataWrapper>

      {!!"AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc" && (
        <div
          style={{
            height: "300px",
            width: "100%",
            marginTop: "58px",
          }}
        >
          <GoogleMaps
            bootstrapURLKeys={{
              key: "AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc",
            }}
            center={
              !!address.latitude && !!address.longitude
                ? {
                    lat: parseFloat(address.latitude),
                    lng: parseFloat(address.longitude),
                  }
                : {
                    lat: parseFloat("-3.724220"),
                    lng: parseFloat("-38.504030"),
                  }
            }
            defaultZoom={18}
            options={{
              mapTypeControl: true,
            }}
            layerTypes={["TrafficLayer", "TransitLayer"]}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            <Marker
              lat={parseFloat(
                !!address.latitude ? address.latitude : "-3.724220"
              )}
              lng={parseFloat(
                !!address.longitude ? address.longitude : "-38.504030"
              )}
              color="#FF3838"
            />
          </GoogleMaps>
        </div>
      )}

      <ButtonsWrapper>
        <ResetButton
          type="button"
          onClick={() => {
            setAddress((prevValue) => ({
              ...prevValue,
              cep: "",
              city: "",
              complement: "",
              floor: "",
              address: "",
              neighborhood: "",
              number: "",
              uf: "",
            }));
          }}
        >
          LIMPAR
        </ResetButton>

        <NextButton
          type="button"
          onClick={isSubmiting ? () => {} : handleAddressSubmit}
        >
          <p>{isSubmiting ? "Carregando..." : "PRÓXIMO"}</p>
          {!isSubmiting && <FiChevronRight size={18} color="#FFF" />}
        </NextButton>
      </ButtonsWrapper>
    </Form>
  );
}

export default MapForm;
