import { Checkbox, Radio } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import Modal from "react-responsive-modal";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/auth";
import { useConsultant } from "../../hooks/consultant";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";
import {Content,
        TitleContainer,
        ConsultantsAndOptionContainer,
        ConsultantsContainer,
        ConsultantContent,
        OptionContainer,
        ButtonsContainer} from "./styles";

interface IModalPropertiesPerConsultant{
    open: boolean;
    functionToCloseModal(): void;
}

interface IConsultants{
    id: number;
    first_name: string;
}

export default function ModalPropertiesPerConsultant({ open, functionToCloseModal }: IModalPropertiesPerConsultant){
    const { dateToExpires, access_token, user } = useAuth();
    const { addToast } = useToast();

    const { consultantsSaved, handleAddConsultants } = useConsultant();

    const history = useHistory();

    const [users, setUsers] = useState<IConsultants[]>([])

    const [consultant, setConsultant] = useState({} as IConsultants);
    const [onlyActives, setOnlyActives] = useState(false);

    const [loadingShowScreenRequest, setLoadingShowScreenRequest] = useState(false);
    const [loadingExcelRequest, setLoadingExcelRequest] = useState(false);

    useEffect(() => {
        async function loadApi(){
            try{
                if(user?.role === 'admin'){
                    const token = await refreshToken(dateToExpires, access_token);

                    api.defaults.headers.authotization = `Bearer ${!!token ? token : access_token}`;

                    if(consultantsSaved.length < 1){
                        const response = await api.get('/api/adm/users', {
                            params: {
                                role: 'consultant'
                            }
                        });
    
                        handleAddConsultants(response.data.data.consultants);
        
                        setUsers(response.data.data.consultants);
                    } else{
                        setUsers(consultantsSaved);

                        return;
                    }
                }
            } catch(err){
                console.log(err);

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }
            }
        }

        if(!!user){
            loadApi();
        } else{
            history.push('/');
        }
        // eslint-disable-next-line
    }, [consultantsSaved, access_token, dateToExpires, user, history, handleAddConsultants]);

    const handleShowReport = useCallback(async() => {
        setLoadingShowScreenRequest(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/reports/propertyByConsultant', {
                consultant_id: !!consultant.id ? consultant.id : undefined,
                only_active: onlyActives,
                type: 'screen'
            });

            if(!!response.data.success){
                history.push({
                    pathname: '/show-properties-per-consultant-report',
                    state: { data: response.data.data, consultant_name: consultant.first_name }
                });

                functionToCloseModal();
            }
        } catch(err){
            console.log(err);
        }

        setLoadingShowScreenRequest(false);
    }, [dateToExpires, access_token, consultant, onlyActives, history, functionToCloseModal]);

    const handleExcelDownloadReport = useCallback(async() => {
        setLoadingExcelRequest(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/reports/propertyByConsultant', {
                consultant_id: !!consultant.id ? consultant.id : undefined,
                only_active: onlyActives,
                type: 'excel'
            }, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Propriedades por consultor.xlsx');
            link.click();
        } catch(err){
            console.log(err);
        }

        setLoadingExcelRequest(false);
    }, [dateToExpires, access_token, consultant, onlyActives]);

    return(
        <Modal open={open} onClose={functionToCloseModal} classNames={{
            modal: 'modalPropertiesPerConsultant'
        }} closeIcon={(<FiX size={18} color="#000"/>)}>
            <Content>
                <TitleContainer>
                    <h3>IMÓVEIS POR CONSULTOR</h3>
                </TitleContainer>

                <ConsultantsAndOptionContainer>
                    <ConsultantsContainer>
                        {users.map(user => (
                            <ConsultantContent key={user.id}>
                                <Radio name="consultant" checked={consultant === user} onChange={() => setConsultant(user)}/>
                                <p onClick={() => setConsultant(user)}>{user.first_name}</p>
                            </ConsultantContent>
                        ))}
                    </ConsultantsContainer>

                    <OptionContainer>
                        <Checkbox checked={onlyActives} onChange={() => setOnlyActives(prevValue => !prevValue)}/>
                        <p onClick={() => setOnlyActives(prevValue => !prevValue)}>Apenas imóveis ativos?</p>
                    </OptionContainer>
                </ConsultantsAndOptionContainer>

                <ButtonsContainer>
                    <button onClick={!!loadingExcelRequest ? () => {} : handleExcelDownloadReport}>{!!loadingExcelRequest ? 'Carregando...' : "Excel"}</button>
                    <button onClick={!!loadingShowScreenRequest ? () => {} : handleShowReport}>{!!loadingShowScreenRequest ? 'Carregando...' : "Visualizar"}</button>
                </ButtonsContainer>
            </Content>
        </Modal>
    );
}