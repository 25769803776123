import styled, { keyframes } from "styled-components";

interface IRoutesContainerToMobile {
  isOpen: boolean;
}

export const Container = styled.div`
    width: 100%;

    background-color: #FFFFFF;

    border-bottom: 1px solid #66666666;
`;

export const Content = styled.header`
    max-width: 1280px;

    padding: 32px 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 0 auto;

    >a{
        >img{
            height: 30px;
        }
    }

    @media(max-width: 767px){
        max-width: 100%;

        padding: 32px 16px;

        >img{
            height: 30px;
        }
    }
`;

export const OptionButton = styled.button`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    border: 0;

    background-color: transparent;

    margin: 0 8px;

    position: relative;

    >a{
        font-size: 15px;
        color: #666666;
    } 

    >p{
        color: #666666;
    }

    >span{
        font-size: 10px;

        color: #FFF;

        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 20px;
        height: 20px;
        
        border-radius: 50%;

        background: #BF0000;

        margin-left: 8px;
    }

    >svg{
        margin-left: 4px;
    }
`;

export const OptionsToRouteContainer = styled.div`
    position: absolute;

    top: 25px;
    left: 0;

    z-index: 20;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    
    background-color: #FFF;

    border: 1px solid #dee2e6;
    border-radius: 4px;

    transition: all 0.2s;

    >a{
        text-decoration: none;

        color: #666666;

        padding: 12px 45px;
    }

    >a.long-link {
        width: 230px;
        padding: 12px 10px;
    }

    >button{
        text-decoration: none;

        color: #666666;

        width: 230px;

        padding: 12px 0;
    }
`;

export const RoutesContainer = styled.nav`
    margin-left: auto;

    display: flex;
    align-items: center;
    justify-content: center;


    .username{
        color: #6ea5ff;

        font-weight: 500;

        margin: 0 8px;
    }

    >button{
        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: 14px;
    }

    @media(max-width: 767px){
        display: none;
    }
`;

export const MenuButton = styled.button`
    display: none;

    @media(max-width: 767px){
        display: flex;

        margin-left: auto;

        background-color: transparent;
    }
`;

const openMenu = keyframes`
    from{
        height: 0px;
    }

    to{
        height: 100%;
    }
`;

const closeMenu = keyframes`
    from{
        height: 100%;
    }

    to{
        height: 0px;
    }
`;

export const RoutesContainerToMobile = styled.div<IRoutesContainerToMobile>`
    display: none;

    @media(max-width: 767px){
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;

        padding-bottom: 12px;

        animation: ${(props) => (!!props.isOpen ? openMenu : closeMenu)} 100s;

        >a{
            font-size: 15px;
            color: #FFF;

            & + a{
                margin-top: 14px;
            }
        }

        .sign-out-button{
            font-size: 15px;
            color: #FFF;

            margin-top: 14px;

            padding: 10px 12px;

            border: 1px solid #FFF;
            border-radius: 4px;

            background-color: transparent;

            transition: all 0.2s;

            &:hover{
                background-color: #FFF;

                color: #000;
            }
        }
    }
`;
