import styled from "styled-components"

export const Content = styled.form`
    width: 400px;
    @media(max-width: 767px){
        width: 330px;
    }
`;

export const TitleContainer = styled.div`
    width: 100%;
    padding: 24px 0 4px;
    border-bottom: 1px solid #666666;
    >h4{
        color: #666666;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
        text-align: center;
    }
`;

export const InputContainer = styled.div`
    width: 100%;
    background: #F9F9F9;
`;

export const InputContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 24px 32px;
    >p{
        color: #666666;
        font-weight: 600;
        margin-bottom: 8px;
    }
    >input{
        width: 100%;
        border: 0;
        border-bottom: 1px solid #B6B6B6;
        background: transparent;
        margin-bottom: 24px;
        padding: 8px;
    }
`;

export const CreateCategoryButton = styled.button`
    background: #00BF33;
    margin: 0 32px;
    width: 83%;
    padding: 16px 0;
    font-weight: 500;
    color: #FFFFFF;
    @media(max-width: 767px){
        margin: 16px 24px 0;
    }
`;