import styled from 'styled-components';

import backgroundImg from './../../assets/pages/Login/objects.svg';

export const Content = styled.div`
    width: 100%;

    margin: 96px auto 0;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    background: url(${backgroundImg}) no-repeat right 32% / 500px 350px;

    >h2{
        font-size: 22px;
        font-weight: 500;

        color: #dd6435;
    }

    @media(max-width: 767px){
        background: #FFF;
    }
`;

export const LoginContainer = styled.div`
    width: 25%;

    margin-top: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 24px 24px 26px;

    >a{
        text-decoration: none;

        font-size: 15px;
        color: #dd6435;

        margin-top: 24px;
    }

    @media(max-width: 767px){
        width: 85%;
    }
`;

export const LoginForm = styled.form`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    >h3{
        align-self: center;

        font-size: 20px;
        font-weight: 400;

        color: #ff5252;

        margin-bottom: 12px;
    }

    >label{
        font-weight: 400;

        display: flex;
        align-items: center;

        >p{
            font-size: 12px;
            color: #ff5252;

            margin-left: 4px;
        }
    }

    >input{
        width: 100%;
        height: 38px;

        margin: 12px 0;

        padding: 0 12px;

        border: 0;
        border-bottom: 1px solid #dedede;

        background: transparent;
    }

    >button{
        margin-top: 24px;

        width: 100%;

        padding: 10px 0;

        background-color: #6ea5ff;

        border: 0;

        color: #FFF;

        font-weight: 500;
    }

    >span{
        margin-top: 24px;

        width: 100%;

        padding: 10px 0;

        background-color: #FFF;

        border: 0.5px solid #6ea5ff;

        text-align: center;
        color: #6ea5ff;

        font-weight: 500;
    }
`;