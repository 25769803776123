import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

const RoutesIndex: React.FC = () => {
    return(
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
}

export default RoutesIndex;