import styled from 'styled-components';

export const Content = styled.div`
	max-width: 375px;
	margin: 52px auto 12%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	>h2{
		font-size: 20px;
		color: #DD6435;
	}
	a{
		font-size: 14px;
		font-weight: 500;
		color: #DD6435;
	}
	@media(max-width: 767px){
		>h2{
			font-size: 17px;
		}
		>a{
			font-size: 13px;
		}
	}
	@media(min-width: 1441px){
		max-width: 425px;
		margin: 64px auto 12%;
		>h2{
			font-size: 22px;
		}
		a{
			font-size: 16px;
		}
	}
`;