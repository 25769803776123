import styled from 'styled-components';

export const Content = styled.div`
    max-width: 600px;
`;

export const TitleContainer = styled.div`
    padding: 24px 0 24px 24px;

    border-bottom: 0.5px solid #bcbfc2;

    width: 400px;

    text-align: center;

    >h3{
        color: #666666;

        font-weight: 600;
    }

    @media(max-width: 767px){
        padding: 24px 0 24px 24px;

        width: 330px;
    }
`;

export const DataContainer = styled.div`
    width: 90%;

    padding-bottom: 18px;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    margin: 0 auto;

    >h3{
        color: #DD6435;

        font-weight: 500;

        margin: 16px 0 8px;
    }

    >p{
        color: #666666;

        & + p{
            margin-top: 4px;
        }
    }
`;

export const ButtonContainer = styled.div`
    width: 90%;

    margin-top: 12px;

    >button{
        width: 100%;

        background-color: #6EA5FF;

        border: 0;

        padding: 12px;

        margin: 0 5%;

        color: #FFF;

        font-weight: 500;
    }

    @media(max-width: 767px){
        width: 90%;
    }
`;