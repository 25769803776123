import styled from "styled-components";

export const Content = styled.div`
    max-width: 600px;

    >p{
        font-weight: 500;
        color: #666666;

        margin: 16px 0 4px 24px;
    }
`;

export const TitleContainer = styled.div`
    padding: 0 0 16px;

    width: 450px;

    border-bottom: 0.5px solid #bcbfc2;

    text-align: center;

    >h3{
        color: #666666;
        font-weight: bold;
    }

    @media(max-width: 767px){
        width: 300px;

        padding: 24px 0;

        text-align: start;

        >h3{
            font-size: 16px;
        }
    }
`;

export const CitiesContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    width: 450px;

    flex-wrap: wrap;

    padding: 0 0 16px 0;

    @media(max-width: 767px){
        flex-wrap: nowrap;

        flex-direction: column;

        width: 100%;
    }
`;

export const CityContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 225px;

    padding-left: 24px;

    >p{
        margin-left: 8px;

        cursor: pointer;
    }

    @media(max-width: 767px){
        width: 100%;
    }
`;

export const OptionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >p{
        margin-left: 8px;
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    >button{
        width: 100%;
        padding: 8px 0;
        background: #6EA5FF;
        color: #FFF;

        & + button{
            margin-top: 8px;
        }
    }
`;
