import styled from "styled-components";

export const Content = styled.div`
    max-width: 400px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 64px auto 24%;

    >h2{
        font-size: 35px;
        font-weight: bold;

        color: #666666;

        margin-bottom: 24px;

        text-align: center;
    }

    >h3{
        color: #666666;

        margin-left: 32px;
    }

    >div{
        width: 100%;

        background: #DD6435;

        text-align: center;

        color: #FFFFFF;
        font-weight: 500;

        padding: 16px 0;
    }
`;

export const ReportTable = styled.table`
    border-spacing: 0 12px;

    width: 100%;

    thead{
        th{
            background: #6ea5ff;

            text-align: start;

            color: #FFFFFF;
            font-weight: 500;

            width: 190px;

            padding: 16px 0 16px 32px;
        }
    }

    tbody{
        td{
            padding: 16px 32px;

            background: #F9F9F9;

            color: #666666;

            >svg{
                margin-left: 16px;
            }
        }

        #td-total{
            text-align: center;
        }
    }
`;