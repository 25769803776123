import { useCallback, useEffect, useState } from 'react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from 'react-responsive-modal';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import refreshToken from '../../utils/refreshToken';
import ModalPropertiesPerConsultant from '../ModalPropertiesPerConsultant';
import ModalPropertiesPerNeighborhood from '../ModalPropertiesPerNeighborhood';
/* import ModalRentReport from '../ModalRentReport'; */
import ModalVisitsPerConsultants from '../ModalVisitsPerConsultants';
import ModalActivePropertiesPerPeriod from './../ModalActivePropertiesPerPeriod';
import Logo7Cantos from './../../assets/components/Header/logoTopo.svg';

import {Content,
        RoutesOptions,
        Separator,
        OptionsToRouteContainer,
        RouteOption} from './styles';

interface IMenuToNavigate{
    open: boolean;
    functionToCloseModal(): void;
}

export default function MenuToNavigate({ open, functionToCloseModal }: IMenuToNavigate){
    const { user, dateToExpires, access_token, signOut } = useAuth();
    const { addToast } = useToast();

    const history = useHistory();
    
    const [openOptionsToRouteConsultants, setOpenOptionsToRouteConsultants] = useState(false);
    const [openOptionsToRouteProperties, setOpenOptionsToRouteProperties] = useState(false);
    const [openOptionsToReports, setOpenOptionsToReports] = useState(false);
    const [openOptionsToFAQ, setOpenOptionsToFAQ] = useState(false);

    const [openModalPropertiesPerConsultant, setOpenModalPropertiesPerConsultant] = useState(false);
    const [openModalPropertiesPerNeighborhood, setOpenModalPropertiesPerNeighborhood] = useState(false);
    const [openModalVisitsPerConsultant, setOpenModalVisitsPerConsultant] = useState(false);
    /* const [openModalRentReport, setOpenModalRentReport] = useState(false); */
    const [openModalActivePropertiesPerPeriod, setOpenModalActivePropertiesPerPeriod] = useState(false);

    const [offers, setOffers] = useState([]);

    useEffect(() => {
        async function loadApi(){
            try{
                if(user?.role === 'admin'){
                    const token = await refreshToken(dateToExpires, access_token);

                    api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                    const response = await api.get('/api/adm/offers/unread/get');
        
                    setOffers(response.data.data);
                }
            } catch(err){
                console.log(err);

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }
            }
        }

        if(!!user){
            loadApi();
        } else{
            history.push('/')
        }
        // eslint-disable-next-line
    }, [user, history, dateToExpires, access_token]);

    const handleSignOut = useCallback(async() => {
        await signOut();

        history.push('/');
    }, [signOut, history]);

    return(
        <Modal 
            open={open} 
            onClose={functionToCloseModal} 
            closeIcon={(<FiX size={18} color="#000" />)}
            classNames={{
                modal: 'menu'
            }}
        >
            <Content>
                <RoutesOptions>
                    <Link to={user?.role !== 'photographer' ? "/schedules" : '/photographers-schedule'}>
                        <img src={Logo7Cantos} alt="7cantos-logo"/>
                    </Link>

                    {user?.role === 'admin' && (
                        <>
                        <button onClick={() => setOpenOptionsToRouteProperties(true)}>
                            <p>Imóveis</p>

                            <FiChevronDown color="#666666" size={16}/>

                            {!!openOptionsToRouteProperties && (
                                <OutsideClickHandler onOutsideClick={() => setOpenOptionsToRouteProperties(false)}>
                                    <OptionsToRouteContainer>
                                        <Link to="/properties/1">Visualizar</Link>
                                        <Link to="/create-property">Cadastrar</Link>
                                    </OptionsToRouteContainer>
                                </OutsideClickHandler>
                            )}
                        </button>

                        <button onClick={() => setOpenOptionsToReports(true)}>
                            <p>Relatórios</p>

                            <FiChevronDown color="#666666" size={16}/>

                            {!!openOptionsToReports && (
                                <OutsideClickHandler onOutsideClick={() => setOpenOptionsToReports(false)}>
                                    <OptionsToRouteContainer>
                                        <button onClick={() => setOpenModalPropertiesPerConsultant(true)}>Imóveis por consultor</button>
                                        <button onClick={() => setOpenModalPropertiesPerNeighborhood(true)}>Imóveis por bairro</button>
                                        <button onClick={() => setOpenModalVisitsPerConsultant(true)}>Visitas por consultor</button>
                                        {/* <button onClick={() => setOpenModalRentReport(true)}>Fechamentos</button> */}
                                        <button onClick={() => setOpenModalActivePropertiesPerPeriod(true)}>Imóveis Ativos por Período</button>
                                    </OptionsToRouteContainer>
                                </OutsideClickHandler>
                            )}
                        </button>

                        <button onClick={() => setOpenOptionsToFAQ(true)}>
                            <p>FAQs</p>

                            <FiChevronDown color="#666666" size={16}/>

                            {!!openOptionsToFAQ && (
                                <OutsideClickHandler onOutsideClick={() => setOpenOptionsToFAQ(false)}>
                                    <OptionsToRouteContainer>
                                        <Link to="/list-faqs/1">Visualizar</Link>
                                        <Link to="/create-faq">Cadastrar</Link>
                                    </OptionsToRouteContainer>
                                </OutsideClickHandler>
                            )}
                        </button>
                        </>
                    )}          

                    {user?.role !== 'photographer' && (
                        <RouteOption>
                            <Link to='/offers/1'>Propostas</Link>
                            {offers.length >= 1 && (<span>{offers.length}</span>)}
                        </RouteOption>
                    )}

                    {user?.role === 'admin' || user?.role === 'photographer' ? (
                       <RouteOption><Link to="/photographers-schedule">Agenda fotográfos</Link></RouteOption>
                    ) : (<></>)}

                    {user?.role === 'admin' && (
                        <button onClick={() => setOpenOptionsToRouteConsultants(true)}>
                            <p>Usuários</p>

                            <FiChevronDown color="#666666" size={16}/>

                            {!!openOptionsToRouteConsultants && (
                                <OutsideClickHandler onOutsideClick={() => setOpenOptionsToRouteConsultants(false)}>
                                    <OptionsToRouteContainer>
                                        <Link to="/users">Visualizar</Link>
                                        <Link to="/create-user">Cadastrar</Link>
                                    </OptionsToRouteContainer>
                                </OutsideClickHandler>
                            )}
                        </button>
                    )}
                </RoutesOptions>

                <Separator />

                <RoutesOptions>
                    <h4><Link to='/my-account'>Configurações</Link></h4>

                    <button onClick={handleSignOut}>Sair</button>
                </RoutesOptions>
            </Content>

                            
            {!!openModalPropertiesPerConsultant && (
                <ModalPropertiesPerConsultant open={openModalPropertiesPerConsultant} functionToCloseModal={() => setOpenModalPropertiesPerConsultant(false)}/>
            )}
                
            {!!openModalPropertiesPerNeighborhood && (
                <ModalPropertiesPerNeighborhood open={openModalPropertiesPerNeighborhood} functionToCloseModal={() => setOpenModalPropertiesPerNeighborhood(false)}/>
            )}

            {!!openModalVisitsPerConsultant && (
                <ModalVisitsPerConsultants open={openModalVisitsPerConsultant} functionToCloseModal={() => setOpenModalVisitsPerConsultant(false)}/>
            )}

            {openModalActivePropertiesPerPeriod && (
                <ModalActivePropertiesPerPeriod open={openModalActivePropertiesPerPeriod} functionToCloseModal={() => setOpenModalActivePropertiesPerPeriod(false)} />
            )}

            {/* {!!openModalRentReport && (
                <ModalRentReport open={openModalRentReport} functionToCloseModal={() => setOpenModalRentReport(false)}/>
            )} */}
        </Modal>
    );
}