import styled from 'styled-components';


export const Content = styled.div`
	max-width: 380px;
	margin: 9% auto 12%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	>h2{
		font-size: 19px;
		color: #DD6435;
	}
	>p{
		font-size: 12px;
		a{
			font-weight: 500;
			color: #DD6435;
		}
	}
	@media(max-width: 767px){
		>h2{
			font-size: 17px;
		}
		>p{
			font-size: 13px;
		}
	}
	@media(min-width: 1441px){
		max-width: 420px;
		>h2{
			font-size: 22px;
		}
		>p{
			font-size: 14px;
		}
	}
`;