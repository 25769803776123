import { FormEvent, useCallback, useEffect, useState } from "react";
import {
  Container,
  Content,
  FormContainer,
  Document,
  DocumentsContainer,
} from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { FiX } from "react-icons/fi";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { useHistory, useLocation } from "react-router-dom";

interface IState {
  description: string;
  documents: string[];
  id: number;
  name: string;
}

const CreateGuarantee = () => {
  const { user, access_token } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<IState>();
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [enteredDocument, setEnteredDocument] = useState("");
  const [necessaryDocuments, setNecessaryDocuments] = useState<string[]>([]);
  const [isEditScreen, setIsEditScreen] = useState<boolean>(false);

  const [errorNameEmpty, setErrorNameEmpty] = useState(false);
  const [errorDescriptionEmpty, setErrorDescriptionEmpty] = useState(false);
  const [errorNecessaryDocsEmpty, setErrorNecessaryDocsEmpty] = useState(false);

  const [loadingCreatingGuarantee, setLoadingCreatingGuarantee] =
    useState(false);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }
    console.log(state);
    if (!!state) {
      setIsEditScreen(true);
      setName(state.name);
      setDescription(state.description);
      setNecessaryDocuments(state.documents);
    }
  }, [history, state, user]);

  const handleAddDocument = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (necessaryDocuments.some((doc) => doc === enteredDocument)) {
        addToast({
          title: "Tag já adicionada",
          type: "error",
        });
      } else {
        setNecessaryDocuments((prevValue) => [...prevValue, enteredDocument]);
      }

      setEnteredDocument("");
    },
    [enteredDocument, necessaryDocuments, addToast]
  );

  const handleRemoveDocument = useCallback(
    (document: string) => {
      const newArray = necessaryDocuments.filter(
        (docFinded) => docFinded !== document
      );

      setNecessaryDocuments(newArray);
    },
    [necessaryDocuments]
  );

  const handleCreateGuarantee = useCallback(async () => {
    if (!name) {
      console.log(name);
      setErrorDescriptionEmpty(false);
      setErrorNecessaryDocsEmpty(false);
      return setErrorNameEmpty(true);
    }

    if (!description) {
      setErrorNameEmpty(false);
      setErrorNecessaryDocsEmpty(false);
      return setErrorDescriptionEmpty(true);
    }

    if (necessaryDocuments.length === 0) {
      setErrorNameEmpty(false);
      setErrorDescriptionEmpty(false);
      return setErrorNecessaryDocsEmpty(true);
    }

    setLoadingCreatingGuarantee(true);

    setErrorNameEmpty(false);
    setErrorDescriptionEmpty(false);
    setErrorNecessaryDocsEmpty(false);

    api.defaults.headers.authorization = `Bearer ${access_token}`;

    const response = isEditScreen
      ? await api.put(`/api/adm/guarantees/${state.id}`, {
          name: name,
          description: description,
          documents: necessaryDocuments,
          contracts_model: ["default"],
        })
      : await api.post("/api/adm/guarantees", {
          name: name,
          description: description,
          documents: necessaryDocuments,
          contracts_model: ["default"],
        });

    if (!!response.data.success) {

      setName("");
      setDescription("");
      setNecessaryDocuments([]);
      addToast({
        title: isEditScreen
          ? "Garantia editada com sucesso"
          : "Garantia criada com sucesso",
        type: "success",
      });
      if (state) {
        history.push("/show-guarantees");
      }
    }

    setLoadingCreatingGuarantee(false);
  }, [name, necessaryDocuments, description]);

  return (
    <Container>
      <Header />
      <Content>
        <h2>{isEditScreen ? "Editar Garantia" : "Cadastrar Garantia"}</h2>
        <FormContainer>
          <label>
            Nome da garantia:{" "}
            {!!errorNameEmpty && (
              <strong style={{ color: "#FF3838" }}>Nome obrigatório.</strong>
            )}
          </label>
          <input
            value={name}
            placeholder="Um título conciso, que possa identificar a garantia."
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <label>
            Descrição completa:{" "}
            {!!errorDescriptionEmpty && (
              <strong style={{ color: "#FF3838" }}>
                Descrição obrigatória.
              </strong>
            )}
          </label>
          <textarea
            value={description}
            placeholder="Descreva todos os benefícios e faça observações sobre o tipo de garantia."
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <p>*Clique enter para confirmar</p>
          <label>
            Documentação necessária:{" "}
            {!!errorNecessaryDocsEmpty && (
              <strong style={{ color: "#FF3838" }}>
                Necessário pelo menos 1 documento.
              </strong>
            )}
          </label>

          <DocumentsContainer onSubmit={handleAddDocument}>
            <>
              {necessaryDocuments.map((document) => (
                <Document>
                  <p>{document}</p>
                  <button
                    onClick={() => handleRemoveDocument(document)}
                    type="button"
                  >
                    <FiX size={12} color="#FFF" />
                  </button>
                </Document>
              ))}
              <input
                value={enteredDocument}
                onChange={(e) => setEnteredDocument(e.target.value)}
              />
            </>
          </DocumentsContainer>
        </FormContainer>
        <button
          onClick={loadingCreatingGuarantee ? () => {} : handleCreateGuarantee}
          id={loadingCreatingGuarantee ? "wait-btn" : ""}
        >
          {isEditScreen
            ? loadingCreatingGuarantee
              ? "Salvando alterações..."
              : "Salvar alterações"
            : loadingCreatingGuarantee
            ? "Criando garantia..."
            : "Criar Garantia"}
        </button>
      </Content>
      <Footer />
    </Container>
  );
};

export default CreateGuarantee;
