import styled from "styled-components";

export const Content = styled.div`
  max-width: 740px;
  padding-bottom: 3rem;
`;

export const TitleContainer = styled.div`
  border-bottom: 1px solid #bcbfc2;
  display: flex;
  justify-content: center;

  padding: 1.5rem 0;

  width: 400px;
  > h3 {
    color: #666666;
    text-transform: uppercase;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    padding: 1.5rem 1.5rem 1.5rem 0;

    width: 330px;
  }
`;

export const DataContainer = styled.div`
  width: 320px;

  padding-bottom: 8px;

  align-items: flex-start;
  justify-content: center;

  flex-direction: column;

  margin: 0 auto;

  > h3 {
    color: #dd6435;

    font-weight: 600;

    margin: 1.25em 0 0.5em;
  }

  > p {
    color: #666666;

    & + p {
      margin-top: 4px;
    }
  }

  >a {
    text-decoration: underline;
    color: #0219a3;
    cursor: pointer;
    overflow-wrap: break-word;
  }
`;

export const ButtonsContainer = styled.div`
  margin: 2rem 0 0;

  display: flex;
  flex-direction: column;
  
  align-items: center;
  gap: 1rem;

  > button {
    width: 80%;
    padding: 1rem 0;
    color: #fff;
    font-weight: 600;
  }

  .model {
    background-color: #03bf34;
  }

  .upload {
    background-color: #6ea4ff;
  }
`;
