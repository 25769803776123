import styled from "styled-components"

export const Container = styled.div`
    position: absolute;

    right: -180px;
    top: 39px;

    width: 400px;

    z-index: 100;

    border: 1px solid #666666;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    background: #FFFFFF;

    padding: 16px 0 0;

    >h3{
        color: #666666;

        font-weight: 600;

        margin-bottom: 16px;
    }

    @media(max-width: 767px){
        display: none;
    }
`;

export const OfferContainer = styled.div`
    width: 100%;

    position: relative;

    &::before{
		content: '';
		border-style: solid;
		border-color: #666666 transparent;
		border-width: 0 12px 12px 12px;
		top: -68px;
		position: absolute;
		right: -webkit-calc(50% - 35px);
		right: calc(50% - 35px);
		transform: translateX(-50%);
		z-index: 5;
	}
`;

export const OfferContent = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 16px 0;

    border-bottom: 1px solid #666666;

    &:first-child{
        border-top: 1px solid #666666;
    }

    &:last-child{
        border-bottom: 0;
    }

    >a{
        margin-left: 16px;

        color: #666666;
    }
`;