import styled from "styled-components";

export const Content = styled.div`
    max-width: 600px;
`;

export const TitleContainer = styled.div`
    padding: 0 0 16px;
    width: 450px;
    border-bottom: 0.5px solid #bcbfc2;
    text-align: center;

    >h3{
        color: #666666;
        font-weight: bold;
    }

    @media(max-width: 767px){
        width: 300px;
        padding: 24px 0;
        text-align: start;
        >h3{
            font-size: 16px;
        }
    }
`;

export const ConsultantsAndDatesContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 16px 0;

    @media(max-width: 767px){
        flex-direction: column;
    }
`;

export const ConsultantsAndOptionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
    
    width: 100%;

    >p{
        margin-bottom: 8px;

        font-size: 14px;
        font-weight: 500;

        color: #666666;
    }
`;

export const ConsultantsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const DatesContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    >p{
        font-size: 14px;
        font-weight: 400;

        color: #666666;

        margin-bottom: 4px;
    }
    
    >input{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        padding: 4px 8px;

        margin-bottom: 24px;
    }

    @media(max-width: 767px){
        width: 100%;
        margin-top: 24px;

        >input{
            width: 100%;
        }
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    >button{
        width: 100%;
        padding: 8px 0;
        background: #6EA5FF;
        color: #FFF;

        & + button{
            margin-top: 8px;
        }
    }
`;