import React, { useMemo } from 'react';

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import {Content,
        TitleContainer,
        DataContainer,
        ButtonContainer} from './styles';

import { FiX } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

interface IDataToShowInModalConsult{
    id: number;
    owner_name: string;
    keys_location: string;
    date: string;
    photographer_id: string;
    property_address: string;
    property_number: string;
    property_city: string;
    property_uf: string;
    property_neighborhood: string;
    property_complement: string;
    property_unity: string | null;
    owner_phone: string;
}

interface IModalConsult{
    open: boolean;
    functionToClose(): void;
    dataToShow: IDataToShowInModalConsult;
}

const ModalPhotographScheduleConsult: React.FC<IModalConsult> = ({ open, functionToClose, dataToShow }: IModalConsult) => {
    const { user } = useAuth();

    const formatDate = useMemo(() => {
        // const date = new Date(dataToShow.date);
        const dateTime = dataToShow.date.split(" ");
        const date = dateTime[0];
        const time = dateTime[1];

        const separatedDate = date.split("-");
        const separatedTime = time.split(":");
        
        return (`${separatedDate[2].padStart(2, '0')}/${separatedDate[1].padStart(2, '0')}/${separatedDate[0]} às ${separatedTime[0]}:00`);
    }, [dataToShow]);

    return(
        <Modal open={open} onClose={functionToClose} classNames={{
            modal: 'modalConsult'
        }} closeIcon={(<FiX size={18} color="#000"/>)}>
            <Content>
                <TitleContainer>
                    <h3>Consultar Agendamento</h3>
                </TitleContainer>

                <DataContainer>
                    <h3>Dados do Proprietário</h3>

                    <p><b>Nome: </b> {dataToShow.owner_name}</p>
                    <p><b>Telefone: </b> {dataToShow.owner_phone}</p>
                </DataContainer>

                <DataContainer>
                    <h3>Horário e Local</h3>

                    <p><b>Data do agendamento: </b> {formatDate}</p>
                    <p><b>Endereço: </b> <a rel="noreferrer" target="_blank" href={`https://www.google.com.br/maps/place/${dataToShow.property_address}, ${dataToShow.property_neighborhood}, ${dataToShow.property_number}, ${dataToShow.property_city} - ${dataToShow.property_uf}`}>{`${dataToShow.property_address}, ${dataToShow.property_neighborhood}, ${dataToShow.property_number}, ${dataToShow.property_city} - ${dataToShow.property_uf}`}</a></p>
                    {!!dataToShow.property_complement && (<p><b>Complemento: </b>{dataToShow.property_complement}</p>)}
                    <p><b>Local das chaves: </b> {`${dataToShow.keys_location}`}</p>
                </DataContainer>

                {user?.role === "photographer" && (
                    <ButtonContainer>
                        <Link to={{
                            pathname: "/photographer-upload-photos",
                            state: { dataToShow }
                        }}>UPLOAD</Link>
                    </ButtonContainer>
                )}

                <ButtonContainer>
                    <button onClick={functionToClose}>FECHAR</button>
                </ButtonContainer>
            </Content>
        </Modal>
    );
}

export default ModalPhotographScheduleConsult;