import { Radio, Checkbox } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { FiX } from "react-icons/fi";
import Modal from "react-responsive-modal";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";

import * as Yup from 'yup';

import {Content,
        TitleContainer,
        ConsultantsAndOptionContainer,
        ConsultantsContainer,
        ConsultantContent,
        ConsultantsAndDatesContainer,
        DatesContainer,
        ButtonsContainer} from "./styles";
import getValidationErrors from "../../utils/getValidationError";
import { isAfter } from "date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

interface IModalVisitsPerConsultants{
    open: boolean;
    functionToCloseModal(): void;
}

export default function ModalActivePropertiesPerPeriod({ open, functionToCloseModal }: IModalVisitsPerConsultants){
    const { dateToExpires, access_token } = useAuth();
    const { addToast } = useToast();
    const [filterByCity, setFilterByCity] = useState(true);

    const handleChange = (event: any) => {
        setFilterByCity(event.target.filterByCity);
    };

    const locales = useMemo(() => {
        return [
        {
            value: 'Recife',
            label: 'Recife',
        }, 
        {
            value: 'Fortaleza',
            label: 'Fortaleza'
        },
        {
            value: 'Aquiraz',
            label: 'Aquiraz',
        }, 
        {
            value: 'Caucaia',
            label: 'Caucaia'
        },
        {
            value: 'Eusébio',
            label: 'Eusébio',
        }, 
        {
            value: 'Maceió',
            label: 'Maceió'
        },
        {
            value: 'Salvador',
            label: 'Salvador',
        }, 
        {
            value: 'Teresina',
            label: 'Caucaia'
        }
    ]
    }, []);
    const [local, setLocal] = useState('');

    const [initialDate, setInitialDate] = useState<Date>(new Date());
    const [finalDate, setFinalDate] = useState<Date>(new Date());
    const [loadingExcelRequest, setLoadingExcelRequest] = useState(false);

    const handleExcelDownloadReport = useCallback(async() => {
        setLoadingExcelRequest(true);

        const data = {
            local,
            initial_date: initialDate.toLocaleDateString('pt-BR'),
            final_date: finalDate.toLocaleDateString('pt-BR'),
            filter_by_city: filterByCity,
        }

        if(isAfter(new Date(initialDate), new Date(finalDate))){
            addToast({
                title: 'Coloque datas válidas!',
                type: 'error'
            });

            setLoadingExcelRequest(false);

            return;
        }

        try{
            const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

            const schema = Yup.object().shape({
                initial_date: Yup.string().required('Digite uma data inicial!').matches(dateRegex, 'Coloque uma data válida!'),
                final_date: Yup.string().required('Digite uma data final!').matches(dateRegex, 'Coloque uma data válida!')
            });

            await schema.validate(data, {
                abortEarly: false
            });

            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/reports/activatePropertiesByDate', {
                local,
                initial_date: `${data.initial_date.substring(6, 10)}-${data.initial_date.substring(3, 5)}-${data.initial_date.substring(0, 2)}`,
                final_date: `${data.final_date.substring(6, 10)}-${data.final_date.substring(3, 5)}-${data.final_date.substring(0, 2)}`,
                filter_by_city: filterByCity
            }, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Imóveis Ativos por Período.xlsx');
            link.click();
        } catch(err){
            if(err instanceof Yup.ValidationError){
                const error = getValidationErrors(err); 

                addToast({
                    title: error[Object.keys(error)[0]],
                    type: 'info',
                    description: ''
                });
            }

            else if(!!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]){
                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            };

           if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }

            console.log(err);
        }

        setLoadingExcelRequest(false);
    }, [dateToExpires, access_token, local, initialDate, finalDate, filterByCity, addToast]);

    return(
        <Modal open={open} onClose={functionToCloseModal} classNames={{
            modal: 'modalVisitsPerConsultant'
        }} closeIcon={(<FiX size={18} color="#000"/>)}>
            <Content>
                <TitleContainer>
                    <h3>IMÓVEIS ATIVOS POR PERÍODO</h3>
                </TitleContainer>

                <ConsultantsAndDatesContainer>
                    <ConsultantsAndOptionContainer>
                        <p>Região: </p>
                        <ConsultantsContainer>
                            {locales.map(localFinded => (
                                <ConsultantContent key={localFinded.value}>
                                    <Checkbox checked={filterByCity} onChange={handleChange}/>
                                    <p onChange={handleChange}>{localFinded.label}</p>
                                </ConsultantContent>
                            ))}
                        </ConsultantsContainer>
                    </ConsultantsAndOptionContainer>

                    <DatesContainer>
                        <p>Data inicial:</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <KeyboardDatePicker 
                                value={initialDate}
                                onChange={e => setInitialDate(e as Date)}
                                format="dd/MM/yyyy"
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>

                        <p>Data final:</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <KeyboardDatePicker 
                                value={finalDate}
                                onChange={e => setFinalDate(e as Date)}
                                format="dd/MM/yyyy"
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </DatesContainer>
                </ConsultantsAndDatesContainer>
                <ButtonsContainer>
                    <button onClick={!!loadingExcelRequest ? () => {} : handleExcelDownloadReport}>{!!loadingExcelRequest ? 'Carregando...' : "Excel"}</button>
                </ButtonsContainer>
            </Content>
        </Modal>
    );
}