import {Container, Content} from './styles'

import logoImg from './../../assets/components/Footer/7cantos.png'

export default function Footer(){
    return(
        <Container>
            <Content>
                <img src={logoImg} alt="logo"/>

                <p>© 7Cantos Imobiliária Ltda. Todos os direitos reservados. CRECI 1284-J.</p>
            </Content>
        </Container>
    );
}