import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;    

    > h2{
        font-weight: 700;
        font-size: 2rem;
        margin-top: 3rem;
        color: #666666
    }
`;

export const GuaranteesTable = styled.div`
    
    margin: 3rem 3rem 0;
    min-width: 70%;
    display: grid;
    grid-template-columns: 20% 60% 10% 10%;
    grid-auto-rows: minmax(2.75rem, auto);
    grid-row-gap: 0.75rem;

    .header {
        background: #6ea4ff;
        color: #FFFFFF;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: start;

    }

    .first-on-row {
        padding-left: 1.75rem
    }

    .header.delete-header {
        
        justify-content: center;
    }
    .description-col {
        display: flex;
        justify-content: center;
    }

    .row {
        display: flex;
        align-items: center;
        background: #f9f9f9;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    
    .status-text {
        text-transform: uppercase;
        color: #0219a3;
        font-weight: 700;
    }

    .row.create-file-icon {
        justify-content: center;

        p{
            color: #0219a3;
        }

        svg {
            cursor:pointer;
        }
    }
`;

export const TableChangeContainer = styled.div`
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    gap: 1%;
    font-weight: 700;
    color: #b6b6b6;
    font-size: 1rem;

    svg, span{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1.75rem;
        min-height: 1.75rem;
    }
    svg:not(.disable):hover, span:hover, #current-page{
        cursor: pointer;
        color: #6ea4ff;
        transition: .15s linear;
        background: #f9f9f9;
        border-radius: 1.5rem;
    }
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 75vh;

  > h2 {
    width: 60%;

    text-align: center;

    color: #ff5252;
  }
`;
