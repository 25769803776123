import { useState } from "react";
import StepIndex from "./StepIndex";
import {
  BackBtn,
  ButtonsWrapper,
  ForwardBtn,
  IndexesWrapper,
  LastBtn,
  Wrapper,
} from "./styles";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useToast } from "../../hooks/toast";

interface IArrayObj {
  content: any[];
  stepTitle: string;
}

interface IProps {
  [x: string]: any;
  elements: IArrayObj[];
  onChange: (
    stepIndex: number,
    fieldIndex: number,
    value: any,
    listMemberIndex?: number
  ) => void;
  onFirstStepGoBack: () => void;
  onLastStepGoForward: (parameter: any) => void;
}

function Stepper({
  ContentWrapper,
  elements = [],
  onChange,
  onFirstStepGoBack,
  onLastStepGoForward,
}: IProps) {
  const { addToast } = useToast();
  const [stepStatus, setStepStatus] = useState({
    current: 0,
    further: 0,
  });

  const currentPage = elements[stepStatus.current].content;

  function isValid() {
    for (const key in currentPage) {
      let value;

      if (Array.isArray(currentPage[key])) {
        for (const innerArrKey in currentPage[key]) {
          value = currentPage[key][innerArrKey].value;
          if (value === 0 || value === "") return false;
        }
      } else {
        value = currentPage[key].value;
        if (value === 0 || value === "") return false;
      }
    }

    return true;
  }

  function handleChange(
    fieldIndex: number,
    value: any,
    listMemberIndex?: number
  ) {
    onChange(stepStatus.current, fieldIndex, value, listMemberIndex);
  }

  function handleGoForward() {
    if (!isValid()) {
      addToast({
        title: "Por favor preencha todos os campos!",
        type: "error",
      });
      return;
    }

    setStepStatus((prevStatus) => {
      const current = prevStatus.current + 1;
      const further = Math.max(current, prevStatus.further);
      return { ...prevStatus, current, further };
    });
  }

  function handleGoBackward() {
    setStepStatus((prevStatus) => {
      const current = prevStatus.current - 1;
      return { ...prevStatus, current };
    });
  }

  function handleStepClick(index: number) {
    setStepStatus((prevStatus) => {
      const current = index <= prevStatus.further ? index : prevStatus.current;
      return { ...prevStatus, current };
    });
  }

  function handleLastStepGoForward() {
    if (!isValid()) {
      addToast({
        title: "Por favor preencha todos os campos!",
        type: "error",
      });
      return;
    }

    onLastStepGoForward(currentPage);
  }

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    handleLastStepGoForward();
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <IndexesWrapper>
        {elements.map((element, index) => (
          <StepIndex
            key={element.stepTitle}
            isSelected={index === stepStatus.current}
            isUnlocked={index <= stepStatus.further}
            label={element.stepTitle}
            onClick={() => handleStepClick(index)}
          >
            {index + 1}
          </StepIndex>
        ))}
      </IndexesWrapper>
      <ContentWrapper onChange={handleChange} index={stepStatus.current}>
        {currentPage}
      </ContentWrapper>
      <ButtonsWrapper>
        <BackBtn
          type="button"
          onClick={
            stepStatus.current === 0 ? onFirstStepGoBack : handleGoBackward
          }
        >
          <MdChevronLeft /> Voltar
        </BackBtn>
        {stepStatus.current === elements.length - 1 ? (
          <LastBtn type="submit">Enviar</LastBtn>
        ) : (
          <ForwardBtn type="button" onClick={handleGoForward}>
            Próximo <MdChevronRight />
          </ForwardBtn>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
}

export default Stepper;
