import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    margin-top: 3rem;
    gap: 4rem;
    position: relative;

    > #first-step {
        width: 0%;
    }
    > #second-step {
        width: 33%;
    }
    > #third-step {
        width: 66%;
    }
    > #fourth-step {
        width: 97%;
    }
`;

export const GrayLineContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 1.76rem;
    z-index: 1;
    border: 1px #DEDEDE solid;
`

export const BlueLineContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 1.76rem;
    z-index: 1;
    border: 1px #6EA6FF solid;
`

