import { Radio } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import Modal from "react-responsive-modal";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";
import {Content,
        TitleContainer,
        CitiesContainer,
        CityContent,
        ButtonsContainer} from "./styles";

interface IModalPropertiesPerNeighborhood{
    open: boolean;
    functionToCloseModal(): void;
}

interface ICities{
    city: string;
    uf: string;
}

export default function ModalPropertiesPerNeighborhood({ open, functionToCloseModal }: IModalPropertiesPerNeighborhood){
    const { dateToExpires, access_token, user } = useAuth();
    const { addToast } = useToast();

    const history = useHistory();

    const [cities, setCities] = useState<ICities[]>([])

    const [city, setCity] = useState('');

    const [loadingShowScreenRequest, setLoadingShowScreenRequest] = useState(false);
    const [loadingExcelRequest, setLoadingExcelRequest] = useState(false);

    useEffect(() => {
        async function loadApi(){
            try{
                if(user?.role === 'admin'){
                    const token = await refreshToken(dateToExpires, access_token);

                    api.defaults.headers.authotization = `Bearer ${!!token ? token : access_token}`;
    
                    const response = await api.get('/api/adm/addresses/cities/get');
    
                    setCities(response.data.data);
                }
            } catch(err){
                console.log(err);

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }
            }
        }

        if(!!user){
            loadApi();
        } else{
            history.push('/');
        }
        // eslint-disable-next-line
    }, [access_token, dateToExpires, user, history]);

    const handleShowReport = useCallback(async() => {
        setLoadingShowScreenRequest(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/reports/propertyByNeighborhood', {
                city: city,
                type: 'screen'
            });

            if(!!response.data.success){
                history.push({
                    pathname: '/show-properties-per-neighborhood-report',
                    state: { data: response.data.data, city }
                });

                functionToCloseModal();
            }
        } catch(err){
            console.log(err);
        }

        setLoadingShowScreenRequest(false);
    }, [dateToExpires, access_token, city, history, functionToCloseModal]);

    const handleExcelDownloadReport = useCallback(async() => {
        setLoadingExcelRequest(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/reports/propertyByNeighborhood', {
                city: city,
                type: 'excel'
            }, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Imóveis por bairro.xlsx');
            link.click();
        } catch(err){
            console.log(err);
        }

        setLoadingExcelRequest(false);
    }, [dateToExpires, access_token, city]);

    return(
        <Modal open={open} onClose={functionToCloseModal} classNames={{
            modal: 'modalPropertiesPerConsultant'
        }} closeIcon={(<FiX size={18} color="#000"/>)}>
            <Content>
                <TitleContainer>
                    <h3>IMÓVEIS POR BAIRRO</h3>
                </TitleContainer>

                <p>Cidade:</p>
                <CitiesContainer>
                    {cities.map((cityFinded, index) => (
                        <CityContent key={index}>
                            <Radio checked={city === cityFinded.city} name="consultant" onChange={() => setCity(cityFinded.city)}/>
                            <p onClick={() => setCity(cityFinded.city)}>{cityFinded.city}</p>
                        </CityContent>
                    ))}
                </CitiesContainer>

                <ButtonsContainer>
                    <button onClick={!!loadingExcelRequest ? () => {} : handleExcelDownloadReport}>{!!loadingExcelRequest ? 'Carregando...' : "Excel"}</button>
                    <button onClick={!!loadingShowScreenRequest ? () => {} : handleShowReport}>{!!loadingShowScreenRequest ? 'Carregando...' : "Visualizar"}</button>
                </ButtonsContainer>
            </Content>
        </Modal>
    );
}