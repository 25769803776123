import FormOption from "./Enum";
import {
  FormOptionAddress,
  FormOptionCharacteristics,
  FormOptionDescription,
  FormOptionPhotos,
} from "./styles";
export const FORM_OPTIONS = [
  { formOption: FormOption.Map, title: "ENDEREÇO", Element: FormOptionAddress },
  {
    formOption: FormOption.Description,
    title: "DESCRIÇÃO",
    Element: FormOptionDescription,
  },
  {
    formOption: FormOption.Characteristic,
    title: "CARATERÍSTICAS",
    Element: FormOptionCharacteristics,
  },
  { formOption: FormOption.Photos, title: "FOTOS", Element: FormOptionPhotos },
];
