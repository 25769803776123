import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Layout from "../../components/Layout";
import { Content, ReportTable } from "./styles";

interface IApiResponse{
    neighborhood: string;
    total: number;
}

interface IState{
    data: IApiResponse[];
    city: string;
}

export default function ShowPropertiesPerNeighborhoodReport(){
    const history = useHistory();

    const { state } = useLocation<IState>();

    useEffect(() => {
        if(!state){
            history.goBack();
        }
    }, [state, history]);

    return(
        <Layout>
            <Content>
                <h2>Imóveis por bairro</h2>

                <div>{state.city}</div>

                <ReportTable>
                    <thead>
                        <tr>
                            <th>Bairro</th>
                            <th>Total de imóveis</th>
                        </tr>
                    </thead>

                    <tbody>
                        {state.data.map((data, index) => (
                            <tr key={index}>
                                <td>{data.neighborhood}</td>
                                <td id="td-total">{data.total}</td>
                            </tr>
                        ))}
                    </tbody>
                </ReportTable>
            </Content>
        </Layout>
    );
}