import SignersList from "../../SignersList";
import {
  GridContainer,
  Form,
  FormButton,
} from "../GeneralStyles/styles";

const TenantRegister: React.FC = () => {
  return (
    <>
      <Form>
        <label>Nome do inquilino:</label>
        <input />
        <GridContainer>
          <label>CPF:</label>
          <label>Nacionalidade:</label>
          <input />
          <select></select>
          <label>Ocupação:</label>
          <label>Estado civil:</label>
          <input />
          <select></select>
        </GridContainer>
        <label>Endereço:</label>
        <input />
        <GridContainer>
          <label>Número:</label>
          <label>Complemento:</label>
          <input />
          <input />
          <label>CEP:</label>
          <label>Bairro:</label>
          <input />
          <input />
          <label>Cidade:</label>
          <label>Estado:</label>
          <input />
          <input />
        </GridContainer>
        <FormButton>Adicionar signatário</FormButton>
      </Form>
      {/* <SignersList /> */}
    </>
  );
};

export default TenantRegister;
