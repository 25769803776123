enum ContentType {
  Boolean = "boolean",
  String = "string",
  Array = "array",
  Integer = "integer",
  Currency = "currency",
  Float = "float",
}

export default ContentType;
