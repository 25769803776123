import React, { FormEvent, useCallback, useState } from 'react';

import { Form } from './styles';

import * as Yup from 'yup';

import getValidationErrors from './../../utils/getValidationError';

import api from './../../services/api';
import { useLocation } from 'react-router';

interface IValidationError{
	email?: string;
}

interface IState{
	user_email: string;
}

const FormToForgotPasswordPage: React.FC = () => {
    const { state } = useLocation<IState>();

	const [email, setEmail] = useState(() => {
		if(!!state){
			return(state.user_email);
		}

		return('');
	});

	const [loading, setLoading] = useState(false);

	const [validationError, setValidationError] = useState({} as IValidationError);
	const [apiError, setApiError] = useState('');

	const [success, setSuccess] = useState('');

    const handleSubmit = useCallback(async(e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setLoading(true);

		try{
			const data = {email};

			const schema = Yup.object().shape({
				email: Yup.string().email('E-mail inválido').required('E-mail obrigatório')
			});

			await schema.validate(data, {
				abortEarly: false
			});

			const response = await api.post('/api/auth/forgot', {
				email: data.email
			});

			if(response.status === 200){
				setSuccess(response.data.message);
			};

			setLoading(false);
			setValidationError({});
			setApiError('');
		} catch(err){
			if(err instanceof Yup.ValidationError){
				const errors = getValidationErrors(err);

				setApiError('');
				setValidationError(errors);
			} else{
				setValidationError({});
				setApiError('E-mail incorreto');
			}

			setLoading(false);
		}
	}, [email]);

    return(
        <Form onSubmit={handleSubmit}>
			<p>E-mail: {!!validationError && (<span>{validationError.email}</span>)}{!!apiError && (<span>{apiError}</span>)}</p>
			<input 
				type="text"
				name="email"
				value={email}
				onChange={e => setEmail(e.target.value)}
			/>

			{!!loading && (<strong>Carregando...</strong>)}

			{!loading && (
				<>
					{!!success ? (<label>{success}</label>) : (<button type="submit">ENVIAR LINK DE RECUPERAÇÃO</button>)}
				</>
			)}
		</Form>
    );
}

export default FormToForgotPasswordPage;