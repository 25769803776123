import styled from "styled-components";

interface IInputContainer{
    thereIsAnError?: boolean;
}

export const Form = styled.form`
    
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;

    >span{
        color: #FF3838;
        font-weight: bold;
        margin-bottom: 8px;
        margin-left: 8px;
        @media(max-width: 767px){
            font-size: 12px;
        }
    }
    @media(max-width: 767px){
        margin-top: 16px;
        flex-direction: column;
    }
    >button{
        margin-left: 8px;
    }
`;

export const InputContainer = styled.div<IInputContainer>`
	border: 1px solid ${props => !!props.thereIsAnError ? "#FF3838" : "#B6B6B6"};
	padding: 4px 16px 4px 0;
	display:flex;
	align-items: center;
	justify-content: center;

    margin-left: 8px;

    

    @media(max-width: 767px){
        padding: 10px;
        margin-left: 0px;
        margin-bottom: 8px;

        input{
            width: 250px;
            background: #FFF;
            border: 0;
            font-size: 18px;
            padding-right: 14px;
            font-size: 14px;
            &::placeholder{
                font-size: 14px;
            }
        }
    }
	@media(min-width: 768px){
        input{
            width: 100%;
            background: #FFF;
            border: 0;
            font-size: 14px;
            margin-left: 15px;
            &::placeholder{
                font-size: 15px;
            }
        }
    }
    @media(min-width: 1441px){
        padding: 6px 16px 6px 0;
        input{
            // width: 250px;
            font-size: 16px;
            &::placeholder{
                font-size: 16px;
            }
        }
    }
`;

export const AddressesOptions = styled.div`
    overflow: scroll; 
    background: #eeeeee;

    position: absolute;

    top: 25;
    left: 0;

    margin-left: 8px;

    width: 100%;

    @media(max-width: 767px){
        margin-left: 0;
    }
`;

export const AddressesOptionsContent = styled.div`
    @media(max-width: 767px){
        padding: 12px 16px;
        width: 294px;
        height: 200px;
    }
    @media(min-width: 768px){
        padding: 12px 32px;
        width: 478px;
        height: 200px;
    }
    @media(min-width: 1441px){
        width: 100%;
        height: 200px;
    }
`;

export const NeighborhoodsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    >h3{
        font-size: 16px;
        font-weight: 500;

        color: #666666;
    }
    >a{
        margin-top: 8px;
        margin-left: 12px;
        border: 0;
        background: transparent;

        font-size: 12px;
        >p{
            font-size: 14px;

            color: #666666;
        }
        & + button{
            margin-top: 16px;
        }
        @media(max-width: 767px){
            text-align: initial;
            font-size: 14px;

            padding: 0 0 4px;
        }
        @media(min-width: 1441px){
            >p{
                font-size: 16px;
            }
        }
    }
    @media(min-width: 1441px){
        >h3{
            font-size: 18px;
        }
    }
`;