import styled from "styled-components";

export const Content = styled.div`
    max-width: 600px;
`;

export const TitleContainer = styled.div`
    padding: 0 0 16px;
    width: 350px;
    border-bottom: 0.5px solid #bcbfc2;
    text-align: center;

    >h3{
        color: #666666;
        font-weight: bold;
    }

    @media(max-width: 767px){
        width: 300px;
        padding: 24px 0;
        text-align: start;
        >h3{
            font-size: 16px;
        }
    }
`;

export const ConsultantsAndOptionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    
    width: 100%;
    padding: 16px 0;
`;

export const ConsultantsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

export const ConsultantContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >p{
        margin-left: 8px;

        font-size: 14px;

        cursor: pointer;
    }
    & + div{
        margin-top: 4px;
    }
`;

export const OptionContainer = styled.div`
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 16px 0;

    background: #F9F9F9;

    width: 100%;

    >p{
        margin-left: 8px;
        font-size: 14px;

        cursor: pointer;
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    >button{
        width: 100%;
        padding: 8px 0;
        background: #6EA5FF;
        color: #FFF;

        & + button{
            margin-top: 8px;
        }
    }
`;