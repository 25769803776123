import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;

    > span {
        font-weight: 600;
    font-size: 1rem;
    color: #606060;
    margin-top: 2rem;
    }

    > label#add-file-lbl {
        font-weight: 600;
        color: #B6B6B6;
        min-width: 30%; 
        border-radius: 2rem;
        font-size: 1rem;
        padding: 0.75em 0;
        border: 2px solid #B6B6B6;
        margin: 2.5rem 0 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5%;
        cursor: pointer;
    }

    > label#file-name-lbl {
        background: #F5F5F5;
        padding: 0.75rem 1.5rem; 
        min-width: 40%;
        > span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            >h6 {
                cursor: pointer;
                font-size: 1rem;
                color: #3241C1;
                font-weight: 600;
            }
            >h6:hover {
                opacity: 0.7;
                transition: linear 0.1s;
            }

            >svg {
                cursor: pointer;
            }
        }
    }

    input[type=file] {
        display: none;
    }
`;
