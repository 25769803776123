import styled from 'styled-components';

export const Content = styled.div`
    width: 20%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 24px 24px 0;

    cursor: all-scroll;

    >img{
        width: 100%;
        height: 147px;
    }


    @media(max-width: 767px){
        width: 38%;

        margin: 24px auto 0;

        >img{
            height: 80px;
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;

    >a {
        width: 50%;
        background: #00bf33;
        font-size: 0.9rem;
        color: #FFF;
        font-weight: 500;
        text-align: center;
        vertical-align: middle;
        padding: 8px 0;
    }
    >button{
        width: 50%;

        padding: 8px 0;

        border: 0;

        background-color: #E00000;

        color: #FFF;

        font-size: 0.9rem;
        font-weight: 500;
}
`