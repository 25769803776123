import styled from "styled-components";

export const ContentHolder = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 3rem;
    margin-bottom: 2.5rem;
`;

export const InfoTitle = styled.h2`
    text-transform: uppercase;
    font-size: 1.15rem;
    font-weight: 700;
    color: #606060;
`;

export const TripleGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 29% 29% 29%;
    column-gap: 6.5%;
`;

export const RadioContainer = styled.div`
    padding-top: 2rem;
    display: flex;
    align-items: center;

    label.radio-lbl {
        color: #606060;
        font-size: 0.9rem;
        margin: 0 1em 0 0.5em;
        font-weight: 400;
    }

    input {
        margin-top: 0;
    }
`
