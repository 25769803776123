import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import Modal from "react-responsive-modal";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";

import * as Yup from "yup";

import {
  Content,
  TitleContainer,
  ConsultantsAndOptionContainer,
  ConsultantsContainer,
  ConsultantsAndDatesContainer,
  DatesContainer,
  ButtonsContainer,
} from "./styles";
import getValidationErrors from "../../utils/getValidationError";
import { isAfter } from "date-fns";
import { useConsultant } from "../../hooks/consultant";

interface IModalVisitsPerConsultants {
  open: boolean;
  functionToCloseModal(): void;
}

interface IConsultant {
  id: number;
  first_name: string;
}

function getCurrentDate(date: Date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDay().toString().padStart(2, "0")}`;
}

function hasConsultant(
  consultant: IConsultant,
  consultantsList: IConsultant[]
) {
  return !!consultantsList.find((element) => element.id === consultant.id);
}

export default function ModalVisitsPerConsultants({
  open,
  functionToCloseModal,
}: IModalVisitsPerConsultants) {
  const history = useHistory();

  const { user, dateToExpires, access_token } = useAuth();
  const { addToast } = useToast();
  const { consultantsSaved, handleAddConsultants } = useConsultant();

  const [consultants, setConsultants] = useState<IConsultant[]>([]);

  const [initialDate, setInitialDate] = useState(getCurrentDate(new Date()));
  const [finalDate, setFinalDate] = useState(getCurrentDate(new Date()));

  const [loadingShowScreenRequest, setLoadingShowScreenRequest] =
    useState(false);
  const [loadingExcelRequest, setLoadingExcelRequest] = useState(false);

  const [users, setUsers] = useState<IConsultant[]>([]);

  useEffect(() => {
    async function loadApi() {
      try {
        if (user?.role === "admin") {
          const token = await refreshToken(dateToExpires, access_token);

          api.defaults.headers.authotization = `Bearer ${
            !!token ? token : access_token
          }`;

          if (consultantsSaved.length < 1) {
            const response = await api.get("/api/adm/users", {
              params: {
                role: "consultant",
              },
            });

            handleAddConsultants(response.data.data.consultants);

            setUsers(response.data.data.consultants);
          } else {
            setUsers(consultantsSaved);

            return;
          }
        }
      } catch (err) {
        console.log(err);

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [
    consultantsSaved,
    access_token,
    dateToExpires,
    user,
    history,
    handleAddConsultants,
  ]);

  async function handleShowReport() {
    setLoadingShowScreenRequest(true);

    const data = {
      consultant_id: consultants.map((element) =>
        element.id ? element.id.toString() : ""
      ),
      initial_date: initialDate,
      final_date: finalDate,
    };

    if (isAfter(new Date(initialDate), new Date(finalDate))) {
      addToast({
        title: "Coloque datas válidas!",
        type: "error",
      });

      setLoadingExcelRequest(false);

      return;
    }

    try {
      const dateRegex = /^\d{4}-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/;

      const schema = Yup.object().shape({
        consultant_id: Yup.array().min(1, "Selecione um consultor!"),
        initial_date: Yup.string()
          .required("Digite uma data inicial!")
          .matches(dateRegex, "Coloque uma data válida!"),
        final_date: Yup.string()
          .required("Digite uma data final!")
          .matches(dateRegex, "Coloque uma data válida!"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post(
        "/api/adm/reports/schedulesByConsultant",
        {
          ...data,
          type: "screen",
        }
      );

      if (!!response.data.success) {
        history.push({
          pathname: "/show-visits-per-consultant-report",
          state: {
            data: response.data.data,
            dates: [initialDate, finalDate],
          },
        });

        functionToCloseModal();
      }
    } catch (err) {
      console.log(err);
    }

    setLoadingShowScreenRequest(false);
  };

  async function handleExcelDownloadReport() {
    setLoadingExcelRequest(true);

    const data = {
      consultant_id: consultants.map((element) =>
        element.id ? element.id.toString() : ""
      ),
      initial_date: initialDate,
      final_date: finalDate,
    };

    if (isAfter(new Date(initialDate), new Date(finalDate))) {
      addToast({
        title: "Coloque datas válidas!",
        type: "error",
      });

      setLoadingExcelRequest(false);

      return;
    }

    try {
      const dateRegex = /^\d{4}-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/;
      const schema = Yup.object().shape({
        consultant_id: Yup.array().min(1, "Selecione um consultor!"),
        initial_date: Yup.string()
          .required("Digite uma data inicial!")
          .matches(dateRegex, "Coloque uma data válida!"),
        final_date: Yup.string()
          .required("Digite uma data final!")
          .matches(dateRegex, "Coloque uma data válida!"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post(
        "/api/adm/reports/schedulesByConsultant",
        {
          ...data,
          type: "excel",
        },
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Visitas por consultor.xlsx");
      link.click();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationErrors(err);

        addToast({
          title: error[Object.keys(error)[0]],
          type: "info",
          description: "",
        });
      } else if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      console.log(err);
    }

    setLoadingExcelRequest(false);
  };

  function handleChange(
    event: ChangeEvent<HTMLInputElement>,
    consultant: IConsultant
  ) {
    hasConsultant(consultant, consultants)
      ? setConsultants((prevConsultants) =>
          prevConsultants.filter((element) => element.id !== consultant.id)
        )
      : setConsultants((prevConsultants) => [...prevConsultants, consultant]);
  }

  return (
    <Modal
      open={open}
      onClose={functionToCloseModal}
      classNames={{
        modal: "modalVisitsPerConsultant",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h3>VISITAS POR CONSULTOR</h3>
        </TitleContainer>

        <ConsultantsAndDatesContainer>
          <ConsultantsAndOptionContainer>
            <p>Consultores: </p>
            <ConsultantsContainer>
              <FormGroup>
                {users.map((user) => (
                  <FormControlLabel
                  style={{margin: '0 0 0.25rem 0'}}
                    control={
                      <Checkbox
                        key={user.id}
                        style={{padding: '0 0.2rem 0 0'}}
                        checked={hasConsultant(user, consultants)}
                        onChange={(event) => handleChange(event, user)}
                        name="consultant"
                        color="secondary"
                      />
                    }
                    label={user.first_name}
                  />
                ))}
              </FormGroup>
            </ConsultantsContainer>
          </ConsultantsAndOptionContainer>

          <DatesContainer>
            <p>Data inicial:</p>
            <TextField
              id="date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={initialDate}
              onChange={(event) => setInitialDate(event.target.value)}
            />

            <p>Data final:</p>
            <TextField
              id="date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={finalDate}
              onChange={(event) => setFinalDate(event.target.value)}
            />
          </DatesContainer>
        </ConsultantsAndDatesContainer>

        <ButtonsContainer>
          <button
            onClick={
              !!loadingExcelRequest ? () => {} : handleExcelDownloadReport
            }
          >
            {!!loadingExcelRequest ? "Carregando..." : "Excel"}
          </button>
          <button
            onClick={!!loadingShowScreenRequest ? () => {} : handleShowReport}
          >
            {!!loadingShowScreenRequest ? "Carregando..." : "Visualizar"}
          </button>
        </ButtonsContainer>
      </Content>
    </Modal>
  );
}
