import React, { DragEvent, useState } from 'react';
import ModalConfirmRemovePhoto from '../ModalConfirmRemovePhoto';

import {ButtonsContainer, Content} from './styles';

interface IPhotos{
    id: number;
    url_m: string;
    url_l: string;
}

interface IImageBox{
    photo: IPhotos;
    boxNumber: number;
    isLoading?: boolean;
    handleDrag: (ev: DragEvent<HTMLDivElement>) => void;
    handleDrop: (ev: DragEvent<HTMLDivElement>) => void;
    handleRemovePhoto(): void;
}

const ImageBox: React.FC<IImageBox> = ({ photo, boxNumber, isLoading, handleDrag, handleDrop, handleRemovePhoto }: IImageBox) => {
    const [openModalConfirmRemovePhoto, setOpenModalConfirmRemovePhoto] = useState(false);
    
    return(
        <Content key={photo.id} draggable={true} id={String(boxNumber)} onDragOver={(ev) => ev.preventDefault()} onDragStart={handleDrag} onDrop={handleDrop}>
            <img src={photo.url_m} alt="PropertyImg"/>
            <ButtonsContainer>
                <a href={photo.url_l} target='_blank'>BAIXAR</a>
                <button type="button" onClick={() => setOpenModalConfirmRemovePhoto(true)}>REMOVER</button>
            </ButtonsContainer>

            <ModalConfirmRemovePhoto 
                open={openModalConfirmRemovePhoto} 
                isLoading={!!isLoading}
                functionToCloseModal={() => setOpenModalConfirmRemovePhoto(false)}
                functionToRemovePhoto={handleRemovePhoto}
            />
        </Content>  
    );
}

export default ImageBox;