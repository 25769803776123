import styled from "styled-components";

export const Content = styled.div`
    width: 400px;

    @media(max-width: 767px){
        width: 330px;
    }
`;

export const TitleContainer = styled.div`
    width: 100%;

    padding: 24px 0 4px;

    border-bottom: 1px solid #666666;

    >h4{
        color: #666666;

        font-size: 20px;
        font-weight: 600;

        margin-bottom: 16px;

        text-align: center;
    }
`;

export const OptionsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    padding: 0 32px;

    margin: 32px 0 16px;

    >p{
        color: #666666;

        font-weight: 600;

        margin-bottom: 8px;
    }
`;

export const RadioContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >p{
        margin-left: 8px;

        color: #666666;
    }

    & + div{
        margin-top: 8px;
    }
`;

export const SelectContainer = styled.div`
    width: 100%;

    background: #F9F9F9;
`;

export const SelectContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    padding: 24px 32px;

    >p{
        color: #666666;

        font-weight: 600;

        margin-bottom: 8px;
    }

    >input{
        width: 100%;

        border: 0;
        border-bottom: 1px solid #B6B6B6;

        background: transparent;

        margin-bottom: 24px;

        padding: 8px;
    }

    .MuiFormControl-marginNormal{
        width: 100%;    

        margin-top: 0;
        margin-bottom: 24px;
    }

    .css-2b097c-container{
        width: 100%;
    }

    .css-yk16xz-control{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        background: transparent;

        border-radius: 0;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }
`;

export const FormContainer = styled.div`
    width: 100%;

    position: relative;

    >p{
        margin: 16px 0 14px;
    }

    >input{
        width: 100%;

        padding: 14px 12px;

        border: 0;
        border-bottom: 1px solid #B6B6B6;
        background: #F9F9F9;

        cursor: default;
    }

    .css-2b097c-container{
        width: 100%;
        
        &:last-child{
            margin-bottom: 64px;
        }
    }

    .css-yk16xz-control{
        border-color: #04c5ff;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    #users{
        width: 100%;
        max-height: 350px;

        overflow-y: auto;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        flex-direction: column;

        position: absolute;

        left: 50;
        top: 25;
        z-index: 10000;

        background: #FFFFFF;

        border: 1px solid #B6B6B6;

        >button{
            padding: 8px 16px;

            width: 100%;

            font-size: 14px;

            text-align: start;
            
            transition: all 0.2s;

            &:hover{
                background: #F9F9F9;
            }
        }
    }

    .MuiAutocomplete-root{
        margin-bottom: 16px;
    }

    @media(max-width: 767px){
        .css-2b097c-container{
            width: 100%;
        }
    }
`;

export const PropertyTenantContainer = styled.div`
width: 100%;

    margin: 24px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >span{
        font-size: 10px;
    }

    >p{
        color: #666666;

        font-weight: 600;

        margin-top: 0.5rem;
    }

    @media(max-width: 767px){
        width: 100%;

        margin-bottom: 16px;
    }
`;

export const DeactivatePropertyButton = styled.button`
    background: #BF0000;

    margin: 16px 32px 0;

    width: 83%;

    padding: 16px 0;

    >p{
        font-weight: 500;

        color: #FFFFFF;
    }

    @media(max-width: 767px){
        margin: 16px 24px 0;
    }
`;
