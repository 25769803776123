import { Radio } from "@material-ui/core";
import { CharacteristicWrapper, RadiosWrapper } from "./styles";

interface IProps {
  label: string;
  radioGroupName: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

function RadioOption({ label, radioGroupName, checked, onChange }: IProps) {
  return (
    <CharacteristicWrapper>
      <p>{label}</p>

      <RadiosWrapper>
        <Radio
          name={radioGroupName}
          checked={checked}
          onChange={() => onChange(true)}
        />
        <p onClick={() => onChange(true)}>Sim</p>

        <Radio
          name={radioGroupName}
          checked={!checked}
          onChange={() => onChange(false)}
        />
        <p onClick={() => onChange(false)}>Não</p>
      </RadiosWrapper>
    </CharacteristicWrapper>
  );
}

export default RadioOption;
