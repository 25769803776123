const DAYS_OF_THE_WEEK = [
  "DOMINGO",
  "SEGUNDA-FEIRA",
  "TERÇA-FEIRA",
  "QUARTA-FEIRA",
  "QUINTA-FEIRA",
  "SEXTA-FEIRA",
  "SÁBADO",
];
export function getDayOfTheWeek(date: string) {
  const [day, month, year] = date.split("/");
  const newDate = new Date(Number(year), Number(month) - 1, Number(day));
  return DAYS_OF_THE_WEEK[newDate.getDay()];
}

export function formatDateToOption(scheduleOption: string) {
  const [date, time] = scheduleOption.split("|");

  const [year, month, day] = date.split("-");
  const dayOfTheWeek = new Date(
    Number(year),
    Number(month) - 1,
    Number(day)
  ).getDay();

  const writtenDayOfTheWeek = DAYS_OF_THE_WEEK[dayOfTheWeek].substring(0, 3);

  return `${writtenDayOfTheWeek} ${day}/${month}/${year.substring(
    2,
    4
  )} - ${time.substring(0, 5)}`;
}
