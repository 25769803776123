import styled from 'styled-components';

interface IInputForm{
	hasError: boolean;
}

export const Container = styled.div`
`;

export const Content = styled.div`
	max-width: 380px;
	margin: 46px auto 12% auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	>h2{
		font-size: 20px;
		color: #DD6435;
		margin-bottom: 21px;
	}
	@media(min-width: 1441px){
		max-width: 420px;
		margin: 58px auto 12% auto;
		>h2{
			font-size: 22px;
		}
	}
`;

export const SuccessMessageContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #DAFFCF;
	padding: 8px 14px;
	margin-bottom: 24px;
	>p{
		font-size: 14px;
		font-weight: 500;
		color: #006403;
	}
	>button{
		background: transparent;
		border: 0;
		margin-left: auto;
	}
	@media(max-width: 767px){
		width: 90%;
		>p{
			font-size: 12px;
			font-weight: 500;
			color: #006403;
		}
	}
	@media(min-width: 1441px){
		padding: 11px 14px;
		>p{
			font-size: 17px;
		}
	}
`;

export const Form = styled.form<IInputForm>`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 32px;
	>p{
		font-size: 14px;
		>span{
			font-size: 12px;
		}
	}
	
	#mask-input{
		width: 100%;
		padding: 8px;
		border: 0;
		border-bottom: ${props => !!props.hasError ? "2px solid #FF3838" : "1px solid #B6B6B6"};
		font-size: 14px;
		margin-bottom: 28px;
		background: transparent;
	}
	>button{
		width: 100%;
		padding: 16px 24px;
		background: #6EA5FF;
		border: 0;
		color: #FFF;
		font-weight: bold;
	}
	@media(max-width: 767px){
		width: 90%;
	}
	@media(min-width: 1441px){
		>p{
			font-size: 17px;
			>span{
				font-size: 14px;
			}
		}
		#mask-input{
			font-size: 15px;
			margin-bottom: 32px;
		}
	}
`;

export const InputForm = styled.input<IInputForm>`
	width: 100%;
	padding: 8px;
	border: 0;
	border-bottom: ${props => !!props.hasError ? "2px solid #FF3838" : "1px solid #B6B6B6"};
	font-size: 14px;
	margin-bottom: 28px;
	background: transparent;
	@media(min-width: 1441px){
		font-size: 15px;
		margin-bottom: 32px;
	}
`;

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	>button{
		width: 50%;
		padding: 10px 0;
		border: 1px solid #B6B6B6;
		background: transparent;
		font-size: 13px;
		font-weight: 600;
		color: #666666;
		& + button{
			margin-left: 12px;
		}
	}
	>label{
		width: 50%;
		padding: 10px 0;
		border: 1px solid #00BF33;
		background: transparent;
		font-size: 13px;
		font-weight: 600;
		color: #00BF33;
		text-align: center;
		margin-right: 12px;
	}
	@media(min-width: 1441px){
		>button{
			padding: 11px 0;
			font-size: 15px;
		}
		>label{
			padding: 11px 0;
			font-size: 15px;
		}
	}
`;