import styled, {css} from 'styled-components';
import {animated} from 'react-spring';

interface ToastProps{
	type?: string;
	hasDescription: boolean;
};

export const Container = styled(animated.div)<ToastProps>`
	width: 360px;
	position: relative;
	padding: 16px 30px 16px 16px;
	border-radius: 10px;
	box-shadow: 2px 2px 8px rgba(0 ,0 ,0, 0.2);
	display: flex;
	${ props => props.type === 'info' && css`
		background: #fcf27e;
		color: #3172b7;
	` }
	${ props => props.type === 'success' && css`
		background: #e6fffa;
		color: #2e656a;
	` }
	${ props => props.type === 'error' && css`
		background: #fddede;
		color: #c53030;
	` }
	& + div{
		margin-top: 8px;
	}
	> svg {
		margin: 10px 12px 0 0;
	}
	div{
		flex: 1;
		p{
			margin-top: 4px;
			font-size: 14px;
			opacity: 0.8;
			line-height: 20px;
		}
	}
	button{
			position: absolute;
			right: 16px;
			top: 12px;
			opacity: 0.6;
			border: 0;
			background: transparent;
			color: inherit;
		}
	${props => !props.hasDescription && css`
		align-items: center;
		svg{
			margin-top: 0;
		}
	`}

	@media(max-width: 767px){
		width: 300px;
	}
`;