import { useCallback, useState } from "react";
import { MdAnnouncement } from "react-icons/md";
import Modal from "react-responsive-modal";
import { useAuth } from "../../hooks/auth";
import { useRefresh } from "../../hooks/refresh";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";

import {Content,
        ButtonsContainer,
        ConfirmButton,
        CancelButton} from './styles';

interface IModalToDemarcateSchedule{
    open: boolean;
    scheduleID?: number;
    functionToCloseModal(): void;
}

export default function ModalDemarcatePhotoghapherSchedule({ open, scheduleID, functionToCloseModal }: IModalToDemarcateSchedule){
    const { dateToExpires, access_token } = useAuth();
    const { addToast } = useToast();
    const { handleRefresh } = useRefresh();

    const [loadingRequestToUnlockDateScheduled, setLoadingRequestToUnlockDateScheduled] = useState(false);

    const handleUnlockDateScheduled = useCallback(async() => {
        setLoadingRequestToUnlockDateScheduled(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.delete(`/api/adm/photographer-schedule/${scheduleID}`);

            if(!!response.data.success){
                addToast({
                    title: "Agendamento desmarcado",
                    type: 'success',
                    description: ''
                });

                handleRefresh();
                functionToCloseModal();
            }

            setLoadingRequestToUnlockDateScheduled(false);
        } catch(err){
            console.log(err);

            setLoadingRequestToUnlockDateScheduled(false);
        }
    }, [dateToExpires, access_token, scheduleID, addToast, handleRefresh, functionToCloseModal]);

    return(
        <Modal open={open} onClose={functionToCloseModal} classNames={{
            modal: "remove-photo-modal"
        }}>
            <Content>
                <MdAnnouncement size={40} color="#666666"/>

                <p>Tem certeza que deseja desmarcar o agendamento?</p>

                <ButtonsContainer>
                    <ConfirmButton 
                        onClick={!!loadingRequestToUnlockDateScheduled ? () => {} : handleUnlockDateScheduled} 
                        isLoading={loadingRequestToUnlockDateScheduled}
                    >
                        {!!loadingRequestToUnlockDateScheduled ? "Carregando..." : "Sim"}
                    </ConfirmButton>
                    <CancelButton onClick={functionToCloseModal}>Não</CancelButton>
                </ButtonsContainer>
            </Content>
        </Modal>
    );
}