import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import STEPS from "./enum";
import UploadBeltImage from "../../components/UploadBeltImage";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineFileCopy,
  MdOutlineFileUpload,
} from "react-icons/md";
import Loading from "../../components/Loading";
import { ButtonsContainer, Container, Content } from "./styles";
import FilesUpload from "../../components/UploadRentalBeltComponents/FilesUpload";
import SignersRegister from "../../components/UploadRentalBeltComponents/SignersRegister";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { CONTRACT_STEPS } from "./constants";

interface ISignerType {
  value: string;
  label: string;
}

interface ISigner {
  signAs: string;
  email: string;
  name: string;
  signOutside: boolean;
  user_id: undefined | number;
}

interface IClient {
  drive_link: string;
  property_id: number;
  rent_process_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: string;
}

interface IParam {
  client: IClient;
}

const UploadRentalBelt: React.FC = () => {
  const { access_token, user, dateToExpires } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const [activeStep, setActiveStep] = useState(STEPS.FILES_UPLOAD);
  const [loadApiError, setLoadApiError] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [signerTypes, setSignerTypes] = useState<ISignerType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [signers, setSigners] = useState<ISigner[]>([]);
  const location = useLocation<IParam>();
  const [isGeneratingContract, setIsGeneratingContract] = useState(false);
  const [contractWasGenerated, setContractWasGenerated] = useState(false);
  const ICON_SIZE = 24;

  const { client } = location.state;

  const onFileSelected = (file: File | undefined): void => {
    setSelectedFile(file);
  };

  const onSignerRegistered = (registeredSigner: ISigner) => {
    setSigners((prevSigners) => [...prevSigners, registeredSigner]);
  };

  const onSignerRemoved = (signerEmail: string) => {
    setSigners((prevSigners) => {
      return prevSigners.filter((signer) => {
        return signer.email !== signerEmail;
      });
    });
  };

  useEffect(() => {
    async function loadApi() {
      try {
        api.defaults.headers.authorization = `Bearer ${access_token}`;
        const response = await api.get("/api/adm/signers-types");

        setSignerTypes(() => {
          return response.data.data.map((signerType: ISignerType) => {
            return { value: signerType.value, label: signerType.label };
          });
        });

        setLoadingData(false);
      } catch (err: any) {
        if (err?.response?.status === 403) {
          setLoadApiError(err?.response?.data?.message);

          setLoadingData(true);
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadApiError(err.response.data.message);

        setLoadingData(true);
      }
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }
  }, [dateToExpires, access_token, user, history, addToast]);

  useEffect(() => {
    const initialSigner = {
      signAs: "lessee",
      email: client.tenant_email,
      name: client.tenant_name,
      signOutside: false,
      user_id: client.tenant_id,
    };
    onSignerRegistered(initialSigner);
  }, [client]);

  const forwardButtonClickHandler = () => {
    if (activeStep === STEPS.SIGNERS_REGISTER) {
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const backButtonClickHandler = () => {
    if (activeStep === STEPS.FILES_UPLOAD) {
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const exitButtonClickHandler = () => {
    history.push("/clients-without-contract");
  };

  const getContent = useCallback(() => {
    switch (activeStep) {
      case STEPS.FILES_UPLOAD:
        return (
          <FilesUpload file={selectedFile} onFileSelected={onFileSelected} />
        );
      case STEPS.SIGNERS_REGISTER:
        return (
          <SignersRegister
            signerTypes={signerTypes}
            signersList={signers}
            onRemoveSigner={onSignerRemoved}
            onFormSubmit={onSignerRegistered}
          />
        );
    }
  }, [activeStep, signerTypes, signers, selectedFile]);

  const sendContractToApi = async (body: FormData) => {
    setIsGeneratingContract(true);
    try {
      api.defaults.headers.authorization = `Bearer ${access_token}`;
      const response = await api.post(
        `/api/adm/upload-rent-contract/${client.property_id}`,
        body
      );
      console.log(response);
      addToast({ title: "Contrato criado com sucesso!", type: "success" });
      setContractWasGenerated(true);
    } catch (err: any) {
      if (err?.response?.status === 403) {
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }
    setIsGeneratingContract(false);
  };

  const contractGeneratedHandler = (isInGenerationProcess: boolean) => {
    addToast({
      title: isInGenerationProcess
        ? "O contrato está sendo gerado"
        : "O contrato já foi gerado",
      type: "error",
    });
  };

  const generateContractHandler = () => {
    let signersIsValid = true;
    let documentIsValid = true;
    let rentProcessIdIsValid = true;

    if (signers.length === 0) {
      signersIsValid = false;
      addToast({ title: "Necessário pelo menos um signatário", type: "error" });
    }

    if (!selectedFile) {
      documentIsValid = false;
      addToast({ title: "Necessário anexar contrato em PDF", type: "error" });
    }

    if (!client.rent_process_id) {
      rentProcessIdIsValid = false;
      addToast({
        title: "Necessário identificador do processo de locação",
        type: "error",
      });
    }

    if (!documentIsValid || !signersIsValid || !rentProcessIdIsValid) return;
    let formattedSignersList = `{
      "signers": [`;
    signers.forEach((signer, index) => {
      formattedSignersList += `{
        "signAs": "${signer.signAs}",
        "email": "${signer.email}",
        "signOutside": ${signer.signOutside}`;
      if (!!signer.user_id) {
        formattedSignersList += `,
        "user_id": "${signer.user_id}"`;
      }
      formattedSignersList += `
    }`;
      if (index + 1 !== signers.length)
        formattedSignersList += `,
      `;
    });

    formattedSignersList += `
  ]
}`;

    const formattedDocument = new FormData();

    if (!!selectedFile) {
      let blob = new Blob([selectedFile], { type: selectedFile?.type });
      formattedDocument.append("document", blob);
    }

    formattedDocument.append(
      "rent_process_id",
      client.rent_process_id.toString()
    );
    formattedDocument.append("signers", formattedSignersList);

    sendContractToApi(formattedDocument);
  };

  if (loadingData) {
    return <Loading />;
  }

  return (
    <>
      <Header />

      <Container>
        <Content>
          <h2>Criação de contrato</h2>
          <p>
            Preencha todos os campos com atenção e confira todas as informações
            antes de finalizar.
          </p>
          <UploadBeltImage currentStep={activeStep} steps={CONTRACT_STEPS} />
          {getContent()}
          <ButtonsContainer>
            <button
              id={"back-btn"}
              onClick={
                activeStep === STEPS.FILES_UPLOAD
                  ? exitButtonClickHandler
                  : backButtonClickHandler
              }
            >
              <span>
                <MdChevronLeft size={ICON_SIZE} fontWeight={700} /> Voltar
              </span>
            </button>
            <button
              id={
                activeStep === STEPS.SIGNERS_REGISTER
                  ? contractWasGenerated || isGeneratingContract
                    ? "disable-btn"
                    : "last-btn"
                  : "forward-btn"
              }
              onClick={
                activeStep === STEPS.SIGNERS_REGISTER
                  ? contractWasGenerated || isGeneratingContract
                    ? () => contractGeneratedHandler(isGeneratingContract)
                    : generateContractHandler
                  : forwardButtonClickHandler
              }
            >
              {activeStep === STEPS.SIGNERS_REGISTER ? (
                isGeneratingContract ? (
                  "Gerando contrato..."
                ) : (
                  <span className="styled-btn">
                    Gerar Contrato <MdOutlineFileUpload size={ICON_SIZE} />
                  </span>
                )
              ) : (
                <span>
                  Próximo <MdChevronRight size={ICON_SIZE} fontWeight={700} />
                </span>
              )}
            </button>
            {activeStep === STEPS.SIGNERS_REGISTER && (
              <button onClick={exitButtonClickHandler}>
                <span className="styled-btn">
                  Todos os Contratos <MdOutlineFileCopy size={ICON_SIZE} />
                </span>
              </button>
            )}
          </ButtonsContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default UploadRentalBelt;
