import styled, { css } from "styled-components";

interface IWrapper {
  $isList: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  width: 90%;
  margin: 32px auto;
  ${({ $isList }) => {
    const style = `display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
    row-gap: 16px;`;
    return $isList
      ? css`
          & > div {
            padding: 16px;
            border: 1px solid #8b8a8f;
            margin-top: 16px;
            ${style},
          }
        `
      : css`
          ${style}
        `;
  }}
`;

export const Button = styled.button`
  background-color: #6ea6ff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: white;
  margin-top: 8px;
`;
