import { Wrapper } from "./styles";
import Select from "react-select";
interface IOption {
  label: string;
  value: number;
}
interface IProps {
  label: string;
  formError?: string;
  options: IOption[];
  onChange: (option: IOption | null) => void;
}

function PropertyAccommodation(props: IProps) {
  return (
    <Wrapper>
      <label>
        {props.label}: {!!props.formError && <p>{props.formError}</p>}
      </label>
      <Select placeholder="Escolha um número" {...props} />
    </Wrapper>
  );
}

export default PropertyAccommodation;
