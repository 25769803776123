import { useCallback, useState } from "react";
import { MdAnnouncement } from "react-icons/md";
import Modal from "react-responsive-modal";
import { useAuth } from "../../hooks/auth";
import { useRefresh } from "../../hooks/refresh";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";

import {
  Content,
  ButtonsContainer,
  ConfirmButton,
  CancelButton,
  FormContainer,
} from "./styles";
import ModalType from "../../pages/Schedules/ModalType";

interface IParam {
  id: string;
  first_name: string;
  email: string;
  phone: string;
  schedule: string;
}

interface IUpdatableUser {
  name?: string;
  email?: string;
  phone?: string;
}

interface IModalUpdateUser {
  open: boolean;
  param: IParam;
  onReturn(modalType: string, schedule: string): void;
}

export default function ModalUpdateUser({
  open,
  param,
  onReturn,
}: IModalUpdateUser) {
  const { dateToExpires, access_token } = useAuth();
  const { addToast } = useToast();
  const { handleRefresh } = useRefresh();

  const [updatableUser, setUpdatableUser] = useState<IUpdatableUser>({});
  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false);

  const handleUpdateUser = useCallback(async () => {
    setLoadingUpdateUser(true);

    try {
      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post(
        `/api/users/update/${param.id}`,
        updatableUser
      );

      if (!!response.data.success) {
        addToast({
          title: "Usuário alterado!",
          type: "success",
          description: "",
        });

        handleRefresh();
        onReturn(ModalType.CONSULT, param.schedule);
      }

      setLoadingUpdateUser(false);
    } catch (err: any) {
      addToast({
        title: "Erro ao alterar!",
        type: "error",
        description: err?.message,
      });

      setLoadingUpdateUser(false);
    }
  }, [
    dateToExpires,
    access_token,
    addToast,
    handleRefresh,
    onReturn,
    param,
    updatableUser,
  ]);

  function handleChange(identifier: string, value: string) {
    setUpdatableUser((prevValue) => ({ ...prevValue, [identifier]: value }));
  }

  return (
    <Modal
      open={open}
      onClose={() => onReturn(ModalType.CONSULT, param.schedule)}
      classNames={{
        modal: "remove-photo-modal",
      }}
    >
      <Content>
        <MdAnnouncement size={40} color="#666666" />

        <h6>Editar usuário</h6>
        <p>*preencher somente os campos a serem alterados</p>
        <FormContainer>
          <p>Nome: </p>
          <input
            placeholder={param.first_name}
            type="text"
            value={updatableUser.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <p>E-mail: </p>
          <input
            placeholder={param.email}
            type="text"
            value={updatableUser.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <p>Telefone: </p>
          <input
            placeholder={param.phone}
            type="text"
            value={updatableUser.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
          />
        </FormContainer>

        <ButtonsContainer>
          <ConfirmButton
            onClick={!!loadingUpdateUser ? () => {} : handleUpdateUser}
            isLoading={loadingUpdateUser}
          >
            {!!loadingUpdateUser ? "Carregando..." : "Editar"}
          </ConfirmButton>
          <CancelButton
            onClick={() => onReturn(ModalType.CONSULT, param.schedule)}
          >
            Cancelar
          </CancelButton>
        </ButtonsContainer>
      </Content>
    </Modal>
  );
}
