import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Layout from "../../components/Layout";
import {
  Content,
  DataContainer,
  DataLabels,
  DataContent,
  ReportTable,
} from "./styles";

interface IState {
  data: Array<{
    first_name: string;
    phone: string;
    email: string;
    owner_name: string;
    owner_phone: string;
    owner_email: string;
    consultant_name: string;
    property_id: number;
    neighborhood: string;
    address: string;
    date: string;
    video_call: boolean;
  }>;
  dates: string[];
}

export default function ShowVisitsPerConsultantReport() {
  const history = useHistory();

  const { state } = useLocation<IState>();

  useEffect(() => {
    if (!state) {
      history.goBack();
    }
  }, [state, history]);

  const [iniYear, iniMonth, iniDay] = state.dates[0].split("-");
  const [finYear, finMonth, finDay] = state.dates[1].split("-");

  return (
    <Layout>
      <Content>
        <h2>Visitas por Consultor</h2>

        <DataContainer>
          <DataLabels>
            <p>PERÍODO</p>
            <p>TOTAL DE VISITAS</p>
          </DataLabels>

          <DataContent>
            <p>{`${iniDay}/${iniMonth}/${iniYear} - ${finDay}/${finMonth}/${finYear}`}</p>
            <p>{state.data.length}</p>
          </DataContent>
        </DataContainer>
        <div style={{ width: "100%", overflow: "auto" }}>
          <ReportTable>
            <thead>
              <tr>
                <th>Consultor</th>
                <th>Cliente</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Imóvel</th>
                <th>Bairro</th>
                <th>Endereço</th>
                <th>Data</th>
                <th>Proprietário</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Vídeo Chamada</th>
              </tr>
            </thead>

            <tbody>
              {state.data.map((dataFinded, index) => (
                <tr key={index}>
                  <td>{dataFinded.consultant_name}</td>
                  <td>{dataFinded.first_name}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{dataFinded.phone}</td>
                  <td>{dataFinded.email}</td>
                  <td>{dataFinded.property_id}</td>
                  <td>{dataFinded.neighborhood}</td>
                  <td>{dataFinded.address}</td>
                  <td>{`${dataFinded.date.substring(
                    8,
                    10
                  )}/${dataFinded.date.substring(
                    5,
                    7
                  )}/${dataFinded.date.substring(0, 4)}`}</td>
                  <td>{dataFinded.owner_name}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {dataFinded.owner_phone}
                  </td>
                  <td>{dataFinded.owner_email}</td>
                  <td>{dataFinded.video_call ? "Sim" : "Não"}</td>
                </tr>
              ))}
            </tbody>
          </ReportTable>
        </div>
      </Content>
    </Layout>
  );
}
