import styled from "styled-components";

export const Container = styled.div`
    max-width: 1260px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 0 auto;

    padding: 64px 0 17%;

    >h2{
        font-size: 35px;
        font-weight: bold;

        color: #666666;
    }

    @media(max-width: 767px){
        margin-bottom: 45%;
    }
`;

export const ConsultantForm = styled.form`
    width: 37%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 48px 0 0;

    >p{
        align-self: flex-start;

        margin-top: 12px;

        font-size: 12px;

        color: rgb(255, 56, 56);
    }

    @media(max-width: 767px){
        width: 90%;
    }
`;

export const InputContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >label{
        color: #666666;
    }

    >input{
        width: 100%;

        border: 0;
        border-bottom: 1px solid #666666;

        padding: 8px;
    }

    .css-2b097c-container{
        width: 100%;

        margin-top: 12px;
    }

    .css-yk16xz-control{
        border: 0;
        border-bottom: 1px solid #666666;

        border-radius: 0;
    }

    .css-1pahdxg-control{
        box-shadow: 0 0 0 0 transparent;
        border: 0;
        border-color: transparent;

        &:hover{
            border-color: transparent;
            border-bottom: 1px solid #666666;

            border-radius: 0;
        }
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    & + div{
        margin-top: 24px;
    }
`;

export const LabelContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    >p{
        margin-left: 12px;

        font-size: 12px;

        color: rgb(255, 56, 56);
    }
`;

export const RadiosContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 12px 16px;

    background: #F5F5F5;

    margin-bottom: 24px;
`;

export const RadioContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    >label{
        margin-left: 8px;
    }

    & + div{
        margin-left: 48px;
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CreateConsultantButton = styled.button`
    background: #00bf33;

    color: #FFFFFF;

    padding: 12px 0;
    width: 48%;
`;

export const ResetButton = styled.button`
    border: 1px solid #666666;

    color: #666666;

    padding: 12px 0;
    width: 48%;
`;