import { Skeleton } from "@material-ui/lab";

function ModelCreateContractSkeleton() {
  return (
    <>
      <Skeleton height="32px" width="100%" />
      <Skeleton height="32px" width="100%" />
      <Skeleton height="32px" width="100%" />
      <Skeleton height="32px" width="100%" />
      <Skeleton height="32px" width="100%" />
      <Skeleton height="32px" width="100%" />
    </>
  );
}

export default ModelCreateContractSkeleton;
