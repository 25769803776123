import { useEffect, useState } from "react";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { useAuth } from "../../hooks/auth";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import {
  ClientsTable,
  Container,
  ErrorMessageContainer,
  TableChangeContainer,
} from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineInsertDriveFile,
} from "react-icons/md";
import ModalCreateContract from "./components/ModalCreateContract";
import ModalType from "./ModalType";
import ModalSelectModelType from "./components/ModalSelectModelType";

interface IClient {
  drive_link: string;
  property_id: number;
  rent_process_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: string;
}
const CLIENTS_PER_PAGE = 3;

function deriveNumberOfPages(clients: IClient[]) {
  const integerNumOfPages = Math.floor(clients.length / CLIENTS_PER_PAGE);
  const remainder = clients.length % CLIENTS_PER_PAGE;

  return integerNumOfPages === 0 ? 1 : integerNumOfPages + (remainder ?? 1);
}

const ClientsWithoutContract = () => {
  const history = useHistory();
  const { access_token, user, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const [clients, setClients] = useState<IClient[]>([]);
  const [loadingClients, setLoadingClients] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loadApiError, setLoadApiError] = useState("");
  const [loadModal, setLoadModal] = useState<{
    modalToOpen: string;
    param: any;
  }>({
    modalToOpen: "",
    param: undefined,
  });

  const numberOfPages = deriveNumberOfPages(clients);

  useEffect(() => {
    async function loadApi() {
      try {
        api.defaults.headers.authorization = `Bearer ${access_token}`;

        const response = await api.get("/api/adm/clients-without-contract");
        const fetchedClients = response.data.data;
        setClients(fetchedClients);

        setLoadingClients(false);
      } catch (err: any) {
        if (err?.response?.status === 403) {
          setLoadApiError(err?.response?.data?.message);

          setLoadingClients(true);
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadApiError(err.response.data.message);

        setLoadingClients(true);
      }
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }

    // eslint-disable-next-line
  }, [dateToExpires, access_token, user, history]);

  const onBackward = () => {
    if (currentPage !== 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const onForward = () => {
    if (currentPage !== numberOfPages)
      setCurrentPage((prevPage) => prevPage + 1);
  };

  function TableContent() {
    return (
      <tbody>
        {Array.from({ length: CLIENTS_PER_PAGE }, (_, index) => {
          const i = (currentPage - 1) * CLIENTS_PER_PAGE + index;
          if (i < clients.length)
            return (
              <tr key={i}>
                <td>{clients[i].property_id}</td>
                <td>{clients[i].tenant_name}</td>
                <td className="status-text">DOCUMENTAÇÃO EM ANÁLISE</td>
                <td
                  onClick={() =>
                    handleOpenModal(ModalType.CREATE_CONTRACT, clients[i])
                  }
                >
                  <MdOutlineInsertDriveFile color="0219a3" size={24} />
                </td>
              </tr>
            );
          return null;
        })}
      </tbody>
    );
  }

  function NavigationFooter() {
    return (
      <>
        {Array.from({ length: numberOfPages }, (_, i) => (
          <span
            key={i + 1}
            id={currentPage === i + 1 ? "current-page" : undefined}
            onClick={() => {
              setCurrentPage(i + 1);
            }}
          >
            {i + 1}
          </span>
        ))}
      </>
    );
  }

  const handleOpenModal = (identifier: string, param: any) => {
    setLoadModal((prevValue) => ({
      ...prevValue,
      modalToOpen: identifier,
      param,
    }));
  };

  if (loadingClients) return <Loading />;

  return (
    <Container>
      {!!loadApiError && (
        <ErrorMessageContainer>
          <h2>{loadApiError}</h2>
        </ErrorMessageContainer>
      )}
      {!loadApiError && (
        <>
          {loadModal.modalToOpen === ModalType.CREATE_CONTRACT && (
            <ModalCreateContract
              open={loadModal.modalToOpen === ModalType.CREATE_CONTRACT}
              functionToClose={() => handleOpenModal("", undefined)}
              functionToChangeModal={() =>
                handleOpenModal(ModalType.MODEL_OPTIONS, loadModal.param)
              }
              dataToShow={loadModal.param}
            />
          )}
          {loadModal.modalToOpen === ModalType.MODEL_OPTIONS && (
            <ModalSelectModelType
              open={loadModal.modalToOpen === ModalType.MODEL_OPTIONS}
              functionToClose={() => handleOpenModal("", undefined)}
              dataToShow={loadModal.param}
            />
          )}
          <Header />
          <h2>Documentações Recebidas</h2>
          <ClientsTable>
            <thead>
              <tr>
                <th>Imóvel</th>
                <th>Cliente</th>
                <th>Status</th>
                <th>Criar Contrato</th>
              </tr>
            </thead>
            <TableContent />
          </ClientsTable>
          <TableChangeContainer>
            <MdChevronLeft
              size={20}
              onClick={onBackward}
              className={currentPage === 1 ? "disable" : ""}
            />

            <NavigationFooter />

            <MdChevronRight
              size={20}
              onClick={onForward}
              className={currentPage === numberOfPages ? "disable" : ""}
            />
          </TableChangeContainer>
          <Footer />
        </>
      )}
    </Container>
  );
};

export default ClientsWithoutContract;
