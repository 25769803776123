import { useEffect } from 'react';

import { useAuth } from './../../hooks/auth';

import { useRouteMatch, Link, useHistory } from 'react-router-dom';

import Layout from '../../components/Layout';

import {Content} from './styles';
import FormToResetPasswordPage from '../../components/FormToResetPasswordPage';

interface IParams{
	token: string;
}

export default function ResetPassword(){
	const { params } = useRouteMatch<IParams>();
	const history = useHistory();

	const { user } = useAuth();

	useEffect(() => {
		function checkParams(){
			if(!params || !!user){
				history.goBack();
			}
		}

		checkParams();
	}, [params, history, user]);

	return(
		<Layout>
			<Content>
				<h2>CRIAR NOVA SENHA</h2>

				<FormToResetPasswordPage />

				<Link to="/login">Ir para login</Link>
			</Content>
		</Layout>
	);
};