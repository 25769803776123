import styled, { keyframes } from 'styled-components';

const openAnimation = keyframes`
    from{
        transform: translateX(-1000px);
    }

    to{
        transform: translateX(0px);
    }
`;

const openSeperatorAnimation = keyframes`
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
`;

export const Content = styled.div`
    height: 100vh;

    background: #FFFFFF;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    @media(min-width: 768px){
        display: none;
    }
`;

export const RoutesOptions = styled.div`
    padding: 36px 0 12px 36px;

    animation: ${openAnimation} 0.7s;

    >button{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        font-size: 17px;

        color: #dd6435;

        >p{
            color: #000;
        }

        >svg{
            margin-left: 4px;
        }

        margin: 24px 0;

        position: relative;
    }

    >a{
        color: #000;

        >img{
            height: 30px;
        }
    }

    >h4{
        >a{
            font-size: 18px;
            font-weight: bold;

            color: #666666;
        }
    }
`;

export const Separator = styled.div`
    width: 100%;
    height: 1px;

    background: #666666;

    animation: ${openSeperatorAnimation} 5s;
`;

export const OptionsToRouteContainer = styled.div`
    position: absolute;

    top: 25px;
    left: 0;

    z-index: 4;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    
    background-color: #FFF;

    border: 1px solid #dee2e6;
    border-radius: 4px;

    transition: all 0.2s;

    >a{
        text-decoration: none;

        color: #666666;

        padding: 12px 45px;
    }

    >button{
        text-decoration: none;

        color: #666666;

        width: 230px;

        padding: 12px 0;
    }
`;

export const RouteOption = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 24px 0;

    >a{
        color: #000;
    }

    >span{
        font-size: 10px;

        color: #FFF;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 20px;
        height: 20px;
        
        border-radius: 50%;

        background: #BF0000;

        margin-left: 8px;
    }
`;