const loadFile = (file: File): Promise<string> => new Promise((response, reject) => {
    const reader = new FileReader();

    reader.addEventListener("abort", e => reject(`File upload aborted:${e}`));
    reader.addEventListener("error", e => reject(`File upload error: ${e}`));

    reader.addEventListener('load', () => response(reader.result as string), false);

    reader.readAsDataURL(file);
});

export default loadFile;