import styled, { css } from "styled-components";

interface IOfferContent{
    status: number;
}

export const Content = styled.div`
    max-width: 1200px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 64px auto 30%;

    >h2{
        font-size: 35px;
        font-weight: bold;

        color: #666666;

        margin-bottom: 24px;
    }

    @media(max-width: 767px){
        margin: 64px auto 40%;
    }
`;

export const OffersContainer = styled.div`
    width: 90%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const LocalContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 24px;

    background: #F9F9F9;

    @media(max-width: 767px){
        flex-direction: column;
    }
`;

export const LocalContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const PropertyDatasContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-direction: column;

    margin-left: 24px;

    >p{
        font-size: 14px;

        margin: 8px 0;

        &:first-child{
            font-size: 16px;
            font-weight: bold;

            color: #6EA5FF;

            margin: 0;
        }

        &:last-child{
            font-weight: 500;

            margin: 0;
        }
    }

    @media(max-width: 767px){
        >p{
            font-size: 12px;

            &:first-child{
                font-size: 14px;
            
            }
        }
    }
`;

export const OfferAuthorNameAndOptionContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    flex-direction: column;

    >div{
        display: flex;
        align-items: center;

        >p{
            font-size: 14px;

            &:first-child{
                font-weight: 500;

                margin-right: 4px;     
            }
        }
    }

    >button{
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 8px;

        background: #6EA5FF;

        padding: 8px 16px;

        >p{
            color: #FFF;

            font-size: 14px;
            font-weight: 500;
        }

        >svg{
            margin-left: 6px;
        }
    }

    @media(max-width: 767px){
        align-items: center;
        justify-content: center;

        margin-top: 16px;
    }
`;

export const OfferContent = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid #b6b6b6;

    margin-top: 32px;

    & + div{
        margin-top: 16px;
    }

    @media(max-width: 767px){
        flex-direction: column;
    }
`;

export const OfferDataContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    margin-left: 24px;

    width: 100%;

    >p{
        font-size: 14px;
        
        width: 100%;

        &:first-child{
            margin-bottom: 8px;
        }
    }

    >span{
        font-size: 10px;

        color: #C3C3C3;

        margin-top: 8px;
    }

    @media(max-width: 767px){
        padding: 24px 16px;
    }
`;

export const OffersStatusContainer = styled.div<IOfferContent>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 350px;
    height: 200px;

    text-align: center;

    border-left: 1px solid #b6b6b6;

    ${props => props.status === 1 && css`
        color: #00BF33;

        font-weight: bold;
    `}

    ${props => props.status === 2 && css`
        color: #1b6a85;

        font-weight: bold;
    `}

    ${props => props.status === 3 && css`
        color: #666666;

        font-weight: bold;
    `}

    ${props => props.status === 4 && css`
        color: #BF0000;

        font-weight: bold;
    `}

    @media(max-width: 767px){
        border-left: 0px;
        border-top: 1px solid #b6b6b6;

        height: auto;

        padding: 24px 0;

        width: 100%;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    width: 350px;
    height: 200px;

    border-left: 1px solid #b6b6b6;

    @media(max-width: 767px){
        border-left: 0px;
        border-top: 1px solid #b6b6b6;

        height: auto;

        padding: 24px 0;

        width: 100%;
    }
`;

export const AcceptOfferButton = styled.button`
    color: #FFF;

    font-size: 14px;
    font-weight: 500;

    background: #1EC64B;

    padding: 8px 0;

    width: 80%;
`;

export const CancelOfferButton = styled.button`
    color: #FFF;

    font-size: 14px;
    font-weight: 500;

    background: #BF0000;

    padding: 8px 0;

    width: 80%;

    margin-top: 8px;
`;