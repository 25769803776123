import styled from "styled-components";

export const Form = styled.form`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	margin: 42px 0;
	>h4{
		font-size: 17px;
		color: #FF3838;
		margin: 0 auto 21px auto;
		text-align: center;
	}
	>p{
		font-size: 14px;
		>span{
			font-size: 12px;
			color: #FF3838;
		}
	}
	>input{
		width: 100%;
		padding: 4px 8px 8px;
		background: transparent;
		border: 0;
		border-bottom: 1px solid #66666666;
		margin: 0 0 26px;
		font-size: 14px;
	}
	>button{
		width: 100%;
		padding: 10px 24px;
		background: #6EA5FF;
		border: 0;
		font-size: 13px;
		font-weight: 500;
		color: #FFFFFF;
	}
	>strong{
		width: 100%;
		text-align: center;
		padding: 10px 24px;
		background: transparent;
		border: 1px solid #6EA5FF;
		font-size: 13px;
		font-weight: 500;
		color: #6EA5FF;
	}
	>label{
		width: 100%;
		text-align: center;
		padding: 10px 24px;
		border: 0;
		background: #00BF33;
		font-size: 13px;
		font-weight: 500;
		color: #FFFFFF;
	}
	@media(max-width: 767px){
		width: 85%;
		>h4{
			font-size: 15px;
		}
		>p{
			font-size: 13px;
			>span{
				font-size: 11px;
			}
		}
		>button{
			font-size: 12px;
		}
		>strong{
			font-size: 12px;
		}
		>label{
			font-size: 12px;
		}
	}
	@media(min-width: 1441px){
		margin: 48px 0;
		>p{
			font-size: 16px;
			>span{
				font-size: 14px;
				color: #FF3838;
			}
		}
		>input{
			padding: 8px;
		}
		>button{
			font-size: 15px;
			padding: 12px 24px;
		}
		>strong{
			font-size: 15px;
			padding: 12px 24px;
		}
		>label{
			font-size: 15px;
			padding: 12px 24px;
		}
	}
`;