import { useCallback, useEffect, useRef, useState } from 'react';

import {Form, InputContainer } from './styles';

import { FiSearch } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';

interface ISearchBarPropertiesPage{
    neighborhood: string;
    street: string;
    ownerEmail: string;
    floor: string;
}

export default function SearchBarPropertiesPage({ neighborhood, street, ownerEmail, floor }: ISearchBarPropertiesPage){
    const inputRef = useRef<HTMLInputElement>(null);

    const history = useHistory();

    const [error, setError] = useState(false);

    const [inputSearchCode, setIinputSearchCode] = useState("");
    const [inputSearchStreet, setIinputSearchStreet] = useState("");
    const [inputSearchNeighborhood, setInputSearchNeighborhood] = useState("");
    const [inputSearchOwnerEmail, setIinputSearchOwnerEmail] = useState("");
    const [inputSearchFloor, setIinputFloor ] = useState("");

    useEffect(() => {  
      street && setIinputSearchStreet(street);
      neighborhood && setInputSearchNeighborhood(neighborhood);
      ownerEmail && setIinputSearchOwnerEmail(ownerEmail);
      floor && setIinputFloor(floor);
    }, [neighborhood, street, ownerEmail, floor]);
  
  
    const handleSubmit = useCallback((event: any) => {
      event.preventDefault();
      try {

        if (inputSearchCode && inputSearchCode.length > 0) {
          const url = `/property/${inputSearchCode}`;
          history.push(url);
        } else {
          const url = `/properties/1/?neighborhood=${inputSearchNeighborhood}&street=${inputSearchStreet}&ownerEmail=${inputSearchOwnerEmail}&floor=${inputSearchFloor}`;
          history.push(url);
        }

      } catch(err){
        setError(true);
      }
        
    }, [inputSearchCode, inputSearchStreet, inputSearchNeighborhood, inputSearchOwnerEmail, inputSearchFloor, history]);
  
    return(
        <>          
          <Form onSubmit={handleSubmit}>
            <InputContainer thereIsAnError={error}>
              <input
                autoComplete="off"
                value={inputSearchCode}
                onChange={e => {
                  setIinputSearchCode(e.target.value);
                }} 
                type="text" 
                placeholder="Código do imóvel"
              />
        		</InputContainer>
            <InputContainer thereIsAnError={error}>
              <input
                autoComplete="off"
                value={inputSearchNeighborhood}
                onChange={e => {
                  setInputSearchNeighborhood(e.target.value);
                }} 
                type="text" 
                placeholder="Bairro do imóvel"
              />
        		</InputContainer>
            <InputContainer thereIsAnError={error}>
              <input
                autoComplete="off"
                value={inputSearchStreet}
                onChange={e => {
                  setIinputSearchStreet(e.target.value);
                }} 
                type="text" 
                placeholder="Rua do imóvel"
              />
        		</InputContainer>
        		<InputContainer thereIsAnError={error}>
              <input
                autoComplete="off"
                value={inputSearchOwnerEmail}
                onChange={e => {
                  setIinputSearchOwnerEmail(e.target.value);
                }} 
                type="email" 
                placeholder="Email do proprietário"
              />
        		</InputContainer>
        		<InputContainer thereIsAnError={error}>
              <input
                autoComplete="off"
                value={inputSearchFloor}
                onChange={e => {
                  setIinputFloor(e.target.value);
                }} 
                type="email" 
                placeholder="Andar do imóvel"
              />
        		</InputContainer>
            <button onClick={(e) => handleSubmit(e)} type="submit"><FiSearch size={20} color="#DD6435"/></button>
          </Form>
        </>
    );
}