import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  flex-direction: column;
  margin: 1rem auto 2rem;

  > div#inputs-container {
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 19% 19% 19% 19% 19%;
    gap: 1.25%;
    background: #f9f9f9;

    > div#input-container {
      display: flex;
      gap: 5%;
    }
  }
  & + div {
    margin-left: 16px;
  }

  @media (max-width: 767px) {
    & + div {
      margin-top: 16px;
      margin-left: 0;
    }
  }
`;
