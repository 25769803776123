import OutsideClickHandler from "react-outside-click-handler"

import { Container, OfferContainer, OfferContent } from './styles';

interface IOffersDropdown{
    data: Array<{
        id: number;
        property_id: number;
        offer_value: string;
    }>;
    functionToCloseDropdown(): void;
}

export default function OffersDropdown(props: IOffersDropdown){
    return(
        <OutsideClickHandler onOutsideClick={props.functionToCloseDropdown}>
            <Container>
                <h3>Propostas</h3>

                <OfferContainer>
                    {props.data.map(data => (
                        <OfferContent key={data.id}>
                            <a href={`${window.location.origin}/see-offer/${data.id}`} onClick={props.functionToCloseDropdown}>
                                Imóvel: {data.property_id}, Valor da proposta: {data.offer_value}
                            </a>
                        </OfferContent>
                    ))}
                </OfferContainer>
            </Container>
        </OutsideClickHandler>
    );
}