import { FiArrowRight } from "react-icons/fi";
import { ButtonsWrapper, NextButton, ResetButton } from "../../styles";
import {
  BigCharacteristicContent,
  CategoryWrapper,
  CharacteristicContainer,
  CharacteristicContent,
  CharacteristicFormContainer,
  CharacteristicInitialContainer,
} from "./styles";
import { useAuth } from "../../../../hooks/auth";
import { useToast } from "../../../../hooks/toast";
import refreshToken from "../../../../utils/refreshToken";
import api from "../../../../services/api";
import { useState } from "react";
import { ACCESS_AND_AREA, PROPERTY_CONTAINS, SINGULARITIES } from "./constants";
import RadioOption from "./RadioOption";

interface IProps {
  propertyID: number | undefined;
  onChangeCurrentForm: () => void;
}

interface ICharacteristic {
  [key: string]: boolean;
}

function CharacteristicsForm({ propertyID, onChangeCurrentForm }: IProps) {
  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const [characteristics, setCharacteristics] = useState({
    is_nearby_clinics: false,
    is_nearby_major_roads: false,
    is_nearby_schools: false,
    is_nearby_mall: false,
    is_nearby_public_transport: false,
    has_24h_concierge: false,
    has_ac: false,
    has_backyard: false,
    has_bbq: false,
    has_cable_tv: false,
    has_elevator: false,
    has_game_room: false,
    has_garden: false,
    has_gourmet: false,
    has_gourmet_porch: false,
    has_gym: false,
    has_home_cinema: false,
    has_party: false,
    has_playground: false,
    has_pool: false,
    has_porch: false,
    has_sauna: false,
    has_sea_view: false,
    has_spa: false,
    has_sports: false,
    has_storage: false,
    has_tennis_court: false,
  } as ICharacteristic);
  const [isSubmiting, setIsSubmiting] = useState(false);

  function handleChange(identifier: string, value: boolean) {
    setCharacteristics((prevValue) => ({ ...prevValue, [identifier]: value }));
  }

  async function handleSubmit() {
    setIsSubmiting(true);

    try {
      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.put(
        `/api/adm/properties/${propertyID}/benefits`,
        {
          ...characteristics,
        }
      );

      if (!!response.data.success) {
        onChangeCurrentForm();

        addToast({
          title: "Características cadastrada!",
          type: "success",
        });
      }
    } catch (err) {
      if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      console.log(err);
    }

    setIsSubmiting(false);
  }

  return (
    <CharacteristicFormContainer>
      <CharacteristicContainer>
        <CharacteristicContent>
          <CharacteristicInitialContainer>
            <h4>Acesso e área</h4>
          </CharacteristicInitialContainer>
          <CategoryWrapper>
            {ACCESS_AND_AREA.map((characteristic) => (
              <RadioOption
                key={characteristic.attributeName}
                {...characteristic}
                checked={characteristics[characteristic.attributeName]}
                onChange={(value: boolean) =>
                  handleChange(characteristic.attributeName, value)
                }
              />
            ))}
          </CategoryWrapper>
        </CharacteristicContent>

        <CharacteristicContent>
          <CharacteristicInitialContainer>
            <h4>Tem no imóvel</h4>
          </CharacteristicInitialContainer>

          <CategoryWrapper>
            {PROPERTY_CONTAINS.map((characteristic) => (
              <RadioOption
                key={characteristic.attributeName}
                {...characteristic}
                checked={characteristics[characteristic.attributeName]}
                onChange={(value: boolean) =>
                  handleChange(characteristic.attributeName, value)
                }
              />
            ))}
          </CategoryWrapper>
        </CharacteristicContent>
      </CharacteristicContainer>

      <BigCharacteristicContent>
        <CharacteristicInitialContainer>
          <h4>Diferenciais</h4>
        </CharacteristicInitialContainer>

        <CategoryWrapper>
          {SINGULARITIES.map((characteristic) => (
            <RadioOption
              key={characteristic.attributeName}
              {...characteristic}
              checked={characteristics[characteristic.attributeName]}
              onChange={(value: boolean) =>
                handleChange(characteristic.attributeName, value)
              }
            />
          ))}
        </CategoryWrapper>
      </BigCharacteristicContent>

      <ButtonsWrapper>
        <ResetButton type="button">LIMPAR</ResetButton>

        <NextButton
          type="button"
          onClick={isSubmiting ? () => {} : handleSubmit}
        >
          <p>{isSubmiting ? "Carregando..." : "Próximo"}</p>
          {!isSubmiting && <FiArrowRight size={18} color="#FFF" />}
        </NextButton>
      </ButtonsWrapper>
    </CharacteristicFormContainer>
  );
}

export default CharacteristicsForm;
