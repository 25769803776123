import React from 'react';

import {LoadingContent} from './styles';

import loadingGif from './../../assets/components/Loading/loading.gif';

const Loading: React.FC = () => {
	return(
		<LoadingContent>
			<img src={loadingGif} alt="loading"/>
		</LoadingContent>
	);
};

export default Loading;