import {
  MdKey,
  MdOutlineAssignment,
  MdOutlineFolder,
  MdOutlineHomeWork,
  MdOutlinePersonOutline,
} from "react-icons/md";
import { Content } from "./styles";

interface IImageComponent {
  label: string;
  isCurrent: boolean;
  alreadyFinished: boolean;
}

const ImageComponent: React.FC<IImageComponent> = ({
  label,
  isCurrent,
  alreadyFinished,
}: IImageComponent) => {
  const labelToDisplay = () => {
    const ICON_SIZE = 20;
    const ICON_COLOR = isCurrent
      ? "#FFF"
      : alreadyFinished
      ? "#6EA6FF"
      : "#B5B2C0";

    switch (label) {
      case "Modelo":
        return <MdOutlineFolder size={ICON_SIZE} color={ICON_COLOR} />;
      case "Proprietário":
        return <MdKey size={ICON_SIZE} color={ICON_COLOR} />;
      case "Inquilino":
        return <MdOutlinePersonOutline size={ICON_SIZE} color={ICON_COLOR} />;
      case "Imóvel":
        return <MdOutlineHomeWork size={ICON_SIZE} color={ICON_COLOR} />;
      case "Contrato":
        return <MdOutlineAssignment size={ICON_SIZE} color={ICON_COLOR} />;
    }
  };

  return (
    <Content>
      <div
        id={
          isCurrent
            ? "current-step-image"
            : alreadyFinished
            ? "finished-step-image"
            : "future-step-image"
        }
      >
        {labelToDisplay()}
      </div>
      <span
        id={
          isCurrent
            ? "current-step-label"
            : alreadyFinished
            ? "finished-step-label"
            : "future-step-label"
        }
      >
        {label}
      </span>
    </Content>
  );
};

export default ImageComponent;
