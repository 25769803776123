import styled, { css } from "styled-components";

interface IFormOption {
  isSelected: boolean;
}

export const Container = styled.div`

`;

export const Content = styled.div`
    max-width: 1200px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 0 auto;
`;

export const EditPropertyContainer = styled.div`
    width: 100%;

    padding: 24px;

    margin-top: 24px;

    @media(max-width: 767px){
        width: 100%;

        padding: 24px 12px;
    }
`;

export const InitialContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >div{
        width: 100%;

        >h3{
            font-size: 36px;
            font-weight: bold;

            margin: 0 auto;

            color: #666666;
        }
    }

    >button{
        margin-left: auto;

        background-color: transparent;

        border: 0;
    }

    @media(max-width: 767px){
        width: 100%;
    }
`;

export const TitleAndErrorTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    >p{
        margin-left: 8px;

        font-size: 16px;
        color: rgb(255, 56, 56);
    }
`;

export const FormsOptionContainer = styled.div`
    width: 520px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin: 24px auto 0;

    @media(max-width: 767px){
        width: 320px;
    }
`;

export const FormOptionAddress = styled.button<IFormOption>`
    border: 2px solid #B6B6B6;
    border-radius: 50%;

    width: 42px;
    height: 42px;

    transition: all 0.2s;

    color: #B6B6B6;

    position: relative;

    ${(props) =>
      !!props.isSelected
        ? css`
        border: 2px solid #6EA6FF;

        color: #6EA6FF;
    `
        : css``}

    &::after{
        content: "ENDEREÇO";
        position: absolute;
        bottom: -24px;
        left: -16px;
        font-size: 12px;
        color: ${(props) => (!!props.isSelected ? "#6EA6FF" : "#B6B6B6")}
    }

    @media(max-width: 767px){
        font-size: 14px;

        padding: 8px;

        &::after{
            font-size: 10px;
            left: -10px;
        }
    }
`;

export const FormOptionDescription = styled.button<IFormOption>`
    border: 2px solid #B6B6B6;
    border-radius: 50%;

    width: 42px;
    height: 42px;

    transition: all 0.2s;

    color: #B6B6B6;

    position: relative;

    ${(props) =>
      !!props.isSelected
        ? css`
        border: 2px solid #6EA6FF;

        color: #6EA6FF;
    `
        : css``}

    &::before{
        content: "";
        position: absolute;
        width: 300%;
        height: 2px;
        background: ${(props) => (!!props.isSelected ? "#6EA6FF" : "#B6B6B6")};
        left: -120px;
        top: 16px;
        cursor: default;
    }

    &::after{
        content: "DESCRIÇÃO";
        position: absolute;
        bottom: -24px;
        left: -16px;
        font-size: 12px;
        color: ${(props) => (!!props.isSelected ? "#6EA6FF" : "#B6B6B6")}
    }

    @media(max-width: 767px){
        font-size: 14px;

        padding: 8px;

        &::before{
            width: 145%;
            left: -55px;
        }

        &::after{
            font-size: 10px;
            left: -10px;
        }
    }
`;

export const FormOptionCharacteristics = styled.button<IFormOption>`
    border: 2px solid #B6B6B6;
    border-radius: 50%;

    width: 42px;
    height: 42px;

    transition: all 0.2s;

    position: relative;

    color: #B6B6B6;

    ${(props) =>
      !!props.isSelected
        ? css`
        border: 2px solid #6EA6FF;

        color: #6EA6FF;
    `
        : css``}

    &::before{
        content: "";
        position: absolute;
        width: 300%;
        height: 2px;
        background: ${(props) => (!!props.isSelected ? "#6EA6FF" : "#B6B6B6")};
        left: -120px;
        top: 16px;
        cursor: default;
    }

    &::after{
        content: "CARACTERÍSTICAS";
        position: absolute;
        bottom: -24px;
        left: -34px;
        font-size: 12px;
        color: ${(props) => (!!props.isSelected ? "#6EA6FF" : "#B6B6B6")}
    }

    @media(max-width: 767px){
        font-size: 14px;

        padding: 8px;

        &::before{
            width: 145%;
            left: -55px;
        }

        &::after{
            font-size: 10px;
            left: -26px;
        }
    }
`;

export const FormOptionPhotos = styled.button<IFormOption>`
    border: 2px solid #B6B6B6;
    border-radius: 50%;

    width: 42px;
    height: 42px;

    transition: all 0.2s;

    color: #B6B6B6;

    position: relative;

    ${(props) =>
      !!props.isSelected
        ? css`
        border: 2px solid #6EA6FF;

        color: #6EA6FF;
    `
        : css``}

    &::before{
        content: "";
        position: absolute;
        width: 300%;
        height: 2px;
        background: ${(props) => (!!props.isSelected ? "#6EA6FF" : "#B6B6B6")};
        left: -120px;
        top: 16px;
        cursor: default;
    }

    &::after{
        content: "FOTOS";
        position: absolute;
        bottom: -24px;
        left: -2px;
        font-size: 12px;
        color: ${(props) => (!!props.isSelected ? "#6EA6FF" : "#B6B6B6")}
    }

    @media(max-width: 767px){
        font-size: 14px;

        padding: 8px;

        &::before{
            width: 145%;
            left: -55px;
        }

        &::after{
            font-size: 10px;
            left: 5;
        }
    }
`;

export const DescriptionForm = styled.div`
    width: 100%;

    background-color: #FFF;

    padding: 24px;

    >p{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        margin: 24px 0 12px;

        font-weight: 500;

        color: #666666;

        >p{
            margin-left: 6px;

            font-size: 10px;
            font-weight: bold;
        }
    }

    #initial-label{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >input{
        width: 100%;

        border: 0;
        border-bottom: 1px solid #B6B6B6;

        border-radius: 0;

        padding: 10px;

        margin-bottom: 26px;
    }

    >textarea{
        width: 100%;
        height: 200px;

        resize: none;

        border: 1px solid #B6B6B6;

        border-radius: 0;

        padding: 8px;

        margin-bottom: 26px;
    }

    @media(max-width: 767px){
        >p{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            flex-direction: column;

            >p{
                margin-left: 0;
            }
        }
    }
`;

export const OptionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 12px;

    >label{
        margin: 0 16px 0 4px;
    }
`;

export const PropertyTypeAndIsRentedContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin-top: 16px;

    @media(max-width: 767px){
        flex-direction: column;

        align-items: center;
        
        justify-content: center;
    }
`;

export const PropertyTypesContentColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
`;

export const PropertyTypesContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 12px;

    padding: 12px;

    background: #F9F9F9;

    @media(max-width: 767px){
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        width: 100%;
    }
`;

export const PropertyTypesContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    >label{
        margin: 0 64px 0 6px;

        cursor: pointer;
    }

    @media(max-width: 767px){
        margin-top: 8px;
    }
`;

export const IsRentedContainer = styled.div`
    width: auto;

    display: flex;
    align-items: center;
    justify-items: center;

    flex-direction: column;

    & + div{
        margin-left: 16px;
    }

    @media(max-width: 767px){
        margin-top: 16px;
        margin-left: 0;
    }
`;

export const RentAndIsRentedRadioContent = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    >p{
        margin-right: 8px;
    }
`;

export const IsRentedRadioContent = styled.div`
    width: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 12px 0 18px 8px;

    background: #dd6435;

    padding: 12px;

    >p{
        margin: 0 8px;

        color: #FFF;

        cursor: pointer;
    }

    .isRentedRadio{
        color: #FFF;
    }

    .MuiRadio-colorSecondary.Mui-checked{
		color: #FFF;
	}
`;

export const PropertyOwnerAndPropertyConsultantContainer = styled.div`
    width: 100%;

    margin: 48px 0 48px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    font-weight: 500;

    color: #666666;

    .css-2b097c-container{
        width: 100%;

        margin-top: 12px;
    }

    .css-yk16xz-control{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        border-radius: 0;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    @media(max-width: 767px){
        flex-direction: column;
    }
`;

export const PropertyOwnerContainer = styled.div`
    width: 45%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >span{
        font-size: 10px;
    }

    >p{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        flex-direction: column;

        >p{
            font-size: 10px;
            font-weight: bold;
        }
    }

    @media(max-width: 767px){
        width: 100%;

        margin-bottom: 16px;
    }
`;

export const FormContainer = styled.div`
    width: 100%;

    position: relative;

    >p{
        margin: 16px 0 14px;
    }

    >input{
        width: 100%;

        padding: 14px 12px;

        border: 0;
        border-bottom: 1px solid #B6B6B6;

        cursor: default;
    }

    .css-2b097c-container{
        width: 100%;
        
        &:last-child{
            margin-bottom: 64px;
        }
    }

    .css-yk16xz-control{
        border-color: #04c5ff;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    #users{
        width: 100%;
        max-height: 350px;

        overflow-y: auto;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        flex-direction: column;

        position: absolute;

        left: 50;
        top: 25;
        z-index: 10000;

        background: #FFFFFF;

        border: 1px solid #B6B6B6;

        >button{
            padding: 8px 16px;

            width: 100%;

            font-size: 14px;

            text-align: start;
            
            transition: all 0.2s;

            &:hover{
                background: #F9F9F9;
            }
        }
    }

    .MuiAutocomplete-root{
        margin-bottom: 16px;
    }

    @media(max-width: 767px){
        >input{
            width: 100%;
        }

        .css-2b097c-container{
            width: 100%;
        }
    }
`;

export const PropertyConsultantContainer = styled.div`
    width: 45%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    margin-top: 12px;
    margin-left: auto;

    >p{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        flex-direction: column;

        >p{
            font-size: 10px;
            font-weight: bold;
        }
    }

    @media(max-width: 767px){
        width: 100%;

        margin-left: 0;
    }
`;

export const PropertyTitleContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
>p{
    margin: 16px 0 14px;
}

>input{
    width: 100%;

    padding: 14px 12px;

    border: 0;
    border-bottom: 1px solid #B6B6B6;

    cursor: default;
}
`;

export const PropertyRankContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;

    >label{
        margin: 24px 0 12px;
        font-weight: 500;
        color: #666666;
    }

    @media(max-width: 767px){
        width: 100%;

        margin-left: 0;
    }
`;

export const RoomsSuitesBathroomsGarageContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 18px 0;

    @media(max-width: 767px){
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
`;

export const RoomsSuitesBathroomsGarageContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >label{
        margin-bottom: 8px;

        font-weight: 500;
        color: #666666;

        >p{
            font-size: 10px;
            font-weight: bold;
        }
    }

    >input{
        border: 1px solid #04c5ff;
        border-radius: 4px;

        padding: 8px;

        &:focus-within{
            border: 3px solid rgba(4, 197, 255, 0.3);
        }
    }

    .css-2b097c-container{
        width: 213px;
    }

    .css-yk16xz-control{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        border-radius: 0;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    @media(max-width: 767px){
        width: 100%;

        >label{
            margin: 16px 0 0;
        }

        .css-2b097c-container{
            width: 100%;
        }
    }
`;

export const RentIptuCondominiumValueAreaContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 48px 0;

    @media(max-width: 767px){
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
`;

export const RentIptuCondominiumValueAreaContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >label{
        margin-bottom: 8px;

        font-weight: 500;
        color: #666666;

        >p{
            font-size: 10px;
            font-weight: bold;
        }
    }

    >input{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        border-radius: 0;

        padding: 8px;
    }

    >p{
        font-size: 12px;

        margin-top: 4px;
    }

    @media(max-width: 767px){
        width: 100%;

        >label{
            margin: 16px 0 0;
        }

        >input{
            width: 100%;
        }
    }
`;

export const AllowCatAndIsFunishedContainer = styled.div`
    width: 90%;

    display: flex;
    align-items: center;
    justify-items: center;

    margin: 18px auto 48px;

    @media(max-width: 767px){
        flex-direction: column;
    }
`;

export const GuaranteesContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    margin: 1rem auto 2rem;
    

    >div#inputs-container {
        margin-top: 1rem;
        padding: 1rem;
        width: 100%;
        display: grid;
        grid-template-columns: 19% 19% 19% 19% 19%;
        column-gap: 1.25%;
        row-gap: 0.75rem; 
        background: #F9F9F9;

        >div#input-container {
            display: flex;
            gap: 5%;
            
        }   
    }
    & + div{
        margin-left: 16px;
    }

    @media(max-width: 767px){
        & + div{
            margin-top: 16px;
            margin-left: 0;
        }
    }
`;

export const AllowCatAndIsFunishedContent = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-items: center;

    flex-direction: column;

    & + div{
        margin-left: 16px;
    }

    @media(max-width: 767px){
        & + div{
            margin-top: 16px;
            margin-left: 0;
        }
    }
`;

export const RadioContentToDescriptionForm = styled.div`
    width: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 6px 0 18px;

    background: #F9F9F9;

    padding: 12px;

    >p{
        margin: 0 8px;

        cursor: pointer;
    }
`;

export const MapForm = styled.form`
    width: 100%;

    background-color: #FFF;

    padding: 24px;

    >p{
        margin: 24px 0 12px;

        font-weight: 500;

        color: #666666;
    }

    .p-example{
        font-size: 12px;
        font-weight: 400;

        color: #666666;

        margin: 12px 0 48px;
    }

    .css-2b097c-container{
        width: 100%;
    }

    .css-yk16xz-control{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        border-radius: 0;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    @media(max-width: 767px){
        .p-example{
            font-size: 10px;
        }
    }
`;

export const LocalDataContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    background-color: #F9F9F9;

    margin-top: 24px;

    padding: 40px 0;

    position: relative;

    >label{
        width: 45%;

        padding: 8px 0;

        background: #DD6435;

        font-weight: 500;
        color: #FFFFFF;

        text-align: center;

        position: absolute;
        top: -16px;
        left: 50;
    }

    @media(max-width: 767px){
        >label{
            width: 90%;

            font-size: 12px;
        }
    }
`;

export const DivToSeparate = styled.div`
    align-self: center;

    width: 95%;
    height: 1px;

    background-color: #FFF;

    margin: 24px 0;
`;

export const LocalDataContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 6px;

    @media(max-width: 767px){
        flex-direction: column;
    }
`;

export const LocalContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >label{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        font-size: 14px;
        font-weight: 500;

        color: #666666;

        margin-bottom: 6px;

        >p{
            margin-left: 6px;

            font-size: 10px;
            font-weight: bold;
            
        }
    }

    >input{
        width: 100%;

        border: 0;
        border-bottom: 1px solid #B6B6B6;

        color: #666666;

        background: transparent;

        padding: 8px;
    }

    & + div{
        margin-left: 18px;
    }

    @media(max-width: 767px){
        & + div{
            margin-left: 0;
            margin-top: 16px;
        }
    }
`;

export const CharacteristicFormContainer = styled.form`
    width: 100%;

    background-color: #FFF;

    padding: 24px;

    >h3{
        color: #000;

        font-size: 28px;

        text-align: center;
    }
`;

export const CharacteristicContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @media(max-width: 767px){
        flex-direction: column;
    }
`;

export const CharacteristicContent = styled.div`
    width: 48%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    margin-top: 24px;

    & + div{
        margin-left: auto;
    }

    @media(max-width: 767px){
        width: 100%;
    }
`;

export const BigCharacteristicContent = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    margin-top: 24px;
`;

export const CharacteristicInitialContainer = styled.div`
    width: 100%;

    padding: 18px;

    font-size: 22px;
    font-weight: 500;

    color: #666666;

    text-align: center;
`;

export const CharacteristicRadiosContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-wrap: wrap;

    background: #F9F9F9;

    padding: 24px 18px 8px;
`;

export const RadioContainer = styled.div`
    width: 210px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    margin-left: 28px;

    >p{
        font-size: 14px;
        font-weight: 500;

        color: #666666;
    }
`;

export const RadioContent = styled.div`
    width: 200px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 6px 0 18px;

    background: #F9F9F9;

    padding: 12px 0;

    >p{
        margin: 0 8px;

        cursor: pointer;
    }
`;

export const PhotosForm = styled.form`
    padding: 24px;

    background-color: #FFF;

    width: 100%;

    margin: 40px 0 25%;

    >p{
        margin-top: 24px;

        color: rgb(255, 56, 56);
    }

    >span{
        font-size: 12px;

        color: #666666;
    }
`;

export const DropZoneContainer = styled.div`
    height: 130px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    text-align: center;

    background-color: #F9F9F9;

    color: #666666;

    font-weight: bold;

    margin-top: 8px;

    >p{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const PhotoContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-wrap: wrap;
`;

export const NextButton = styled.button`
    width: 15%;

    padding: 12px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #6EA5FF;

    border: 0;

    margin-left: 8px;

    >p{
        color: #FFF;
    }

    >svg{
        margin-left: 4px;
    }

    @media(max-width: 767px){
        width: 45%;
    }
`;

export const RegisterButton = styled.button`
    width: 15%;

    padding: 12px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #00BF33;

    border: 0;

    margin-left: 8px;

    >p{
        color: #FFF;
    }

    @media(max-width: 767px){
        width: 45%;
    }
`;

export const PublishOnImovelWebButton = styled.button`
    width: 20%;

    padding: 12px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #DD6435;

    border: 0;

    margin-left: 8px;

    >p{
        color: #FFF;
    }

    @media(max-width: 767px){
        width: 45%;
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 24px auto 0;
`;
