import styled from "styled-components";

export const Dialog = styled.dialog`
  max-width: 740px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  align-self: center;
  margin: auto;

  &[open] {
    animation: slide-in-from-top 0.25s ease-out;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.6);
  }

  @keyframes slide-in-from-top {
    0% {
      transform: translateY(-15%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div`
  border-bottom: 1px solid #bcbfc2;
  display: flex;
  justify-content: center;

  padding: 24px 0px 24px 0px;

  width: 400px;
  > h3 {
    color: #666666;
    text-transform: uppercase;
    font-weight: 600;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    padding: 24px 0 24px 24px;

    width: 330px;
  }
`;

export const DataContainer = styled.form`
  width: 320px;

  padding-bottom: 4px;

  align-items: flex-start;
  justify-content: center;

  flex-direction: column;

  margin: 0px 40px 0px;

  > h3 {
    color: #dd6435;
    font-size: 16px;

    font-weight: 500;

    margin: 12px 0 4px;
  }

  > p {
    margin: 16px 0 14px;
  }

  > input {
    width: 100%;

    padding: 14px 12px;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    cursor: default;
  }

  @media (max-width: 767px) {
    width: 80%;
  }
`;

export const ButtonContainer = styled.div`
  margin: 16px 0;
  gap: 16px;
  display: flex;

  justify-content: center;

  @media (max-width: 767px) {
    width: 90%;
  }
`;

export const Button = styled.button`
  width: 50%;

  border: 0;

  padding: 12px;

  color: #fff;

  font-weight: 500;
  text-transform: uppercase;
  background-color: ${({ type }) =>
    type === "submit" ? "#6ea5ff" : "#bf0001"};
`;
