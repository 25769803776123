import Layout from "../../components/Layout";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {Content,
        CreateFAQForm,
        CreateArticleForm,
        CategoryLabelContainer,
        ButtonsContainer,
        TagsContainer,
        Tag,
        RadiosContainer,
        RadioContent,
        AnnexImageContainer} from './styles';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import ModalCreateCategory from "../../components/ModalCreateCategory";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";
import { useAuth } from "../../hooks/auth";
import loadFile from "../../utils/loadFile";
import { useToast } from "../../hooks/toast";
import { FiX } from "react-icons/fi";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Radio } from "@material-ui/core";
import { useRefresh } from "../../hooks/refresh";

import * as Yup from 'yup';
import getValidationErrors from "../../utils/getValidationError";
import { useHistory } from "react-router";

interface ICategory{
    id: number;
    name: string;
}

interface IData{
    name: string;
}

interface IAutocomplete{
    value: number;
    label: string;
}

export default function CreateFAQ(){
    const history = useHistory();

    const { access_token, dateToExpires } = useAuth();
    const { addToast } = useToast();
    const { refresh, handleRefresh } = useRefresh();

    const [openModalCreateCategory, setOpenModalCreateCategory] = useState(false);

    const [title, setTitle] = useState('');
    const [article, setArticle] = useState('');
    
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [categorySelected, setCategorySelected] = useState({} as IAutocomplete);

    const [tagTyped, setTagTyped] = useState('');

    const [tags, setTags] = useState<string[]>([]);

    const [author, setAuthor] = useState('');
    const [authorRole,setAuthorRole] = useState('');

    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingRequestToCreateCategory, setLoadingRequestToCreateCategory] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);

    const [contentType, setContentType] = useState<'faq' | 'blog'>('faq');

    const [thumbFilename, setThumbFilename] = useState({
        fileName: '',
        url: '',
    });
    const [authorFilename, setAuthorFilename] = useState({
        fileName: '',
        url: '',
    });

    const [uploadImagesIsLoading, setUploadImagesIsLoading] = useState({
        thumb: false,
        author: false,
    });

    useEffect(() => {
        async function loadApi(){
            setLoadingCategories(true);

            try{
                const token = await refreshToken(dateToExpires, access_token);

                api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                const response = await api.get('/api/adm/faq-category');

                setCategories(response.data.data);
            } catch(err){
                console.log(err);
            }

            setLoadingCategories(false);
        }

        loadApi();
    }, [refresh, dateToExpires, access_token]);

    const imageHandler = useCallback(async() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('multiple', 'true');
        input.click();
        input.onchange = async function() {
            if(!!input.files){
                const files = Array.from(input.files);

                files.forEach(async(file) => {
                    try{
                        const token = await refreshToken(dateToExpires, access_token);

                        api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                        const imageBase64 = await loadFile(file);

                        const imageBase64Formated = imageBase64.replaceAll('data:image/jpeg;base64,', '').replaceAll('data:image/png;base64,', '');

                        const response = await api.post('/api/adm/faq/photo', {
                            photo: imageBase64Formated,
                        });

                        setArticle(prevValue => `${prevValue.substring(0, prevValue.length - 4)} <img src=${response.data.data.url} alt="file"/> ${prevValue.substring(prevValue.length - 4, prevValue.length)}`)
                    } catch(err){
                        console.log(err);

                        if(!!err.response.data.errors){
                            const errorsNumber = Object.keys(err.response.data.errors).length;

                            for(let i = 0; i < errorsNumber; i++){
                                err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                                    addToast({
                                        title: error,
                                        type: 'error',
                                    }); 
                                })
                            }
                        };

                        if(!!err.response?.data.message){
                            addToast({
                                title: err.response.data.message,
                                type: 'error'
                            });
                        }
                    }
                });
            }
        }; // react thing
    }, [addToast, access_token, dateToExpires]);

    const modules = useMemo(() => {
        return ({
            /* toolbar: {
              handlers: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean'],
                [{image: imageHandler()}]
            ],
            }, */
            toolbar: {
                handlers: {image: imageHandler},
                container: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, 
                     {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image'],
                    ['clean']
                ]
            },
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            }
          });
    }, [imageHandler]);

    const formats = useMemo(() => {
        return ([
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
          ]);
    }, []);

    const optionsFormatted = useMemo(() => {
        return categories.map(category => ({
            value: category.id,
            label: category.name
        }));
    }, [categories]);

    const handleCreateCategory = useCallback(async(data: IData) => {
        setLoadingRequestToCreateCategory(true);

        try{
            const schema = Yup.object().shape({
                name: Yup.string().required('Campo obrigatório!')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/faq-category', data);

            if(!!response.data.success){
                addToast({
                    title: "Categoria criada!",
                    type: 'success',
                    description: ''
                });

                setCategorySelected({
                    value: response.data.data.id,
                    label: response.data.data.name
                });

                handleRefresh();
                setOpenModalCreateCategory(false);
            };
        } catch(err){
            console.log(err);

            if(err instanceof Yup.ValidationError){
                const errors = getValidationErrors(err);

                addToast({
                    title: errors[Object.keys(errors)[0]],
                    type: 'info',
                    description: ''
                });   
            }

            else if(!!err.response.data.errors){
                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            };

            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }
        }

        setLoadingRequestToCreateCategory(false);
    }, [addToast, handleRefresh, access_token, dateToExpires]);

    const handleAddTag = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(tags.some(tag => tag === tagTyped)){
            addToast({
                title: 'Tag já adicionada',
                type: 'error',
            });
        } else{
            setTags(prevValue => [...prevValue, tagTyped]);
        }

        setTagTyped('');
    }, [tagTyped, tags, addToast]);

    const handleRemoveTag = useCallback((tag: string) => {
        const newArray = tags.filter(tagFinded => tagFinded !== tag);

        setTags(newArray);
    }, [tags]);

    const handleSelectUser = useCallback((category_id: number | undefined) => {
        const category_selected = categories.find(client => (client.id === category_id));

        if(!!category_selected){
            setCategorySelected({
                value: category_selected.id,
                label: category_selected.name
            });
        }
    }, [categories]);

    const handleRegister = useCallback(async() => {
        setLoadingRequest(true);

        try{
            const dataToRequest = {
                title,
                category_id: String(categorySelected.value),
                tags: tags.join(),
                article,
                type: 'faq'
            };

            const schema = Yup.object().shape({
                title: Yup.string().required('Campo Obrigatório'),
                category_id: Yup.string().required('Campo Obrigatório'),
                article: Yup.string().required('Campo Obrigatório'),
            });

            await schema.validate(dataToRequest, {
                abortEarly: false
            });

            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/faq', {
                ...dataToRequest, category_id: categorySelected.value,
            });

            if(!!response.data.success){
                addToast({
                    title: "FAQ criada!",
                    type: 'success',
                    description: ''
                });

                history.push('/list-faqs/1');
            };
        } catch(err){
            console.log(err);

            if(err instanceof Yup.ValidationError){
                const errors = getValidationErrors(err);

                addToast({
                    title: errors[Object.keys(errors)[0]],
                    type: 'info',
                    description: ''
                });   
            }

            else if(!!err.response.data.errors){
                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            };

            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }
        }

        setLoadingRequest(false);
    }, [history, title, article, categorySelected, tags, dateToExpires, access_token, addToast]);

    const handleChangeThumbImage = useCallback(async(e: ChangeEvent<HTMLInputElement>, fileType: 'authorImage' | 'thumbImage') => {
        console.log(e.target);

        setUploadImagesIsLoading(prevValue => (fileType === 'authorImage' ? {
            ...prevValue,
            author: true,
        } : {
            ...prevValue,
            thumb: true,
        }))

        if(!!e.target.files){
            try{
                const token = await refreshToken(dateToExpires, access_token);

                api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                const imageBase64 = await loadFile(e.target.files[0]);

                const imageBase64Formated = imageBase64.replaceAll('data:image/jpeg;base64,', '').replaceAll('data:image/png;base64,', '');

                const response = await api.post('/api/adm/faq/photo', {
                    photo: imageBase64Formated,
                });

                if(fileType === 'authorImage'){
                    setAuthorFilename({
                        url: response.data.data.url,
                        fileName: e.target.files ? e.target.files[0].name : '',
                    });
                } else {
                    setThumbFilename({
                        url: response.data.data.url,
                        fileName: e.target.files ? e.target.files[0].name : '',
                    });
                }
            } catch(err){
                console.log(err);

                if(!!err.response.data.errors){
                    const errorsNumber = Object.keys(err.response.data.errors).length;

                    for(let i = 0; i < errorsNumber; i++){
                        err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                            addToast({
                                title: error,
                                type: 'error',
                            }); 
                        })
                    }
                };

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }
            } finally{
                setUploadImagesIsLoading({
                    thumb: false,
                    author: false,
                })
            }
        }
    }, [access_token, dateToExpires, addToast]);

    const handleCreateArticle = useCallback(async() => {
        setLoadingRequest(true);

        try{
            const dataToRequest = {
                title,
                category_id: String(categorySelected.value),
                tags: tags.join(),
                article,
                type: 'article',
                author,
                author_profession: authorRole,
                thumbnail: thumbFilename.url,
                author_photo: authorFilename.url,
            };

            const schema = Yup.object().shape({
                title: Yup.string().required('Campo Obrigatório'),
                thumbnail: Yup.string().required('Campo Obrigatório'),
                author_photo: Yup.string().required('Campo Obrigatório'),
                category_id: Yup.string().required('Campo Obrigatório'),
                article: Yup.string().required('Campo Obrigatório'),
                author: Yup.string().required('Campo Obrigatório'),
                author_profession: Yup.string().required('Campo Obrigatório'),
            });

            await schema.validate(dataToRequest, {
                abortEarly: false
            });

            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/faq', {
                ...dataToRequest, category_id: categorySelected.value,
            });

            if(!!response.data.success){
                addToast({
                    title: "Artigo criada!",
                    type: 'success',
                    description: ''
                });

                history.push('/list-faqs/1');
            };
        } catch(err){
            console.log(err);

            if(err instanceof Yup.ValidationError){
                const errors = getValidationErrors(err);

                addToast({
                    title: errors[Object.keys(errors)[0]],
                    type: 'info',
                    description: ''
                });   
            }

            else if(!!err.response.data.errors){
                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            };

            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }
        }

        setLoadingRequest(false);
    }, [history, title, article, author, authorFilename, thumbFilename, authorRole, categorySelected, tags, dateToExpires, access_token, addToast]);

    const handleResetForm = useCallback(() => {
        setTitle('');
        setCategorySelected({} as IAutocomplete);
        setArticle('');
        setAuthorFilename({
            fileName: '',
            url: ''
        });
        setThumbFilename({
            fileName: '',
            url: ''
        });
        setAuthorRole('');
        setAuthor('');
        setTags([]);
    }, []);

    return(
        <Layout>
            {!!openModalCreateCategory && (
                <ModalCreateCategory 
                    open={openModalCreateCategory} 
                    isLoading={loadingRequestToCreateCategory} 
                    functionToCreateModal={handleCreateCategory}
                    functionToCloseModal={() => setOpenModalCreateCategory(false)}
                />
            )}

            <Content>
                <h3>Adicionar Artigo/FAQ</h3>

                <RadiosContainer>
                    <RadioContent>
                        <Radio 
                            checked={contentType === 'blog'}
                            onChange={() => setContentType('blog')}
                        />
                        <p onClick={() => setContentType('blog')}>Artigo do blog</p>
                    </RadioContent>

                    <RadioContent>
                        <Radio 
                            checked={contentType === 'faq'}
                            onChange={() => setContentType('faq')}
                        />
                        <p onClick={() => setContentType('faq')}>Pergunta frequentes</p>
                    </RadioContent>
                </RadiosContainer>

                {contentType === 'faq' && (
                    <CreateFAQForm>
                        <label>Pergunta: </label>
                        <input 
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />

                        <CategoryLabelContainer>
                            <label>Categoria: </label>

                            <button type='button' onClick={() => setOpenModalCreateCategory(true)}>+ ADICIONAR CATEGORIA</button>
                        </CategoryLabelContainer>
                        <Autocomplete 
                            id="combo-box-demo"
                            options={optionsFormatted}
                            getOptionLabel={(option) => option.label}
                            value={categorySelected}
                            style={{ width: '100%' }}
                            noOptionsText="Sem opções"
                            onChange={(e, value) => handleSelectUser(value?.value)}
                            renderInput={(params) => <TextField {...params} label={loadingCategories ? 'Carregando...' : ''} margin="normal"/>}
                        />

                        <label>Resposta: </label>
                        <ReactQuill 
                            id="react-quill"
                            theme="snow" 
                            value={article} 
                            onChange={setArticle}
                            modules={modules}
                            formats={formats}
                        />

                        <p>*Clique enter para confirmar</p>
                        <label>Tags: </label>
                        <TagsContainer onSubmit={handleAddTag}>
                            <>
                                {tags.map(tag => (
                                    <Tag>
                                        <p>{tag}</p>
                                        <button onClick={() => handleRemoveTag(tag)} type="button"><FiX size={12} color="#FFF"/></button>
                                    </Tag>
                                ))}
                                <input 
                                    value={tagTyped}
                                    onChange={e => setTagTyped(e.target.value)}
                                />
                            </>
                        </TagsContainer>

                        <ButtonsContainer>
                            <button type="button" onClick={loadingRequest ? () => {} : handleRegister}>{loadingRequest ? "CARREGANDO..." : "CADASTRAR"}</button>
                            <button type="button" onClick={handleResetForm}>LIMPAR</button>
                        </ButtonsContainer>
                    </CreateFAQForm>
                )}

                {contentType === 'blog' && (
                    <CreateArticleForm>
                        <label>Título: </label>
                        <input 
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />

                        <AnnexImageContainer>
                            <label>
                                <p>{uploadImagesIsLoading.thumb ? "CARREGANDO..." :  "ANEXAR ARQUIVO"}</p>
                                {uploadImagesIsLoading.thumb ? <></> : <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={e => handleChangeThumbImage(e, 'thumbImage')}/>}
                            </label>

                            <p>{!!thumbFilename.fileName ? thumbFilename.fileName :  "Escolha uma foto de capa"}</p>
                        </AnnexImageContainer>

                        <CategoryLabelContainer>
                            <label>Categoria: </label>

                            <button type='button' onClick={() => setOpenModalCreateCategory(true)}>+ ADICIONAR CATEGORIA</button>
                        </CategoryLabelContainer>
                        <Autocomplete 
                            id="combo-box-demo"
                            options={optionsFormatted}
                            getOptionLabel={(option) => option.label}
                            value={categorySelected}
                            style={{ width: '100%' }}
                            noOptionsText="Sem opções"
                            onChange={(e, value) => handleSelectUser(value?.value)}
                            renderInput={(params) => <TextField {...params} label={loadingCategories ? 'Carregando...' : ''} margin="normal"/>}
                        />

                        <label>Autor: </label>
                        <input 
                            value={author}
                            onChange={e => setAuthor(e.target.value)}
                        />

                        <AnnexImageContainer>
                            <label>
                                <p>{uploadImagesIsLoading.author ? "CARREGANDO..." : "ANEXAR ARQUIVO"}</p>
                                {uploadImagesIsLoading.author ? <></> : <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={e => handleChangeThumbImage(e, 'authorImage')}/>}
                            </label>

                            <p>{authorFilename.fileName ? authorFilename.fileName : "Escolha uma foto do autor"}</p>
                        </AnnexImageContainer>

                        <label>Cargo do Autor: </label>
                        <input 
                            value={authorRole}
                            onChange={e => setAuthorRole(e.target.value)}
                        />  

                        <label>Texto: </label>
                        <ReactQuill 
                            id="react-quill"
                            theme="snow" 
                            value={article} 
                            onChange={setArticle}
                            modules={modules}
                            formats={formats}
                        />

                        <p>*Clique enter para confirmar</p>
                        <label>Tags: </label>
                        <TagsContainer onSubmit={handleAddTag}>
                            <>
                                {tags.map(tag => (
                                    <Tag>
                                        <p>{tag}</p>
                                        <button onClick={() => handleRemoveTag(tag)} type="button"><FiX size={12} color="#FFF"/></button>
                                    </Tag>
                                ))}
                                <input 
                                    value={tagTyped}
                                    onChange={e => setTagTyped(e.target.value)}
                                />
                            </>
                        </TagsContainer>

                        <ButtonsContainer>
                            <button type="button" onClick={loadingRequest ? () => {} : handleCreateArticle}>{loadingRequest ? "CARREGANDO..." : "CADASTRAR"}</button>
                            <button type="reset" onClick={handleResetForm}>LIMPAR</button>
                        </ButtonsContainer>
                    </CreateArticleForm>
                )}
            </Content>
        </Layout>
    );
}