import STEPS from "../../pages/UploadRentalBelt/enum";
import ImageComponent from "./ImageComponent";
import { BlueLineContainer, Container, GrayLineContainer } from "./styles";

interface IBeltImage {
  currentStep: number;
  steps: string[];
}

const UploadBeltImage: React.FC<IBeltImage> = ({
  currentStep,
  steps = [],
}: IBeltImage) => {
  const renderBlueLine = (step: number) => {
    switch (step) {
      case STEPS.FILES_UPLOAD:
        return <BlueLineContainer id="first-step" />;
      case STEPS.SIGNERS_REGISTER:
        return <BlueLineContainer id="second-step" />;
    }
  };

  return (
    <Container>
      {steps.map((label, index) => {
        return (
          <ImageComponent
            key={label}
            label={label}
            isCurrent={index + 1 === currentStep ? true : false}
            alreadyFinished={currentStep > index + 1 ? true : false}
          />
        );
      })}
      <GrayLineContainer />
      {renderBlueLine(currentStep)}
    </Container>
  );
};

export default UploadBeltImage;
