import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import {Content,
        TitleContainer,
        DataContainer,
        ButtonContainer} from './styles';

import { FiX } from 'react-icons/fi';

interface IUsers{
    first_name: string;
    email: string;
    phone: string;
}

interface IModalShowClients{
    open: boolean;
    functionToClose(): void;
    client: IUsers;
    owner: IUsers;
}

export default function ModalShowClients({ open, functionToClose, client, owner }: IModalShowClients){
    return(
        <Modal open={open} onClose={functionToClose} classNames={{
            modal: 'modalClients'
        }} closeIcon={(<FiX size={18} color="#000"/>)}>
            <Content>
                <TitleContainer>
                    <h3>Dados do Cliente</h3>
                </TitleContainer>

                <DataContainer>
                    <h3>Cliente</h3>

                    <p><b>Nome: </b> {client.first_name}</p>
                    <p><b>Email: </b> {client.email}</p>
                    <p><b>Telefone: </b> {client.phone}</p>
                </DataContainer>

                <DataContainer>
                    <h3>Proprietário</h3>

                    <p><b>Nome: </b> {!!owner ? owner.first_name : ''}</p>
                    <p><b>Email: </b> {!!owner ? owner.email : ''}</p>
                    <p><b>Telefone: </b> {!!owner ? owner.phone : ''}</p>
                </DataContainer>

                <ButtonContainer>
                    <button onClick={functionToClose}>Fechar</button>
                </ButtonContainer>
            </Content>
        </Modal>
    );
}