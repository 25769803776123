import styled from "styled-components";


export const Wrapper = styled.form`
  padding: 24px;

  background-color: #fff;

  width: 100%;

  margin: 40px 0 25%;

  > p {
    margin-top: 24px;

    color: rgb(255, 56, 56);
  }

  > span {
    font-size: 12px;

    color: #666666;
  }
`;

export const DropZoneWrapper = styled.div`
  height: 130px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  text-align: center;

  background-color: #f9f9f9;

  color: #666666;

  font-weight: bold;

  margin-top: 8px;

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PhotoWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-wrap: wrap;
`;

export const RegisterButton = styled.button`
  width: 15%;

  padding: 12px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #00bf33;

  border: 0;

  margin-left: 8px;

  > p {
    color: #fff;
  }

  @media (max-width: 767px) {
    width: 45%;
  }
`;
