import styled from 'styled-components';

export const Content = styled.div`
    max-width: 600px;

    >hr{
        width: 100%;
        height: 0.1px;

        color: rgba(188, 191, 194, 0.2);
    }

    @media(max-width: 767px){
        width: 122%;
    }
`;

export const TitleContainer = styled.div`
    padding: 24px 300px 24px 24px;

    @media(max-width: 767px){
        padding: 24px 130px 24px 24px;
    }
`;

export const FormContainer = styled.form`
    padding: 12px 24px 0 24px;

    position: relative;

    >p{
        margin: 16px 0 14px;
    }

    >input{
        width: 100%;

        padding: 14px 12px;

        border: 0;
        border-bottom: 1px solid #B6B6B6;

        cursor: default;
    }

    #users{
        width: 100%;
        max-height: 350px;

        overflow-y: auto;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        flex-direction: column;

        position: absolute;

        left: 50;
        top: 25;
        z-index: 10000;

        background: #FFFFFF;

        border: 1px solid #B6B6B6;

        >button{
            padding: 8px 16px;

            width: 100%;

            font-size: 14px;

            text-align: start;
            
            transition: all 0.2s;

            &:hover{
                background: #F9F9F9;
            }
        }
    }

    .css-2b097c-container{
        width: 100%;
        
        &:last-child{
            margin-bottom: 64px;
        }
    }

    .css-yk16xz-control{
        border-color: #04c5ff;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }

    #users{
        width: 21.5%;
        max-height: 350px;

        overflow-y: auto;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        flex-direction: column;

        position: fixed;

        left: 50;
        top: 25;
        z-index: 10000;

        background: #FFFFFF;

        border: 1px solid #B6B6B6;

        >button{
            padding: 8px 16px;

            width: 100%;

            font-size: 14px;

            text-align: start;
            
            transition: all 0.2s;

            &:hover{
                background: #F9F9F9;
            }
        }
    }

    .MuiAutocomplete-root{
        margin-bottom: 16px;
    }

    @media(max-width: 767px){
        >input{
            width: 100%;
        }

        #users{
            width: 68.2%;
        }

        .css-2b097c-container{
            width: 100%;
        }
    }
`;

export const CheckboxContainer = styled.div`
    input {
        margin-right: 8px;
    }
`

export const ButtonsContainer = styled.div`
    width: 75%;

    padding: 18px 0 6px;

    >button{
        background-color: #04c5ff;

        border: 0;
        border-radius: 4px;

        padding: 12px;

        margin-left: 100%;

        color: #FFF;
    }

    >p{
        width: 35%;

        background-color: transparent;

        border: 1px solid #04c5ff;
        border-radius: 4px;

        padding: 12px;

        margin-left: 90%;

        color: #04c5ff;
    }

    @media(max-width: 767px){
        width: 67%;

        >p{
            width: 53%;

            margin-left: 85%;
        }
    }
`;