import { Skeleton } from "@material-ui/lab";
import { ButtonsContainer, Content, DataContainer } from "./styles";

const ModalSkeleton: React.FC = () => {
  return (
    <Content>
      <Skeleton variant="text" width="70%" height="3rem" />
      <Skeleton variant="rect" height="200px" width="100%" />
      <DataContainer>
        <Skeleton variant="text" height='2rem' width='45%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
      </DataContainer>
      <DataContainer>
        <Skeleton variant="text" height='2rem' width='45%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
      </DataContainer>
      <DataContainer>
        <Skeleton variant="text" height='2rem' width='45%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
        <Skeleton variant="text" height='1.5rem' width='75%'/>
      </DataContainer>
      <ButtonsContainer>
        <Skeleton variant="rect" width='47.5%' height='3rem'/>
        <Skeleton variant="rect" width='47.5%' height='3rem'/>
      </ButtonsContainer>
    </Content>
  );
};

export default ModalSkeleton;
