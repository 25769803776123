import React, { createContext, useCallback, useContext, useState } from 'react';

interface IRefreshContext{
    refresh: number;
    handleRefresh(): void;
}

const RefreshContext = createContext({} as IRefreshContext);

export const RefreshProvider: React.FC = ({ children }) => {
    const [refresh, setRefresh] = useState(0);

    const handleRefresh = useCallback(() => {
        setRefresh(prevValue => prevValue + 1);
    }, []);

    return(
        <RefreshContext.Provider value={{ refresh, handleRefresh }}>
            {children}
        </RefreshContext.Provider>
    );
}

export function useRefresh(){
    const { refresh, handleRefresh } = useContext(RefreshContext);

    return ({ refresh, handleRefresh });
}
