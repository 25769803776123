import { useEffect, useMemo, useState, useCallback } from 'react';

import api from './../../services/api';

import refreshToken from './../../utils/refreshToken';

import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { useAuth } from './../../hooks/auth';

import Layout from './../../components/Layout/';
import Loading from './../../components/Loading/';

import {Container,
        ErrorMessageContainer,
        Content,
        PropertiesContainer,
        InitialContainer,
        PropertiesTable,
        PagesContainer,
        PageOptionPrevious,
        PageOption,
        PageOptionLatter,
        SearchBarContainer,
        IconContainer} from './styles';

import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight, FiSearch } from 'react-icons/fi';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useToast } from '../../hooks/toast';
import { useRefresh } from '../../hooks/refresh';
import ModalConfirmRemoveFAQ from '../../components/ModalConfirmRemoveFAQ';

interface ITags{
    id: number;
    title: string;
    category: string;
}

interface IParams{
    page: string;
}

export default function ListFAQ(){
    const history = useHistory();
    const { params } = useRouteMatch<IParams>();

    const { dateToExpires, access_token, user } = useAuth();
    const { addToast } = useToast();
    const { refresh, handleRefresh } = useRefresh();

    const [tags, setTags] = useState<ITags[]>([]);
    const [lastPage, setLastPage] = useState<number>(0);

    const [loadingApiProperties, setLoadingApiProperties] = useState(true);

    const [tagIdToRemove, setTagIdToRemove] = useState(0);
    const [openModalConfirmRemoveProperty, setOpenModalConfirmRemoveProperty] = useState(false);
    const [loadingApiRequestToRemoveProperty, setLoadingApiRequestToRemoveProperty] = useState(false);

    const [faqCode, setFaqCode] = useState('');

    const [loadApiError, setLoadApiError] = useState('');

    useEffect(() => {
        window.scrollTo(0,0);

        async function loadApi() {
            try{
                const token = await refreshToken(dateToExpires, access_token);

                api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                const response = await api.get('/api/adm/faq');

                setTags(response.data.data.data);
                setLastPage(response.data.data.last_page);
                setLoadingApiProperties(false);
            } catch(err){
                if(err.response.status === 403){
                    setLoadApiError(err.response.data.message);

                    setLoadingApiProperties(false);
                }

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }

                setLoadApiError(err.response.data.message);

                setLoadingApiProperties(false);
            }
        }

        if(!!user){
            loadApi();
        } else{
            history.push('/')
        }

        // eslint-disable-next-line
    }, [history, access_token, dateToExpires, user, params, refresh, window.location.search]);

    const pagesNumber = useMemo(() => {
        const pagesNumberArray = [];

        for(let i = 1; i <= lastPage; i++){
            pagesNumberArray.push(i);
        }

        if(pagesNumberArray.length > 5 && Number(params.page) < 5){
            pagesNumberArray.length = 5;

            const pagesNumberArrayFormated = [...pagesNumberArray ];

            return pagesNumberArrayFormated;
        }

        if(pagesNumberArray.length > 5 && Number(params.page) >= 5 && Number(params.page) < pagesNumberArray.length - 3){
            return ([pagesNumberArray[Number(params.page) - 4], pagesNumberArray[Number(params.page) - 3], pagesNumberArray[Number(params.page) - 2], pagesNumberArray[Number(params.page) - 1], pagesNumberArray[Number(params.page)], pagesNumberArray[Number(params.page) + 1], pagesNumberArray[Number(params.page) + 2]]);
        }

        if(pagesNumberArray.length > 5 && Number(params.page) >= pagesNumberArray.length -4){
            return ([pagesNumberArray[pagesNumberArray.length - 5], pagesNumberArray[pagesNumberArray.length - 4], pagesNumberArray[pagesNumberArray.length - 3], pagesNumberArray[pagesNumberArray.length - 2], pagesNumberArray[pagesNumberArray.length - 1]]);
        }

        return pagesNumberArray;
    }, [lastPage, params.page]);

    const handleRemoveProperty = useCallback(async() => {
        setLoadingApiRequestToRemoveProperty(true);
        
        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.delete(`/api/adm/faq/${tagIdToRemove}`);

            if(!!response.data.success){
                addToast({
                    type: 'success',
                    title: 'FAQ removida!'
                });

                handleRefresh();
                setOpenModalConfirmRemoveProperty(false);
            }
        } catch(err){
            console.log(err);
        }

        setLoadingApiRequestToRemoveProperty(false);
    }, [dateToExpires, access_token, tagIdToRemove, addToast, handleRefresh]);

    const handleChangePagesNumber = useCallback((page: number) => {
        history.push(`/list-faqs/${page}`);

        window.location.reload();
    }, [history]);

    return(
        <Container>
            <Layout>
                {!!loadingApiProperties && (<Loading />)}

                <ModalConfirmRemoveFAQ 
                    open={openModalConfirmRemoveProperty}
                    isLoading={loadingApiRequestToRemoveProperty}
                    functionToRemoveProperty={handleRemoveProperty}
                    functionToCloseModal={() => setOpenModalConfirmRemoveProperty(false)}
                />

                {!loadingApiProperties && (
                    <>
                        {!!loadApiError && (
                            <ErrorMessageContainer>
                                <h2>{loadApiError}</h2>
                            </ErrorMessageContainer>
                        )}

                        {!loadApiError && (
                            <Content>
                                <PropertiesContainer>
                                    <InitialContainer>
                                        <h3>FAQs</h3>
        
                                        <SearchBarContainer>
                                            <input 
                                                type="text" 
                                                placeholder="Pesquisar por código"
                                                value={faqCode}
                                                onChange={e => setFaqCode(e.target.value)}
                                            />
        
                                            <IconContainer onClick={() => history.push(`/edit-faq/${faqCode}`)}>
                                                <FiSearch color="#DD6435" size={18}/>
                                            </IconContainer>
                                        </SearchBarContainer>
                                        

                                        <Link to="/create-faq">ADICIONAR FAQ</Link>
                                    </InitialContainer>
        
                                    <PropertiesTable>
                                        <thead>
                                            <tr>
                                                <th className="th-code">Código</th>
                                                <th>Título da pergunta</th>
                                                <th>Categoria</th>
                                                <th>Editar</th>
                                                <th>Excluir</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {tags.map(tag => (
                                                <tr key={tag.id}>
                                                    <td>{tag.id}</td>
                                                    <td id="td-title">{tag.title}</td>
                                                    <td>{tag.category}</td>
                                                    <td><Link to={`/edit-faq/${tag.id}`}><BiEdit color="#04c5ff" size={24}/></Link></td>
                                                    <td><BiTrash color="#ff5252" size={24} onClick={() => {
                                                        setOpenModalConfirmRemoveProperty(true);
                                                        setTagIdToRemove(tag.id);  
                                                    }}/></td>
                                                </tr> 
                                            ))}
                                        </tbody>
                                    </PropertiesTable>
        
                                    <PagesContainer>
                                        <PageOptionPrevious>
                                            {Number(params.page) !== 1 ? (<Link to={`/list-faqs/${1}`} onClick={() => handleChangePagesNumber(Number(1))}><FiChevronsLeft color="#666666" size={24}/></Link>) : (<FiChevronsLeft color="#666666" size={24}/>)}
                                        </PageOptionPrevious>

                                        <PageOptionPrevious>
                                        {Number(params.page) !== 1 ? (<Link to={`/list-faqs/${Number(params.page) - 1}`} onClick={() => handleChangePagesNumber(Number(params.page) - 1)}><FiChevronLeft color="#666666" size={24}/></Link>) : (<FiChevronLeft color="#666666" size={24}/>)}
                                        </PageOptionPrevious>
        
                                        {pagesNumber.map(page => (
                                            <PageOption key={page} isSelected={Number(params.page) === page}>
                                                {Number.isInteger(page) ? (<Link to={`/list-faqs/${page}`} onClick={() => handleChangePagesNumber(Number(page))}>{page}</Link>) : (<p>{page}</p>)}
                                            </PageOption>
                                        ))}
        
                                        <PageOptionLatter>
                                        {Number(params.page) !== (pagesNumber[pagesNumber.length - 1]) ? (<Link to={`/list-faqs/${Number(params.page) + 1}`} onClick={() => handleChangePagesNumber(Number(params.page) + 1)}><FiChevronRight color="#666666" size={24}/></Link>) : (<FiChevronRight color="#666666" size={24}/>)}
                                        </PageOptionLatter>

                                        <PageOptionLatter>
                                            {Number(params.page) !== (pagesNumber[pagesNumber.length - 1]) ? (<Link to={`/list-faqs/${Number(lastPage)}`} onClick={() => handleChangePagesNumber(lastPage)}><FiChevronsRight color="#666666" size={24}/></Link>) : (<FiChevronsRight color="#666666" size={24}/>)}
                                        </PageOptionLatter>
                                    </PagesContainer>
                                </PropertiesContainer>
                            </Content>  

                        )}
                    </>
                )}
            </Layout>
        </Container>
    );
};