import { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { useAuth } from "../../hooks/auth";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import {
  Container,
  ErrorMessageContainer,
  RegionsTable,
  TableChangeContainer,
} from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { MdChevronLeft, MdChevronRight, MdOutlineEdit } from "react-icons/md";
import refreshToken from "../../utils/refreshToken";
import { useRefresh } from "../../hooks/refresh";

interface ICommercialRegion {
  id: number;
  name: string;
  relevance_order: number;
}

const ShowCommercialRegions = () => {
  const history = useHistory();
  const { access_token, user, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const { refresh, handleRefresh } = useRefresh();
  const [commercialRegions, setCommercialRegions] = useState<
    ICommercialRegion[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [loadApiError, setLoadApiError] = useState("");

  const REGIONS_PER_PAGE = 10;

  useEffect(() => {
    async function loadApi() {
      setLoading(true);
      try {
        api.defaults.headers.authorization = `Bearer ${access_token}`;

        const response = await api.get("/api/adm/commercial-region");
        const fetchedRegions = response.data.data;

        const sortedRegions = [...fetchedRegions];

        setCommercialRegions(
          sortedRegions.sort((reg1, reg2) => {
            return reg1.relevance_order - reg2.relevance_order;
          })
        );

        const integerNumOfPages = Math.floor(
          fetchedRegions.length / REGIONS_PER_PAGE
        );
        const remainder = fetchedRegions.length % REGIONS_PER_PAGE;
        setNumberOfPages(
          integerNumOfPages === 0
            ? 1
            : integerNumOfPages + (remainder === 0 ? 0 : 1)
        );
      } catch (err: any) {
        if (err?.response?.status === 403) {
          setLoadApiError(err?.response?.data?.message);
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadApiError(err.response.data.message);
      }
      setLoading(false);
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }

    // eslint-disable-next-line
  }, [dateToExpires, access_token, user, history]);

  const onBackward = () => {
    if (currentPage !== 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const onForward = () => {
    if (currentPage !== numberOfPages)
      setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleEditRegion = (commercialRegion: ICommercialRegion) => {
    history.push("/create-commercial-region", { ...commercialRegion });
  };

  const getContent = (pageRegions: ICommercialRegion[], activePage: number) => {
    let content = [];

    for (
      let i = (activePage - 1) * REGIONS_PER_PAGE;
      i < REGIONS_PER_PAGE * activePage;
      i++
    )
      if (i < pageRegions.length)
        content.push(
          <>
            <div
              className="row table-item first-on-row"
              key={pageRegions[i].name}
            >
              <span>{pageRegions[i].name}</span>
            </div>
            <div
              className="row table-item"
              key={pageRegions[i].relevance_order}
            >
              <span style={{ width: "100%", textAlign: "center" }}>
                {pageRegions[i].relevance_order}
              </span>
            </div>
            <div className="row table-item create-file-icon" key={i}>
              <span onClick={() => handleEditRegion(pageRegions[i])}>
                <MdOutlineEdit color="0219a3" size={24} />
              </span>
            </div>
          </>
        );

    return content;
  };

  const getNavigationFooter = (activePage: number) => {
    let content = [];

    for (let i = 1; i <= numberOfPages; i++)
      content.push(
        <span
          key={i}
          id={activePage === i ? "current-page" : ""}
          onClick={() => {
            setCurrentPage(i);
          }}
        >
          {i}
        </span>
      );

    return content;
  };

  if (loading) return <Loading />;

  return (
    <Container>
      {!!loadApiError && (
        <ErrorMessageContainer>
          <h2>{loadApiError}</h2>
        </ErrorMessageContainer>
      )}
      {!loadApiError && (
        <>
          <Header />
          <h2>Regiões Comerciais Cadastradas</h2>
          <RegionsTable>
            <div className="header table-item first-on-row">
              <span>Nome</span>
            </div>
            <div className="header table-item  description-col">
              <span>Relevância</span>
            </div>
            <div className="header table-item delete-header">
              <span>Editar</span>
            </div>
            {getContent(commercialRegions, currentPage)}
          </RegionsTable>
          <TableChangeContainer>
            <MdChevronLeft
              size={20}
              onClick={onBackward}
              className={currentPage === 1 ? "disable" : ""}
            />

            {getNavigationFooter(currentPage)}

            <MdChevronRight
              size={20}
              onClick={onForward}
              className={currentPage === numberOfPages ? "disable" : ""}
            />
          </TableChangeContainer>
          <Footer />
        </>
      )}
    </Container>
  );
};

export default ShowCommercialRegions;
