import React, { useEffect, useState } from "react";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {
  Content,
  TitleContainer,
  FormContainer,
  ButtonContainer,
} from "./styles";

import { FiX } from "react-icons/fi";
import { RiArrowLeftRightLine } from "react-icons/ri";

import refreshToken from "../../utils/refreshToken";
import api from "../../services/api";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationError";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { useRefresh } from "../../hooks/refresh";
import OutsideClickHandler from "react-outside-click-handler";
import Select from "react-select";
import { LabelContainer } from "../../pages/CreateUsers/styles";

interface IConsultant {
  id: number | null;
  first_name: string;
}

interface IParam {
  schedule: string;
  consultants: IConsultant[];
}

interface IModalConsult {
  open: boolean;
  functionToClose(): void;
  param: IParam;
}

interface IDate {
  id: number;
  date: string;
  consultant_id: number;
}

function setFetchedDatesToSelectFields(dates: IDate[] = []) {
  const dateFields = dates.map((date) => date.date.substring(0, 10));
  const uniques = Array.from(new Set(dateFields));
  const orderDates = uniques.sort((a, b) => +new Date(a) - +new Date(b));
  const formatDate = orderDates.map((date, index) => {
    return {
      value: orderDates[index],
      label: `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(
        0,
        4
      )}`,
    };
  });

  return formatDate;
}

const ModalConsult: React.FC<IModalConsult> = ({
  open,
  functionToClose,
  param,
}: IModalConsult) => {
  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const { handleRefresh } = useRefresh();
  const { schedule, consultants } = param;

  const [scheduleDestination, setScheduleDestination] = useState({
    consultantId: schedule.split("|")[2],
    date: "",
    hour: "",
  });
  const [availableDates, setAvailableDates] = useState<IDate[]>([]);
  const [showConsultantsList, setShowConsultantsList] = useState(false);
  const [loadingRequestToUpdate, setloadingRequestToUpdate] = useState(false);

  const selectedConsultant = consultants.find(
    (consultant) => +scheduleDestination.consultantId === consultant.id
  );
  const dateFields =
    availableDates.length > 0 && scheduleDestination.consultantId
      ? setFetchedDatesToSelectFields(availableDates)
      : [];

  const hourFields = scheduleDestination.date
    ? availableDates
        .filter(({ date }) => date.includes(scheduleDestination.date))
        .map(({ date }) => ({
          value: date.substring(11, 19),
          label: date.substring(11, 19),
        }))
    : [];

  if (consultants.length === 0) {
    addToast({
      title: "Nenhum consultor encontrado",
      type: "error",
    });
  }

  useEffect(() => {
    async function fetchDates() {
      const response = await api.get(
        `/api/client/schedules/consultant/${scheduleDestination.consultantId}`
      );
      const dates = response.data.data;
      setAvailableDates(dates);
    }
    fetchDates();
  }, [scheduleDestination.consultantId]);

  const handleUpdate = async () => {
    setloadingRequestToUpdate(true);

    try {
      const schema = Yup.object().shape({
        consultantId: Yup.string().required("Selecione um consultor"),
        date: Yup.string().required("Selecione um dia"),
        hour: Yup.string().required("Selecione uma hora"),
      });

      await schema.validate(scheduleDestination, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const { consultantId, date, hour } = scheduleDestination;
      const response = await api.put("/api/adm/schedules/change-consultants", {
        schedule_origin: schedule,
        schedule_destination: `${date}|${hour}|${consultantId}`,
      });

      if (!!response.data.success) {
        addToast({
          title: "Alteração concluída",
          type: "success",
          description: "",
        });

        handleRefresh();
        functionToClose();
      }
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        addToast({
          title: errors[Object.keys(errors)[0]],
          type: "info",
          description: "",
        });
      } else if (!!err.response.data.errors) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      setloadingRequestToUpdate(false);
    }
  };

  function handleChange(identifier: string, value: string | undefined) {
    setScheduleDestination((prevDestination) => ({
      ...prevDestination,
      [identifier]: value,
    }));
  }

  return (
    <Modal
      open={open}
      onClose={functionToClose}
      classNames={{
        modal: "modalUpdate",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h3>ALTERAR AGENDAMENTO</h3>
        </TitleContainer>
        <hr />
        <FormContainer>
          <p>Consultor:</p>
          <input value={selectedConsultant?.first_name ?? ""} readOnly />
          <span
            className="iconContainer"
            onMouseDown={() => {
              handleChange("consultantId", undefined);
              setShowConsultantsList(true);
            }}
          >
            <RiArrowLeftRightLine size={24} color="#6ea4ff" />
          </span>
          {consultants.length >= 1 && showConsultantsList ? (
            <OutsideClickHandler
              onOutsideClick={() => {
                handleChange("consultantId", undefined);
                setShowConsultantsList(false);
              }}
            >
              <div id="consultants">
                {consultants.map((consultant) => (
                  <button
                    key={consultant.id}
                    type="button"
                    onClick={() => {
                      handleChange("consultantId", consultant.id!.toString());
                      setShowConsultantsList(false);
                    }}
                  >
                    {consultant.first_name}
                  </button>
                ))}
              </div>
            </OutsideClickHandler>
          ) : null}
          <p>Novo dia:</p>
          <Select
            options={dateFields}
            onChange={(e) => handleChange("date", e?.value)}
            placeholder="Escolha uma data"
            noOptionsMessage={() =>
              "Não temos datas para disponíveis para agendamento"
            }
          />

          <p>Nova hora:</p>
          <Select
            options={hourFields}
            onChange={(e) => handleChange("hour", e?.value)}
            placeholder="Escolha um horário"
            noOptionsMessage={() =>
              "Não temos horários disponíveis nessa data."
            }
          />
        </FormContainer>

        <ButtonContainer>
          {!!loadingRequestToUpdate ? (
            <p>Carregando...</p>
          ) : (
            <button className="btn-edit" onClick={handleUpdate}>
              SALVAR ALTERAÇÕES
            </button>
          )}
          <button className="btn-close" onClick={functionToClose}>
            CANCELAR
          </button>
        </ButtonContainer>
      </Content>
    </Modal>
  );
};

export default ModalConsult;
