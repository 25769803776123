import { MdClose, MdOutlineAttachment } from "react-icons/md";
import { Content } from "./styles";
import { ChangeEvent, useState } from "react";

interface IFilesUpload {
  file: File | undefined;
  onFileSelected(file: File | undefined): void;
}

const FilesUpload: React.FC<IFilesUpload> = ({
  file,
  onFileSelected,
}: IFilesUpload) => {
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(file);
  const [fileInputKey, setFileInputKey] = useState<string | undefined>();

  const onFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    setUploadedFile(file);
    onFileSelected(file);
    setFileInputKey(file?.name);
  };

  const onFileRemove = () => {
    setUploadedFile(undefined);
    onFileSelected(undefined);
    setFileInputKey(undefined);
  };

  const onFileCheckHandler = () => {
    if (!!uploadedFile) {
      const fileURL = URL.createObjectURL(uploadedFile);
      window.open(fileURL, '_blank');
    }
  };

  return (
    <Content>
      <span>Selecione o arquivo do contrato:</span>
      <label htmlFor="add-file-btn" id="add-file-lbl">
        <MdOutlineAttachment size={24} fontWeight={600} /> Anexar arquivo
      </label>
      <input
        key={fileInputKey}
        type="file"
        id="add-file-btn"
        accept=".pdf"
        onChange={onFileUpload}
      />
      {!!uploadedFile ? (
        <label id="file-name-lbl">
          <span>
            <h6 onClick={onFileCheckHandler}>{uploadedFile.name}</h6>
            <MdClose size={18} color="#000" onClick={onFileRemove} />
          </span>
        </label>
      ) : (
        ""
      )}
    </Content>
  );
};

export default FilesUpload;
