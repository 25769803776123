import { MdAnnouncement } from "react-icons/md";
import Modal from "react-responsive-modal";

import {
  Content,
  ButtonsContainer,
  ConfirmButton,
  CancelButton,
} from "./styles";

interface IModalConfirmRemoveGuarantee {
  open: boolean;
  isLoading: boolean;
  functionToRemoveGuarantee(): void;
  functionToCloseModal(): void;
}

export default function ModalConfirmRemoveGuarantee({
  open,
  isLoading,
  functionToRemoveGuarantee,
  functionToCloseModal,
}: IModalConfirmRemoveGuarantee) {
  return (
    <Modal
      open={open}
      onClose={functionToCloseModal}
      classNames={{
        modal: "remove-photo-modal",
      }}
    >
      <Content>
        <MdAnnouncement size={40} color="#666666" />

        <p>Tem certeza que deseja deletar essa garantia?</p>

        <ButtonsContainer>
          <ConfirmButton
            onClick={!!isLoading ? () => {} : functionToRemoveGuarantee}
            isLoading={isLoading}
          >
            {!!isLoading ? "Carregando..." : "Sim"}
          </ConfirmButton>
          <CancelButton onClick={functionToCloseModal}>Não</CancelButton>
        </ButtonsContainer>
      </Content>
    </Modal>
  );
}
