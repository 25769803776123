import styled from "styled-components";

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.label`
  font-size: ${12 / 16}rem;
  font-weight: 500;
`;

export const Input = styled.input`
  margin-top: 8px;
  border: none;
  border-bottom: 1px solid #b6b6b6;
  padding: 0 0 4px 4px;
`;
