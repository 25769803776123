import { MdAnnouncement } from "react-icons/md";
import Modal from "react-responsive-modal";

import {Content,
        ButtonsContainer,
        ConfirmButton,
        CancelButton} from './styles';

interface IModalConfirmRemovePhoto{
    open: boolean;
    isLoading: boolean;
    functionToRemovePhoto(): void;
    functionToCloseModal(): void;
}

export default function ModalConfirmRemovePhoto({ open, isLoading, functionToRemovePhoto, functionToCloseModal }: IModalConfirmRemovePhoto){
    return(
        <Modal open={open} onClose={functionToCloseModal} classNames={{
            modal: "remove-photo-modal"
        }}>
            <Content>
                <MdAnnouncement size={40} color="#666666"/>

                <p>Tem certeza que deseja deletar essa foto?</p>

                <ButtonsContainer>
                    <ConfirmButton onClick={!!isLoading ? () => {} : functionToRemovePhoto} isLoading={isLoading}>{!!isLoading ? "Carregando..." : "Sim"}</ConfirmButton>
                    <CancelButton onClick={functionToCloseModal}>Não</CancelButton>
                </ButtonsContainer>
            </Content>
        </Modal>
    );
}