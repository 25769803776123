import React, { createContext, useState, useCallback, useContext } from 'react';

import api from './../services/api';

interface ISignInCredentials{
	email: string;
	password: string;
}

interface IUser{
	id: number;
	first_name: string;
	email: string;
	phone: string;
	type: string;
	role: string;
}

interface IAuthState{
	user: IUser;
	access_token: string;
	dateToExpires: Date;
}

interface IUpdateUserCredentials{
	first_name: string;
	email: string;
	phone: string;
	password: string;
	password_confirmation: string;
}

interface IUpdateUserCredentialsResponse{
	message: string;
}

interface IContext{
	signIn(credentials: ISignInCredentials): Promise<string>;
	signOut(): Promise<void>;
	updateUser(credentials: IUpdateUserCredentials): Promise<IUpdateUserCredentialsResponse>;
    user: IUser | undefined;
    access_token: string;
    dateToExpires: Date;
}

const AuthContext = createContext<IContext>({} as IContext);

const AuthProvider: React.FC = ({ children }) => {
	const [data, setData] = useState<IAuthState>(() => {
		const access_token = localStorage.getItem('@7Cantos:token');
		const user = localStorage.getItem('@7Cantos:user');
		const dateToExpires = localStorage.getItem('@7Cantos:dateToExpires');

		if(access_token && user && dateToExpires){
			api.defaults.headers.authorization = `Bearer ${access_token}`;

			return {user: JSON.parse(user), access_token, dateToExpires: new Date(dateToExpires)};
		}

		return ({} as IAuthState);
	});

	const signIn = useCallback(async({email, password}: ISignInCredentials): Promise<string> => {
		const responseToken = await api.post('/api/auth/login', {
			email,
			password
		});

		const { access_token, role } = responseToken.data;

		const expiresIn = responseToken.data.expires_in;

		const actualDate = new Date(Date.now());

		const dateToExpires = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate(), actualDate.getHours(), actualDate.getMinutes(), actualDate.getSeconds() + expiresIn);

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const responseUser = await api.post('/api/auth/me', {
			headers:{
				Authorization: `Bearer ${access_token}`
			}
		});

		const user = {...responseUser.data, role};

		localStorage.setItem('@7Cantos:token', access_token);
		localStorage.setItem('@7Cantos:user', JSON.stringify(user));
		localStorage.setItem('@7Cantos:dateToExpires', dateToExpires.toString());

		setData({user, access_token, dateToExpires});

		return role;
	}, []);

	const signOut = useCallback(async() => {
		api.defaults.headers.authorization = `Bearer ${data.access_token}`;

		await api.post('/api/auth/logout');

		setData({} as IAuthState);

		localStorage.removeItem('@7Cantos:token');
		localStorage.removeItem('@7Cantos:user');
		localStorage.removeItem('@7Cantos:dateToExpires');
		localStorage.removeItem('@7Cantos:consultants');
	}, [data]);

	const updateUser = useCallback(async({first_name, email, phone, password, password_confirmation}: IUpdateUserCredentials) => {
		const access_token = localStorage.getItem('@7Cantos:token');
		const dateToExpires = localStorage.getItem('@7Cantos:dateToExpires');

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const responseUpdate = await api.put('/api/client/users', {
			first_name,
			email,
			phone,
			password,
			password_confirmation
		});

		 const responseUser = await api.post('/api/auth/me', {
			headers: {
				Authorization: `Bearer ${access_token}`
			}
		});

		const user = responseUser.data;

		localStorage.setItem('@7Cantos:user', JSON.stringify(user));

		if(!!access_token && dateToExpires){
			setData({user, access_token, dateToExpires: new Date(dateToExpires)});
		}

		return ({
			message: responseUpdate.data.message
		})
	}, []);

	return(
		<AuthContext.Provider value={{signIn, signOut, updateUser, user: data.user, access_token: data.access_token, dateToExpires: data.dateToExpires}}>
			{children}
		</AuthContext.Provider>
	);
};

function useAuth():IContext{
	const context = useContext(AuthContext);

	return context;
}

export {AuthProvider, useAuth};