import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 3.7rem;
        min-height: 3.7rem;
        border-radius: 2rem;
        background-color: #FFF;
    }

    >#finished-step-image {
        border: 2px solid #6EA6FF;
    }
    >#current-step-image {
        background-color: #6EA6FF;
    }
    >#future-step-image {
        border: 2px solid #C4C4C4;
    }
    
    >span {
        margin-top: 1.5em;
        font-size: 0.85rem;    
    }

    > #finished-step-label {
        font-weight: 500;
        color: #606060;
    }

    > #current-step-label {
        font-weight: 600;
    }

    > #future-step-label {
        color: #8B8A8F;
    }
`;
