import { createContext, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";

interface IClient {
  drive_link: string;
  property_id: number;
  rent_process_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: string;
}

interface IParam {
  client: IClient;
}

interface ISigner {
  signAs: string;
  email: string;
  name: string;
  signOutside: boolean;
  user_id: undefined | number;
}

//nosso obj
interface IUpdatedFieldData {
  label: string;
  type: string;
  value: any;
}

interface IStepperObj {
  stepTitle: string;
  content: (IUpdatedFieldData[] | IUpdatedFieldData)[];
}

interface ContractObj {
  [key: string]: string | boolean | number | ContractObj[];
}

interface IProps {
  children: React.ReactNode;
}

interface IAction {
  type: string;
  payload: any;
}

function setStepperObjToModelContractObj(formObj: IStepperObj[]) {
  const contractObj = {} as ContractObj;
  for (const key in formObj) {
    const { content, stepTitle } = formObj[key];
    const listArr = [] as ContractObj[];
    for (const innerKey in content) {
      if (Array.isArray(content[innerKey])) {
        const listArrMember = {} as ContractObj;
        const listMember = content[innerKey] as IUpdatedFieldData[];
        listMember.forEach(({ label, value }) => {
          listArrMember[label] =
            typeof value === "string" ? value.trim() : value;
        });
        listArr.push(listArrMember);
      } else {
        const { label, value } = content[innerKey] as IUpdatedFieldData;
        contractObj[label] = typeof value === "string" ? value.trim() : value;
      }
    }
    if (listArr.length > 0) {
      contractObj[stepTitle.toUpperCase() + " LISTA"] = listArr;
    }
  }
  return contractObj;
}

export const ModelContractStepperContext = createContext({
  signers: [] as ISigner[],
  addSigner: (signer: ISigner) => {},
  removeSigner: (email: string) => {},
  addRawContractData: (data: IStepperObj[]) => {},
  updateRawContractData: (
    stepIdentifier: number,
    fieldIdentifier: number,
    value: any,
    listMemberIdentifier?: number
  ) => {},
  addFieldToList: (fieldShape: IUpdatedFieldData[], index: number) => {},
  contract_data: {} as ContractObj,
  rawContractData: [] as IStepperObj[],
});

function signersReducer(state: ISigner[], action: IAction) {
  if (action.type === "ADD") {
    return [...state, action.payload];
  }
  if (action.type === "REMOVE") {
    return state.filter((signer) => signer.email !== action.payload);
  }

  return state;
}

function contractDataReducer(state: IStepperObj[], action: IAction) {
  if (action.type === "ADD") {
    return [...state, ...action.payload];
  }
  if (action.type === "UPDATE") {
    const { stepIdentifier, fieldIdentifier, value, listMemberIdentifier } =
      action.payload;

    const newStepsArr = [...state];
    if (listMemberIdentifier !== undefined) {
      const newFieldsArr = [
        ...newStepsArr[stepIdentifier].content,
      ] as IUpdatedFieldData[][];
      const newListMemberArr = [...newFieldsArr[listMemberIdentifier]];
      newListMemberArr[fieldIdentifier].value = value;
      newStepsArr[stepIdentifier].content[listMemberIdentifier] =
        newListMemberArr;
    } else {
      const newFieldsArr = [
        ...newStepsArr[stepIdentifier].content,
      ] as IUpdatedFieldData[];
      newFieldsArr[fieldIdentifier].value = value;
      newStepsArr[stepIdentifier].content = newFieldsArr;
    }
    return newStepsArr;
  }
  if (action.type === "ADD_FIELD") {
    const { fieldShape, index } = action.payload;
    const fieldsArray = [];
    for (const key in fieldShape) {
      const { label, type } = fieldShape[key];
      fieldsArray.push({ label, type, value: "" });
    }

    const updatedStateContent = [...state[index].content, fieldsArray];
    const newStateArrayMember = {
      ...state[index],
      stepTitle: state[index].stepTitle,
      content: updatedStateContent,
    };

    const newState = state.map((element, stateIndex) =>
      stateIndex === index ? newStateArrayMember : element
    );

    return newState;
  }
  return state;
}

export default function ModelContractStepperProvider({ children }: IProps) {
  const location = useLocation<IParam>();
  const { client } = location.state;
  const [signers, signersDispatch] = useReducer(signersReducer, [
    {
      signAs: "lessee",
      email: client.tenant_email,
      name: client.tenant_name,
      signOutside: false,
      user_id: client.tenant_id,
    },
  ]);
  const [rawContractData, rawContractDataDispatch] = useReducer(
    contractDataReducer,
    []
  );

  function handleAddSigner(signer: ISigner) {
    signersDispatch({ type: "ADD", payload: signer });
  }

  function handleRemoveSigner(email: string) {
    signersDispatch({ type: "REMOVE", payload: email });
  }

  function handleAddRawContractData(data: IStepperObj[]) {
    rawContractDataDispatch({ type: "ADD", payload: data });
  }

  function handleUpdateRawContractData(
    stepIdentifier: number,
    fieldIdentifier: number,
    value: any,
    listMemberIdentifier?: number
  ) {
    rawContractDataDispatch({
      type: "UPDATE",
      payload: {
        stepIdentifier,
        fieldIdentifier,
        value,
        listMemberIdentifier,
      },
    });
  }

  function handleAddFieldToList(
    fieldShape: IUpdatedFieldData[],
    index: number
  ) {
    rawContractDataDispatch({
      type: "ADD_FIELD",
      payload: { fieldShape, index },
    });
  }

  const ctxValue = {
    signers,
    addSigner: handleAddSigner,
    removeSigner: handleRemoveSigner,
    addRawContractData: handleAddRawContractData,
    updateRawContractData: handleUpdateRawContractData,
    addFieldToList: handleAddFieldToList,
    contract_data: setStepperObjToModelContractObj(rawContractData),
    rawContractData,
  };
  return (
    <ModelContractStepperContext.Provider value={ctxValue}>
      {children}
    </ModelContractStepperContext.Provider>
  );
}
