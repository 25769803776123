import ContentType from "../../../pages/ModelContract/components/FormStep/ContentType";
import { FieldWrapper, Input, Title } from "./styles";

interface IContractField {
  label: string;
  type: string;
  value: any;
}

interface IDataFieldProps {
  element: IContractField;
  index: number;
  listMemberIndex?: number;
  onChange: (
    index: number,
    formattedValue: number | string,
    listMemberIndex?: number
  ) => void;
}
function getInputType(type: string) {
  if (
    type === ContentType.Integer ||
    type === ContentType.Currency ||
    type === ContentType.Float
  )
    return "number";

  return "text";
}
function DataField({
  element,
  index,
  onChange,
  listMemberIndex,
}: IDataFieldProps) {
  const { label, type, value } = element;

  function handleInputChange(value: any, type: string) {
    const formattedValue = type === ContentType.String ? value : +value;
    onChange(index, formattedValue, listMemberIndex);
  }
  return (
    <FieldWrapper>
      <Title>{label}</Title>
      <Input
        type={getInputType(type)}
        value={value}
        onChange={(event) => handleInputChange(event.target.value, type)}
      />
    </FieldWrapper>
  );
}

export default DataField;
