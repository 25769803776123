import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >label{
        margin-bottom: 8px;

        font-weight: 500;
        color: #666666;

        >p{
            font-size: 10px;
            font-weight: bold;
        }
    }

    >input{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        border-radius: 0;

        padding: 8px;
    }

    >p{
        font-size: 12px;

        margin-top: 4px;
    }

    @media(max-width: 767px){
        width: 100%;

        >label{
            margin: 16px 0 0;
        }

        >input{
            width: 100%;
        }
    }
`;
