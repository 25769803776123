import { Radio } from "@material-ui/core";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FiX } from "react-icons/fi";
import Modal from "react-responsive-modal";
import { useHistory } from "react-router";
import Select from "react-select";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import getValidationErrors from "../../utils/getValidationError";
import refreshToken from "../../utils/refreshToken";

import * as Yup from "yup";

import {
  Content,
  TitleContainer,
  OptionsContainer,
  PropertyTenantContainer,
  FormContainer,
  RadioContent,
  SelectContainer,
  SelectContent,
  DeactivatePropertyButton,
} from "./styles";
import { useRefresh } from "../../hooks/refresh";
import { useConsultant } from "../../hooks/consultant";
import CurrencyInput from "../Currency";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import OutsideClickHandler from "react-outside-click-handler";
import axios, { CancelTokenSource } from "axios";

interface IRentReasons {
  id: number;
  reason: string;
}

interface IModalDeactivateProperty {
  open: boolean;
  property: number;
  rentReasons: IRentReasons[];
  functionToCloseModal(): void;
}

interface IConsultant {
  id: number;
  first_name: string;
}

interface IUsers {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}

export default function ModalDeactivateProperty({
  open,
  property,
  rentReasons,
  functionToCloseModal,
}: IModalDeactivateProperty) {
  const history = useHistory();

  const { dateToExpires, access_token, user } = useAuth();
  const { addToast } = useToast();
  const { handleRefresh } = useRefresh();
  const { handleAddConsultants, consultantsSaved } = useConsultant();

  const [consultants, setConsultants] = useState<IConsultant[]>([]);
  const [consultantId, setConsultantId] = useState<number>();

  const [reasonId, setReasonId] = useState<number>();

  const [closingValue, setClosingValue] = useState("");

  const [email, setEmail] = useState("");

  const [loadingConsultants, setLoadingConsultants] = useState(false);

  const [loadingRequest, setLoadingRequest] = useState(false);

  const [loadingUsersRequest, setLoadingUsersRequest] = useState(false);

  const [rentedBy, setRentedBy] = useState<string>("7CANTOS");

  const [rent_date, setRentDate] = useState<Date | Date[]>(
    new Date(Date.now())
  );

  const [tenantId, setTenantId] = useState<number>();

  const [users, setUsers] = useState<IUsers[]>([]);

  useEffect(() => {
    setLoadingConsultants(true);

    async function loadApi() {
      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        if (consultantsSaved.length < 1) {
          const response = await api.get("/api/adm/users", {
            params: {
              role: "consultant",
            },
          });

          handleAddConsultants(response.data.data.consultants);

          setConsultants(response.data.data.consultants);
        } else {
          setConsultants(consultantsSaved);

          setLoadingConsultants(false);

          return;
        }
      } catch (err) {
        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        console.log(err);
      }

      setLoadingConsultants(false);
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("");
    }
    // eslint-disable-next-line
  }, [
    consultantsSaved,
    dateToExpires,
    access_token,
    user,
    history,
    open,
    handleAddConsultants,
  ]);

  const consultantOptions = useMemo(() => {
    return consultants.map((consultant) => ({
      label: consultant.first_name,
      value: consultant.id,
    }));
  }, [consultants]);

  const reasonsOptions = useMemo(() => {
    return rentReasons?.map((rentReason) => ({
      label: rentReason.reason,
      value: rentReason.id,
    }));
  }, [rentReasons]);

  let cancelToken: undefined | CancelTokenSource;

  const handleSearchChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchTerm = e.target.value;

      setEmail(searchTerm);

      if (cancelToken !== undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      // eslint-disable-next-line
      cancelToken = axios.CancelToken.source();

      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get("/api/adm/users", {
          params: {
            email: searchTerm,
          },
          cancelToken: cancelToken?.token,
        });

        if (response.data.data.users.length === 0) {
          addToast({
            title: "Nenhum usuário encontrado",
            type: "error",
          });

          setLoadingUsersRequest(false);
        }

        setUsers(response.data.data.users);
      } catch (err) {
        console.log(err);
      }
    },
    [cancelToken, access_token, dateToExpires, addToast]
  );

  const handleDeactivateProperty = useCallback(async () => {
    setLoadingRequest(true);

    if (rentedBy === "7CANTOS") {
      if (!rent_date) {
        addToast({
          title: "Coloque uma data válida!",
          type: "info",
        });

        setLoadingRequest(false);

        return;
      } else if (!closingValue || closingValue === "0") {
        addToast({
          title: "Valor de fechamento deve ser diferente de R$ 0,00!",
          type: "info",
        });

        setLoadingRequest(false);

        return;
      }
    }

    const dataToRequest =
      rentedBy === "OWNER"
        ? {
            property,
            reason_id: String(!!reasonId ? reasonId : ""),
            consultant_id: String(!!consultantId ? consultantId : ""),
          }
        : {
            property,
            consultant_id: String(!!consultantId ? consultantId : ""),
            rent_date: `${rent_date
              .toLocaleString()
              .substring(6, 10)}-${rent_date
              .toLocaleString()
              .substring(3, 5)}-${rent_date.toLocaleString().substring(0, 2)}`,
          };

    try {
      // eslint-disable-next-line
      const dateRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

      const schema = Yup.object().shape(
        rentedBy === "OWNER"
          ? {
              reason_id: Yup.string().required("Campo Obrigatório"),
            }
          : {
              consultant_id: Yup.string().required("Campo Obrigatório"),
              rent_date: Yup.string()
                .required("Digite uma data inicial!")
                .matches(dateRegex, "Coloque uma data válida!"),
            }
      );

      await schema.validate(dataToRequest, {
        abortEarly: false,
      });

      const response = await api.post(
        `/api/adm/properties/${property}/deactivate`,
        rentedBy === "OWNER"
          ? {
              rented_by: rentedBy,
              reason_id: reasonId,
            }
          : {
              rented_by: rentedBy,
              consultant_id: consultantId,
              final_rent_value: parseFloat(
                `${closingValue
                  .replaceAll("R$", "")
                  .substring(
                    0,
                    closingValue.replaceAll("R$", "").length - 2
                  )}.${closingValue
                  .replaceAll("R$", "")
                  .substring(
                    closingValue.replaceAll("R$", "").length - 2,
                    closingValue.replaceAll("R$", "").length
                  )}`
              ),
              rent_date: `${rent_date
                .toLocaleString()
                .substring(6, 10)}-${rent_date
                .toLocaleString()
                .substring(3, 5)}-${rent_date
                .toLocaleString()
                .substring(0, 2)}`,
              tenant_id: tenantId,
            }
      );

      if (!!response.data.success) {
        addToast({
          title: "Propriedade desativada!",
          type: "success",
        });

        functionToCloseModal();
        handleRefresh();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationErrors(err);

        addToast({
          title: error[Object.keys(error)[0]],
          type: "info",
          description: "",
        });
      }

      if (!!err.response?.data.errors) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }

    setLoadingRequest(false);
  }, [
    rent_date,
    closingValue,
    reasonId,
    rentedBy,
    consultantId,
    tenantId,
    property,
    addToast,
    functionToCloseModal,
    handleRefresh,
  ]);

  const handleSelectUser = useCallback(
    (user_id: number | undefined) => {
      const user_selected = users.find((client) => client.id === user_id);
      setUsers([]);

      if (!!user_selected) {
        setTenantId(user_selected.id);
        setEmail(user_selected?.email);
      }
    },
    [users]
  );

  return (
    <Modal
      open={open}
      onClose={functionToCloseModal}
      classNames={{
        modal: "modalDeactivateProperty",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h4>IMÓVEL ALUGADO</h4>
        </TitleContainer>

        <OptionsContainer>
          <p>Por quem o imóvel foi alugado?</p>

          <RadioContent>
            <Radio
              name="responsible-radio"
              checked={rentedBy === "7CANTOS"}
              onChange={() => setRentedBy("7CANTOS")}
            />

            <p onClick={() => setRentedBy("7CANTOS")}>7Cantos</p>
          </RadioContent>

          <RadioContent>
            <Radio
              name="responsible-radio"
              checked={rentedBy === "OWNER"}
              onChange={() => setRentedBy("OWNER")}
            />

            <p onClick={() => setRentedBy("OWNER")}>Proprietário</p>
          </RadioContent>
        </OptionsContainer>

        <SelectContainer>
          {rentedBy === "7CANTOS" && (
            <SelectContent>
              <p>Valor do fechamento:</p>
              <CurrencyInput
                value={Number(closingValue)}
                onValueChange={(e) => setClosingValue(String(e))}
              />

              <p>Data do fechamento:</p>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  invalidDateMessage=""
                  label=""
                  format="dd/MM/yyyy"
                  value={rent_date}
                  onChange={(e) => setRentDate(e as Date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>

              <p>Consultor responsável: </p>
              <Select
                options={consultantOptions}
                placeholder={!!loadingConsultants ? "Carregando..." : ""}
                onChange={(e) => setConsultantId(e?.value)}
                noOptionsMessage={() =>
                  !!loadingConsultants ? "Carregando..." : "Sem consultantes"
                }
                menuPosition={"fixed"}
              />

              <PropertyTenantContainer>
                <span>
                  * Digite ao menos 4 caracteres e pressione enter para
                  pesquisar
                </span>
                <p>Email do inquilino: </p>

                <FormContainer>
                  <input
                    readOnly={loadingUsersRequest}
                    value={!!loadingUsersRequest ? "Carregando..." : email}
                    onChange={
                      email.length <= 2
                        ? (e) => setEmail(e.target.value)
                        : handleSearchChange
                    }
                  />

                  {users.length >= 1 ? (
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setEmail("");
                        setUsers([]);
                      }}
                    >
                      <div id="users">
                        {users.map((user) => (
                          <button
                            key={user.id}
                            type="button"
                            onClick={() => handleSelectUser(user.id)}
                          >
                            {user.email}
                          </button>
                        ))}
                      </div>
                    </OutsideClickHandler>
                  ) : (
                    <></>
                  )}
                </FormContainer>
              </PropertyTenantContainer>
            </SelectContent>
          )}

          {rentedBy === "OWNER" && (
            <SelectContent>
              <p>Selecione o motivo: </p>

              <Select
                options={reasonsOptions}
                placeholder=""
                onChange={(e) => setReasonId(e?.value)}
                noOptionsMessage={() => "Sem motivos"}
                menuPosition={"fixed"}
              />
            </SelectContent>
          )}
        </SelectContainer>

        <DeactivatePropertyButton
          onClick={!!loadingRequest ? () => {} : handleDeactivateProperty}
        >
          <p>{!!loadingRequest ? "Carregando..." : "DESATIVAR ANÚNCIO"}</p>
        </DeactivatePropertyButton>
      </Content>
    </Modal>
  );
}
