import { MdDeleteOutline } from "react-icons/md";
import { Container, GridContainer } from "./styles";
import { useContext } from "react";
import { ModelContractStepperContext } from "../../store/ModelContractStepper/model-contract-stepper";

const getSignAsText = (signAs: string | unknown) => {
  switch (signAs) {
    case "lessor":
      return "Locador";
    case "lessee":
      return "Locatário";
    case "surety":
      return "Fiador";
    case "witness":
      return "Testemunha";
    case "party":
      return "Parte";
    case "administrator":
      return "Administrador";
  }
};

function SignersList() {
  const { signers, removeSigner } = useContext(ModelContractStepperContext);
  return (
    <Container>
      <label className="main-title-lbl">Signatários adicionados:</label>
      {signers.map((signer) => {
        return (
          <GridContainer key={signer.email}>
            <label className="title-lbl">Signatário:</label>
            <label className="title-lbl">Email:</label>
            <label className="title-lbl">Tipo:</label>
            <label></label>
            <label className="data-lbl">{signer.name}</label>
            <label className="data-lbl">{signer.email}</label>
            <label className="data-lbl">{getSignAsText(signer.signAs)}</label>
            <MdDeleteOutline
              size={24}
              color="#CACACA"
              onClick={() => removeSigner(signer.email)}
            />
          </GridContainer>
        );
      })}
    </Container>
  );
}

export default SignersList;
