import styled from 'styled-components';

interface IPageOption{
    isSelected: boolean;
}

export const Container = styled.div`
`;

export const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    >h2{
        width: 60%;
        text-align: center;
        color: #ff5252;
    }
`;

export const Content = styled.div`
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto 10%;
    @media(max-width: 767px){
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

export const PropertiesContainer = styled.div`
    width: 100%;
    padding: 24px;
    border-radius: 4px;
    margin-top: 24px;
    @media(max-width: 767px){
        width: 100%;
        padding: 24px 12px;
    }
    @media(min-width: 1441px){
        width: 80%;
    }
`;

export const InitialContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    >h3{
        font-size: 28px;
        font-weight: bold;
        color: #666666;
    }
    >a{
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;
        border: 0;
        background-color: #00BF33;
        font-weight: 500;
        color: #FFF;
        transition: all 0.2s;
        margin-left: auto;
    }
    @media(max-width: 767px){
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        >a{
            margin-top: 12px;
            margin-left: 0;
            width: 100%;
        }
    }
`;

export const SearchBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
    width: 20%;
    border: 1px solid #666666;
    padding: 8px;

    >input{
        border: 0;
    }

    @media(max-width:767px){
        margin-left: 0;
        margin-top: 8px;

        width: 72%;
    }
`;

export const IconContainer = styled.button`
    margin-left: 2px;
    border: 0;
`;

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(0,0,0,0.1);
    margin: 24px 0 0;
`;

export const PropertiesTable = styled.table`
    width: 100%;
    border-spacing: 0 12px;
    text-align: left;
    margin: 48px auto 0;
    overflow: auto;
    >thead{
        background: #6EA5FF;
        color: #FFFFFF;
        th{
            padding: 16px 32px;
        }
        .th-code{
            width: 17%;
        }
    }
    >tbody{
        td{
            padding: 16px 32px;
            background: #F9F9F9;
            color: #666666;
            font-weight: 500;
            svg{
                margin-left: auto;
                width: 100%;
                cursor: pointer;
            }
        }
        #td-title{
            width: 100%;
        }
    }
    @media(max-width: 767px){
        display: block;
        width: 100%;
        margin: 0;
    }
`;

export const PagesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    @media(max-width: 767px){
        width: 100%;
    }
`;

export const PageOptionPrevious = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const PageOption = styled.div<IPageOption>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: ${props => !!props.isSelected ? "#F9F9F9" : "transparent"};
    font-size: 20px;
    font-weight: bold;
    transition: all 0.2s;
    margin: 0 12px;
    >a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: ${props => !!props.isSelected ? "#6EA5FF" : "#B6B6B6"};
    }
`;

export const PageOptionLatter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;