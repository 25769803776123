import styled from "styled-components";

interface IConfirmButton{
    isLoading: boolean;
}

export const Content = styled.div`
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    background: #FFFFFF;
    margin: 0 auto;
    >p{
        font-size: 20px;
        font-weight: bold;
        margin: 36px 0;
        text-align: center;
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ConfirmButton = styled.button<IConfirmButton>`
    background: ${props => !!props.isLoading ? "transparent" : "#00BF33"};
    font-weight: 500;
    color: ${props => !!props.isLoading ? "#00BF33" : "#FFFFFF"};
    border: ${props => !!props.isLoading ? "1px solid #00BF33" : "0"};
    padding: 8px 0;
    width: 45%;
`; 

export const CancelButton = styled.button`
    background: #BF0000;
    font-weight: 500;
    color: #FFFFFF;
    padding: 8px 0;
    width: 45%;
    margin-left: 16px;
`;