export const ACCESS_AND_AREA = [
  {
    label: "Perto de vias de acesso",
    radioGroupName: "selector-group3",
    attributeName: "is_nearby_major_roads",
  },
  {
    label: "Perto de shopping center",
    radioGroupName: "selector-group4",
    attributeName: "is_nearby_mall",
  },
  {
    label: "Perto de transporte público",
    radioGroupName: "selector-group5",
    attributeName: "is_nearby_public_transport",
  },
  {
    label: "Perto de Escolas",
    radioGroupName: "selector-group6",
    attributeName: "is_nearby_schools",
  },
  {
    label: "Perto de hospitais",
    radioGroupName: "selector-group7",
    attributeName: "is_nearby_clinics",
  },
];

export const PROPERTY_CONTAINS = [
  {
    label: "Ar condicionado",
    radioGroupName: "selector-group8",
    attributeName: "has_ac",
  },
  {
    label: "Quintal",
    radioGroupName: "selector-group9",
    attributeName: "has_backyard",
  },
  {
    label: "Varanda",
    radioGroupName: "selector-group10",
    attributeName: "has_porch",
  },
  {
    label: "Deposito",
    radioGroupName: "selector-group11",
    attributeName: "has_storage",
  },
  {
    label: "TV a cabo",
    radioGroupName: "selector-group12",
    attributeName: "has_cable_tv",
  },
];

export const SINGULARITIES = [
  {
    label: "Churrasqueira",
    radioGroupName: "selector-group13",
    attributeName: "has_bbq",
  },
  {
    label: "Elevador",
    radioGroupName: "selector-group14",
    attributeName: "has_elevator",
  },
  {
    label: "Jardim",
    radioGroupName: "selector-group15",
    attributeName: "has_garden",
  },
  {
    label: "Espaço Gourmet",
    radioGroupName: "selector-group16",
    attributeName: "has_gourmet",
  },
  {
    label: "Academia",
    radioGroupName: "selector-group17",
    attributeName: "has_gym",
  },
  {
    label: "Salão de festas",
    radioGroupName: "selector-group18",
    attributeName: "has_party",
  },
  {
    label: "Playground",
    radioGroupName: "selector-group19",
    attributeName: "has_playground",
  },
  {
    label: "Piscina",
    radioGroupName: "selector-group20",
    attributeName: "has_pool",
  },
  {
    label: "Quadra poliesportiva",
    radioGroupName: "selector-group21",
    attributeName: "has_sports",
  },
  {
    label: "Cinema em casa",
    radioGroupName: "selector-group22",
    attributeName: "has_home_cinema",
  },
  {
    label: "Quadra de tênis",
    radioGroupName: "selector-group23",
    attributeName: "has_tennis_court",
  },
  {
    label: "Sauna",
    radioGroupName: "selector-group24",
    attributeName: "has_sauna",
  },
  {
    label: "SPA",
    radioGroupName: "selector-group25",
    attributeName: "has_spa",
  },
  {
    label: "Vista-Mar",
    radioGroupName: "selector-group26",
    attributeName: "has_sea_view",
  },
  {
    label: "Varanda gourmet",
    radioGroupName: "selector-group27",
    attributeName: "has_gourmet_porch",
  },
  {
    label: "Portaria 24 horas",
    radioGroupName: "selector-group28",
    attributeName: "has_24h_concierge",
  },
  {
    label: "Salão de jogos",
    radioGroupName: "selector-group29",
    attributeName: "has_game_room",
  },
];
