import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";
import { useToast } from "../../../../hooks/toast";
import refreshToken from "../../../../utils/refreshToken";
import api from "../../../../services/api";
import { Content } from "./styles";
import ModelContractFormSkeleton from "./Skeleton/Skeleton";
import Stepper from "../../../../components/Stepper";
import ContractStep from "../../../../components/ContractStep";
import { ModelContractStepperContext } from "../../../../store/ModelContractStepper/model-contract-stepper";

//obj que vem do back
interface IFieldData {
  label: string;
  type: string;
}

interface IListData {
  list: IFieldData[];
  type: string;
}

interface IInnerObj {
  [key: string]: IListData | IFieldData;
}

interface IFetchedObj {
  [key: string]: IInnerObj;
}

//nosso obj
interface IUpdatedFieldData {
  label: string;
  type: string;
  value: any;
}

interface IProps {
  model: string;
  onForward: () => void;
}

function setListToArrayOfArrays(listObj: IListData) {
  const innerArr: IUpdatedFieldData[] = [];
  listObj.list.forEach(({ label, type }) =>
    innerArr.push({ label, type, value: "" })
  );
  return innerArr;
}

function setFetchedObjToStepperObj(object: IFetchedObj) {
  const array = [];
  for (const key in object) {
    const innerArray = [];
    for (const index in object[key]) {
      const { type } = object[key][index];
      let newObj;
      if (type === "array") {
        const listObj = object[key][index] as IListData;
        newObj = [...setListToArrayOfArrays(listObj)];
      } else {
        const { label, type } = object[key][index] as IFieldData;
        newObj = { label, type, value: "" };
      }

      innerArray.push(newObj);
    }
    array.push({ stepTitle: key, content: innerArray });
  }

  return array;
}

function FormStep({ model, onForward }: IProps) {
  const { access_token, dateToExpires, user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [fetchingContractFields, setFetchingContractFields] = useState(false);

  const { rawContractData, updateRawContractData, addRawContractData } =
    useContext(ModelContractStepperContext);

  useEffect(() => {
    async function loadApi() {
      setFetchingContractFields(true);
      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${token ?? access_token}`;
        const response = await api.get(`/api/adm/contract-variables/${model}`, {
          params: { newVersion: true },
        });
        const stepperArray = setFetchedObjToStepperObj(response.data.data[0]);

        addRawContractData(stepperArray);
      } catch (err: any) {
        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
      setFetchingContractFields(false);
    }
    if (!!user) {
      if (rawContractData.length === 0) loadApi();
    } else {
      history.push("/");
    }
  }, [
    dateToExpires,
    access_token,
    user,
    history,
    addToast,
    model,
    rawContractData,
    addRawContractData,
  ]);

  function handleChange(
    stepIdentifier: number,
    fieldIdentifier: number,
    value: any,
    listMemberIdentifier?: number
  ) {
    updateRawContractData(
      stepIdentifier,
      fieldIdentifier,
      value,
      listMemberIdentifier
    );
  }

  function handleGoBack() {
    history.push("/clients-without-contract");
  }

  return (
    <Content>
      {fetchingContractFields && <ModelContractFormSkeleton />}
      {!fetchingContractFields &&
        Object.entries(rawContractData).length > 0 && (
          <Stepper
            onChange={handleChange}
            ContentWrapper={ContractStep}
            elements={rawContractData}
            onFirstStepGoBack={handleGoBack}
            onLastStepGoForward={onForward}
          />
        )}
    </Content>
  );
}

export default FormStep;
