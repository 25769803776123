import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Layout from "../../components/Layout";
import formatValue from "../../utils/formatValue";
import {Content,
        DataContainer,
        DataLabels,
        DataContent,
        ReportTable,} from "./styles";

interface IState{
    data: Array<{
        id: number;
        rent_value: string;
        number: string;
        address: string;
        neighborhood: string;
    }>;
    consultant_name: string;
}

export default function ShowPropertiesPerConsultantReport(){
    const history = useHistory();

    const { state } = useLocation<IState>();

    useEffect(() => {
        if(!state){
            history.goBack();
        }
    }, [state, history]);

    return(
        <Layout>
            <Content>
                <h2>Imóveis por Consultor</h2>

                <DataContainer>
                    <DataLabels>
                        <p>CONSULTOR</p>
                        <p>TOTAL DE IMÓVEIS</p>
                    </DataLabels>
                        

                    <DataContent>
                        <p>{state.consultant_name}</p>
                        <p>{state.data.length}</p>
                    </DataContent>
                </DataContainer>

                <ReportTable>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Valor de aluguel</th>
                            <th>Rua</th>
                            <th>Número</th>
                            <th>Bairro</th>
                        </tr>
                    </thead>

                    <tbody>
                        {state.data.map((data, index) => (
                            <tr key={index}>
                                <td>{data.id}</td>
                                <td>{formatValue(Number(data.rent_value))}</td>
                                <td>{data.address}</td>
                                <td>{data.number}</td>
                                <td>{data.neighborhood}</td>
                            </tr>
                        ))}
                    </tbody>
                </ReportTable>
            </Content>
        </Layout>
    );
}