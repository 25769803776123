import React from "react";

interface AddressLinkProps {
  address: string;
  service: "google" | "apple" | "waze";
}

function AddressLink({ address, service }: AddressLinkProps) {
  const encodedAddress = encodeURIComponent(address);

  let url = "";
  switch (service) {
    case "google":
      url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
      break;
    case "apple":
      url = `maps://?q=${encodedAddress}`;
      break;
    case "waze":
      url = `https://www.waze.com/ul?q=${encodedAddress}`;
      break;
    default:
      break;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {address}
    </a>
  );
}

export default AddressLink;
