import { MdOutlinePersonAdd } from "react-icons/md";
import DataField from "./DataField";
import { Button, Wrapper } from "./styles";
import { useContext } from "react";
import { ModelContractStepperContext } from "../../store/ModelContractStepper/model-contract-stepper";

interface IUpdatedFieldData {
  label: string;
  type: string;
  value: any;
}

interface IProps {
  children: (IUpdatedFieldData[] | IUpdatedFieldData)[];
  index: number;
  onChange: (index: number, value: any, listMemberIndex?: number) => void;
}

function ContractStep({ children, index, onChange }: IProps) {
  const { addFieldToList } = useContext(ModelContractStepperContext);
  const isList = Array.isArray(children[0]);
  return (
    <Wrapper $isList={isList}>
      {children.map((element, outerIndex) => {
        if (Array.isArray(element)) {
          return (
            <div key={outerIndex}>
              {element.map((listMember, index) => {
                return (
                  <DataField
                    key={`${listMember.label + outerIndex}`}
                    element={listMember}
                    index={index}
                    onChange={onChange}
                    listMemberIndex={outerIndex}
                  />
                );
              })}
            </div>
          );
        }
        return (
          <DataField
            key={element.label}
            element={element}
            index={outerIndex}
            onChange={onChange}
          />
        );
      })}
      {isList && (
        <Button
          type="button"
          onClick={() =>
            addFieldToList(children[0] as IUpdatedFieldData[], index)
          }
        >
          <MdOutlinePersonAdd />
        </Button>
      )}
    </Wrapper>
  );
}

export default ContractStep;
