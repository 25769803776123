import Layout from "../../components/Layout";

import {Container,
        ConsultantForm,
        InputContainer,
        LabelContainer,
        ButtonsContainer,
        CreateConsultantButton,
        ResetButton} from './styles';

import { useForm } from 'react-hook-form';

import MaskedInput from 'react-text-mask';

import { useCallback, useEffect, useMemo, useState } from "react";

import * as Yup from 'yup';

import getValidationErrors from "../../utils/getValidationError";
import refreshToken from "../../utils/refreshToken";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { useHistory, useRouteMatch } from "react-router-dom";
import Loading from "../../components/Loading";
import Select, { ValueType } from "react-select";
import { useToast } from "../../hooks/toast";

interface IEditUser{
    first_name: string;
    phone: string;
    email: string;
    password: string;
    password_confirmation: string;
}

interface IUser{
    id: number;
    first_name: string;
    phone: string;
    email: string;
    city: string;
    role_id: number;
    fallback: IConsultant[];
    jestor_table: number;
    jestor_label: number;
    jestor_fallback_label: number;
}

interface ICities{
    city: string;
    UF: string;
}

interface IFormErrors{
    [key: string]: string;
}

interface IApiFormErros{
    [key: string]: string[];
}

interface IParams{
    id: string;
}

interface IConsultant{
    first_name: string;
    id: number;
}

interface IConsultantOption{
    label: string;
    value: number;
}

export default function EditUser(){
    const { access_token, dateToExpires, user } = useAuth();
    const { addToast } = useToast();

    const history = useHistory();
    const { params } = useRouteMatch<IParams>();

    const { register, handleSubmit } = useForm<IEditUser>();

    const [userFromApi, setUserFromApi] = useState({} as IUser);

    const [city, setCity] = useState('');
    const [cities, setCities] = useState<ICities[]>([]);

    const [consultants, setConsultants] = useState<IConsultant[]>([]);
    const [fallback, setFallback] = useState<Array<any>>([]);

    const [phone, setPhone] = useState('');
    const [roleID, setRoleID] = useState<number>();

    const [formErrors, setFormErrors] = useState({} as IFormErrors);
    const [apiFormErrors, setApiFormErros] = useState({} as IApiFormErros);

    const [loading, setLoading] = useState(true);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [loadingApiRequestToGetCities, setLoadingApiRequestToGetCities] = useState(false);
    const [loadingApiRequestToGetConsultants, setLoadingApiRequestToGetConsultants] = useState(false);

    useEffect(() => {
        async function loadAPI(){
            try{
                const token = await refreshToken(dateToExpires, access_token);

                api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                const response = await api.get(`/api/adm/users/${params.id}`);

                setUserFromApi(response.data.data);
                setRoleID(response.data.data.role_id);
                setCity(response.data.data.city);
                setPhone(response.data.data.phone);
                setFallback(response.data.data.fallback)

                setLoading(false);
            } catch(err: any){
                console.log(err);

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }

                setLoading(false);
            }
        }

        if(!!user){
            loadAPI();
        } else{
            history.push('/');
        }
        // eslint-disable-next-line
    }, [access_token, user, dateToExpires, history, params.id]);

    useEffect(() => {
        
        if(!!userFromApi.city){
            loadCitiesApi();
        }
        if (userFromApi.role_id === 3) {
            loadConsultantsApi();  
        }
        // eslint-disable-next-line
    }, [dateToExpires, access_token, userFromApi]);

    useEffect(() => {
        if (consultants.length > 0 && fallback.length > 0) {
            handleSetFallback();  
        }
    }, [consultants]);

    const loadCitiesApi = async function(){
        setLoadingApiRequestToGetCities(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.get('/api/adm/addresses/cities/get');

            setCities(response.data.data);
        } catch(err: any){
            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }

            console.log(err);
        }

        setLoadingApiRequestToGetCities(false);
    };

    const loadConsultantsApi = async function(){
        
        try{
            setLoadingApiRequestToGetConsultants(true);
            const token = await refreshToken(dateToExpires, access_token);
            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.get('/api/adm/users', {
                params: {
                    role: 'consultant'
                }
            });

            setConsultants(response.data.data.consultants);
           
        } catch(err: any){
            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }

            console.log(err);
        }

        setLoadingApiRequestToGetConsultants(false);
    }

    const citiesOptions = useMemo(() => {
        return cities.map(city => ({
            label: `${city.city}-${city.UF}`,
            value: city.city
        }));
    }, [cities]);

    const consultantsOptions: IConsultantOption[] = useMemo(() => {
        return consultants.map(consultant => ({
            label: `${consultant.first_name}`,
            value: consultant.id
        }));
    }, [consultants]);

    const handleSetFallback = () => {
        
        const fallbackFormated = fallback.map((consultantFallback: any) => {
            const value = consultantFallback.consultant_id;
            const consultant = consultants.filter(consultant => consultant.id === consultantFallback.consultant_id);
            const label = consultant[0]?.first_name;

            return {
                value,
                label
            }
        });
        setFallback([ ...fallbackFormated ]);
    }

    const handleEditUser = useCallback(async(data) => {
        setLoadingRequest(true);
        setFormErrors({});
        setApiFormErros({});

        const dataToRequest = {...data, phone };

        // eslint-disable-next-line
        const phoneRegex = /\(?\d{2,}\)?[ -]?\d{1}?[ -]?\d{4}[\-\s]?\d{4}/g;

        try{
            const schema = Yup.object().shape({
                first_name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone obrigatório').matches(phoneRegex, 'Informe um número válido'),
            });

            await schema.validate(dataToRequest, {
                abortEarly: false
            });

            const token = await refreshToken(dateToExpires, access_token);
            let requestBody = { ...dataToRequest };

            if (roleID === 3) {
                if (fallback && fallback.length > 0) {
                    requestBody.fallback = fallback.map(consultant => ({ consultant_id: consultant.value }))
                } else {
                    requestBody.fallback = [];
                }
            }

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.put(`/api/adm/users/${params.id}`, {
                ...requestBody
            });

            if(!!response.data.success){
                setLoadingRequest(false);

                addToast({
                    title: 'Usuário atualizado!',
                    type: 'success'
                });

                history.push('/users');
            }

        } catch(err: any){
            if(err instanceof Yup.ValidationError){
                const error = getValidationErrors(err);

                addToast({
                    title: error[Object.keys(error)[0]],
                    type: 'info',
                });   

                setFormErrors(error);
            }

            else if(!!err.response.data.errors){
                setApiFormErros(err.response.data.errors);

                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            }

            console.log(err);

            setLoadingRequest(false);
        }
    }, [phone, fallback, roleID, history, access_token, dateToExpires, params.id, addToast]);

    return(
        <Layout>
            {!!loading && (<Loading />)}
            {!loading && (
                <Container>
                    <h2>Editar Usuário</h2>

                    <ConsultantForm onSubmit={handleSubmit(handleEditUser)}>
                        <InputContainer>
                            <LabelContainer>
                                <label>Nome:</label>
                                {!!formErrors.first_name && (
                                    <p>{formErrors.first_name}</p>
                                )}
                            </LabelContainer>
                            <input defaultValue={userFromApi.first_name} type="text" name="first_name" ref={register}/>
                        </InputContainer>

                        <InputContainer>
                            <LabelContainer>
                                <label>E-mail:</label>
                            </LabelContainer>
                            <input defaultValue={userFromApi.email} type="text" readOnly/>
                        </InputContainer>

                        <InputContainer>
                            <LabelContainer>
                                <label>Telefone:</label>
                                {!!formErrors.phone && (
                                    <p>{formErrors.phone}</p>
                                )}

                                {!!apiFormErrors.phone && (
                                    <p>{apiFormErrors.phone[0]}</p>
                                )}
                            </LabelContainer>
                            <MaskedInput 
                                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,  /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                type="text"
                                value={phone} 
                                onChange={e => setPhone(e.target.value)}
                            />
                        </InputContainer>
                        
                        {roleID === 3 &&
                            <InputContainer>
                                <LabelContainer>
                                    <label>Consultor reserva:</label>
                                </LabelContainer>
                                
                                <Select
                                    placeholder={!!loadingApiRequestToGetConsultants ? "Carregando..." : ""}
                                    options={consultantsOptions}
                                    noOptionsMessage={(obj) => "Carregando..."}
                                    onChange={(selectedOption: ValueType<IConsultantOption, true>) => setFallback(selectedOption as IConsultantOption[])}
                                    value={fallback}
                                    isMulti
                                />
                            </InputContainer>
                        }

                        {!!city && (
                            <InputContainer>
                                <LabelContainer>
                                    <label>Cidade:</label>
                                    {!!formErrors.city && (
                                        <p>{formErrors.city}</p>
                                    )}

                                    {!!apiFormErrors.city && (
                                        <p>{apiFormErrors.city[0]}</p>
                                    )}
                                </LabelContainer>
                                <Select 
                                    value={{
                                        label: city,
                                        value: city
                                    }}
                                    placeholder={!!loadingApiRequestToGetCities ? "Carregando..." : ""}
                                    options={citiesOptions}
                                    onChange={(e) => setCity(e?.value as string)}
                                    noOptionsMessage={obj => {
                                        return 'Sem cidade'
                                    }}
                                />
                            </InputContainer>
                        )}
 
                        {roleID === 3 ? (
                            <InputContainer>
                                <LabelContainer>
                                    <label>Tabela do Jestor:</label>
                                    {
                                        formErrors.jestor_table && (
                                            <p>{formErrors.jestor_table}</p>
                                        )
                                    }
                                </LabelContainer>
                                <input defaultValue={userFromApi.jestor_table} type="text" name="jestor_table" ref={register}/>
                            </InputContainer>
                        ) : (<></>)}

                        {roleID === 3 ? (
                            <InputContainer>
                                <LabelContainer>
                                    <label>Label:</label>
                                    {
                                        formErrors.jestor_label && (
                                            <p>{formErrors.jestor_label}</p>
                                        )
                                    }
                                </LabelContainer>
                                <input defaultValue={userFromApi.jestor_label} type="text" name="jestor_label" ref={register}/>
                            </InputContainer>
                        ) : (<></>)}

                        {roleID === 3 ? (
                            <InputContainer>
                                <LabelContainer>
                                    <label>Label Consultor Reserva:</label>
                                    {
                                        formErrors.jestor_fallback_label && (
                                            <p>{formErrors.jestor_fallback_label}</p>
                                        )
                                    }
                                </LabelContainer>
                                <input defaultValue={userFromApi.jestor_fallback_label} type="text" name="jestor_fallback_label" ref={register}/>
                            </InputContainer>
                        ) : (<></>)}

                        <ButtonsContainer>
                            <CreateConsultantButton type={!!loadingRequest ? "button" : "submit"}>{!!loadingRequest ? "Carregando..." : "SALVAR"}</CreateConsultantButton>
                            <ResetButton type="reset">RESETAR</ResetButton>
                        </ButtonsContainer>
                    </ConsultantForm>
                </Container>
            )}
        </Layout>
    );
}