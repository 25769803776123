import { Skeleton } from "@material-ui/lab";
import { Content, DataContainer } from "./styles";

const ScheduleSkeleton: React.FC = () => {
  return (
    <Content>
      <Skeleton variant="text" width="70%" height="3rem" />
      <DataContainer>
        <Skeleton variant="text" height="2rem" width="45%" />
        <Skeleton variant="text" height="1.5rem" width="75%" />
      </DataContainer>
      <DataContainer>
        <Skeleton variant="text" height="2rem" width="45%" />
        <Skeleton variant="text" height="1.5rem" width="75%" />
      </DataContainer>
    </Content>
  );
};

export default ScheduleSkeleton;
