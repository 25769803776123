import { Form } from "../GeneralStyles/styles";

const ContractModel: React.FC = () => {
  return (
    <Form>
      <label>Modelo de contrato:</label>
      <select></select>
    </Form>
  );
};

export default ContractModel;
