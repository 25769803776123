import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: 0;
	}
	body {
		background: #FFFFFF;
		color: #212529;
		-webkit-font-smoothing: antialiased;

		.MuiRadio-colorSecondary.Mui-checked{
			color: #DD6435;
		}

		.MuiCheckbox-colorSecondary.Mui-checked{
			color: #DD6435;
		}

		.PrivateSwitchBase-root-1{
			padding: 0;
		}

		.modalDeactivateProperty{
			padding: 0 0 24px 0;
		}

		.modalConsult{
			padding: 0;
			padding-bottom: 12px;

			margin: 2% 0;

			border-radius: 4px;

			.react-responsive-modal-closeButton{
				top: 26px;
			}
		}

		.modalClients{
			padding: 0;
			padding-bottom: 12px;

			margin: 0;

			border-radius: 4px;

			top: 50px;

			.react-responsive-modal-closeButton{
				top: 26px;
			}
		}

		.modalSchedule{
			padding: 0;
			padding-bottom: 12px;

			margin: 0;

			border-radius: 4px;

			top: 25px;

			.react-responsive-modal-closeButton{
				top: 26px;
			}
		}

		.modalPropertiesPerConsultant{
			.react-responsive-modal-closeButton{
				top: 22px;
			}
		}

		.menu{
			top: 0;
			left: 0;

			padding: 0;
			margin: 0;

			max-width: 100%;
			width: 100%;
		}

		.remove-photo-modal{
			margin: 15% auto;
		}

		@media(max-width: 767px){
			.modalConsult{
				max-width: 330px;

				display: flex;

				top: 50px;
				margin: 0 auto;
			}

			.modalVisitsPerConsultant{
				max-width: 330px;

				display: flex;

				top: 50px;
				margin: 0 auto;
			}

			.modalClients{
				max-width: 330px;

				display: flex;

				top: 50px;

				margin: 0 auto;
			}

			.modalSchedule{
				max-width: 330px;

				display: flex;

				top: 50px;
				margin: 0 auto;
			}

			.modalPropertiesPerConsultant{
				top: 50px;
				margin: 0 auto;
			}

			.remove-photo-modal{
				width: 90%;

				margin: 50% auto;
			}

			.modalDeactivateProperty{
				margin: 25% auto;
			}

		}
		#modal{
			display: flex;
		}

	}

	a{
		text-decoration: none;
		color: #FFFFFF;
	}

	body, input, button, textarea {
		font-size: 16px;
		font-family: 'Montserrat', sans-serif;
	}
	h1, h2, h3, h4, h5, h6, strong{
		font-weight: 400;
	}
	button{
		cursor: pointer;
		background: transparent;
		border: 0;
	}
`;
