import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100svh;

  > h2 {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 3rem;
    color: #666666;
  }
`;

export const ClientsTable = styled.table`
  margin-top: 3rem;
  min-width: 70%;
  flex-grow: 1;
  tr {
    display: grid;
    grid-template-columns: 15% 25% 35% 25%;
    grid-auto-rows: minmax(2.75rem, auto);
    grid-row-gap: 0.75rem;
  }

  th {
    background: #6ea4ff;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: start;

    &:first-child {
      padding-left: 1.75rem;
    }
    &:last-child {
      justify-content: center;
    }
  }

  td {
    display: flex;
    align-items: center;
    background: #f9f9f9;

    &:first-child {
      padding-left: 1.75rem;
    }
    &:last-child {
      justify-content: center;

      p {
        color: #0219a3;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  .status-text {
    text-transform: uppercase;
    color: #0219a3;
    font-weight: 700;
  }
`;

export const TableChangeContainer = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
  gap: 1%;
  font-weight: 700;
  color: #b6b6b6;
  font-size: 1rem;

  svg,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.75rem;
    min-height: 1.75rem;
  }
  svg:not(.disable):hover,
  span:hover,
  #current-page {
    cursor: pointer;
    color: #6ea4ff;
    transition: 0.15s linear;
    background: #f9f9f9;
    border-radius: 1.5rem;
  }
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 75vh;

  > h2 {
    width: 60%;

    text-align: center;

    color: #ff5252;
  }
`;
