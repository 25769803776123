import React, {FormEvent, useCallback, useState, useEffect} from 'react';

import { Link, useHistory } from 'react-router-dom';

import * as Yup from 'yup'; 
import Layout from '../../components/Layout';

import { useAuth } from './../../hooks/auth';

import getValidationError from './../../utils/getValidationError';

import {Content,
        LoginContainer,
        LoginForm} from './styles';

interface Errors{
    [key: string]: string;
}

const Login: React.FC = () => {
    const history = useHistory();

    const { signIn, user } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loadingRequest, setLoadingRequest] = useState(false);

    const [validationError, setValidationError] = useState({} as Errors);
    const [apiLoginError, setApiLoginError] = useState(false);

    useEffect(() => {
        const userLogin = localStorage.getItem('@7Cantos:user');

        if(!!userLogin){
            history.push("/schedules");
        }

    }, [history]);

    const handleSignIn = useCallback(async(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = {email: email, password: password}

        try{
            setApiLoginError(false);

            setLoadingRequest(true);

            const schema = Yup.object().shape({
                email: Yup.string().required('E-mail obrigatório').email('E-mail inválido'),
                password: Yup.string().required('Senha obrigatória')
            });

            await schema.validate(data, {
                abortEarly: false
            });

            const role = await signIn({email: email, password: password});

            setLoadingRequest(false);

            history.push(role === 'photographer' ? '/photographers-schedule' : '/schedules');
        } catch(err){
            if(err instanceof Yup.ValidationError){
                const error = getValidationError(err);

                setValidationError(error);
                setLoadingRequest(false);

                return;
            }

            setApiLoginError(true);
            setLoadingRequest(false);
        }
    }, [email, password, signIn, history]);

    return (
        <Layout isLoginScreen>
            {!user && (
                <Content>
                    <h2>FAZER LOGIN</h2>

                    <LoginContainer>
                        <LoginForm onSubmit={handleSignIn}>
                            {!!apiLoginError && (
                                <h3>E-mail/Senha inválida</h3>
                            )}

                            <label>E-mail: {!!validationError.email && (<p>{validationError.email}</p>)}</label>
                            <input 
                                type="text" 
                                name="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />

                            <label>Senha: {!!validationError.password && (<p>{validationError.password}</p>)}</label>
                            <input 
                                type="password" 
                                name="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />

                            {!loadingRequest ? (
                                <button type="submit">ENTRAR</button>
                            ) : (<span>Carregando...</span>)}
                            
                        </LoginForm>

                        <Link to="/forgot-password">Recuperar senha</Link>
                    </LoginContainer>
                </Content>
            )}
        </Layout>
    );
}

export default Login;