import React from "react";
import { IndexBtn, IndexLabel, Wrapper } from "./styles";

interface IProps {
  //   onClick: () => void;
  isSelected: boolean;
  isUnlocked: boolean;
  label: string;
  children: React.ReactNode;
  [x: string]: any;
}

function StepIndex({
  children,
  label,
  isSelected,
  isUnlocked,
  ...props
}: IProps) {
  return (
    <Wrapper>
      <IndexBtn
        type="button"
        isSelected={isSelected}
        isUnlocked={isUnlocked}
        {...props}
      >
        {children}
      </IndexBtn>
      <IndexLabel isSelected={isSelected} isUnlocked={isUnlocked}>
        {label}
      </IndexLabel>
    </Wrapper>
  );
}

export default StepIndex;
