import AreaInput from "../../../../../components/AreaInput";
import CurrencyInput from "../../../../../components/Currency";
import { Wrapper } from "./styles";

interface IProps {
  label: string;
  formError?: string;
  component: string;
  value: number;
  onChange: (value: number | string) => void;
}

function PropertyValue(props: IProps) {
  return (
    <Wrapper>
      <label>
        {props.label}: {!!props.formError && <p>{props.formError}</p>}
      </label>
      {props.component === "currency" ? (
        <CurrencyInput value={props.value} onValueChange={props.onChange} />
      ) : (
        <AreaInput
          type="text"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      )}
    </Wrapper>
  );
}

export default PropertyValue;
