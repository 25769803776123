import { useCallback, useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import ModalConfirmRemoveUser from "../../components/ModalConfirmRemoveUser";
import { useAuth } from "../../hooks/auth";
import { useRefresh } from "../../hooks/refresh";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";

import {Container, Content, ConsultansTable} from './styles';

interface IUser{
    id: number;
    first_name: string;
    email: string;
    phone: string;
}

interface IUsers{
    adms: IUser[];
    consultants: IUser[];
    photographers: IUser[];
}

export default function Users(){
    const { user, access_token, dateToExpires } = useAuth();
    const { addToast } = useToast();
    const { refresh, handleRefresh } = useRefresh();

    const history = useHistory();

    const [users, setUsers] = useState({} as IUsers);

    const [loading, setLoading] = useState(true);

    const [userIdToRemove, setUserIdToRemove] = useState(0);
    const [openModalConfirmRemoveUser, setOpenModalConfirmRemoveUser] = useState(false);
    const [loadingApiRequestToRemoveUser, setLoadingApiRequestToRemoveUser] = useState(false);

    useEffect(() => {
        async function loadAPI(){
            try{
                const token = await refreshToken(dateToExpires, access_token);

                api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                const response = await api.get('/api/adm/users', {
                    params: {
                        role: 'staff'
                    }
                });

                setUsers(response.data.data);

                setLoading(false);
            } catch(err){
                console.log(err);

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }

                setLoading(false);
            }
        }

        if(!!user){
            loadAPI();
        } else{
            history.push('/');
        }
        // eslint-disable-next-line
    }, [access_token, user, dateToExpires, history, refresh]);

    const handleRemoveUser = useCallback(async() => {
        setLoadingApiRequestToRemoveUser(true);
        
        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.delete(`/api/adm/users/${userIdToRemove}`);

            if(!!response.data.success){
                handleRefresh();

                addToast({
                    title: 'Usuário removido!',
                    type: 'success'
                });

                setOpenModalConfirmRemoveUser(false);
            }
        } catch(err){
            console.log(err);
        }

        setLoadingApiRequestToRemoveUser(false);
    }, [dateToExpires, access_token, userIdToRemove, handleRefresh, addToast]);

    return(
        <Container>
            <Layout>
                {!!loading && (
                    <Loading />
                )}

                <ModalConfirmRemoveUser 
                    open={openModalConfirmRemoveUser}
                    isLoading={loadingApiRequestToRemoveUser}
                    functionToRemoveUser={handleRemoveUser}
                    functionToCloseModal={() => setOpenModalConfirmRemoveUser(false)}
                />

                {!loading && (
                    <Content>
                        <h2>Usuários</h2>

                        <ConsultansTable cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Telefone</th>
                                    <th>Função</th>
                                    <th>Editar</th>
                                    <th>Excluir</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.consultants?.map(consultant => (
                                    <tr key={consultant.id}>
                                        <td>{consultant.first_name}</td>
                                        <td>{consultant.email}</td>
                                        <td id="td-phone">{consultant.phone}</td>
                                        <td>Consultor</td>
                                        <td><Link to={`/edit-user/${consultant.id}`}><BiEdit color="#003882" size={24}/></Link></td>
                                        <td><BiTrash color="#E00000" size={24} onClick={() => {
                                            setOpenModalConfirmRemoveUser(true);
                                            setUserIdToRemove(consultant.id);
                                        }}/></td>
                                    </tr>
                                ))}
                                {users.adms?.map(adm => (
                                    <tr key={adm.id}>
                                        <td>{adm.first_name}</td>
                                        <td>{adm.email}</td>
                                        <td id="td-phone">{adm.phone}</td>
                                        <td>Administrador</td>
                                        <td><Link to={`/edit-user/${String(adm.id)}`}><BiEdit color="#003882" size={24}/></Link></td>
                                        <td><BiTrash color="#E00000" size={24} onClick={() => {
                                            setOpenModalConfirmRemoveUser(true);
                                            setUserIdToRemove(adm.id);
                                        }}/></td>
                                    </tr>
                                ))}
                                {users.photographers?.map(adm => (
                                    <tr key={adm.id}>
                                        <td>{adm.first_name}</td>
                                        <td>{adm.email}</td>
                                        <td id="td-phone">{adm.phone}</td>
                                        <td>Fotográfo</td>
                                        <td><Link to={`/edit-user/${String(adm.id)}`}><BiEdit color="#003882" size={24}/></Link></td>
                                        <td><BiTrash color="#E00000" size={24} onClick={() => {
                                            setOpenModalConfirmRemoveUser(true);
                                            setUserIdToRemove(adm.id);
                                        }}/></td>
                                    </tr>
                                ))}
                            </tbody>
                        </ConsultansTable>

                        <Link to="/create-user">
                            ADICIONAR USUÁRIO
                        </Link>
                    </Content>
                )}
            </Layout>
        </Container>
    );
}