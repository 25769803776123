import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  > hr {
    width: 100%;
    height: 0.1px;

    color: rgba(188, 191, 194, 0.2);
  }
`;

export const TitleContainer = styled.div`
  padding: 24px 300px 24px 24px;

  @media (max-width: 767px) {
    padding: 24px 130px 24px 24px;
  }
`;

export const FormContainer = styled.form`
  padding: 12px 24px 24px 24px;
  > hr {
    width: 100%;
    height: 0.1px;

    color: rgba(188, 191, 194, 0.2);

    margin: 24px 0 8px;
  }

  > p {
    margin: 24px 0 4px;
  }

  > input {
    width: 100%;

    padding: 8px 12px;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    cursor: default;
  }

  .css-yk16xz-control {
    border: 0;
    border-bottom: 1px solid #b6b6b6;
    border-radius: 0;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1pahdxg-control {
    background-color: "transparent";

    border: 0;
    border-bottom: 1px solid #b6b6b6;
    border-radius: 0;

    box-shadow: 0 0 0 1px transparent;
  }

  .css-1pahdxg-control:hover {
    border-color: #b6b6b6;
  }

  .MuiAutocomplete-root {
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    > input {
      width: 100%;
    }

    #users {
      width: 68.2%;
    }

    .css-2b097c-container {
      width: 100%;
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 75%;

  padding: 18px 0 6px;

  > button {
    background-color: #04c5ff;

    border: 0;
    border-radius: 4px;

    padding: 12px;

    margin-left: 100%;

    color: #fff;
  }

  > p {
    width: 35%;

    background-color: transparent;

    border: 1px solid #04c5ff;
    border-radius: 4px;

    padding: 12px;

    margin-left: 90%;

    color: #04c5ff;
  }

  @media (max-width: 767px) {
    width: 67%;

    > p {
      width: 53%;

      margin-left: 85%;
    }
  }
`;
