import styled from "styled-components";

export const Form = styled.form`
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    
    label {
        font-weight: 600;
        font-size: 1rem;
        color: #606060;
        margin-top: 2rem;
    }

    select {
        margin-top: 0.75rem;
        font-size: 0.9rem;
        padding: 0.75rem 1.5rem;
        border-radius: 0.8em;
        border: 1px solid #B6B6B6;
        appearance: none;
    }

    input {
        margin-top: 0.75rem;
        font-size: 0.9rem;
        padding: 0.75rem 1.5rem;
        border-radius: 0.8em;
        border: 1px solid #B6B6B6;
    }
`;

export const GridContainer = styled.div`
    display: grid;
    width: 100%;
    column-gap: 4%;
    grid-template-columns: 48% 48%;
`;

export const FormButton = styled.button`
    background-color: #23C820;
    font-weight: 700;
    color: #FFF;
    min-width: 33%; 
    border-radius: 2rem;
    font-size: 1rem;
    padding: 0.75em 0;
    margin: 3rem auto 1rem;
`;