import styled from "styled-components";

interface IFormOption {
  isSelected: boolean;
  isUnlocked: boolean;
}

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 8px;
`;
export const IndexBtn = styled.button<IFormOption>`
  border-radius: 50%;
  width: 42px;
  height: 42px;
  border: 1px solid
    ${({ isUnlocked, isSelected }) =>
      isUnlocked || isSelected ? "#6ea6ff" : "#b6b6b6"};
  background-color: ${({ isSelected }) => (isSelected ? "#6ea6ff" : undefined)};
  color: ${({ isSelected, isUnlocked }) =>
    isSelected ? "#FFF" : isUnlocked ? "#6ea6ff" : "#b6b6b6"};
  font-weight: 500;
`;

export const IndexLabel = styled.label<IFormOption>`
  text-transform: uppercase;
  font-size: ${10 / 16}rem;
  font-weight: 600;
  color: ${({ isUnlocked }) => (isUnlocked ? "#6ea6ff" : "#b6b6b6")};
`;
