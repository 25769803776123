import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import STEPS from "./enum";
import { ButtonsContainer, Container, Content } from "./styles";
import BeltImage from "../../components/RentalBeltComponents/BeltImage";
import ContractModel from "../../components/RentalBeltComponents/ContractModel";
import OwnerRegister from "../../components/RentalBeltComponents/OwnerRegister";
import TenantRegister from "../../components/RentalBeltComponents/TenantRegister";
import ContractCreation from "../../components/RentalBeltComponents/ContractCreation";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { MdChevronRight } from "react-icons/md";
import Loading from "../../components/Loading";

const RentalBelt: React.FC = () => {
  const { access_token, user, dateToExpires } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const [activeStep, setActiveStep] = useState(STEPS.CONTRACT_MODEL);
  const [loadApiError, setLoadApiError] = useState("");
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    async function loadApi() {
      try {
        api.defaults.headers.authorization = `Bearer ${access_token}`;
        const response = await api.get("/api/adm/contract-models");
        console.log(response.data.data);

        setLoadingData(false);
      } catch (err: any) {
        if (err?.response?.status === 403) {
          setLoadApiError(err?.response?.data?.message);

          setLoadingData(true);
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadApiError(err.response.data.message);

        setLoadingData(true);
      }
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }
  }, [dateToExpires, access_token, user, history]);

  const forwardButtonClickHandler = () => {
    if (activeStep === STEPS.CONTRACT_CREATION) {
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const backButtonClickHandler = () => {
    if (activeStep === STEPS.CONTRACT_MODEL) {
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const getContent = useCallback(() => {
    switch (activeStep) {
      case STEPS.CONTRACT_MODEL:
        return <ContractModel />;
      case STEPS.OWNER_REGISTER:
        return <OwnerRegister />;
      case STEPS.TENANT_REGISTER:
        return <TenantRegister />;
      case STEPS.CONTRACT_CREATION:
        return <ContractCreation />;
    }
  }, [activeStep]);

  if(loadingData) {
    return <Loading />
  }

  return (
    <Container>
      <Content>
        <h2>Criação de contrato</h2>
        <p>
          Preencha todos os campos com atenção e confira todas as informações
          antes de finalizar.
        </p>
        <BeltImage currentStep={activeStep} />
        {getContent()}
        <ButtonsContainer>
          <button
            id={activeStep === STEPS.CONTRACT_MODEL ? "first-btn" : "back-btn"}
            onClick={backButtonClickHandler}
          >
            Voltar
          </button>
          <button
            id={
              activeStep === STEPS.CONTRACT_CREATION
                ? "last-btn"
                : "forward-btn"
            }
            onClick={forwardButtonClickHandler}
          >
            {activeStep === STEPS.CONTRACT_CREATION ? (
              "Gerar contrato"
            ) : (
              <span>
                Próximo <MdChevronRight size={24} fontWeight={700}/>
              </span>
            )}
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default RentalBelt;
