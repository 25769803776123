import React, { useState } from "react";
import Layout from "../../components/Layout";

import {
  Container,
  Content,
  EditPropertyContainer,
  InitialContainer,
  TitleAndErrorTextContainer,
  FormsOptionContainer,
} from "./styles";

import FormOption from "./Enum";
import MapForm from "./components/MapForm";
import DescriptionForm from "./components/DescriptionForm";
import CharacteristicsForm from "./components/CharacteristicsForm";
import PhotosForm from "./components/PhotosForm";
import { FORM_OPTIONS } from "./constants";
import FormOptionModel from "./components/FormOptionModel";

const PropertyCreate: React.FC = () => {
  const [formOptions, setFormOptions] = useState({
    current: FormOption.Map,
    further: FormOption.Map,
  });

  const [addressID, setAddressID] = useState<number>();
  const [propertyID, setPropertyID] = useState<number>();

  function handleSetFormOption(formToGo: number) {
    setFormOptions((curFormOption) => {
      const further =
        formToGo > curFormOption.further ? formToGo : curFormOption.further;
      return { ...curFormOption, current: formToGo, further };
    });
  }

  function isFormFree(targetForm: number) {
    return targetForm <= formOptions.further;
  }

  function handleChangeFormOnClick(targetForm: number) {
    if (isFormFree(targetForm))
      setFormOptions((curFormOptions) => ({
        ...curFormOptions,
        current: targetForm,
      }));
  }

  return (
    <Container>
      <Layout>
        <Content>
          <EditPropertyContainer>
            <InitialContainer>
              <TitleAndErrorTextContainer>
                <h3>Cadastrar imóvel</h3>
              </TitleAndErrorTextContainer>
            </InitialContainer>

            <FormsOptionContainer>
              {FORM_OPTIONS.map((option) => (
                <FormOptionModel
                  key={option.formOption}
                  isSelected={option.formOption === formOptions.current}
                  isUnlocked={isFormFree(option.formOption)}
                  onClick={() => handleChangeFormOnClick(option.formOption)}
                  title={option.title}
                  FormOptionComponent={option.Element}
                  index={option.formOption + 1}
                />
              ))}
            </FormsOptionContainer>

            {formOptions.current === FormOption.Map && (
              <MapForm
                onChangeCurrentForm={() =>
                  handleSetFormOption(FormOption.Description)
                }
                onAddressIdReceived={(id) => setAddressID(id)}
              />
            )}

            {formOptions.current === FormOption.Description && (
              <DescriptionForm
                address_id={addressID}
                onChangeCurrentForm={() =>
                  handleSetFormOption(FormOption.Characteristic)
                }
                onPropertyIdReceived={(id) => setPropertyID(id)}
              />
            )}

            {formOptions.current === FormOption.Characteristic && (
              <CharacteristicsForm
                propertyID={propertyID}
                onChangeCurrentForm={() =>
                  handleSetFormOption(FormOption.Photos)
                }
              />
            )}

            {formOptions.current === FormOption.Photos && (
              <PhotosForm propertyID={propertyID} />
            )}
          </EditPropertyContainer>
        </Content>
      </Layout>
    </Container>
  );
};

export default PropertyCreate;
