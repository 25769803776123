import api from './../services/api';

import { isAfter } from 'date-fns';

export default async function refreshToken(expires_in: Date, token: string): Promise<string | undefined>{
    const actualDate = new Date(Date.now());

    if(isAfter(actualDate, expires_in)){
		localStorage.removeItem('@7Cantos:token');
		localStorage.removeItem('@7Cantos:user');
		localStorage.removeItem('@7Cantos:dateToExpires');
        localStorage.removeItem('@7Cantos:consultants');

        window.location.reload();
        
        return;
	}
    
    if(+expires_in - +new Date(Date.now()) <= 600000){
        api.defaults.headers.authorization = `Bearer ${token}`;

        const response = await api.post('/api/auth/refresh');

		const dateToExpires = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate(), actualDate.getHours(), actualDate.getMinutes(), actualDate.getSeconds() + response.data.expires_in);

        localStorage.setItem('@7Cantos:token', response.data.access_token);
        localStorage.setItem('@7Cantos:dateToExpires', dateToExpires.toString());

        return(response.data.access_token);
    }

    return(token);
}