import Layout from "../../components/Layout";

import {Container,
        ConsultantForm,
        InputContainer,
        LabelContainer,
        RadiosContainer,
        RadioContent,
        ButtonsContainer,
        CreateConsultantButton,
        ResetButton} from './styles';

import { useForm } from 'react-hook-form';

import MaskedInput from 'react-text-mask';

import { useCallback, useEffect, useMemo, useState } from "react";

import * as Yup from 'yup';

import getValidationErrors from "../../utils/getValidationError";
import refreshToken from "../../utils/refreshToken";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { Radio } from "@material-ui/core";

import Select, { ValueType } from 'react-select';
import { useToast } from "../../hooks/toast";

interface ICreateUser{
    first_name: string;
    phone: string;
    email: string;
    password: string;
    password_confirmation: string;
}

interface IFormErrors{
    [key: string]: string;
}

interface IApiFormErros{
    [key: string]: string[];
}

interface ICities{
    city: string;
    UF: string;
}

interface IConsultant{
    first_name: string;
    id: number;
}

interface IConsultantOption{
    label: string;
    value: number;
}

export default function CreateUser(){
    const { access_token, dateToExpires } = useAuth();
    const { addToast } = useToast();

    const history = useHistory();

    const { register, handleSubmit } = useForm<ICreateUser>();

    const [phone, setPhone] = useState('');
    const [roleID, setRoleID] = useState<number>();

    const [cities, setCities] = useState<ICities[]>([]);
    const [city, setCity] = useState('');
    
    const [consultants, setConsultants] = useState<IConsultant[]>([]);
    const [fallback, setFallback] = useState<Array<any>>([]);

    const [formErrors, setFormErrors] = useState({} as IFormErrors);
    const [apiFormErrors, setApiFormErros] = useState({} as IApiFormErros);

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [loadingApiRequestToGetCities, setLoadingApiRequestToGetCities] = useState(false);
    const [loadingApiRequestToGetConsultants, setLoadingApiRequestToGetConsultants] = useState(false);

    useEffect(() => {
        if (roleID === 3){
            loadConsultantsApi();
            loadCitiesApi();
        } else if (roleID === 4) {
            loadCitiesApi();
        } else{
            return;
        }
        // eslint-disable-next-line
    }, [dateToExpires, access_token, roleID]);

    const loadCitiesApi = async function (){
        setLoadingApiRequestToGetCities(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.get('/api/adm/addresses/cities/get');

            setCities(response.data.data);
        } catch(err: any){
            console.log(err);

            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }
        }

        setLoadingApiRequestToGetCities(false);
    };

    const loadConsultantsApi = async function(){
        
        try{
            setLoadingApiRequestToGetConsultants(true);
            const token = await refreshToken(dateToExpires, access_token);
            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.get('/api/adm/users', {
                params: {
                    role: 'consultant'
                }
            });

            setConsultants(response.data.data.consultants);
           
        } catch(err: any){
            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }

            console.log(err);
        }

        setLoadingApiRequestToGetConsultants(false);
    }

    const citiesOptions = useMemo(() => {
        return cities.map(city => ({
            label: `${city.city}-${city.UF}`,
            value: city.city
        }));
    }, [cities]);
    
    const consultantsOptions: IConsultantOption[] = useMemo(() => {
        return consultants.map(consultant => ({
            label: `${consultant.first_name}`,
            value: consultant.id
        }));
    }, [consultants]);

    const handleOnChangeFallback = (consultantsOptions: IConsultantOption[]) => {
        const fallbackIds: Array<number> = consultantsOptions.map((consultantOption: IConsultantOption) => consultantOption?.value);
        setFallback(fallbackIds.map(id => ({ consultant_id: id })));
    }
    
    const handleCreateUser = useCallback(async(data) => {
        setLoadingRequest(true);
        setFormErrors({});
        setApiFormErros({});

        const dataToRequest = roleID === 1 ? {...data, role_id: roleID, phone} : {...data, role_id: roleID, phone, city};
        
        // eslint-disable-next-line
        const phoneRegex = /\(?\d{2,}\)?[ -]?\d{1}?[ -]?\d{4}[\-\s]?\d{4}/g;

        try{
            const schema = Yup.object().shape(roleID === 1 ? {
                first_name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone obrigatório').matches(phoneRegex, 'Informe um número válido'),
                email: Yup.string().required('E-mail obrigatório').email('Informe um e-mail válido'),
                role_id: Yup.number().required('Informe a função do usuário'),
                password: Yup.string().required('Senha obrigatória').min(6, 'A senha deve ter pelo menos 6 caracteres'),
                password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'Confirmação de senha incorreta').required('Confirmação de senha obrigatória')
            } : {
                first_name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone obrigatório').matches(phoneRegex, 'Informe um número válido'),
                email: Yup.string().required('E-mail obrigatório').email('Informe um e-mail válido'),
                role_id: Yup.number().required('Informe a função do usuário'),
                password: Yup.string().required('Senha obrigatória').min(6, 'A senha deve ter pelo menos 6 caracteres'),
                city: Yup.string().required('Cidade obirgatória'),
                password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'Confirmação de senha incorreta').required('Confirmação de senha obrigatória')
            });

            await schema.validate(dataToRequest, {
                abortEarly: false
            });

            const token = await refreshToken(dateToExpires, access_token);
            let requestBody = { ...dataToRequest };
            
            if (roleID === 3 && fallback && fallback.length > 0) {
                requestBody.fallback = fallback;
            }

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/users', {
                ...requestBody
            });

            if(!!response.data.success){
                setLoadingRequest(false);

                addToast({
                    title: "Usuário criado",
                    type: 'success'
                });

                history.push('/users');
            }

        } catch(err: any){
            if(err instanceof Yup.ValidationError){
                const error = getValidationErrors(err);

                addToast({
                    title: error[Object.keys(error)[0]],
                    type: 'info',
                });   

                setFormErrors(error);
            }

            else if(!!err.response.data.errors){
                setApiFormErros(err.response.data.errors);

                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            }

            setLoadingRequest(false);
        }
    }, [phone, fallback, roleID, city, history, access_token, dateToExpires, addToast]);

    return(
        <Layout>
            <Container>
                <h2>Cadastrar Usuário</h2>

                <ConsultantForm onSubmit={handleSubmit(handleCreateUser)}>
                    <InputContainer>
                        <LabelContainer>
                            <label>Nome:</label>
                            {!!formErrors.first_name && (
                                <p>{formErrors.first_name}</p>
                            )}
                        </LabelContainer>
                        <input type="text" name="first_name" ref={register}/>
                    </InputContainer>

                    <InputContainer>
                        <LabelContainer>
                            <label>E-mail:</label>
                            {!!formErrors.email && (
                                <p>{formErrors.email}</p>
                            )}

                            {!!apiFormErrors.email && (
                                <p>{apiFormErrors.email[0]}</p>
                            )}
                        </LabelContainer>
                        <input type="text" name="email" ref={register}/>
                    </InputContainer>

                    <InputContainer>
                        <LabelContainer>
                            <label>Telefone:</label>
                            {!!formErrors.phone && (
                                <p>{formErrors.phone}</p>
                            )}

                            {!!apiFormErrors.phone && (
                                <p>{apiFormErrors.phone[0]}</p>
                            )}
                        </LabelContainer>
                        <MaskedInput 
                            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,  /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                            type="text" value={phone} 
                            onChange={e => setPhone(e.target.value)}
                        />
                    </InputContainer>

                    {!!formErrors.role_id && (
                        <p>{formErrors.role_id}</p>
                    )}
                    <RadiosContainer>
                        <RadioContent>
                            <Radio name="role_id" checked={roleID === 1} onChange={() => setRoleID(1)}/>
                            <label onClick={() => setRoleID(1)}>Administrador</label>
                        </RadioContent>

                        <RadioContent>
                            <Radio name="role_id" checked={roleID === 3} onChange={() => setRoleID(3)}/>
                            <label onClick={() => setRoleID(3)}>Consultor</label>
                        </RadioContent>

                        <RadioContent>
                            <Radio name="role_id" checked={roleID === 4} onChange={() => setRoleID(4)}/>
                            <label onClick={() => setRoleID(4)}>Fotográfo</label>
                        </RadioContent>
                    </RadiosContainer>
                    
                    {roleID === 3 && 
                        <InputContainer>
                            <LabelContainer>
                                <label>Consultor reserva:</label>
                            </LabelContainer>
                            
                            <Select
                                placeholder={!!loadingApiRequestToGetConsultants ? "Carregando..." : ""}
                                options={consultantsOptions}
                                noOptionsMessage={(obj) => "Carregando..."}
                                onChange={(selectedOption: ValueType<IConsultantOption, true>) => handleOnChangeFallback(selectedOption as IConsultantOption[])}
                                isMulti
                            />
                        </InputContainer>
                    }

                    {roleID === 3 || roleID === 4 ? (
                        <InputContainer>
                            <LabelContainer>
                                <label>Cidade:</label>
                                {!!formErrors.city && (
                                    <p>{formErrors.city}</p>
                                )}

                                {!!apiFormErrors.city && (
                                    <p>{apiFormErrors.city[0]}</p>
                                )}
                            </LabelContainer>
                            <Select
                                placeholder={!!loadingApiRequestToGetCities ? "Carregando..." : ""}
                                options={citiesOptions}
                                noOptionsMessage={(obj) => "Carregando..."}
                                onChange={e => setCity(e!.value)}
                            />
                        </InputContainer>
                    ) : (<></>)}

                    {roleID === 3 ? (
                        <InputContainer>
                            <LabelContainer>
                                <label>Tabela do Jestor:</label>
                                {
                                    formErrors.jestor_table && (
                                        <p>{formErrors.jestor_table}</p>
                                    )
                                }
                            </LabelContainer>
                            <input type="text" name="jestor_table" ref={register}/>
                        </InputContainer>
                    ) : (<></>)}

                    {roleID === 3 ? (
                        <InputContainer>
                            <LabelContainer>
                                <label>Label:</label>
                                {
                                    formErrors.jestor_label && (
                                        <p>{formErrors.jestor_label}</p>
                                    )
                                }
                            </LabelContainer>
                            <input type="text" name="jestor_label" ref={register}/>
                        </InputContainer>
                    ) : (<></>)}

                    {roleID === 3 ? (
                        <InputContainer>
                            <LabelContainer>
                                <label>Label Consultor Reserva:</label>
                                {
                                    formErrors.jestor_fallback_label && (
                                        <p>{formErrors.jestor_fallback_label}</p>
                                    )
                                }
                            </LabelContainer>
                            <input type="text" name="jestor_fallback_label" ref={register}/>
                        </InputContainer>
                    ) : (<></>)}

                    <InputContainer>
                        <LabelContainer>
                            <label>Criar senha:</label>
                            {!!formErrors.password && (
                                <p>{formErrors.password}</p>
                            )}

                            {!!apiFormErrors.password && (
                                <p>{apiFormErrors.password[0]}</p>
                            )}
                        </LabelContainer>
                        <input type="password" name="password" ref={register}/>
                    </InputContainer>

                    <InputContainer>
                        <LabelContainer>
                            <label>Confirmar senha:</label>
                            {!!formErrors.password_confirmation && (
                                <p>{formErrors.password_confirmation}</p>
                            )}
                            
                            {!!apiFormErrors.password_confirmation && (
                                <p>{apiFormErrors.password_confirmation[0]}</p>
                            )}
                        </LabelContainer>
                        <input type="password" name="password_confirmation" ref={register}/>
                    </InputContainer>
                    
                    <ButtonsContainer>
                        <CreateConsultantButton type={!!loadingRequest ? "button" : "submit"}>{!!loadingRequest ? "Carregando..." : "CADASTRAR"}</CreateConsultantButton>
                        <ResetButton type="reset" onClick={() => {
                            setRoleID(undefined);
                            setCity('');
                        }}>RESETAR</ResetButton>
                    </ButtonsContainer>
                </ConsultantForm>
            </Container>
        </Layout>
    );
}