import { MdAnnouncement } from "react-icons/md";
import Modal from "react-responsive-modal";

import {Content,
        ButtonsContainer,
        ConfirmButton,
        CancelButton} from './styles';

interface IModalConfirmCloneProperty{
    open: boolean;
    isLoading: boolean;
    propertyId: number;
    functionToCloneProperty(): void;
    functionToCloseModal(): void;
}

export default function ModalConfirmCloneProperty({ open, isLoading, propertyId, functionToCloneProperty, functionToCloseModal }: IModalConfirmCloneProperty){
    return(
        <Modal open={open} onClose={functionToCloseModal} classNames={{
            modal: "remove-photo-modal"
        }}>
            <Content>
                <MdAnnouncement size={40} color="#666666"/>

                <p>Tem certeza que deseja clonar o imóvel de código {propertyId}?</p>

                <ButtonsContainer>
                    <ConfirmButton onClick={!!isLoading ? () => {} : functionToCloneProperty} isLoading={isLoading}>{!!isLoading ? "Carregando..." : "Clonar Imóvel"}</ConfirmButton>
                    <CancelButton onClick={functionToCloseModal}>Não</CancelButton>
                </ButtonsContainer>
            </Content>
        </Modal>
    );
}