import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  Button,
  ButtonContainer,
  DataContainer,
  Dialog,
  TitleContainer,
} from "./styles";

interface IProps {
  onClose: () => void;
  onAddOwner: (identifier: string, value: any) => void;
  owner: IUserData;
}
interface IModal {
  open: () => void;
  close: () => void;
}

interface IUserData {
  first_name: string;
  email: string;
  phone: string;
}

const DEFAULT_OWNER = {
  first_name: "",
  email: "",
  phone: "",
};

const ModalAddOwner = forwardRef<IModal, IProps>(function ModalAddOwner(
  { onClose, onAddOwner, owner },
  ref
) {
  const [userData, setUserData] = useState<IUserData>(DEFAULT_OWNER);

  const dialog = useRef<HTMLDialogElement>(null);
  useImperativeHandle(ref, () => {
    return {
      open() {
        dialog.current?.showModal();
      },
      close() {
        dialog.current?.close();
      },
    };
  });

  useEffect(() => {
    if (!owner) {
      setUserData(DEFAULT_OWNER);
    }
  }, [owner]);

  function handleChange(identifier: string, value: string) {
    setUserData((prevUserData) => ({ ...prevUserData, [identifier]: value }));
  }

  function handleAddOwner() {
    onAddOwner("user_data", userData);
  }

  return createPortal(
    <Dialog ref={dialog}>
      <TitleContainer>
        <h3>Adicionar Proprietário</h3>
      </TitleContainer>
      <DataContainer method="dialog" onSubmit={handleAddOwner}>
        <h3>CLIENTE </h3>
        <p>Nome: </p>
        <input
          type="text"
          value={userData.first_name}
          onChange={(e) => handleChange("first_name", e.target.value)}
          required
        />
        <p>Email: </p>
        <input
          type="text"
          value={userData.email}
          onChange={(e) => handleChange("email", e.target.value)}
          required
        />
        <p>Telefone: </p>
        <input
          type="text"
          value={userData.phone}
          onChange={(e) => handleChange("phone", e.target.value)}
          required
        />
        <ButtonContainer>
          <Button type="button" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="submit">Adicionar</Button>
        </ButtonContainer>
      </DataContainer>
    </Dialog>,
    document.getElementById("modal")!
  );
});

export default ModalAddOwner;
