import { useEffect, useState } from "react";
import { Container, Content, FormContainer } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { useHistory, useLocation } from "react-router-dom";

interface IState {
  id: number;
  name: string;
  relevance_order: number;
}

interface IInputs {
  name: string;
  relevance_order: number;
}

const CreateCommercialRegion = () => {
  const { user, access_token } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<IState>();
  const isEditing = !!state;
  const history = useHistory();

  const [inputs, setInputs] = useState({
    name: {
      value: isEditing ? state.name : "",
      isValid: true,
    },
    relevance_order: {
      value: isEditing ? state.relevance_order : "",
      isValid: true,
    },
  });

  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }
  }, [history, user]);

  function handleCreateCommercialRegion() {
    const commercialRegion = {
      name: inputs.name.value,
      relevance_order: +inputs.relevance_order.value,
    };
    const nameIsValid = commercialRegion.name.trim().length > 0;
    const relevanceOrderIsValid = commercialRegion.relevance_order > 0;

    if (!nameIsValid || !relevanceOrderIsValid) {
      setInputs((prevInputs) => {
        return {
          name: {
            value: prevInputs.name.value,
            isValid: nameIsValid,
          },
          relevance_order: {
            value: prevInputs.relevance_order.value,
            isValid: relevanceOrderIsValid,
          },
        };
      });
      return;
    }
    onSubmit(commercialRegion);
  }

  async function onSubmit(commercialRegion: IInputs) {
    setIsSubmiting(true);
    try {
      api.defaults.headers.authorization = `Bearer ${access_token}`;

      const response = isEditing
        ? await api.put(
            `/api/adm/commercial-region/${state.id}`,
            commercialRegion
          )
        : await api.post("/api/adm/commercial-region", commercialRegion);

      if (!!response.data.success) {
        addToast({
          title: isEditing
            ? "Região comercial editada com sucesso"
            : "Região comercial criada com sucesso",
          type: "success",
        });
        if (isEditing) {
          history.push("/show-commercial-regions");
        }
      }
    } catch (err: any) {
      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }

    setIsSubmiting(false);
  }

  function handleInputChange(identifier: string, value: any) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [identifier]: { value, isValid: true },
    }));
  }

  return (
    <Container>
      <Header />
      <Content>
        <h2>
          {isEditing ? "Editar Região Comercial" : "Cadastrar Região Comercial"}
        </h2>
        <FormContainer>
          <label>
            Nome da região comercial:{" "}
            {!inputs.name.isValid && (
              <strong style={{ color: "#FF3838" }}>Nome obrigatório.</strong>
            )}
          </label>
          <input
            value={inputs.name.value}
            onChange={(e) => {
              handleInputChange("name", e.target.value);
            }}
          />
          <label>
            Ordem de relevância:{" "}
            {!inputs.relevance_order.isValid && (
              <strong style={{ color: "#FF3838" }}>
                Relevância obrigatória.
              </strong>
            )}
          </label>
          <input
            type="number"
            value={inputs.relevance_order.value}
            onChange={(e) => {
              handleInputChange("relevance_order", e.target.value);
            }}
          />
          <button
            onClick={isSubmiting ? () => {} : handleCreateCommercialRegion}
            id={isSubmiting ? "wait-btn" : ""}
          >
            {isEditing
              ? isSubmiting
                ? "Salvando alterações..."
                : "Salvar alterações"
              : isSubmiting
              ? "Criando região comercial..."
              : "Criar Região Comercial"}
          </button>
        </FormContainer>
      </Content>
      <Footer />
    </Container>
  );
};

export default CreateCommercialRegion;
