import { MdAnnouncement } from "react-icons/md";
import Modal from "react-responsive-modal";

import {Content,
        ButtonsContainer,
        ConfirmButton,
        CancelButton} from './styles';

interface IModalConfirmRemoveUser{
    open: boolean;
    isLoading: boolean;
    functionToRemoveUser(): void;
    functionToCloseModal(): void;
}

export default function ModalConfirmRemoveUser({ open, isLoading, functionToRemoveUser, functionToCloseModal }: IModalConfirmRemoveUser){
    return(
        <Modal open={open} onClose={functionToCloseModal} classNames={{
            modal: "remove-photo-modal"
        }}>
            <Content>
                <MdAnnouncement size={40} color="#666666"/>

                <p>Tem certeza que deseja deletar esse usuário?</p>

                <ButtonsContainer>
                    <ConfirmButton onClick={!!isLoading ? () => {} : functionToRemoveUser} isLoading={isLoading}>{!!isLoading ? "Carregando..." : "Sim"}</ConfirmButton>
                    <CancelButton onClick={functionToCloseModal}>Não</CancelButton>
                </ButtonsContainer>
            </Content>
        </Modal>
    );
}