import FloorTypeOptions from "./enums/FloorTypeOptions";
import PropertyTypeOptions from "./enums/PropertyTypeOptions";

export const PROPERTY_TYPES = [
  { label: "Casa", value: PropertyTypeOptions.HOUSE },
  { label: "Casa de Condomínio", value: PropertyTypeOptions.CONDOMINIUM_HOUSE },
  { label: "Apartamento", value: PropertyTypeOptions.APARTMENT },
  { label: "Flat", value: PropertyTypeOptions.FLAT },
];

export const RANK_OPTIONS = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const FLOOR_TYPES = [
  { name: "floor-type-low", label: "Baixo", value: FloorTypeOptions.LOW },
  {
    name: "floor-type-medium",
    label: "Intermediário",
    value: FloorTypeOptions.MEDIUM,
  },
  { name: "floor-type-high", label: "Alto", value: FloorTypeOptions.HIGH },
];

const BATHROOMS_RANGE = { min: 1, max: 10 };
const ROOMS_RANGE = { min: 1, max: 10 };
const SUITES_RANGE = { min: 0, max: 5 };
const GARAGES_RANGE = { min: 0, max: 5 };

function getOptions(
  minValue: number,
  maxValue: number,
  lastLabelFormat?: string
) {
  let options = [];
  for (let i = minValue; i <= maxValue; i++)
    options.push({
      label: String(i).concat(lastLabelFormat ?? ""),
      value: i,
    });
  return options;
}

export const ACCOMMODATION_OPTIONS = [
  {
    label: "Quartos",
    options: getOptions(ROOMS_RANGE.min, ROOMS_RANGE.max),
    attributeName: "rooms",
  },
  {
    label: "Suites",
    options: getOptions(SUITES_RANGE.min, SUITES_RANGE.max, "+"),
    attributeName: "suites",
  },
  {
    label: "Banheiros",
    options: getOptions(BATHROOMS_RANGE.min, BATHROOMS_RANGE.max),
    attributeName: "bathrooms",
  },
  {
    label: "Vagas",
    options: getOptions(GARAGES_RANGE.min, GARAGES_RANGE.max, "+"),
    attributeName: "garages",
  },
];

export const PROPERTY_VALUES = [
  {
    label: "Valor do Aluguel (R$)",
    attributeName: "rent_value",
    component: "currency",
  },
  {
    label: "Valor do Condomínio (R$)",
    attributeName: "condominium_value",
    component: "currency",
  },
  {
    label: "Valor do IPTU (R$)",
    attributeName: "iptu_value",
    component: "currency",
  },
  {
    label: "Taxa do Lixo (R$)",
    attributeName: "garbage_tax",
    component: "currency",
  },
  { label: "Área útil (m²)", attributeName: "area", component: "area" },
];

export const PROPERTY_PERMISSIONS = [
  {
    label: "Mobiliado",
    name: "selector-group30",
    attributeName: "is_furnished",
  },
  {
    label: "Permite Pets",
    name: "selector-group31",
    attributeName: "allow_pets",
  },
  {
    label: "Água inclusa",
    name: "selector-group32",
    attributeName: "water_included",
  },
  {
    label: "Gás incluso",
    name: "selector-group33",
    attributeName: "gas_included",
  },
  {
    label: "Admin pela 7cantos",
    name: "selector-group34",
    attributeName: "is_adm",
  },
];
