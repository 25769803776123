import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
    max-width: 1200px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 64px auto 5%;

    >h2{
        font-size: 35px;
        font-weight: bold;
        color: #666666;

        margin-bottom: 16px;
    }

    >a{
        background: #00BF33;

        padding: 12px 64px;

        font-weight: 600;
        color: #FFFFFF;

        margin: 36px 0;
    }
`;

export const ConsultansTable = styled.table`
    border-spacing: 0 12px;

    thead{
        th{
            background: #6ea5ff;

            text-align: start;

            color: #FFFFFF;
            font-weight: 500;

            padding: 16px 32px;
        }
    }

    tbody{
        td{
            padding: 16px 32px;

            background: #F9F9F9;

            color: #666666;

            >svg{
                margin-left: 16px;

                cursor: pointer;
            }
        }

        #td-phone{
            width: 200px;
        }
    }

    @media(max-width: 767px){
        overflow-x: auto;

        display: block;

        width: 100%;

        margin: 0;
    }
`;