import styled from "styled-components";

export const Content = styled.div`
    max-width: 1100px;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    margin: 64px auto 5%;

    >h2{
        font-size: 32px;
        font-weight: bold;

        color: #666666;

        text-align: center;

        margin: 0 auto 24px;
    }

    >h3{
        color: #666666;
    }
`;

export const DataContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    margin: 0 auto;

    @media(max-width: 767px){
        width: 300px;
    }
`;

export const DataLabels = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    background: #DD6435;

    color: #FFF;

    padding: 12px 0;

    >p{
        width: 200px;
        text-align: center;

        font-weight: 500;
    }

    @media(max-width: 767px){
        >p{
            width: 150px;

            font-size: 12px;
        }
    }
`;

export const DataContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin: 8px 0;

    width: 100%;

    >p{
        width: 190px;
        text-align: center;

        font-weight: 500;

        padding: 12px 0;

        color: #666666;

        background: #F9F9F9;
    }

    @media(max-width: 767px){
        >p{
            width: 140px;

            font-size: 12px;
        }
    }
`;

export const ReportTable = styled.table`
    border-spacing: 0 12px;

    width: 100%;

    thead{
        th{
            background: #6ea5ff;

            text-align: start;

            color: #FFFFFF;
            font-weight: 500;

            width: 190px;

            padding: 16px 0 16px 32px;
        }
    }

    tbody{
        td{
            padding: 16px 32px;

            background: #F9F9F9;

            color: #666666;

            >svg{
                margin-left: 16px;
            }
        }

        #td-phone{
            width: 200px;
        }
    }
`;