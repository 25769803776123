import styled from "styled-components";

export const Wrapper = styled.form`
  width: 100%;
`;

export const IndexesWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 16px auto 0;
`;

export const StepBtn = styled.button`
  width: 30%;
  border-radius: 2rem;
  padding: 8px 0;
  font-weight: 500;
  font-size: ${12 / 16}rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    font-size: ${16/16}rem;
  }
`;

export const BackBtn = styled(StepBtn)`
  border: 1px solid #b6b6b6;
  color: #606060;
`;
export const ForwardBtn = styled(StepBtn)`
  border: 1px solid #6ea6ff;
  color: #fff;
  background-color: #6ea6ff;
`;
export const LastBtn = styled(StepBtn)``;
