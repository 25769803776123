import React, { useState, useCallback, ChangeEvent } from "react";

import {
  Content,
  TitleContainer,
  FormContainer,
  ButtonsContainer,
  CheckboxContainer,
} from "./styles";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import api from "./../../services/api";
import refreshToken from "./../../utils/refreshToken";

import { useAuth } from "./../../hooks/auth";

import { FiX } from "react-icons/fi";
import { useToast } from "../../hooks/toast";
import { useRefresh } from "../../hooks/refresh";

import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationError";
import axios, { CancelTokenSource } from "axios";
import OutsideClickHandler from "react-outside-click-handler";
import useModalFetch from "../../hooks/useModalFetch";
import ScheduleSkeleton from "./ScheduleSkeleton";
/* import OutsideClickHandler from 'react-outside-click-handler'; */

interface IUsers {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}

interface IUsers {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}
interface IProperty {
  id: number;
  title: string;
}

interface IModalSchedule {
  open: boolean;
  param: string;
  functionToCloseModalSchedule(): void;
}

const getPropertiesOptions = (properties: IProperty[]) => {
  return properties.map((property) => ({
    value: property.id,
    label: `${property.id} - ${property.title}`,
  }));
};

const ModalSchedule: React.FC<IModalSchedule> = ({
  open,
  param,
  functionToCloseModalSchedule,
}: IModalSchedule) => {
  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const { handleRefresh } = useRefresh();
  const {
    fetchedData: dataToShow,
    isFetching,
    error,
  } = useModalFetch(`/api/adm/schedule/panel`);

  const [userSelected, setUserSelected] = useState({} as IUsers);

  const [email, setEmail] = useState("");

  const [users, setUsers] = useState<IUsers[]>([]);
  const [registerNewClient, setRegisterNewClient] = useState(false);

  const [clientIdSelected, setClientIdSelected] = useState<number>();
  const [propertyIdSelected, setPropertyIdSelected] = useState<number>();

  const [loadingRequestToSchedule, setLoadingRequestToSchedule] =
    useState(false);

  let cancelToken: undefined | CancelTokenSource;

  const handleSearchChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchTerm = e.target.value;

      setEmail(searchTerm);

      if (cancelToken !== undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      // eslint-disable-next-line
      cancelToken = axios.CancelToken.source();

      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get("/api/adm/users", {
          params: {
            email: searchTerm,
          },
          cancelToken: cancelToken?.token,
        });

        if (response.data.data.users.length === 0) {
          addToast({
            title: "Nenhum usuário encontrado",
            type: "error",
          });
        }

        setUsers(response.data.data.users);
      } catch (err) {
        console.log(err);
      }
    },
    [cancelToken, access_token, dateToExpires, addToast]
  );

  const handleSelectUser = useCallback(
    (user_id: number | undefined) => {
      const user_selected = users.find((client) => client.id === user_id);
      setClientIdSelected(user_id);
      setUsers([]);

      if (!!user_selected) {
        setEmail(user_selected?.email);
        setUserSelected(user_selected);
      }
    },
    [users]
  );

  const handleSchedule = useCallback(async () => {
    setLoadingRequestToSchedule(true);

    let data;

    if (registerNewClient)
      data = {
        propertyIdSelected,
        phone: userSelected.phone,
        email: userSelected.email,
        name: userSelected.first_name,
      };
    else {
      data = { propertyIdSelected, clientIdSelected };
    }

    try {
      let schema;
      if (registerNewClient) {
        schema = Yup.object().shape({
          email: Yup.string().email("Digite um e-mail"),
          phone: Yup.string().required("Digite um telefone"),
          name: Yup.string().required("Digite um nome"),
          propertyIdSelected: Yup.number().required("Selecione um imóvel"),
        });
      } else {
        schema = Yup.object().shape({
          clientIdSelected: Yup.number().required("Selecione um e-mail"),
          propertyIdSelected: Yup.number().required("Selecione um imóvel"),
        });
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      let body = {};

      if (registerNewClient) {
        body = {
          schedule: param,
          property_id: propertyIdSelected,
          user_data: {
            ...userSelected,
          },
        };
      } else {
        body = {
          schedule: param,
          property_id: propertyIdSelected,
          client_id: clientIdSelected,
        };
      }

      const response = await api.post("/api/adm/schedules/mark", {
        ...body,
      });

      if (!!response.data.success) {
        addToast({
          title: "Agendamento concluído",
          type: "success",
          description: "",
        });

        handleRefresh();
        functionToCloseModalSchedule();
      }

      setLoadingRequestToSchedule(false);
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        addToast({
          title: errors[Object.keys(errors)[0]],
          type: "info",
          description: "",
        });
      } else if (!!err.response.data.errors) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      setLoadingRequestToSchedule(false);
    }
  }, [
    param,
    propertyIdSelected,
    clientIdSelected,
    dateToExpires,
    access_token,
    addToast,
    functionToCloseModalSchedule,
    handleRefresh,
  ]);

  function handleToggleRegisterNewClient() {
    setRegisterNewClient((prevData) => !prevData);
    setUserSelected((prevValue) => ({ ...prevValue, email: "" }));
    setEmail("");
  }

  let content = <ScheduleSkeleton />;
  if (!isFetching && Object.keys(dataToShow).length !== 0)
    content = (
      <Content>
        <TitleContainer>
          <h3>Agendamento</h3>
        </TitleContainer>
        <hr />
        <FormContainer>
          <CheckboxContainer>
            <input
              checked={registerNewClient}
              type="checkbox"
              id="registered"
              onChange={handleToggleRegisterNewClient}
            />
            <label htmlFor="registered">Novo Cliente</label>
          </CheckboxContainer>
          <p>E-mail:</p>
          <input
            value={registerNewClient ? userSelected.email : email}
            onChange={
              registerNewClient
                ? (e) =>
                    setUserSelected((prevValue) => ({
                      ...prevValue,
                      email: e.target.value,
                    }))
                : email.length <= 2
                ? (e) => setEmail(e.target.value)
                : handleSearchChange
            }
          />

          {users.length >= 1 ? (
            <OutsideClickHandler
              onOutsideClick={() => {
                setEmail("");
                setUsers([]);
              }}
            >
              <div id="users">
                {users.map((user) => (
                  <button
                    key={user.id}
                    type="button"
                    onClick={() => handleSelectUser(user.id)}
                  >
                    {user.email}
                  </button>
                ))}
              </div>
            </OutsideClickHandler>
          ) : (
            <></>
          )}

          {(!!userSelected.first_name || registerNewClient) && (
            <>
              <p>Nome: </p>
              <input
                type="text"
                readOnly={!registerNewClient}
                value={userSelected.first_name}
                onChange={(e) =>
                  setUserSelected((prevValue) => ({
                    ...prevValue,
                    first_name: e.target.value,
                  }))
                }
              />

              <p>Telefone: </p>
              <input
                type="text"
                readOnly={!registerNewClient}
                value={userSelected.phone}
                onChange={(e) =>
                  setUserSelected((prevValue) => ({
                    ...prevValue,
                    phone: e.target.value,
                  }))
                }
              />
            </>
          )}

          <p>Imóvel:</p>
          <Autocomplete
            id="combo-box-demo"
            options={getPropertiesOptions(dataToShow.properties)}
            getOptionLabel={(option) => option.label}
            style={{ width: "100%" }}
            noOptionsText="Sem opções"
            onChange={(e, value) => setPropertyIdSelected(value?.value)}
            renderInput={(params) => (
              <TextField {...params} label="" margin="normal" />
            )}
          />
        </FormContainer>

        <hr />

        <ButtonsContainer>
          {!!loadingRequestToSchedule ? (
            <p>Carregando...</p>
          ) : (
            <button onClick={handleSchedule}>Agendar</button>
          )}
        </ButtonsContainer>
      </Content>
    );

  return (
    <Modal
      open={open}
      onClose={functionToCloseModalSchedule}
      classNames={{
        modal: "modalSchedule",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      {content}
    </Modal>
  );
};

export default ModalSchedule;
