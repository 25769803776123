/* import { useCallback, useState } from "react"; */
import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import Modal from "react-responsive-modal";
/* import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";

import * as Yup from 'yup'; */

import {Content,
    TitleContainer,
    InputContainer,
    InputContent,
    CreateCategoryButton} from './styles';
/* import getValidationErrors from "../../utils/getValidationError";
import { useToast } from "../../hooks/toast";
import { useRefresh } from "../../hooks/refresh"; */

interface IModalCreateCategory{
    open: boolean;
    isLoading: boolean;
    functionToCreateModal(data: IData): void;
    functionToCloseModal(): void;
}

interface IData{
    name: string;
}

export default function ModalCreateCategory({ open, isLoading, functionToCreateModal, functionToCloseModal }: IModalCreateCategory){
    /* const { access_token, dateToExpires } = useAuth();
    const { addToast } = useToast();
    const { handleRefresh } = useRefresh(); */

    const { handleSubmit, register } = useForm();

    /* const [loadingRequest, setLoadingRequest] = useState(false); */

    /* const handleCreateCategory = useCallback(async(data: IData) => {
        setLoadingRequest(true);

        try{
            const schema = Yup.object().shape({
                name: Yup.string().required('Campo obrigatório!')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.post('/api/adm/faq-category', data);

            if(!!response.data.success){
                addToast({
                    title: "Categoria criada!",
                    type: 'success',
                    description: ''
                });

                handleRefresh();
                functionToCloseModal();
            };
        } catch(err){
            console.log(err);

            if(err instanceof Yup.ValidationError){
                const errors = getValidationErrors(err);

                addToast({
                    title: errors[Object.keys(errors)[0]],
                    type: 'info',
                    description: ''
                });   
            }

            else if(!!err.response.data.errors){
                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            };

            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }
        }

        setLoadingRequest(false);
    }, [addToast, functionToCloseModal, handleRefresh, access_token, dateToExpires]); */

    return(
        <Modal 
            open={open} 
            onClose={functionToCloseModal} 
            classNames={{
                modal: 'modalDeactivateProperty'
            }}
            closeIcon={(<FiX size={18} color="#000"/>)}
        >
            <Content onSubmit={handleSubmit(functionToCreateModal)}>
                <TitleContainer>
                    <h4>ADICIONAR CATEGORIA</h4>
                </TitleContainer>

                <InputContainer>
                    <InputContent>
                        <p>Nome da nova categoria: </p>

                        <input
                            name="name"
                            ref={register}
                        />
                    </InputContent>
                </InputContainer>

                <CreateCategoryButton type={isLoading ? 'button' : 'submit'}>
                    {isLoading ? 'CARREGANDO...' : 'ADICIONAR'}
                </CreateCategoryButton>
            </Content>
        </Modal>
    );
}