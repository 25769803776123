import styled from "styled-components";

export const Content = styled.div`
  max-width: 740px;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;

  @media(max-width: 767px){
  }
`;

export const DataContainer = styled.div`
  width: 320px;

  padding-bottom: 4px;

  align-items: flex-start;

  flex-direction: column;
  gap: 0.5rem;
  
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
