import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useAuth } from "./../../hooks/auth";

import { Link, useHistory } from "react-router-dom";

import OutsideClickHandler from "react-outside-click-handler";

import {
  Container,
  Content,
  RoutesContainer,
  MenuButton,
  OptionButton,
  OptionsToRouteContainer,
} from "./styles";

import logoImg from "./../../assets/components/Header/logoTopo.svg";
import { FiChevronDown, FiMenu } from "react-icons/fi";
import ModalPropertiesPerConsultant from "../ModalPropertiesPerConsultant";
import ModalPropertiesPerNeighborhood from "../ModalPropertiesPerNeighborhood";
import MenuToNavigate from "../MenuToNavigate";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";
import { useToast } from "../../hooks/toast";
import OffersDropdown from "../OffersDropdown";
import ModalVisitsPerConsultants from "../ModalVisitsPerConsultants";
import ModalActivePropertiesPerPeriod from "../ModalActivePropertiesPerPeriod";
/* import ModalRentReport from '../ModalRentReport'; */

interface IHeader {
  isLoginScreen?: boolean;
}

const Header: React.FC<IHeader> = ({ isLoginScreen }: IHeader) => {
  const history = useHistory();

  const { addToast } = useToast();

  const { user, access_token, dateToExpires, signOut } = useAuth();

  const [openMenu, setOpenMenu] = useState(false);

  const [openOptionsToRouteConsultants, setOpenOptionsToRouteConsultants] =
    useState(false);
  const [openOptionsToRouteProperties, setOpenOptionsToRouteProperties] =
    useState(false);
  const [openOptionsToReports, setOpenOptionsToReports] = useState(false);
  const [openOptionsToFAQ, setOpenOptionsToFAQ] = useState(false);
  const [openOptionsToContracts, setOpenOptionsToContracts] = useState(false);
  const [openOptionsToGuarantees, setOpenOptionsToGuarantees] = useState(false);
  const [openOptionsToCommercialRegion, setOpenOptionsToCommercialRegion] =
    useState(false);
  const [openOptionsToMoreOptions, setOpenOptionsToMoreOptions] =
    useState(false);

  const [
    openModalPropertiesPerConsultant,
    setOpenModalPropertiesPerConsultant,
  ] = useState(false);
  const [
    openModalPropertiesPerNeighborhood,
    setOpenModalPropertiesPerNeighborhood,
  ] = useState(false);
  const [openModalVisitsPerConsultant, setOpenModalVisitsPerConsultant] =
    useState(false);
  /* const [openModalRentReport, setOpenModalRentReport] = useState(false); */
  const [
    openModalActivePropertiesPerPeriod,
    setOpenModalActivePropertiesPerPeriod,
  ] = useState(false);

  const [openOffersDropdown, setOpenOffersDropdown] = useState(false);

  const [offers, setOffers] = useState([]);

  useEffect(() => {
    async function loadApi() {
      try {
        if (user?.role === "admin") {
          const token = await refreshToken(dateToExpires, access_token);

          api.defaults.headers.authorization = `Bearer ${
            !!token ? token : access_token
          }`;

          if (window.location.pathname.includes("/see-offer/")) {
            setTimeout(async () => {
              const response = await api.get("/api/adm/offers/unread/get");

              setOffers(response.data.data);
            }, 2000);
          } else {
            const response = await api.get("/api/adm/offers/unread/get");

            setOffers(response.data.data);
          }
        }
      } catch (err) {
        console.log(err);

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
    }

    if (!!user || isLoginScreen) {
      loadApi();
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [user, history, dateToExpires, access_token]);

  const formatUserName = useMemo(() => {
    if (!!user) {
      const indexOfSpace = user.first_name.indexOf(" ");

      if (indexOfSpace < 0) {
        return user.first_name;
      }

      const formatName = user.first_name.substring(0, indexOfSpace);

      return formatName;
    }
  }, [user]);

  const handleSignOut = useCallback(async () => {
    await signOut();

    history.push("/");
  }, [signOut, history]);

  return (
    <Container>
      <Content>
        <Link
          to={
            !!isLoginScreen
              ? "/"
              : user?.role !== "photographer"
              ? "/schedules"
              : "/photographers-schedule"
          }
        >
          <img src={logoImg} alt="logo" />
        </Link>

        {!!openModalPropertiesPerConsultant && (
          <ModalPropertiesPerConsultant
            open={openModalPropertiesPerConsultant}
            functionToCloseModal={() =>
              setOpenModalPropertiesPerConsultant(false)
            }
          />
        )}

        {!!openModalPropertiesPerNeighborhood && (
          <ModalPropertiesPerNeighborhood
            open={openModalPropertiesPerNeighborhood}
            functionToCloseModal={() =>
              setOpenModalPropertiesPerNeighborhood(false)
            }
          />
        )}

        {!!openModalVisitsPerConsultant && (
          <ModalVisitsPerConsultants
            open={openModalVisitsPerConsultant}
            functionToCloseModal={() => setOpenModalVisitsPerConsultant(false)}
          />
        )}

        {/* {!!openModalRentReport && (
                    <ModalRentReport open={openModalRentReport} functionToCloseModal={() => setOpenModalRentReport(false)}/>
                )} */}

        {openModalActivePropertiesPerPeriod && (
          <ModalActivePropertiesPerPeriod
            open={openModalActivePropertiesPerPeriod}
            functionToCloseModal={() =>
              setOpenModalActivePropertiesPerPeriod(false)
            }
          />
        )}

        {!isLoginScreen && (
          <>
            <RoutesContainer>
              {user?.role === "admin" && (
                <>
                  <OptionButton
                    onClick={() =>
                      setOpenOptionsToRouteProperties(
                        !openOptionsToRouteProperties
                      )
                    }
                  >
                    <p>Imóveis</p>
                    <FiChevronDown color="#666666" size={16} />

                    {!!openOptionsToRouteProperties && (
                      <OutsideClickHandler
                        onOutsideClick={() =>
                          setOpenOptionsToRouteProperties(false)
                        }
                      >
                        <OptionsToRouteContainer>
                          <Link to="/properties/1">Visualizar</Link>
                          <Link to="/create-property">Cadastrar</Link>
                        </OptionsToRouteContainer>
                      </OutsideClickHandler>
                    )}
                  </OptionButton>

                  <OptionButton onClick={() => setOpenOptionsToReports(true)}>
                    <p>Relatórios</p>
                    <FiChevronDown color="#666666" size={16} />

                    {!!openOptionsToReports && (
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenOptionsToReports(false)}
                      >
                        <OptionsToRouteContainer>
                          <button
                            onClick={() =>
                              setOpenModalPropertiesPerConsultant(true)
                            }
                          >
                            Imóveis por consultor
                          </button>
                          <button
                            onClick={() =>
                              setOpenModalPropertiesPerNeighborhood(true)
                            }
                          >
                            Imóveis por bairro
                          </button>
                          <button
                            onClick={() =>
                              setOpenModalVisitsPerConsultant(true)
                            }
                          >
                            Visitas por consultor
                          </button>
                          {/* <button onClick={() => setOpenModalRentReport(true)}>Fechamentos</button> */}
                          <button
                            onClick={() =>
                              setOpenModalActivePropertiesPerPeriod(true)
                            }
                          >
                            Imóveis Ativos por Período
                          </button>
                        </OptionsToRouteContainer>
                      </OutsideClickHandler>
                    )}
                  </OptionButton>

                  {/* <OptionButton onClick={() => setOpenOptionsToFAQ(true)}>
                    <p>FAQs</p>
                    <FiChevronDown color="#666666" size={16} />

                    {!!openOptionsToFAQ && (
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenOptionsToFAQ(false)}
                      >
                        <OptionsToRouteContainer>
                          <Link to="/list-faqs/1">Visualizar</Link>
                          <Link to="/create-faq">Cadastrar</Link>
                        </OptionsToRouteContainer>
                      </OutsideClickHandler>
                    )}
                  </OptionButton> */}

                  <OptionButton
                    onClick={() => setOpenOptionsToGuarantees(true)}
                  >
                    <p>Garantias</p>
                    <FiChevronDown color="#666666" size={16} />

                    {!!openOptionsToGuarantees && (
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenOptionsToGuarantees(false)}
                      >
                        <OptionsToRouteContainer>
                          <Link to="/show-guarantees">Visualizar</Link>
                          <Link to="/create-guarantee">Cadastrar</Link>
                          {/* <button onClick={() => {
                            history.push("/create-guarantee", {isEdit: false});
                          }}>Cadastrar</button> */}
                        </OptionsToRouteContainer>
                      </OutsideClickHandler>
                    )}
                  </OptionButton>

                  <OptionButton
                    onClick={() => setOpenOptionsToCommercialRegion(true)}
                  >
                    <p>Regiões Comerciais</p>
                    <FiChevronDown color="#666666" size={16} />

                    {!!openOptionsToCommercialRegion && (
                      <OutsideClickHandler
                        onOutsideClick={() =>
                          setOpenOptionsToCommercialRegion(false)
                        }
                      >
                        <OptionsToRouteContainer>
                          <Link to="/show-commercial-regions">Visualizar</Link>
                          <Link to="/create-commercial-region">Cadastrar</Link>
                        </OptionsToRouteContainer>
                      </OutsideClickHandler>
                    )}
                  </OptionButton>

                  {/* <OptionButton onClick={() => setOpenOptionsToContracts(true)}>
                    <p>Contratos</p>
                    <FiChevronDown color="#666666" size={16} />

                    {!!openOptionsToContracts && (
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenOptionsToContracts(false)}
                      >
                        <OptionsToRouteContainer>
                          <Link
                            to="/clients-without-contract"
                            className="long-link"
                          >
                            Clientes sem contrato
                          </Link>
                        </OptionsToRouteContainer>
                      </OutsideClickHandler>
                    )}
                  </OptionButton> */}
                </>
              )}

              {user?.role !== "photographer" && (
                <OptionButton>
                  <Link to="/offers/1">Propostas</Link>
                  {offers.length >= 1 && (
                    <span onClick={() => setOpenOffersDropdown(true)}>
                      {offers.length}
                    </span>
                  )}

                  {!!openOffersDropdown && (
                    <OffersDropdown
                      data={offers}
                      functionToCloseDropdown={() =>
                        setOpenOffersDropdown(false)
                      }
                    />
                  )}
                </OptionButton>
              )}

              {/* {user?.role === "admin" || user?.role === "photographer" ? (
                <OptionButton>
                  <Link to="/photographers-schedule">Agenda fotográfos</Link>
                </OptionButton>
              ) : (
                <></>
              )} */}

              {user?.role === "admin" && (
                <OptionButton
                  onClick={() => setOpenOptionsToRouteConsultants(true)}
                >
                  <p>Usuários</p>
                  <FiChevronDown color="#666666" size={16} />

                  {!!openOptionsToRouteConsultants && (
                    <OutsideClickHandler
                      onOutsideClick={() =>
                        setOpenOptionsToRouteConsultants(false)
                      }
                    >
                      <OptionsToRouteContainer>
                        <Link to="/users">Visualizar</Link>
                        <Link to="/create-user">Cadastrar</Link>
                        <Link to="/consultants/update">Consultores em Massa</Link>
                      </OptionsToRouteContainer>
                    </OutsideClickHandler>
                  )}
                </OptionButton>
              )}

              <OptionButton onClick={() => setOpenOptionsToMoreOptions(true)}>
                <p>Mais</p>
                <FiChevronDown color="#666666" size={16} />

                {!!openOptionsToMoreOptions && (
                  <OutsideClickHandler
                    onOutsideClick={() => setOpenOptionsToMoreOptions(false)}
                  >
                    <OptionsToRouteContainer>
                      <Link to="/photographers-schedule" className="long-link">
                        Agenda Fotógrafos
                      </Link>
                      {user?.role === "admin" && (
                        <>
                          <Link
                            to="/clients-without-contract"
                            className="long-link"
                          >
                            Clientes Sem Contrato
                          </Link>
                          <Link to="/list-faqs/1">FAQS</Link>
                        </>
                      )}
                    </OptionsToRouteContainer>
                  </OutsideClickHandler>
                )}
              </OptionButton>

              {!!user && (
                <Link to="/my-account" className="username">
                  {formatUserName}
                </Link>
              )}

              <button onClick={handleSignOut}>
                <p style={{ color: "#dd6435" }}>Sair</p>
              </button>
            </RoutesContainer>

            <MenuButton onClick={() => setOpenMenu(!!openMenu ? false : true)}>
              <FiMenu size={26} color="#dd6435" />
            </MenuButton>

            {!!openMenu && (
              <MenuToNavigate
                open={openMenu}
                functionToCloseModal={() => setOpenMenu(false)}
              />
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default Header;
