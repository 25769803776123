import React, { useEffect, useMemo, useState, useCallback } from "react";

import api from "./../../services/api";

import refreshToken from "./../../utils/refreshToken";

import formatValue from "./../../utils/formatValue";

import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { useAuth } from "./../../hooks/auth";

import Layout from "./../../components/Layout/";
import Loading from "./../../components/Loading/";

import {
  Container,
  ErrorMessageContainer,
  Content,
  PropertiesContainer,
  InitialContainer,
  PropertiesTable,
  PagesContainer,
  PageOptionPrevious,
  TableButtonsContainer,
  PageOption,
  PageOptionLatter,
} from "./styles";

import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { BiEdit, BiTrash, BiCopy } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
import ModalConfirmRemoveProperty from "../../components/ModalConfirmRemoveProperty";
import ModalConfirmCloneProperty from "../../components/ModalConfirmCloneProperty";
import { useToast } from "../../hooks/toast";
import { useRefresh } from "../../hooks/refresh";

import SearchBarPropertiesPage from "./../../components/SearchBarPropertiesPage/";

interface IProperties {
  id: number;
  title: string;
  is_rented: number;
  rent_value: string;
  address: string;
  neighborhood: string;
  number: string;
}

interface IParams {
  page: string;
}

function buildUrl(neighborhood: string, street: string, ownerEmail: string, floor: string, page: number) {
  let initial = `/properties/${page}/?`
  initial += `neighborhood=${neighborhood}`
  initial += `&street=${street}`
  initial += `&ownerEmail=${ownerEmail}`
  initial += `&floor=${floor}`

  return initial;
}

const Properties: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<IParams>();

  const { dateToExpires, access_token, user } = useAuth();
  const { addToast } = useToast();
  const { refresh, handleRefresh } = useRefresh();

  const [neighborhood, setNeighborhood] = useState("");
  const [street, setStreet] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [floor, setFloor] = useState("");

  const [properties, setProperties] = useState<IProperties[]>([]);
  const [lastPage, setLastPage] = useState<number>(0);

  const [loadingApiProperties, setLoadingApiProperties] = useState(true);

  const [propertyIdToRemove, setPropertyIdToRemove] = useState(0);
  const [propertyIdToClone, setPropertyIdToClone] = useState(0);
  const [openModalConfirmRemoveProperty, setOpenModalConfirmRemoveProperty] =
    useState(false);
  const [openModalConfirmCloneProperty, setOpenModalConfirmCloneProperty] =
    useState(false);
  const [
    loadingApiRequestToRemoveProperty,
    setLoadingApiRequestToRemoveProperty,
  ] = useState(false);
  const [
    loadingApiRequestToCloneProperty,
    setLoadingApiRequestToCloneProperty,
  ] = useState(false);

  const [loadApiError, setLoadApiError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    const paramsFinded = new URLSearchParams(window.location.search);

    const neighborhood = paramsFinded.get("neighborhood");
    const street = paramsFinded.get("street");
    const ownerEmail = paramsFinded.get("ownerEmail");
    const floor = paramsFinded.get("floor");

      setNeighborhood(neighborhood ? neighborhood : "");
      setStreet(street ? street : "");
      setOwnerEmail(ownerEmail ? ownerEmail : "");
      setFloor(floor ? floor : "");

    async function loadApi() {
      setLoadingApiProperties(true);

      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get("/api/adm/properties", {
            params: {
              page: Number(params.page),
              neighborhood,
              street,
              owner: ownerEmail,
              floor
            },
        });

        setProperties(response.data.data.data);
        setLastPage(response.data.data.last_page);
        setLoadingApiProperties(false);
      } catch (err: any) {
        if (err.response.status === 403) {
          setLoadApiError(err.response.data.message);
          setLoadingApiProperties(false);
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
        setLoadApiError(err.response.data.message);
        setLoadingApiProperties(false);
      }
      setLoadingApiProperties(false);
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }

    // eslint-disable-next-line
  }, [
    history,
    access_token,
    dateToExpires,
    user,
    params,
    refresh,
    neighborhood,
    window.location.search,
  ]);

  const pagesNumber = useMemo(() => {
    const pagesNumberArray = [];

    for (let i = 1; i <= lastPage; i++) {
      pagesNumberArray.push(i);
    }

    if (pagesNumberArray.length > 5 && Number(params.page) < 5) {
      pagesNumberArray.length = 5;

      const pagesNumberArrayFormated = [...pagesNumberArray];

      return pagesNumberArrayFormated;
    }

    if (
      pagesNumberArray.length > 5 &&
      Number(params.page) >= 5 &&
      Number(params.page) < pagesNumberArray.length - 3
    ) {
      return [
        pagesNumberArray[Number(params.page) - 4],
        pagesNumberArray[Number(params.page) - 3],
        pagesNumberArray[Number(params.page) - 2],
        pagesNumberArray[Number(params.page) - 1],
        pagesNumberArray[Number(params.page)],
        pagesNumberArray[Number(params.page) + 1],
        pagesNumberArray[Number(params.page) + 2],
      ];
    }

    if (
      pagesNumberArray.length > 5 &&
      Number(params.page) >= pagesNumberArray.length - 4
    ) {
      return [
        pagesNumberArray[pagesNumberArray.length - 5],
        pagesNumberArray[pagesNumberArray.length - 4],
        pagesNumberArray[pagesNumberArray.length - 3],
        pagesNumberArray[pagesNumberArray.length - 2],
        pagesNumberArray[pagesNumberArray.length - 1],
      ];
    }

    return pagesNumberArray;
  }, [lastPage, params.page]);

  const handleRemoveProperty = useCallback(async () => {
    setLoadingApiRequestToRemoveProperty(true);

    try {
      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.delete(
        `/api/adm/properties/${propertyIdToRemove}`
      );

      if (!!response.data.success) {
        addToast({
          type: "success",
          title: "Imóvel removido",
        });

        handleRefresh();
        setOpenModalConfirmRemoveProperty(false);
      }
    } catch (err: any) {
      console.log(err);
    }

    setLoadingApiRequestToRemoveProperty(false);
  }, [
    dateToExpires,
    access_token,
    propertyIdToRemove,
    addToast,
    handleRefresh,
  ]);

  const handleCloneProperty = async () => {
    setLoadingApiRequestToCloneProperty(true);

    try {
      const token = await refreshToken(dateToExpires, access_token);
      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post(
        `/api/adm/properties/clone/${propertyIdToClone}`
      );

      if (!!response.data.success) {
        addToast({
          type: "success",
          title: `Imóvel ${propertyIdToClone} clonado. Novo código: ${response.data.data.id}`,
        });
        history.push(`/property/${response.data.data.id}`);
      }
    } catch (err: any) {
      console.log(err);
      addToast({
        type: "error",
        title: `Erro ao clonar o imóvel. Por favor, tente novamente`,
      });
      setLoadingApiRequestToCloneProperty(false);
    }
  };

  return (
    <Container>
      <Layout>
        {!!loadingApiProperties && <Loading />}

        <ModalConfirmRemoveProperty
          open={openModalConfirmRemoveProperty}
          isLoading={loadingApiRequestToRemoveProperty}
          functionToRemoveProperty={handleRemoveProperty}
          functionToCloseModal={() => setOpenModalConfirmRemoveProperty(false)}
        />
        <ModalConfirmCloneProperty
          open={openModalConfirmCloneProperty}
          propertyId={propertyIdToClone}
          isLoading={loadingApiRequestToCloneProperty}
          functionToCloneProperty={handleCloneProperty}
          functionToCloseModal={() => setOpenModalConfirmCloneProperty(false)}
        />

        {!loadingApiProperties && (
          <>
            {!!loadApiError && (
              <ErrorMessageContainer>
                <h2>{loadApiError}</h2>
              </ErrorMessageContainer>
            )}

            {!loadApiError && (
              <Content>
                <PropertiesContainer>
                  <InitialContainer>
                    <h3>Imóveis</h3>
                    <Link to="/create-property">ADICIONAR IMÓVEL</Link>
                  </InitialContainer>
                  <SearchBarPropertiesPage neighborhood={neighborhood} street={street} ownerEmail={ownerEmail} floor={floor} />

                  <PropertiesTable>
                    <thead>
                      <tr>
                        <th className="th-code">Código</th>
                        <th>Título</th>
                        <th>Endereço</th>
                        <th>Situação</th>
                        <th>Valor</th>
                        <th>Ações</th>
                      </tr>
                    </thead>

                    <tbody>
                      {properties.map((property) => (
                        <tr key={property.id}>
                          <td className="th-code">{property.id}</td>
                          <td>{property.title}</td>
                          <td>{`${property.address}, ${property.neighborhood}, ${property.number}`}</td>
                          <td>
                            {!!property.is_rented ? "Alugado" : "Publicado"}
                          </td>
                          <td>
                            {formatValue(parseFloat(property.rent_value))}
                          </td>
                          <td>
                            <TableButtonsContainer>
                              <a
                                title="Editar"
                                href={`/property/${property.id}`}
                              >
                                <BiEdit color="#04c5ff" size={24} />
                              </a>
                              <BiCopy
                                title="Clonar"
                                color="rgb(0, 56, 130)"
                                size={24}
                                onClick={() => {
                                  setOpenModalConfirmCloneProperty(true);
                                  setPropertyIdToClone(property.id);
                                }}
                              />
                              <BiTrash
                                title="Excluir"
                                color="#ff5252"
                                size={24}
                                onClick={() => {
                                  setOpenModalConfirmRemoveProperty(true);
                                  setPropertyIdToRemove(property.id);
                                }}
                              />
                              <MdAttachMoney
                                title="Propostas"
                                color="#00ad14"
                                size={24}
                                onClick={() => {
                                  history.push(`/offers/1`);
                                }}
                              />
                            </TableButtonsContainer>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </PropertiesTable>

                  <PagesContainer>
                    <PageOptionPrevious>
                      {Number(params.page) !== 1 ? (
                        <Link
                          to={
                            buildUrl(neighborhood,street, ownerEmail, floor, 1)
                          }
                        >
                          <FiChevronsLeft color="#666666" size={24} />
                        </Link>
                      ) : (
                        <FiChevronsLeft color="#666666" size={24} />
                      )}
                    </PageOptionPrevious>

                    <PageOptionPrevious>
                      {Number(params.page) !== 1 ? (
                        <Link
                          to={
                            buildUrl(neighborhood,street, ownerEmail, floor, Number(params.page) - 1)
                          }
                        >
                          <FiChevronLeft color="#666666" size={24} />
                        </Link>
                      ) : (
                        <FiChevronLeft color="#666666" size={24} />
                      )}
                    </PageOptionPrevious>

                    {pagesNumber.map((page) => (
                      <PageOption
                        key={page}
                        isSelected={Number(params.page) === page}
                      >
                        {Number.isInteger(page) ? (
                          <Link
                            to={
                              buildUrl(neighborhood,street, ownerEmail, floor, page)
                            }
                          >
                            {page}
                          </Link>
                        ) : (
                          <p>{page}</p>
                        )}
                      </PageOption>
                    ))}

                    <PageOptionLatter>
                      {Number(params.page) !==
                      pagesNumber[pagesNumber.length - 1] ? (
                        <Link
                          to={
                            buildUrl(neighborhood,street, ownerEmail, floor, Number(params.page) + 1)
                          }
                        >
                          <FiChevronRight color="#666666" size={24} />
                        </Link>
                      ) : (
                        <FiChevronRight color="#666666" size={24} />
                      )}
                    </PageOptionLatter>

                    <PageOptionLatter>
                      {Number(params.page) !==
                      pagesNumber[pagesNumber.length - 1] ? (
                        <Link
                          to={
                            buildUrl(neighborhood,street, ownerEmail, floor, Number(lastPage))
                          }
                        >
                          <FiChevronsRight color="#666666" size={24} />
                        </Link>
                      ) : (
                        <FiChevronsRight color="#666666" size={24} />
                      )}
                    </PageOptionLatter>
                  </PagesContainer>
                </PropertiesContainer>
              </Content>
            )}
          </>
        )}
      </Layout>
    </Container>
  );
};

export default Properties;
