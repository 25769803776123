import styled from 'styled-components';

export const Content = styled.div`
	max-width: 375px;
	margin: 52px auto 12%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	>h2{
		font-size: 20px;
		color: #DD6435;
	}

	.error {
		font-size: 14px;
		margin-top: 20px;
		color: red;
	}
	.success {
		font-size: 14px;
		margin-top: 20px;
		color: green;
	}

	a{
		font-size: 14px;
		font-weight: 500;
		color: #DD6435;
	}
	@media(max-width: 767px){
		>h2{
			font-size: 17px;
		}
		>a{
			font-size: 13px;
		}
	}
	@media(min-width: 1441px){
		max-width: 425px;
		margin: 64px auto 12%;
		>h2{
			font-size: 22px;
		}
		a{
			font-size: 16px;
		}
	}
`;

export const Form = styled.form`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	margin: 42px 0;
	>p{
		font-size: 14px;
		>span{
			font-size: 12px;
			color: #FF3838;
		}
	}
	>div{
		width: 100%;
		font-size: 14px;
		margin-top: 10px;
		margin-bottom: 20px;
	}
	>button{
		width: 100%;
		padding: 10px 24px;
		background: #6EA5FF;
		border: 0;
		font-size: 13px;
		font-weight: 500;
		color: #FFFFFF;
	}
`;