import React from "react";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {
  Content,
  TitleContainer,
  DataContainer,
  ButtonsContainer,
} from "./styles";

import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";

interface IClient {
  drive_link: string;
  property_id: number;
  rent_process_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: string;
}

interface IModalCreateContract {
  open: boolean;
  functionToClose(): void;
  functionToChangeModal(): void;
  dataToShow: IClient;
}

const ModalCreateContract: React.FC<IModalCreateContract> = ({
  open,
  functionToClose,
  functionToChangeModal,
  dataToShow,
}: IModalCreateContract) => {
  const history = useHistory();

  function handleOpenContractPage(mode: string) {
    history.push(`/contract-${mode}`, {
      client: dataToShow,
    });
  }

  return (
    <Modal
      open={open}
      onClose={functionToClose}
      classNames={{
        modal: "modalConsult",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h3>Documentação do cliente</h3>
        </TitleContainer>

        <DataContainer>
          <h3>CLIENTE</h3>

          <p>
            <b>Nome: </b> {dataToShow.tenant_name}
          </p>
          <p>
            <b>Email: </b> {dataToShow.tenant_email}
          </p>
          <p>
            <b>Telefone: </b> {dataToShow.tenant_phone}
          </p>
          <h3>DOCUMENTOS RECEBIDOS</h3>
          {dataToShow.drive_link ? (
            <a href={dataToShow.drive_link} target="_blank" rel="noreferrer">
              {dataToShow.drive_link}
            </a>
          ) : (
            "Não há link para o drive."
          )}
        </DataContainer>

        <ButtonsContainer>
          <button
            className="btn-contract model"
            onClick={functionToChangeModal}
          >
            CRIAR CONTRATO VIA MODELO
          </button>
          <button
            className="btn-contract upload"
            onClick={() => handleOpenContractPage("upload")}
          >
            UPLOAD DE CONTRATO
          </button>
        </ButtonsContainer>
      </Content>
    </Modal>
  );
};

export default ModalCreateContract;
