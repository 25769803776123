import React, { ReactNode } from 'react';
import Footer from '../Footer';

import Header from './../Header/';

interface ILayout{
    isLoginScreen?: boolean;
    children: ReactNode;
}

const Layout: React.FC<ILayout> = ({ isLoginScreen, children }: ILayout) => {
    return (
        <>
            <Header isLoginScreen={isLoginScreen}/>
                {children}

            {!isLoginScreen && (<Footer />)}      
        </>
   );
}

export default Layout;