import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useAuth } from './../../hooks/auth';

import {Content} from './styles';
import FormToForgotPasswordPage from '../../components/FormToForgotPasswordPage';
import Layout from '../../components/Layout';

export default function ForgotPassword(){
	const { user } = useAuth();
	const history = useHistory();

	useEffect(() => {
		if(!!user){
			history.goBack()
		}
	}, [user, history]);

	return(
		<Layout isLoginScreen>
			{!user && (
                <Content>
                    <h2>RECUPERAR SENHA</h2>

                    <FormToForgotPasswordPage />
                </Content>
            )}
		</Layout>
	);
};