import styled, { css } from "styled-components"

interface IPageOption{
    isSelected: boolean;
}

interface IOffersTableTD{
    status?: number;
}

export const Content = styled.div`
    max-width: 1200px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 64px auto 22%;

    >h2{
        font-size: 35px;
        font-weight: bold;

        color: #666666;

        margin-bottom: 24px;
    }

    @media(max-width: 767px){
        align-items: flex-start;
        justify-content: flex-start;

        >h2{
            text-align: center;
        }
    }
`;

export const OffersTable = styled.table`
    border-spacing: 0 12px;

    >thead{
        background: #6EA5FF;

        color: #FFFFFF;

        >tr{
            >th{
                text-align: start;

                padding: 16px 32px;

                font-size: 14px;
            }
        }
    }

    #td-id{
        width: 120px;
    }

    @media(max-width: 767px){
        overflow-x: auto;

        display: block;

        width: 100%;

        margin: 0;
    }
`;

export const OffersTableTD = styled.td`
    padding: 16px 32px;

    background: #F9F9F9;

    color: #666666;

    font-weight: 500;

    svg{
        margin-left: auto;
        width: 100%;

        cursor: pointer;
    }

    @media(max-width: 767px){
        font-size: 12px;
    }
`;

export const LastTableTD = styled.td<IOffersTableTD>`
    padding: 16px 32px;

    width: 350px;

    font-size: 16px;

    background: #F9F9F9;

    >a{
        ${props => props.status === 0 || props.status === 2 ? css`
            color: #0218A2;
        ` : css `
            color: #28a745;
        `};

        ${props => props.status === 4 && css`
            color: #dc3545;
        `};

        padding: 2px 8px;

        border-radius: 8px;

        text-decoration: underline;

        font-weight: bold;
    }

    @media(max-width: 767px){
        padding: 16px 0;

        >a{
            font-size: 10px;
        }
    }
`;

export const PagesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 24px;

    @media(max-width: 767px){
        width: 100%;
    }
`;

export const PageOptionPrevious = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    height: 36px;

    a{
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const PageOption = styled.div<IPageOption>`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    width: 36px;
    height: 36px;

    background: ${props => !!props.isSelected ? "#F9F9F9" : "transparent"};

    font-size: 20px;
    font-weight: bold;

    transition: all 0.2s;

    margin: 0 12px;

    >a{
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        text-decoration: none;

        color: ${props => !!props.isSelected ? "#6EA5FF" : "#B6B6B6"};
    }
`;

export const PageOptionLatter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    height: 36px;

    a{
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`;