import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import formatValue from "../../utils/formatValue";
import {Content,
        DataContainer,
        DataLabels,
        DataContent,
        ReportTable,} from "./styles";

interface IState{
    data: Array<{
        address: string;
        city: string;
        first_name: string;
        final_rent_value: null | string;
        number: string;
        property_id: number;
        uf: string;
    }>;
    total: string;
    total_of_properties: number;
    average_rent: string;
}

export default function ShowRentsReport(){
    const history = useHistory();

    const { state } = useLocation<IState>();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!state){
            history.goBack();
        }

        setLoading(false);
    }, [state, history]);

    return(
        <Layout>
            {!!loading ? (<Loading />) : (
                <Content>
                    <h2>Fechamentos</h2>

                    <DataContainer>
                        <DataLabels>
                            <p>VALOR TOTAL DE LOCAÇÃO</p>
                            <p>VALOR MÉDIO DE LOCAÇÃO</p>
                            <p>TOTAL DE IMÓVEIS ATIVOS</p>
                        </DataLabels>
                            

                        <DataContent>
                            <p>{formatValue(Number(state.total))}</p>
                            <p>{formatValue(Number(state.average_rent))}</p>
                            <p>{state.total_of_properties}</p>
                        </DataContent>
                    </DataContainer>

                    <ReportTable>
                        <thead>
                            <tr>
                                <th>Consultor</th>
                                <th>Valor final de locação</th>
                                <th>Rua</th>
                                <th>Número</th>
                                <th>Cidade</th>
                                <th>UF</th>

                            </tr>
                        </thead>

                        <tbody>
                            {state.data.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.first_name}</td>
                                    <td>{formatValue(!!data.final_rent_value ? Number(data.final_rent_value) : 0)}</td>
                                    <td>{data.address}</td>
                                    <td>{data.number}</td>
                                    <td>{data.city}</td>
                                    <td>{data.uf}</td>
                                </tr>
                            ))}
                        </tbody>
                    </ReportTable>
                </Content>
            )}
        </Layout>
    );
}