import styled, { css } from "styled-components";

interface IFormOption {
  isSelected: boolean;
  isUnlocked: boolean;
  title: string;
}

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1200px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  margin: 0 auto;
`;

export const EditPropertyContainer = styled.div`
  width: 100%;

  padding: 24px;

  margin-top: 24px;

  @media (max-width: 767px) {
    width: 100%;

    padding: 24px 12px;
  }
`;

export const InitialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    width: 100%;

    > h3 {
      font-size: 36px;
      font-weight: bold;

      margin: 0 auto;

      color: #666666;
    }
  }

  > button {
    margin-left: auto;

    background-color: transparent;

    border: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const TitleAndErrorTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > p {
    margin-left: 8px;

    font-size: 16px;
    color: rgb(255, 56, 56);
  }
`;

export const FormsOptionContainer = styled.div`
  width: 520px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin: 24px auto 0;

  @media (max-width: 767px) {
    width: 320px;
  }
`;

const FormOption = styled.button<IFormOption>`
  border: 2px solid #b6b6b6;
  border-radius: 50%;

  width: 42px;
  height: 42px;

  transition: all 0.2s;

  color: #b6b6b6;

  position: relative;

  ${(props) =>
    props.isUnlocked
      ? css`
          border-color: #6ea6ff;

          color: #6ea6ff;
        `
      : css``}

  ${(props) =>
    props.isSelected
      ? css`
          background: #6ea6ff;

          color: #fff;
        `
      : css``}
&::after {
    content: "${(props) => props.title}";
    position: absolute;
    bottom: -24px;
    font-size: 12px;
    color: ${(props) =>
      props.isSelected || props.isUnlocked ? "#6EA6FF" : "#B6B6B6"};
  }

  ${(props) => {
    const { isUnlocked, isSelected } = props;

    return props.title !== "ENDEREÇO"
      ? css`
          &::before {
            content: "";
            position: absolute;
            width: 300%;
            height: 2px;
            background: ${isUnlocked || isSelected ? "#6EA6FF" : "#B6B6B6"};
            left: -120px;
            top: 16px;
            cursor: default;
          }
        `
      : css``;
  }}

  @media(max-width: 767px) {
    font-size: 14px;

    padding: 8px;
  }
`;

export const FormOptionAddress = styled(FormOption)<IFormOption>`
  &::after {
    left: -16px;
  }
  @media (max-width: 767px) {
    &::after {
      font-size: 10px;
      left: -10px;
    }
  }
`;

export const FormOptionDescription = styled(FormOption)<IFormOption>`
  &::after {
    left: -16px;
  }

  @media (max-width: 767px) {
    &::before {
      width: 145%;
      left: -55px;
    }

    &::after {
      font-size: 10px;
      left: -10px;
    }
  }
`;

export const FormOptionCharacteristics = styled(FormOption)<IFormOption>`
  &::after {
    left: -34px;
  }
  @media (max-width: 767px) {
    &::before {
      width: 145%;
      left: -55px;
    }

    &::after {
      font-size: 10px;
      left: -26px;
    }
  }
`;

export const FormOptionPhotos = styled(FormOption)<IFormOption>`
  &::after {
    left: -2px;
  }

  @media (max-width: 767px) {
    &::before {
      width: 145%;
      left: -55px;
    }

    &::after {
      font-size: 10px;
      left: 5;
    }
  }
`;

export const DivToSeparate = styled.div`
  align-self: center;

  width: 95%;
  height: 1px;

  background-color: #fff;

  margin: 24px 0;
`;

export const ResetButton = styled.button`
  width: 15%;

  padding: 11px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  border: 1px solid #666666;

  color: #666666;

  @media (max-width: 767px) {
    width: 45%;
  }
`;

export const NextButton = styled.button`
  width: 15%;

  padding: 12px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #6ea5ff;

  border: 0;

  margin-left: 8px;

  > p {
    color: #fff;
  }

  > svg {
    margin-left: 4px;
  }

  @media (max-width: 767px) {
    width: 45%;
  }
`;


export const ButtonsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 24px auto 0;
`;