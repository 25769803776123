import styled from "styled-components";

export const Container = styled.div`
    
    display: flex;
    flex-direction: column;
    width: 80%;

    div {
        padding: 1.75rem 0;
        border-bottom: 1px solid #A7A6AD;
    }

    div:last-child {
        border: none;
    }
    
    .main-title-lbl {
        font-weight: 600;
        font-size: 1rem;
        color: #606060;
        margin-top: 2rem;
    }
    
    .title-lbl {
        font-weight: 400;
        color: #8B8A8F;
        margin: 0 0 0.5rem;
    }
    .data-lbl {
        font-weight: 500;
        color: #606060;
    }
`;

export const GridContainer = styled.div`
    display: grid;
    width: 100%;
    column-gap: 3%;
    grid-template-columns: 25% 35% 20% 11%;

    >svg {
        cursor: pointer;
    }
`;