import styled from "styled-components";

export const Content = styled.div`
  max-width: 740px;
  display: flex;
  flex-direction: column;

  & img {
    flex: 1;
    max-height: 180px;
    margin: 8px 40px;
  }

  /* & .MuiAccordion-root {
    margin: 0 40px !important;
    box-shadow: none;

    &::before {
      content: none;
    }
  }

  & .MuiAccordionSummary-content {
    margin: 0;
  }
  & .MuiButtonBase-root {
    padding: 0;

    & svg {
      color: #dd6435;
      margin: 0 16px;
    }
  }
  & .MuiAccordionDetails-root {
    width: 320px;

    padding: 0;

    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
    > p {
      color: #666666;
      font-size: 14px;
      > a {
        color: #666666;
      }
      > a:hover {
        color: #b9b9b9;
      }
      & + p {
        margin-top: 4px;
      }
    }
  } */
`;

export const TitleContainer = styled.div`
  border-bottom: 1px solid #bcbfc2;
  display: flex;
  justify-content: center;

  padding: 24px 0px 24px 0px;

  width: 400px;
  > h3 {
    color: #666666;

    font-weight: 600;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    padding: 24px 0 24px 24px;

    width: 330px;
  }
`;

export const VideoCallContainer = styled.div`
  width: 90%;

  margin: 12px auto;

  padding: 16px;

  background-color: #f5c6cb;

  border-radius: 4px;
`;

export const FieldTitle = styled.h3`
  color: #dd6435;
  font-size: ${14/16}rem;
  margin: 8px 0;
  font-weight: 500;
`;

export const FieldContent = styled.div`
  width: 320px;
  margin: 0 40px;
  padding: 0;

  align-items: flex-start;
  justify-content: center;

  flex-direction: column;
  > p {
    color: #666666;
    font-size: ${12/16}rem;
    > a {
      color: #666666;
    }
    > a:hover {
      color: #b9b9b9;
    }
    & + p {
      margin-top: 4px;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin: 12px 16px;
  
  display: flex;

  justify-content: center;

  > button {
    width: 43%;
    font-size: ${14/16}rem;
    border: 0;

    padding: 12px;
    margin: 0 2%;

    color: #fff;

    font-weight: 500;
  }

  .btn-close {
    background-color: #bf0001;
  }

  .btn-edit {
    background-color: #6ea5ff;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;
