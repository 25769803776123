import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    background: #F9F9F9;
`;

export const Content = styled.div`
    max-width: 1280px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto;

    padding: 24px 0;

    >img{
        height: 52px;
    }

    >p{
        margin-left: auto;

        font-size: 14px;
    }

    @media(max-width: 767px){
        flex-direction: column;

        >p{
            margin-left: 0;
            margin-top: 24px;

            text-align: center;

            font-size: 10px;
        }
    }
`;
