import STEPS from "../../../pages/RentalBelt/enum";
import ImageComponent from "./ImageComponent";
import { BlueLineContainer, Container, GrayLineContainer } from "./styles";

interface IBeltImage {
  currentStep: number;
}

const BeltImage: React.FC<IBeltImage> = ({ currentStep }: IBeltImage) => {
  const STEP_LABELS = [
    "Modelo",
    "Proprietário",
    "Inquilino",
    "Contrato",
  ];

  const renderBlueLine = (step: number) => {
    switch (step) {
      case STEPS.CONTRACT_MODEL:
        return <BlueLineContainer id="first-step" />;
      case STEPS.OWNER_REGISTER:
        return <BlueLineContainer id="second-step" />;
      case STEPS.TENANT_REGISTER:
        return <BlueLineContainer id="third-step" />;
      case STEPS.CONTRACT_CREATION:
        return <BlueLineContainer id="fourth-step" />;
    }
  };

  return (
    <Container>
      {STEP_LABELS.map((label, index) => {
        return (
          <ImageComponent
            label={label}
            isCurrent={index + 1 === currentStep ? true : false}
            alreadyFinished={currentStep > index + 1 ? true : false}
          />
        );
      })}
      <GrayLineContainer />
      {renderBlueLine(currentStep)}
    </Container>
  );
};

export default BeltImage;
