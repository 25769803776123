import React from "react";

import { Switch, Route } from "react-router-dom";

import Login from "./../pages/Login/";
import Schedule from "./../pages/Schedules/";
import Properties from "./../pages/Properties/";
import EditProperty from "./../pages/EditProperty/";
import CreateProperty from "../pages/CreateProperty";
import Users from "../pages/Users";
import CreateUsers from "../pages/CreateUsers";
import EditUser from "../pages/EditUser";
import Offers from "../pages/Offers";
import SeeOffer from "../pages/SeeOffer";
import ShowPropertiesPerConsultantReport from "../pages/ShowPropertiesPerConsultantReport";
import ShowPropertiesPerNeighborhoodReport from "../pages/ShowPropertiesPerNeighborhoodReport";
import MyAccount from "../pages/MyAccount";
import ShowVisitsPerConsultantReport from "../pages/ShowVisitsPerConsultantReport";
import ShowRentsReport from "../pages/ShowRentsReport";
import PhotographerSchedule from "../pages/PhotographerSchedule";
import CreateFAQ from "../pages/CreateFAQ";
import ListFAQ from "../pages/ListFAQ";
import EditFAQ from "../pages/EditFAQ";
import PhotographerUploadImage from "../pages/PhotographerUploadImage";
import ShowActivesPropertiesPerPeriod from "../pages/ShowActivesPropertiesPerPeriod";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import RentalBelt from "../pages/RentalBelt";
import UploadRentalBelt from "../pages/UploadRentalBelt";
import ClientsWithoutContract from "../pages/ClientsWithoutContract";
import CreateGuarantee from "../pages/CreateGuarantee";
import ShowGuarantees from "../pages/ShowGuarantees";
import UpdateBulkConsultants from "../pages/UpdateBulkConsultants";
import CreateCommercialRegion from "../pages/CreateCommercialRegion";
import ShowCommercialRegions from "../pages/ShowCommercialRegions";
import ModelContract from "../pages/ContextModelContract";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/password/reset/:token" component={ResetPassword} />
      <Route path="/schedules" component={Schedule} />
      <Route path="/properties/:page" component={Properties} />
      <Route path="/property/:id" component={EditProperty} />
      <Route path="/create-property" component={CreateProperty} />
      <Route path="/consultants/update" component={UpdateBulkConsultants} />
      <Route
        path="/show-properties-per-consultant-report"
        component={ShowPropertiesPerConsultantReport}
      />
      <Route
        path="/show-properties-per-neighborhood-report"
        component={ShowPropertiesPerNeighborhoodReport}
      />
      <Route
        path="/show-visits-per-consultant-report"
        component={ShowVisitsPerConsultantReport}
      />
      <Route
        path="/show-actives-properties-per-period"
        component={ShowActivesPropertiesPerPeriod}
      />
      <Route path="/show-rents-report" component={ShowRentsReport} />
      <Route path="/users" component={Users} />
      <Route path="/create-user" component={CreateUsers} />
      <Route path="/edit-user/:id" component={EditUser} />
      <Route path="/offers/:page" component={Offers} />
      <Route path="/see-offer/:offer_id" component={SeeOffer} />
      <Route path="/my-account" component={MyAccount} />
      <Route path="/photographers-schedule" component={PhotographerSchedule} />
      <Route
        path="/photographer-upload-photos"
        component={PhotographerUploadImage}
      />
      <Route path="/create-faq" component={CreateFAQ} />
      <Route path="/list-faqs/:page" component={ListFAQ} />
      <Route path="/edit-faq/:id" component={EditFAQ} />
      <Route path="/contract" component={RentalBelt} />
      <Route path="/contract-upload" component={UploadRentalBelt} />
      <Route path="/contract-model/:type" component={ModelContract} />
      <Route
        path="/clients-without-contract"
        component={ClientsWithoutContract}
      />
      <Route path="/create-guarantee" component={CreateGuarantee} />
      <Route path="/show-guarantees" component={ShowGuarantees} />
      <Route
        path="/create-commercial-region"
        component={CreateCommercialRegion}
      />
      <Route
        path="/show-commercial-regions"
        component={ShowCommercialRegions}
      />
    </Switch>
  );
};

export default Routes;
