import { useLocation } from "react-router-dom";
import ModelContractStepperProvider from "../../store/ModelContractStepper/model-contract-stepper";
import ModelContract from "../ModelContract";

interface IClient {
  drive_link: string;
  property_id: number;
  rent_process_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: string;
}

interface IParam {
  client: IClient;
}
function ContextModelContract() {
  const location = useLocation<IParam>();
  const { client } = location.state;
  return (
    <ModelContractStepperProvider>
      <ModelContract {...client} />
    </ModelContractStepperProvider>
  );
}

export default ContextModelContract;
