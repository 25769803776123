import { Skeleton } from "@material-ui/lab";
import { Wrapper } from "./styles";

function ModelContractFormSkeleton() {
  return (
    <Wrapper>
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
      <Skeleton height="28px" />
    </Wrapper>
  );
}

export default ModelContractFormSkeleton;
