import styled from "styled-components";

interface IConfirmButton {
  isLoading: boolean;
}

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  background: #ffffff;

  > h6 {
    font-size: 20px;
    font-weight: bold;

    margin: 36px 0 12px;

    text-align: center;
  }

  > p {
    
    font-size: 10px;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    margin: 16px 0 14px;
  }

  > input {
    width: 100%;

    padding: 14px 12px;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    cursor: default;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfirmButton = styled.button<IConfirmButton>`
  background: ${(props) => (!!props.isLoading ? "transparent" : "#00BF33")};

  font-weight: 500;

  color: ${(props) => (!!props.isLoading ? "#00BF33" : "#FFFFFF")};

  border: ${(props) => (!!props.isLoading ? "1px solid #00BF33" : "0")};

  padding: 8px 0;

  width: 45%;
`;

export const CancelButton = styled.button`
  background: #bf0000;

  font-weight: 500;

  color: #ffffff;

  padding: 8px 0;

  width: 45%;

  margin-left: 16px;
`;
