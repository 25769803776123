import styled from "styled-components";

export const RadioLabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    >label{
        margin: 0 16px 0 6px;

        cursor: pointer;
    }

    @media(max-width: 767px){
        margin-top: 8px;
    }
`;