import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  DragEvent,
  ChangeEvent,
} from "react";

import { FiArrowLeft, FiArrowRight, FiPaperclip } from "react-icons/fi";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Select from "react-select";

import Layout from "../../components/Layout";
import Marker from "../../components/Marker";
import Loading from "../../components/Loading";
import ImageBox from "./../../components/ImageBox/";

import { useAuth } from "../../hooks/auth";

import api from "../../services/api";

import getValidationErrors, { Errors } from "../../utils/getValidationError";
import refreshToken from "../../utils/refreshToken";

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import GoogleMaps from "google-map-react";

import { useDropzone } from "react-dropzone";

import * as Yup from "yup";

import GeoCode from "react-geocode";

import { FaHandshake } from "react-icons/fa";

import {
  Container,
  Content,
  EditPropertyContainer,
  InitialContainer,
  TitleAndErrorTextContainer,
  FormsOptionContainer,
  FormOptionAddress,
  FormOptionDescription,
  FormOptionCharacteristics,
  FormOptionPhotos,
  DescriptionForm,
  PropertyTypeAndIsRentedContainer,
  PropertyTypesContainer,
  PropertyTypesContent,
  PropertyTypesContentColumn,
  IsRentedContainer,
  RentAndIsRentedRadioContent,
  PropertyOwnerAndPropertyConsultantContainer,
  PropertyOwnerContainer,
  FormContainer,
  PropertyConsultantContainer,
  RoomsSuitesBathroomsGarageContainer,
  RoomsSuitesBathroomsGarageContent,
  RentIptuCondominiumValueAreaContainer,
  RentIptuCondominiumValueAreaContent,
  AllowCatAndIsFunishedContainer,
  AllowCatAndIsFunishedContent,
  IsRentedRadioContent,
  RadioContentToDescriptionForm,
  NextButton,
  MapForm,
  LocalDataContainer,
  LocalDataContent,
  LocalContent,
  CharacteristicFormContainer,
  CharacteristicContainer,
  CharacteristicContent,
  BigCharacteristicContent,
  CharacteristicInitialContainer,
  CharacteristicRadiosContainer,
  RadioContainer,
  RadioContent,
  PhotosForm,
  DropZoneContainer,
  PhotoContainer,
  ButtonsContainer,
  RegisterButton,
  PropertyRankContainer,
  PublishOnImovelWebButton,
  GuaranteesContainer,
  PropertyTitleContainer,
} from "./styles";

import { Radio } from "@material-ui/core";

import CurrencyInput from "../../components/Currency";
import AreaInput from "../../components/AreaInput";

import loadFile from "../../utils/loadFile";
import MaskedInput from "react-text-mask";
import { useToast } from "../../hooks/toast";
import { useRefresh } from "../../hooks/refresh";
import ModalDeactivateProperty from "../../components/ModalDeactivateProperty";
import ModalConfirmActivateProperty from "../../components/ModalConfirmActivateProperty";
import OutsideClickHandler from "react-outside-click-handler";
import ModalEditDeactivateProperty from "../../components/ModalEditDeactivateProperty";
import axios, { CancelTokenSource } from "axios";
import { useConsultant } from "../../hooks/consultant";
import asyncForEach from "../../utils/asyncForEach";

interface IParams {
  id: string;
}

interface IPhotos {
  id: number;
  order: number;
  url_l: string;
  url_m: string;
  url_s: string;
}

interface IConsultants {
  id: number;
  first_name: string;
}

interface IImageError {
  name: string;
  reason: string;
}

interface IImagesWithError {
  name: string;
  reason: string;
}

interface IStateProps {
  isToShowPhotos: string;
  imagesWithError: IImagesWithError[];
}

interface IPhotosToReOrder {
  id: number;
  newOrder: number;
}

interface IRentReasons {
  id: number;
  reason: string;
}

interface IUsers {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}

interface IRent {
  consultant_id: number | null;
  final_rent_value: string | null;
  id: number;
  operation: number;
  property_id: number;
  reason_id: null | number;
  rent_date: string | null;
  rented_by: string;
  rented_by_new: string;
  tenant_id: number | null;
  tenant_email: string | undefined;
}

interface IGuarantee {
  id: number;
  name: string;
  documents: string[];
  description: string;
}

interface IOwnerDetails {
  name: string;
  phone: string;
}

const ZAP_PUBLICATION_OPTIONS = {
  STANDARD: "STANDARD",
  PREMIUM: "PREMIUM",
  SUPER_PREMIUM: "SUPER_PREMIUM",
};

const FLOOR_TYPE_OPTIONS = {
  LOW: "Baixo",
  MEDIUM: "Intermediário",
  HIGH: "Alto",
};

const EditProperty: React.FC = () => {
  const history = useHistory();

  const { state } = useLocation<IStateProps>();

  const { access_token, dateToExpires, user } = useAuth();
  const { addToast } = useToast();
  const { handleRefresh, refresh } = useRefresh();

  const { params } = useRouteMatch<IParams>();

  const [loadingApiData, setLoadingApiData] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingGoogleMapsApi, setLoadingGoogleMapsApi] = useState(true);
  const [loadingSharedLink, setLoadingSharedLink] = useState(false);

  const [loadingPhotosRequest, setLoadingPhotosRequest] = useState(false);

  const [formOptionSelected, setFormOptionSelected] = useState(
    !!state ? state.isToShowPhotos : "map"
  );

  const [googleMapsSearch, setGoogleMapsSearch] = useState("");

  const [photos, setPhotos] = useState<IPhotos[]>([]);

  const [rentData, setRentData] = useState({} as IRent);

  const [consultants, setConsultants] = useState<IConsultants[]>([]);

  const [addressID, setAddressID] = useState<number>();
  const [logradouro, setLogradouro] = useState("");
  const [cep, setCEP] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [floor, setFloor] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUF] = useState("");
  const [googleID, setGoogleID] = useState("");

  const [isRented, setIsRented] = useState<number>();
  const [propertyType, setPropertyType] = useState<number>();
  const [zapPublicationType, setZapPublicationType] = useState<string>(
    ZAP_PUBLICATION_OPTIONS.STANDARD
  );
  const [floorType, setFloorType] = useState<string>("");
  const [ownerID, setOwnerID] = useState<number>();
  const [rank, setRank] = useState<number>();
  const [ownerEmail, setOwnerEmail] = useState<string | undefined>("");
  const [consultantID, setConsultantID] = useState<number>();
  const [propertyConsultant, setPropertyConsultant] = useState<
    string | undefined
  >("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [rooms, setRooms] = useState<number>();
  const [suites, setSuites] = useState<number>();
  const [bathrooms, setBathrooms] = useState<number>();
  const [garage, setGarage] = useState<number>();
  const [rentValue, setRentValue] = useState("");
  const [condominiumValue, setCondominiumValue] = useState("");
  const [iptuValue, setIptuValue] = useState("");
  const [garbageTax, setGarbageTax] = useState("");
  const [area, setArea] = useState("");
  const [allowPets, setAllowPets] = useState<number>();
  const [isFunished, setIsFunished] = useState<number>();
  const [waterIncluded, setWaterIncluded] = useState<number>();
  const [gasIncluded, setGasIncluded] = useState<number>();
  const [isAdm, setIsAdm] = useState<number>();
  const [yearBuilt, setYearBuilt] = useState("");

  const [isNearbyMajorRoads, setIsNearbyMajorRoads] = useState<number>();
  const [isNearbyMall, setIsNearbyMall] = useState<number>();
  const [isNearbyPublicTransport, setIsNearbyPublicTransport] =
    useState<number>();
  const [isNearbySchools, setIsNearbySchools] = useState<number>();
  const [isNearbyClinics, setIsNearbyClinics] = useState<number>();

  const [has24hConcierge, setHas24hConcierge] = useState<number>();
  const [hasAC, setHasAC] = useState<number>();
  const [hasBackyard, setHasBackyard] = useState<number>();
  const [hasBBQ, setHasBBQ] = useState<number>();
  const [hasCableTV, setHasCableTV] = useState<number>();
  const [hasElevator, setHasElevator] = useState<number>();
  const [hasGameRoom, setHasGameRoom] = useState<number>();
  const [hasGarden, setHasGarden] = useState<number>();
  const [hasGourmet, setHasGourmet] = useState<number>();
  const [hasGourmetPorch, setHasGourmetPorch] = useState<number>();
  const [hasGym, setHasGym] = useState<number>();
  const [hasHomeCinema, setHasHomeCinema] = useState<number>();
  const [hasParty, setHasParty] = useState<number>();
  const [hasPlayground, setHasPlayground] = useState<number>();
  const [hasPool, setHasPool] = useState<number>();
  const [hasPorch, setHasPorch] = useState<number>();
  const [hasSauna, setHasSauna] = useState<number>();
  const [hasSeaView, setHasSeaView] = useState<number>();
  const [hasSPA, setHasSPA] = useState<number>();
  const [hasSports, setHasSports] = useState<number>();
  const [hasStorage, setHasStorage] = useState<number>();
  const [hasTennisCourt, setHasTennisCourt] = useState<number>();

  const [rentReasons, setRentReasons] = useState<IRentReasons[]>([]);

  const [dragId, setDragId] = useState<string>();

  const [photosToReOrder, setPhotosToReOrder] = useState<IPhotosToReOrder[]>(
    []
  );

  const [latitude, setLatitude] = useState<number>(-3.72422);
  const [longitude, setLongitude] = useState<number>(-38.50403);

  const [formErrors, setFormErrors] = useState({} as Errors);

  const [imageError, setImageError] = useState<IImageError[]>([]);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingRequestToRemovePhoto, setLoadingRequestToRemovePhoto] =
    useState(false);

  const [openModalDeactivateProperty, setOpenModalDeactivateProperty] =
    useState(false);
  const [
    openModalConfirmActivateProperty,
    setOpenModalConfirmActivateProperty,
  ] = useState(false);
  const [openModalEditDeactivateProperty, setOpenModalEditDeactivateProperty] =
    useState(false);

  const [
    loadingRequestToActivateProperty,
    setLoadingRequestToActivateProperty,
  ] = useState(false);

  const [email, setEmail] = useState("");

  const [users, setUsers] = useState<IUsers[]>([]);

  const [loadingUsersRequest, setLoadingUsersRequest] = useState(false);
  const [guarantees, setGuarantees] = useState<IGuarantee[]>();
  const [selectedGuarantees, setSelectedGuarantees] = useState<number[]>([]);

  const [ownerDetails, setOwnerDetails] = useState<IOwnerDetails>({
    name: "",
    phone: "",
  });

  const consultantsOptions = useMemo(() => {
    return consultants.map((consultant) => ({
      label: consultant.first_name,
      value: consultant.id,
    }));
  }, [consultants]);

  const numberOfGaragesSuitesOptions = useMemo(() => {
    return [
      { label: "0", value: 0 },
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5+", value: 5 },
    ];
  }, []);

  const numberOfRoomsBathroomsOptions = useMemo(() => {
    return [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 },
      { label: "8", value: 8 },
      { label: "9", value: 9 },
      { label: "10", value: 10 },
    ];
  }, []);

  const numberOfRankOptions = useMemo(() => {
    return [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
    ];
  }, []);

  const formatPhotos = useMemo(() => {
    return photos.map((photo, index) => ({
      id: photo.id,
      order: index + 1,
      url_l: photo.url_l,
      url_m: photo.url_m,
      url_s: photo.url_s,
    }));
  }, [photos]);

  useEffect(() => {
    async function loadApi() {
      try {
        setLoadingApiData(true);
        setLoadingGoogleMapsApi(true);

        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get(
          `/api/adm/properties/${Number(params.id)}`
        );

        setRentData(response.data.data.property.current_rent);

        setPhotos(response.data.data.property.photos);

        setAddressID(response.data.data.property.address.id);
        setLogradouro(response.data.data.property.address.address);
        setCEP(response.data.data.property.address.cep);
        setNumber(
          !!response.data.data.property.address.number
            ? response.data.data.property.address.number
            : ""
        );
        setComplement(
          !!response.data.data.property.address.complement
            ? response.data.data.property.address.complement
            : ""
        );
        setNeighborhood(response.data.data.property.address.neighborhood);
        setFloor(response.data.data.property.address.floor);
        setCity(response.data.data.property.address.city);
        setUF(response.data.data.property.address.uf);
        setLatitude(response.data.data.property.address.latitude);
        setLongitude(response.data.data.property.address.longitude);
        setGoogleID(response.data.data.property.address.google_id);

        setIsRented(response.data.data.property.is_rented);
        setPropertyType(response.data.data.property.property_type_id);
        setZapPublicationType(response.data.data.property.zap_publication_type);
        setFloorType(response.data.data.property.floor_type);
        setOwnerID(
          !!response.data.data.property.owner
            ? response.data.data.property.owner.id
            : 0
        );
        setRank(response.data.data.property.rank);
        setOwnerEmail(
          !!response.data.data.property.owner
            ? response.data.data.property.owner.email
            : ""
        );
        setOwnerDetails({
          name: response.data.data.property.owner.first_name ?? "",
          phone: response.data.data.property.owner.phone ?? "",
        });
        setEmail(
          !!response.data.data.property.owner
            ? response.data.data.property.owner.email
            : ""
        );
        setConsultantID(response.data.data.property.consultant_id);
        setTitle(response.data.data.property.title);
        setDescription(response.data.data.property.description);
        setRooms(response.data.data.property.rooms);
        setSuites(response.data.data.property.suites);
        setBathrooms(response.data.data.property.bathrooms);
        setGarage(response.data.data.property.garages);
        setRentValue(
          String(response.data.data.property.rent_value).replaceAll(".", "")
        );
        setIptuValue(
          String(response.data.data.property.iptu_value).replaceAll(".", "")
        );
        setGarbageTax(
          String(response.data.data.property.garbage_tax).replaceAll(".", "")
        );
        setCondominiumValue(
          String(response.data.data.property.condominium_value).replaceAll(
            ".",
            ""
          )
        );
        setArea(String(response.data.data.property.area));
        setIsFunished(response.data.data.property.is_furnished);
        setAllowPets(response.data.data.property.allow_pets);
        setWaterIncluded(response.data.data.property.water_included);
        setGasIncluded(response.data.data.property.gas_included);
        setIsAdm(response.data.data.property.is_adm);
        setYearBuilt(response.data.data.property.year_built);

        setIsNearbyMajorRoads(
          response.data.data.property.is_nearby_major_roads
        );
        setIsNearbyMall(response.data.data.property.is_nearby_mall);
        setIsNearbyPublicTransport(
          response.data.data.property.is_nearby_public_transport
        );
        setIsNearbySchools(response.data.data.property.is_nearby_schools);
        setIsNearbyClinics(response.data.data.property.is_nearby_clinics);

        setHas24hConcierge(response.data.data.property.has_24h_concierge);
        setHasAC(response.data.data.property.has_ac);
        setHasBackyard(response.data.data.property.has_backyard);
        setHasBBQ(response.data.data.property.has_bbq);
        setHasCableTV(response.data.data.property.has_cable_tv);
        setHasElevator(response.data.data.property.has_elevator);
        setHasGameRoom(response.data.data.property.has_game_room);
        setHasGarden(response.data.data.property.has_garden);
        setHasGourmet(response.data.data.property.has_gourmet);
        setHasGourmetPorch(response.data.data.property.has_gourmet_porch);
        setHasGym(response.data.data.property.has_gym);
        setHasHomeCinema(response.data.data.property.has_home_cinema);
        setHasParty(response.data.data.property.has_party);
        setHasPlayground(response.data.data.property.has_playground);
        setHasPool(response.data.data.property.has_pool);
        setHasPorch(response.data.data.property.has_porch);
        setHasSauna(response.data.data.property.has_sauna);
        setHasSeaView(response.data.data.property.has_sea_view);
        setHasSPA(response.data.data.property.has_spa);
        setHasSports(response.data.data.property.has_sports);
        setHasStorage(response.data.data.property.has_storage);
        setHasTennisCourt(response.data.data.property.has_tennis_court);

        setLoadingApiData(false);

        setRentReasons(response.data.data.rentReasons);

        setSelectedGuarantees(
          response.data.data.property.guarantees.map(
            (guarantee: IGuarantee) => {
              return guarantee.id;
            }
          )
        );
        setNote(response.data.data.property.note);
      } catch (err) {
        console.log(err);

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadingApiData(false);
      }
    }

    async function loadGuarantees() {
      try {
        const token = await refreshToken(dateToExpires, access_token);
        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;
        const response = await api.get("/api/adm/guarantees");
        setGuarantees(response.data.data);
      } catch (err) {
        console.log(err);
        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
    }
    if (!!user) {
      loadApi();
      loadGuarantees();
    } else {
      history.push("/");
    }
  }, [
    dateToExpires,
    access_token,
    user,
    history,
    params.id,
    refresh,
    addToast,
  ]);

  const { handleAddConsultants, consultantsSaved } = useConsultant();

  useEffect(() => {
    async function loadApi() {
      try {
        const token = await refreshToken(dateToExpires, access_token);

        if (!loadingApiData) {
          api.defaults.headers.authorization = `Bearer ${
            !!token ? token : access_token
          }`;
          if (consultantsSaved.length < 1) {
            const response = await api.get("/api/adm/users", {
              params: {
                role: "consultant",
              },
            });

            handleAddConsultants(response.data.data.consultants);

            const consultant_name = response.data.data.consultants.find(
              (consultant: { id: number }) => consultant.id === consultantID
            );

            setConsultants(response.data.data.consultants);

            setPropertyConsultant(
              !!consultant_name ? consultant_name.first_name : ""
            );
          } else {
            const consultant_name = consultantsSaved.find(
              (consultant: { id: number }) => consultant.id === consultantID
            );

            setConsultants(consultantsSaved);

            setPropertyConsultant(
              !!consultant_name ? consultant_name.first_name : ""
            );

            return;
          }
        }
        setLoadingUsers(false);
      } catch (err) {
        console.log(err);

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadingApiData(false);
      }
    }
    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [
    consultantsSaved,
    handleAddConsultants,
    dateToExpires,
    access_token,
    user,
    history,
    params.id,
    addToast,
    loadingApiData,
  ]);

  const handleGoToDescriptionForm = useCallback(async () => {
    setLoadingRequest(true);

    try {
      const data = {
        logradouro: logradouro,
        cep: cep,
        number: number,
        complement: complement,
        neighborhood: neighborhood,
        floor: floor,
        city: city,
        uf: uf,
      };

      const cepRegex = /^\d{5}-\d{3}$/g;

      const schema = Yup.object().shape({
        logradouro: Yup.string().required("Logradouro obrigatório"),
        cep: Yup.string()
          .required("CEP obrigatório")
          .matches(cepRegex, "CEP inválido"),
        number: Yup.string().required("Número obrigatório"),
        neighborhood: Yup.string().required("Bairro obrigatório"),
        city: Yup.string().required("Cidade obrigatória"),
        uf: Yup.string().required("UF obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.put(`/api/adm/addresses/${addressID}`, {
        cep: data.cep,
        address: data.logradouro,
        complement: data.complement,
        number: Number(data.number),
        city: data.city,
        uf: data.uf,
        neighborhood: data.neighborhood,
        floor: data.floor,
        latitude: String(latitude),
        longitude: String(longitude),
        google_id: googleID,
      });

      if (!!response.data.success) {
        setFormErrors({});

        addToast({
          title: "Endereço atualizado!",
          type: "success",
        });

        handleRefresh();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationErrors(err);

        addToast({
          title: error[Object.keys(error)[0]],
          type: "info",
          description: "",
        });

        setFormErrors(error);
      } else if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }

    setLoadingRequest(false);
  }, [
    logradouro,
    cep,
    number,
    complement,
    neighborhood,
    floor,
    city,
    uf,
    latitude,
    longitude,
    googleID,
    addressID,
    access_token,
    dateToExpires,
    addToast,
    handleRefresh,
  ]);

  const handlePublishOnImovelWeb = useCallback(async () => {
    try {
      const token = await refreshToken(dateToExpires, access_token);
      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post(
        `/api/adm/publish-on-imovel-web/${params.id}`
      );

      if (response.data.success) {
        addToast({
          title: "Imóvel publicado no imovelWeb",
          type: "success",
        });

        handleRefresh();
      }
    } catch (err) {
      addToast({
        title: "Erro ao publicar no imovelWeb",
        type: "info",
        description: "",
      });
    }
  }, [handleRefresh, addToast, params.id, dateToExpires, access_token]);

  const handleGoToCharacteristicForm = useCallback(async () => {
    setLoadingRequest(true);

    try {
      const data = {
        property_type_id: propertyType,
        zap_publication_type: zapPublicationType,
        floor_type: floorType,
        owner_email: ownerEmail,
        property_consultant: propertyConsultant,
        title: title,
        description: description,
        rooms: rooms,
        suites: suites,
        bathrooms: bathrooms,
        garage: garage,
        rent_value: rentValue.replaceAll("R$", ""),
        condominium_value: condominiumValue.replaceAll("R$", ""),
        iptu_value: iptuValue.replaceAll("R$", ""),
        garbage_tax: garbageTax.replaceAll("R$", ""),
        area: area.replaceAll("m²", ""),
        is_funished: isFunished,
        allow_pets: allowPets,
        rank: rank,
        water_included: waterIncluded,
        gas_included: gasIncluded,
        is_adm: isAdm,
        guarantees: selectedGuarantees,
        note: note,
      };

      const schema = Yup.object().shape({
        property_type_id: Yup.number().required("Informe o tipo do imóvel"),
        zap_publication_type: Yup.string().required(
          "Informe o tipo do publicação no ZAP"
        ),
        owner_email: Yup.string().required(
          "E-mail do proprietário obrigatório"
        ),
        property_consultant: Yup.string().required("Consultante obrigatório"),
        title: Yup.string().required("Título obrigatório"),
        description: Yup.string().required("Descrição obrigatória"),
        rooms: Yup.number()
          .required("Número de quartos obrigatório")
          .min(1, "Deve conter no mínimo 1 quarto"),
        suites: Yup.number()
          .required("Número de suítes obrigatório")
          .max(5, "Número máximo de suítes atingido"),
        bathrooms: Yup.number()
          .required("Número de banheiros obrigatório")
          .max(10, "Número máximo de banheiros atingido"),
        garage: Yup.number()
          .required("Número de garagens obrigatório")
          .max(5, "Número máximo de vagas atingido"),
        rent_value: Yup.string().required("Valor do aluguel obrigatório"),
        condominium_value: Yup.string().required(
          "Valor do condomínio obrigatório"
        ),
        iptu_value: Yup.string().required("Valor do iptu obrigatório"),
        area: Yup.string().required("Área obrigatória"),
        is_funished: Yup.number().required("Informe se o imóvel é mobiliado"),
        allow_pets: Yup.number().required("Informe se o imóvel permite pets"),
        water_included: Yup.number().required(
          "Informe se o imóvel tem água inclusa"
        ),
        gas_included: Yup.number().required(
          "Informe se o imóvel tem gás inclusa"
        ),
        guarantees: Yup.array()
          .required("Mínimo de 1 garantia")
          .min(1, "Necessária ao menos 1 garantia"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.put(`/api/adm/properties/${params.id}`, {
        property_type_id: propertyType,
        zap_publication_type: zapPublicationType,
        floor_type: floorType,
        user_id: ownerID,
        consultant_id: consultantID,
        address_id: addressID,
        title: title,
        description: description,
        rooms: rooms,
        suites: suites,
        bathrooms: bathrooms,
        garages: garage,
        rent_value: Number(
          `${rentValue.substring(
            0,
            rentValue.length - 2
          )}.${rentValue.substring(rentValue.length - 2, rentValue.length)}`
        ),
        condominium_value: Number(
          `${condominiumValue.substring(
            0,
            condominiumValue.length - 2
          )}.${condominiumValue.substring(
            condominiumValue.length - 2,
            condominiumValue.length
          )}`
        ),
        iptu_value: Number(
          `${iptuValue.substring(
            0,
            iptuValue.length - 2
          )}.${iptuValue.substring(iptuValue.length - 2, iptuValue.length)}`
        ),
        garbage_tax: Number(
          `${garbageTax.substring(
            0,
            garbageTax.length - 2
          )}.${garbageTax.substring(garbageTax.length - 2, garbageTax.length)}`
        ),
        area: Number(area.replaceAll("m²", "").replaceAll(",", ".")),
        is_furnished: isFunished,
        allow_pets: allowPets,
        rank: rank,
        water_included: waterIncluded,
        gas_included: gasIncluded,
        is_adm: isAdm,
        year_built: yearBuilt,
        guarantees: selectedGuarantees,
        note: note
      });

      if (!!response.data.success) {
        setFormErrors({});

        addToast({
          title: "Descrição atualizada!",
          type: "success",
        });

        handleRefresh();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationErrors(err);

        addToast({
          title: error[Object.keys(error)[0]],
          type: "info",
          description: "",
        });

        setFormErrors(error);
      } else if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      console.log(err);
    }

    setLoadingRequest(false);
  }, [
    handleRefresh,
    addToast,
    params.id,
    addressID,
    propertyType,
    zapPublicationType,
    floorType,
    ownerEmail,
    ownerID,
    rank,
    propertyConsultant,
    consultantID,
    title,
    description,
    rooms,
    suites,
    bathrooms,
    garage,
    rentValue,
    condominiumValue,
    iptuValue,
    garbageTax,
    area,
    isFunished,
    allowPets,
    dateToExpires,
    access_token,
    waterIncluded,
    gasIncluded,
    isAdm,
    yearBuilt,
    selectedGuarantees,
  ]);

  const handleGoToPhotosForm = useCallback(async () => {
    setLoadingRequest(true);

    try {
      const data = {
        is_nearby_clinics: isNearbyClinics,
        is_nearby_major_roads: isNearbyMajorRoads,
        is_nearby_schools: isNearbySchools,
        is_nearby_mall: isNearbyMall,
        is_nearby_public_transport: isNearbyPublicTransport,
        has_24h_concierge: has24hConcierge,
        has_ac: hasAC,
        has_backyard: hasBackyard,
        has_bbq: hasBBQ,
        has_cable_tv: hasCableTV,
        has_elevator: hasElevator,
        has_game_room: hasGameRoom,
        has_garden: hasGarden,
        has_gourmet: hasGourmet,
        has_gourmet_porch: hasGourmetPorch,
        has_gym: hasGym,
        has_home_cinema: hasHomeCinema,
        has_party: hasParty,
        has_playground: hasPlayground,
        has_pool: hasPool,
        has_porch: hasPorch,
        has_sauna: hasSauna,
        has_sea_view: hasSeaView,
        has_spa: hasSPA,
        has_sports: hasSports,
        has_storage: hasStorage,
        has_tennis_court: hasTennisCourt,
      };

      const schema = Yup.object().shape({
        is_nearby_clinics: Yup.number().required("Informe o valor dessa campo"),
        is_nearby_major_roads: Yup.number().required(
          "Informe o valor dessa campo"
        ),
        is_nearby_schools: Yup.number().required("Informe o valor dessa campo"),
        is_nearby_mall: Yup.number().required("Informe o valor dessa campo"),
        is_nearby_public_transport: Yup.number().required(
          "Informe o valor dessa campo"
        ),
        has_24h_concierge: Yup.number().required("Informe o valor dessa campo"),
        has_ac: Yup.number().required("Informe o valor dessa campo"),
        has_backyard: Yup.number().required("Informe o valor dessa campo"),
        has_bbq: Yup.number().required("Informe o valor dessa campo"),
        has_cable_tv: Yup.number().required("Informe o valor dessa campo"),
        has_elevator: Yup.number().required("Informe o valor dessa campo"),
        has_game_room: Yup.number().required("Informe o valor dessa campo"),
        has_garden: Yup.number().required("Informe o valor dessa campo"),
        has_gourmet: Yup.number().required("Informe o valor dessa campo"),
        has_gourmet_porch: Yup.number().required("Informe o valor dessa campo"),
        has_gym: Yup.number().required("Informe o valor dessa campo"),
        has_home_cinema: Yup.number().required("Informe o valor dessa campo"),
        has_party: Yup.number().required("Informe o valor dessa campo"),
        has_playground: Yup.number().required("Informe o valor dessa campo"),
        has_pool: Yup.number().required("Informe o valor dessa campo"),
        has_porch: Yup.number().required("Informe o valor dessa campo"),
        has_sauna: Yup.number().required("Informe o valor dessa campo"),
        has_sea_view: Yup.number().required("Informe o valor dessa campo"),
        has_spa: Yup.number().required("Informe o valor dessa campo"),
        has_sports: Yup.number().required("Informe o valor dessa campo"),
        has_storage: Yup.number().required("Informe o valor dessa campo"),
        has_tennis_court: Yup.number().required("Informe o valor dessa campo"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.put(
        `/api/adm/properties/${params.id}/benefits`,
        {
          ...data,
        }
      );

      if (!!response.data.success) {
        setFormErrors({});

        addToast({
          title: "Características atualizada!",
          type: "success",
        });

        handleRefresh();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationErrors(err);

        addToast({
          title: error[Object.keys(error)[0]],
          type: "info",
          description: "",
        });

        setFormErrors(error);
      } else if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      console.log(err);
    }

    setLoadingRequest(false);
  }, [
    addToast,
    handleRefresh,
    dateToExpires,
    params.id,
    access_token,
    isNearbyClinics,
    isNearbyMajorRoads,
    isNearbySchools,
    isNearbyMall,
    isNearbyPublicTransport,
    has24hConcierge,
    hasAC,
    hasBackyard,
    hasBBQ,
    hasCableTV,
    hasElevator,
    hasGameRoom,
    hasGarden,
    hasGourmet,
    hasGourmetPorch,
    hasGym,
    hasHomeCinema,
    hasParty,
    hasPlayground,
    hasPool,
    hasPorch,
    hasSauna,
    hasSeaView,
    hasSPA,
    hasSports,
    hasStorage,
    hasTennisCourt,
  ]);

  const handleChangeOrders = useCallback(async () => {
    setLoadingRequest(true);
    try {
      if (photosToReOrder.length < 1) {
        setLoadingRequest(false);

        addToast({
          title: "Você não está reodenando nenhuma imagem",
          type: "error",
        });

        return;
      }

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.put(`/api/adm/photos`, {
        photos: photosToReOrder,
      });

      if (!!response.data.success) {
        setFormErrors({});

        addToast({
          title: "Imagens reordenadas!",
          type: "success",
        });

        handleRefresh();
      }
    } catch (err) {
      if (!!err.response) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }

    setLoadingRequest(false);
  }, [dateToExpires, access_token, photosToReOrder, handleRefresh, addToast]);

  const hadleGetMoreInformationsAboutLocal = useCallback(
    async (address: string, place_id: string) => {
      try {
        GeoCode.setApiKey(
          !!process.env.REACT_APP_GOOGLE_MAPS_KEY
            ? process.env.REACT_APP_GOOGLE_MAPS_KEY
            : ""
        );

        const response = await GeoCode.fromAddress(address);

        const request = await geocodeByPlaceId(place_id);

        request[0].address_components.forEach((address) => {
          if (address.types.join().includes("route")) {
            setLogradouro(address.long_name);

            return;
          }

          if (address.types.join().includes("administrative_area_level_2")) {
            setCity(address.long_name);

            return;
          }

          if (address.types.join().includes("sublocality_level_1")) {
            setNeighborhood(address.long_name);

            return;
          }

          if (address.types.join().includes("administrative_area_level_1")) {
            setUF(address.short_name);

            return;
          }

          if (address.types.join().includes("street_number")) {
            setNumber(address.short_name);

            return;
          }

          if (address.types.join().includes("postal_code")) {
            setCEP(address.short_name);

            return;
          }
        });

        setLatitude(response.results[0].geometry.location.lat);
        setLongitude(response.results[0].geometry.location.lng);
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const hadleDragImageBox = useCallback((ev: DragEvent<HTMLDivElement>) => {
    setDragId(ev.currentTarget.id);
  }, []);

  const handleDropImageBox = useCallback(
    (ev: DragEvent<HTMLDivElement>) => {
      const dragPhoto = formatPhotos.find(
        (photo) => String(photo.id) === dragId
      );
      const dropPhoto = formatPhotos.find(
        (photo) => String(photo.id) === ev.currentTarget?.id
      );

      if (!!dragPhoto && !!dropPhoto) {
        const dragPhotoOrder = dragPhoto.order;
        const dropPhotoOrder = dropPhoto.order;

        const newPhotosState = formatPhotos.map((photo) => {
          if (dropPhotoOrder > dragPhotoOrder) {
            if (photo.order > dragPhotoOrder && photo.order < dropPhotoOrder) {
              photo.order -= 1;
            }
            if (String(photo.id) === dragId) {
              photo.order = dropPhotoOrder - 1;
            }
          }
          if (dropPhotoOrder < dragPhotoOrder) {
            if (photo.order < dragPhotoOrder && photo.order > dropPhotoOrder) {
              photo.order += 1;
            }
            if (String(photo.id) === dragId) {
              photo.order = dropPhotoOrder - 1;
            }
          }

          return photo;
        });

        const orderPhotosState = newPhotosState.sort(
          (a, b) => a.order - b.order
        );

        const dragPhotoIdExists = photosToReOrder.some(
          (photo) => photo.id === dragPhoto.id
        );
        const dropPhotoIdExists = photosToReOrder.some(
          (photo) => photo.id === dropPhoto.id
        );

        if (!!dragPhotoIdExists && !dropPhotoIdExists) {
          const newArray = photosToReOrder.filter(
            (photo) => photo.id !== dragPhoto.id
          );

          setPhotosToReOrder([
            ...newArray,
            { id: dragPhoto?.id, newOrder: dragPhoto?.order },
            { id: dropPhoto?.id, newOrder: dropPhoto?.order },
          ]);
        } else if (!!dropPhotoIdExists && !dragPhotoIdExists) {
          const newArray = photosToReOrder.filter(
            (photo) => photo.id !== dropPhoto.id
          );

          setPhotosToReOrder([
            ...newArray,
            { id: dragPhoto?.id, newOrder: dragPhoto?.order },
            { id: dropPhoto?.id, newOrder: dropPhoto?.order },
          ]);
        } else if (!!dragPhotoIdExists && !!dropPhotoIdExists) {
          const newArray = photosToReOrder.filter(
            (photo) => photo.id !== dropPhoto.id
          );
          const newArrayToSave = newArray.filter(
            (photo) => photo.id !== dragPhoto.id
          );

          setPhotosToReOrder([
            ...newArrayToSave,
            { id: dragPhoto?.id, newOrder: dragPhoto?.order },
            { id: dropPhoto?.id, newOrder: dropPhoto?.order },
          ]);
        } else {
          setPhotosToReOrder((prevValue) => [
            ...prevValue,
            { id: dragPhoto?.id, newOrder: dragPhoto?.order },
            { id: dropPhoto?.id, newOrder: dropPhoto?.order },
          ]);
        }

        setPhotos(orderPhotosState);
      }
    },
    [formatPhotos, dragId, photosToReOrder]
  );

  const handleOnDrop = useCallback(
    async (acceptedFiles: File[]) => {
      let imagesWithError: IImageError[] = [];

      setLoadingPhotosRequest(true);

      if (
        acceptedFiles.length > 28 ||
        formatPhotos.length === 28 ||
        formatPhotos.length + acceptedFiles.length > 28
      ) {
        setImageError([{ name: "", reason: "O número máximo de fotos é 28" }]);
        setLoadingPhotosRequest(false);
        return;
      }

      await asyncForEach(acceptedFiles, async (file, index) => {
        try {
          if (file.size > 4000000) {
            imagesWithError.push({
              name: file.name,
              reason: "é maior que 4mb",
            });

            return;
          }

          if (file.type !== "image/png" && file.type !== "image/jpeg") {
            imagesWithError.push({
              name: file.name,
              reason: "não e uma imagem png ou jpg",
            });

            return;
          }

          const imageBase64 = await loadFile(file);

          if (imageBase64.includes("data:image/jpeg;base64,")) {
            const imageBase64Formated = imageBase64.replaceAll(
              "data:image/jpeg;base64,",
              ""
            );

            const response = await api.post(
              `/api/adm/properties/${params.id}/photos`,
              {
                order: formatPhotos.length + index + 1,
                photo: imageBase64Formated,
              }
            );

            if (response.data.success) {
              setPhotos((prevValue) => [
                ...prevValue,
                {
                  ...response.data.data,
                  url_l: response.data.data.photo,
                  url_m: response.data.data.photo,
                  url_s: response.data.data.photo,
                },
              ]);

              if (index + 1 === acceptedFiles.length - imagesWithError.length) {
                setLoadingPhotosRequest(false);

                addToast({
                  title: "Imagens adicionadas!",
                  type: "success",
                });
              }
            }

            return;
          } else if (imageBase64.includes("data:image/png;base64,")) {
            const imageBase64Formated = imageBase64.replaceAll(
              "data:image/png;base64,",
              ""
            );

            const response = await api.post(
              `/api/adm/properties/${params.id}/photos`,
              {
                order: formatPhotos.length + index + 1,
                photo: imageBase64Formated,
              }
            );

            if (response.data.success) {
              setPhotos((prevValue) => [
                ...prevValue,
                {
                  ...response.data.data,
                  url_l: response.data.data.photo,
                  url_m: response.data.data.photo,
                  url_s: response.data.data.photo,
                },
              ]);

              if (index + 1 === acceptedFiles.length - imagesWithError.length) {
                setLoadingPhotosRequest(false);

                addToast({
                  title: "Imagens adicionadas!",
                  type: "success",
                });
              }
            }
            return;
          }
        } catch (err) {
          setLoadingPhotosRequest(false);

          if (
            !!err.response.data.errors[
              Object.keys(err.response.data.errors)[0]
            ][0]
          ) {
            const errorsNumber = Object.keys(err.response.data.errors).length;

            for (let i = 0; i < errorsNumber; i++) {
              err.response.data.errors[
                Object.keys(err.response.data.errors)[i]
              ].forEach((error: string) => {
                addToast({
                  title: error,
                  type: "error",
                });
              });
            }
          }

          console.log(err);
        }
      });
      setImageError(imagesWithError);
    },
    [params.id, formatPhotos, addToast]
  );
  const handleRemovePhoto = useCallback(
    async (id: number) => {
      setLoadingRequestToRemovePhoto(true);

      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.delete(`/api/adm/photos/${id}`);

        if (!!response.data.success) {
          const newArrayToShow = photos.filter((photo) => photo.id !== id);

          addToast({
            title: "Imagem removida!",
            type: "success",
          });

          setPhotos(newArrayToShow);
        }
      } catch (err) {
        if (!!err.response) {
          const errorsNumber = Object.keys(err.response.data.errors).length;

          for (let i = 0; i < errorsNumber; i++) {
            err.response.data.errors[
              Object.keys(err.response.data.errors)[i]
            ].forEach((error: string) => {
              addToast({
                title: error,
                type: "error",
              });
            });
          }
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }

      setLoadingRequestToRemovePhoto(false);
    },
    [access_token, dateToExpires, photos, addToast]
  );

  const handleActivateModal = useCallback(async () => {
    setLoadingRequestToActivateProperty(true);

    try {
      const response = await api.post(
        `/api/adm/properties/${params.id}/activate`
      );

      if (!!response.data.success) {
        setFormErrors({});

        addToast({
          title: "Imóvel ativado!",
          type: "success",
        });

        handleRefresh();
      }
    } catch (err) {
      if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      console.log(err);
    }

    setOpenModalConfirmActivateProperty(false);
    setLoadingRequestToActivateProperty(false);
  }, [params.id, addToast, handleRefresh]);

  const handleSelectUser = useCallback(
    (user_id: number | undefined) => {
      const user_selected = users.find((client) => client.id === user_id);

      setUsers([]);

      if (!!user_selected) {
        setOwnerID(user_selected.id);
        setOwnerEmail(user_selected?.email);
        setEmail(user_selected?.email);
        setOwnerDetails({
          name: user_selected?.first_name,
          phone: user_selected?.phone,
        });
      }
    },
    [users]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (e) => handleOnDrop(e),
  });

  let cancelToken: undefined | CancelTokenSource;

  const handleSearchChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchTerm = e.target.value;

      setEmail(searchTerm);

      if (cancelToken !== undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      // eslint-disable-next-line
      cancelToken = axios.CancelToken.source();

      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get("/api/adm/users", {
          params: {
            email: searchTerm,
          },
          cancelToken: cancelToken?.token,
        });

        if (response.data.data.users.length === 0) {
          addToast({
            title: "Nenhum usuário encontrado",
            type: "error",
          });

          setLoadingUsersRequest(false);
        }

        setUsers(response.data.data.users);
      } catch (err) {
        console.log(err);
      }
    },
    [cancelToken, access_token, dateToExpires, addToast]
  );

  const onGuaranteeSelectedHandler = (e: any) => {
    if (e.target.checked) {
      setSelectedGuarantees((prevGuarantees) => [
        ...prevGuarantees,
        Number(e.target.value),
      ]);
    } else {
      setSelectedGuarantees((prevGuarantees) =>
        prevGuarantees.filter((guarantee) => {
          return Number(e.target.value) !== guarantee;
        })
      );
    }
  };

  const propertyHasGuarantee = (guaranteeId: number) => {
    const hasGuarantee = selectedGuarantees.find(
      (guarantee) => guarantee === guaranteeId
    );
    return !!hasGuarantee;
  };

  async function handleShareLink() {
    setLoadingSharedLink(true);
    try {
      const token = await refreshToken(dateToExpires, access_token);
      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;
      const response = await api.post(
        `/api/adm/properties/shared-link/${params.id}`
      );
      const URL =
        process.env.REACT_APP_ENVIROMENT === "deploy"
          ? "https://www.7cantos.com/imovel/"
          : "https://web-app7cantos-staging.herokuapp.com/imovel/";
      await navigator.clipboard.writeText(URL + `${response.data.data.key}`);
      addToast({
        title: "Link copiado para o Clipboard",
        type: "success",
      });
    } catch (err) {
      console.log(err);
      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }
    setLoadingSharedLink(false);
  }

  return (
    <Container>
      <Layout>
        {!!loadingApiData && !!loadingGoogleMapsApi ? (
          <Loading />
        ) : (
          <Content>
            <EditPropertyContainer>
              <InitialContainer>
                <TitleAndErrorTextContainer>
                  <h3>Editar imóvel</h3>
                </TitleAndErrorTextContainer>
              </InitialContainer>

              <FormsOptionContainer>
                <FormOptionAddress
                  isSelected={
                    formOptionSelected === "photos" ||
                    formOptionSelected === "characteristic" ||
                    formOptionSelected === "description" ||
                    formOptionSelected === "map"
                  }
                  onClick={() => setFormOptionSelected("map")}
                >
                  1
                </FormOptionAddress>
                <FormOptionDescription
                  isSelected={
                    formOptionSelected === "description" ||
                    formOptionSelected === "characteristic" ||
                    formOptionSelected === "photos"
                  }
                  onClick={() => setFormOptionSelected("description")}
                >
                  2
                </FormOptionDescription>
                <FormOptionCharacteristics
                  isSelected={
                    formOptionSelected === "characteristic" ||
                    formOptionSelected === "photos"
                  }
                  onClick={() => setFormOptionSelected("characteristic")}
                >
                  3
                </FormOptionCharacteristics>
                <FormOptionPhotos
                  isSelected={formOptionSelected === "photos"}
                  onClick={() => setFormOptionSelected("photos")}
                >
                  4
                </FormOptionPhotos>
              </FormsOptionContainer>

              {formOptionSelected === "map" && (
                <MapForm>
                  <p>Endereço completo para busca</p>
                  <GooglePlacesAutocomplete
                    onLoadFailed={(err) => console.log(err)}
                    autocompletionRequest={{
                      componentRestrictions: { country: "br" },
                      types: ["address"],
                      location: {
                        lat: -17.221666,
                        lng: -46.875,
                      },
                      bounds: [
                        {
                          lat: -17.221666,
                          lng: -46.875,
                        },
                        {
                          lat: -17.221666,
                          lng: -46.875,
                        },
                      ],
                    }}
                    selectProps={{
                      inputValue: googleMapsSearch,
                      loadingMessage(obj: { inputValue: string }) {
                        return "Carregando...";
                      },
                      onInputChange(e) {
                        setGoogleMapsSearch(e);
                      },
                      onChange(e) {
                        hadleGetMoreInformationsAboutLocal(
                          e?.label,
                          e?.value.place_id
                        );

                        setGoogleMapsSearch(e?.label);
                        setGoogleID(
                          !!e?.value.place_id ? e?.value.place_id : googleID
                        );
                      },
                      noOptionsMessage(obj: { inputValue: string }) {
                        return "Sem local";
                      },
                      placeholder: "Digite o endereço do local",
                    }}
                  />

                  <p className="p-example">
                    Ex: Av. Dom Luís, 500 - Aldeota, Fortaleza - CE, Brasil
                  </p>

                  <LocalDataContainer>
                    <label>Confira abaixo Se os dados estão corretos:</label>

                    <LocalDataContent>
                      <LocalContent>
                        <label>
                          Logradouro:{" "}
                          {!!formErrors.logradouro && (
                            <p>{formErrors.logradouro}</p>
                          )}
                        </label>
                        <input
                          type="text"
                          value={logradouro}
                          onChange={(e) => setLogradouro(e.target.value)}
                        />
                      </LocalContent>

                      <LocalContent>
                        <label>
                          CEP {!!formErrors.cep && <p>{formErrors.cep}</p>}
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          type="text"
                          value={cep}
                          onChange={(e) => setCEP(e.target.value)}
                        />
                      </LocalContent>

                      <LocalContent>
                        <label>
                          Número{" "}
                          {!!formErrors.number && <p>{formErrors.number}</p>}
                        </label>
                        <input
                          type="text"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                      </LocalContent>

                      <LocalContent>
                        <label>Complemento (opcional)</label>
                        <input
                          type="text"
                          value={complement}
                          onChange={(e) => setComplement(e.target.value)}
                        />
                      </LocalContent>
                    </LocalDataContent>

                    <LocalDataContent>
                      <LocalContent>
                        <label>
                          Bairro{" "}
                          {!!formErrors.neighborhood && (
                            <p>{formErrors.neighborhood}</p>
                          )}
                        </label>
                        <input
                          type="text"
                          value={neighborhood}
                          onChange={(e) => setNeighborhood(e.target.value)}
                        />
                      </LocalContent>

                      <LocalContent>
                        <label>
                          Cidade {!!formErrors.city && <p>{formErrors.city}</p>}
                        </label>
                        <input
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </LocalContent>

                      <LocalContent>
                        <label>
                          UF {!!formErrors.uf && <p>{formErrors.uf}</p>}
                        </label>
                        <input
                          type="text"
                          value={uf}
                          onChange={(e) => setUF(e.target.value)}
                        />
                      </LocalContent>

                      <LocalContent>
                        <label>
                          Andar{" "}
                          {!!formErrors.floor && <p>{formErrors.floor}</p>}
                        </label>
                        <input
                          type="text"
                          value={floor}
                          onChange={(e) => setFloor(e.target.value)}
                        />
                      </LocalContent>
                    </LocalDataContent>
                  </LocalDataContainer>

                  {!!"AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc" && (
                    <div
                      style={{
                        height: "300px",
                        width: "100%",
                        marginTop: "58px",
                      }}
                    >
                      <GoogleMaps
                        bootstrapURLKeys={{
                          key: "AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc",
                        }}
                        center={{
                          lat: parseFloat(latitude.toString()),
                          lng: parseFloat(longitude.toString()),
                        }}
                        defaultZoom={18}
                        options={{
                          mapTypeControl: true,
                        }}
                        layerTypes={["TrafficLayer", "TransitLayer"]}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={() => setLoadingGoogleMapsApi(false)}
                      >
                        <Marker
                          lat={latitude}
                          lng={longitude}
                          color="#FF3838"
                        />
                      </GoogleMaps>
                    </div>
                  )}

                  <ButtonsContainer>
                    <RegisterButton
                      type="button"
                      onClick={
                        !!loadingRequest ? () => {} : handleGoToDescriptionForm
                      }
                    >
                      <p>{!!loadingRequest ? "Carregando..." : "Salvar"}</p>
                    </RegisterButton>
                    <NextButton
                      type="button"
                      onClick={() => {
                        setFormOptionSelected("description");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <p>Próximo</p>
                      <FiArrowRight size={18} color="#FFF" />
                    </NextButton>
                  </ButtonsContainer>
                </MapForm>
              )}

              {formOptionSelected === "description" && (
                <DescriptionForm>
                  {!!openModalDeactivateProperty && (
                    <ModalDeactivateProperty
                      open={openModalDeactivateProperty}
                      property={Number(params.id)}
                      rentReasons={rentReasons}
                      functionToCloseModal={() =>
                        setOpenModalDeactivateProperty(false)
                      }
                    />
                  )}

                  {!!openModalConfirmActivateProperty && (
                    <ModalConfirmActivateProperty
                      open={openModalConfirmActivateProperty}
                      isLoading={loadingRequestToActivateProperty}
                      functionToActivateProperty={handleActivateModal}
                      functionToCloseModal={() =>
                        setOpenModalConfirmActivateProperty(false)
                      }
                    />
                  )}

                  {!!openModalEditDeactivateProperty && (
                    <ModalEditDeactivateProperty
                      open={openModalEditDeactivateProperty}
                      data={rentData}
                      rentReasons={rentReasons}
                      functionToCloseModal={() =>
                        setOpenModalEditDeactivateProperty(false)
                      }
                    />
                  )}

                  <PropertyTypeAndIsRentedContainer>
                    <PropertyTypesContentColumn>
                      <p id="initial-label">
                        Qual é o tipo do imóvel?{" "}
                        {!!formErrors.property_type_id && (
                          <p>{formErrors.property_type_id}</p>
                        )}
                      </p>
                      <PropertyTypesContainer>
                        <PropertyTypesContent>
                          <Radio
                            name="selector-group2"
                            defaultChecked={propertyType === 2}
                            checked={propertyType === 2}
                            onChange={() => setPropertyType(2)}
                          />
                          <label onClick={() => setPropertyType(2)}>Casa</label>
                        </PropertyTypesContent>

                        <PropertyTypesContent>
                          <Radio
                            name="selector-group2"
                            defaultChecked={propertyType === 4}
                            checked={propertyType === 4}
                            onChange={() => setPropertyType(4)}
                          />
                          <label onClick={() => setPropertyType(4)}>
                            Casa de Condomínio
                          </label>
                        </PropertyTypesContent>

                        <PropertyTypesContent>
                          <Radio
                            name="selector-group2"
                            defaultChecked={propertyType === 1}
                            checked={propertyType === 1}
                            onChange={() => setPropertyType(1)}
                          />
                          <label onClick={() => setPropertyType(1)}>
                            Apartamento
                          </label>
                        </PropertyTypesContent>

                        <PropertyTypesContent>
                          <Radio
                            name="selector-group2"
                            defaultChecked={propertyType === 3}
                            checked={propertyType === 3}
                            onChange={() => setPropertyType(3)}
                          />
                          <label onClick={() => setPropertyType(3)}>Flat</label>
                        </PropertyTypesContent>
                      </PropertyTypesContainer>
                    </PropertyTypesContentColumn>
                    <IsRentedContainer>
                      <label>Está alugado?</label>

                      <IsRentedRadioContent>
                        <Radio
                          checked={!!isRented}
                          name="selector-group1"
                          className="isRentedRadio"
                          defaultChecked={!!isRented}
                          onChange={() => setOpenModalDeactivateProperty(true)}
                        />
                        <p onClick={() => setOpenModalDeactivateProperty(true)}>
                          Sim
                        </p>

                        <Radio
                          checked={!isRented}
                          name="selector-group1"
                          className="isRentedRadio"
                          defaultChecked={!isRented}
                          onChange={() =>
                            setOpenModalConfirmActivateProperty(true)
                          }
                        />
                        <p
                          onClick={() =>
                            setOpenModalConfirmActivateProperty(true)
                          }
                        >
                          Não
                        </p>
                      </IsRentedRadioContent>

                      {rentData !== null && rentData.rented_by !== null ? (
                        <RentAndIsRentedRadioContent
                          onClick={() =>
                            setOpenModalEditDeactivateProperty(true)
                          }
                        >
                          <p>Fechamento</p>
                          <FaHandshake size={24} />
                        </RentAndIsRentedRadioContent>
                      ) : (
                        <></>
                      )}
                    </IsRentedContainer>
                    <PropertyRankContainer>
                      <label>
                        Ranking {!!formErrors.rooms && <p>{formErrors.rank}</p>}
                      </label>

                      <Select
                        placeholder=""
                        value={{
                          label: String(rank === 5 ? "5" : rank),
                          value: rank,
                        }}
                        options={numberOfRankOptions}
                        onChange={(e) => setRank(e?.value)}
                      />
                    </PropertyRankContainer>
                  </PropertyTypeAndIsRentedContainer>

                  <PropertyTypeAndIsRentedContainer>
                    <PropertyTypesContentColumn>
                      <p id="zap-publication-label">
                        Tipo de publicação no ZAP
                      </p>
                      <PropertyTypesContainer>
                        <PropertyTypesContent>
                          <Radio
                            name="zap-option-standard"
                            defaultChecked={
                              zapPublicationType ===
                              ZAP_PUBLICATION_OPTIONS.STANDARD
                            }
                            checked={
                              zapPublicationType ===
                              ZAP_PUBLICATION_OPTIONS.STANDARD
                            }
                            onChange={() =>
                              setZapPublicationType(
                                ZAP_PUBLICATION_OPTIONS.STANDARD
                              )
                            }
                          />
                          <label
                            onClick={() =>
                              setZapPublicationType(
                                ZAP_PUBLICATION_OPTIONS.STANDARD
                              )
                            }
                          >
                            Padrão
                          </label>
                        </PropertyTypesContent>

                        <PropertyTypesContent>
                          <Radio
                            name="zap-option-premium"
                            defaultChecked={
                              zapPublicationType ===
                              ZAP_PUBLICATION_OPTIONS.PREMIUM
                            }
                            checked={
                              zapPublicationType ===
                              ZAP_PUBLICATION_OPTIONS.PREMIUM
                            }
                            onChange={() =>
                              setZapPublicationType(
                                ZAP_PUBLICATION_OPTIONS.PREMIUM
                              )
                            }
                          />
                          <label
                            onClick={() =>
                              setZapPublicationType(
                                ZAP_PUBLICATION_OPTIONS.PREMIUM
                              )
                            }
                          >
                            Destaque
                          </label>
                        </PropertyTypesContent>

                        <PropertyTypesContent>
                          <Radio
                            name="zap-option-super-premium"
                            defaultChecked={
                              zapPublicationType ===
                              ZAP_PUBLICATION_OPTIONS.SUPER_PREMIUM
                            }
                            checked={
                              zapPublicationType ===
                              ZAP_PUBLICATION_OPTIONS.SUPER_PREMIUM
                            }
                            onChange={() =>
                              setZapPublicationType(
                                ZAP_PUBLICATION_OPTIONS.SUPER_PREMIUM
                              )
                            }
                          />
                          <label
                            onClick={() =>
                              setZapPublicationType(
                                ZAP_PUBLICATION_OPTIONS.SUPER_PREMIUM
                              )
                            }
                          >
                            Super destaque
                          </label>
                        </PropertyTypesContent>
                      </PropertyTypesContainer>
                    </PropertyTypesContentColumn>

                    <PropertyTypesContentColumn>
                      <p id="floor-type">Tipo de andar</p>
                      <PropertyTypesContainer>
                        <PropertyTypesContent>
                          <Radio
                            name="floor-type-low"
                            checked={floorType === FLOOR_TYPE_OPTIONS.LOW}
                            onChange={() =>
                              setFloorType(FLOOR_TYPE_OPTIONS.LOW)
                            }
                          />
                          <label
                            onClick={() => setFloorType(FLOOR_TYPE_OPTIONS.LOW)}
                          >
                            Baixo
                          </label>
                        </PropertyTypesContent>

                        <PropertyTypesContent>
                          <Radio
                            name="floor-type-medium"
                            checked={floorType === FLOOR_TYPE_OPTIONS.MEDIUM}
                            onChange={() =>
                              setFloorType(FLOOR_TYPE_OPTIONS.MEDIUM)
                            }
                          />
                          <label
                            onClick={() =>
                              setFloorType(FLOOR_TYPE_OPTIONS.MEDIUM)
                            }
                          >
                            Intermediário
                          </label>
                        </PropertyTypesContent>

                        <PropertyTypesContent>
                          <Radio
                            name="floor-type-high"
                            checked={floorType === FLOOR_TYPE_OPTIONS.HIGH}
                            onChange={() =>
                              setFloorType(FLOOR_TYPE_OPTIONS.HIGH)
                            }
                          />
                          <label
                            onClick={() =>
                              setFloorType(FLOOR_TYPE_OPTIONS.HIGH)
                            }
                          >
                            Alto
                          </label>
                        </PropertyTypesContent>
                      </PropertyTypesContainer>
                    </PropertyTypesContentColumn>
                  </PropertyTypeAndIsRentedContainer>

                  <PropertyOwnerAndPropertyConsultantContainer>
                    <PropertyOwnerContainer>
                      <span>
                        * Digite ao menos 4 caracteres e pressione enter para
                        pesquisar
                      </span>
                      <p>
                        Possui proprietário cadastrado?{" "}
                        {!!formErrors.owner_email && (
                          <p>{formErrors.owner_email}</p>
                        )}
                      </p>

                      <FormContainer>
                        <input
                          readOnly={loadingUsersRequest}
                          value={
                            !!loadingUsersRequest ? "Carregando..." : email
                          }
                          onChange={
                            email.length <= 2
                              ? (e) => setEmail(e.target.value)
                              : handleSearchChange
                          }
                        />

                        {users.length >= 1 ? (
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              setEmail("");
                              setUsers([]);
                            }}
                          >
                            <div id="users">
                              {users.map((user) => (
                                <button
                                  key={user.id}
                                  type="button"
                                  onClick={() => handleSelectUser(user.id)}
                                >
                                  {user.email}
                                </button>
                              ))}
                            </div>
                          </OutsideClickHandler>
                        ) : (
                          <></>
                        )}
                        <p>Proprietário:</p>
                        <input readOnly value={ownerDetails.name} />
                        <p>Telefone:</p>
                        <input readOnly value={ownerDetails.phone} />
                      </FormContainer>
                    </PropertyOwnerContainer>

                    <PropertyConsultantContainer>
                      <p>
                        Consultor responsável pelo imóvel{" "}
                        {!!formErrors.property_consultant && (
                          <p>{formErrors.property_consultant}</p>
                        )}
                      </p>

                      <Select
                        value={{
                          label: propertyConsultant,
                          value: consultantID,
                        }}
                        options={consultantsOptions}
                        placeholder={!!loadingUsers ? "Carregando" : ""}
                        noOptionsMessage={() =>
                          !!loadingUsers ? "Carregando" : ""
                        }
                        onChange={(e) => {
                          setPropertyConsultant(e?.label);
                          setConsultantID(e?.value);
                        }}
                      />
                      <PropertyTitleContainer>
                        <p>
                          Qual é o título da publicação?{" "}
                          {!!formErrors.title && <p>{formErrors.title}</p>}
                        </p>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </PropertyTitleContainer>
                    </PropertyConsultantContainer>
                  </PropertyOwnerAndPropertyConsultantContainer>

                  <p>
                    Descrição Completa{" "}
                    {!!formErrors.description && (
                      <p>{formErrors.description}</p>
                    )}
                  </p>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <p>Ano de construção do prédio:</p>
                  <input
                    type="text"
                    value={yearBuilt}
                    onChange={(e) => setYearBuilt(e.target.value)}
                  />

                  <RoomsSuitesBathroomsGarageContainer>
                    <RoomsSuitesBathroomsGarageContent>
                      <label>
                        Quartos{" "}
                        {!!formErrors.rooms && <p>{formErrors.rooms}</p>}
                      </label>
                      <Select
                        placeholder="Escolha um número"
                        value={{
                          label: String(rooms === 5 ? "5+" : rooms),
                          value: rooms,
                        }}
                        options={numberOfRoomsBathroomsOptions}
                        onChange={(e) => setRooms(e?.value)}
                      />
                    </RoomsSuitesBathroomsGarageContent>

                    <RoomsSuitesBathroomsGarageContent>
                      <label>
                        Suites{" "}
                        {!!formErrors.suites && <p>{formErrors.suites}</p>}
                      </label>
                      <Select
                        placeholder="Escolha um número"
                        value={{
                          label: String(suites === 5 ? "5+" : suites),
                          value: suites,
                        }}
                        options={numberOfGaragesSuitesOptions}
                        onChange={(e) => setSuites(e?.value)}
                      />
                    </RoomsSuitesBathroomsGarageContent>

                    <RoomsSuitesBathroomsGarageContent>
                      <label>
                        Banheiros{" "}
                        {!!formErrors.bathrooms && (
                          <p>{formErrors.bathrooms}</p>
                        )}
                      </label>
                      <Select
                        placeholder="Escolha um número"
                        value={{
                          label: String(bathrooms),
                          value: bathrooms,
                        }}
                        options={numberOfRoomsBathroomsOptions}
                        onChange={(e) => setBathrooms(e?.value)}
                      />
                    </RoomsSuitesBathroomsGarageContent>

                    <RoomsSuitesBathroomsGarageContent>
                      <label>
                        Vagas{" "}
                        {!!formErrors.garage && <p>{formErrors.garage}</p>}
                      </label>
                      <Select
                        placeholder="Escolha um número"
                        value={{
                          label: String(garage === 5 ? "5+" : garage),
                          value: garage,
                        }}
                        options={numberOfGaragesSuitesOptions}
                        onChange={(e) => setGarage(e?.value)}
                      />
                    </RoomsSuitesBathroomsGarageContent>
                  </RoomsSuitesBathroomsGarageContainer>

                  <RentIptuCondominiumValueAreaContainer>
                    <RentIptuCondominiumValueAreaContent>
                      <label>
                        Valor do Aluguel (R$) reais{" "}
                        {!!formErrors.rent_value && (
                          <p>{formErrors.rent_value}</p>
                        )}
                      </label>
                      <CurrencyInput
                        value={Number(rentValue)}
                        onValueChange={(e: number) => setRentValue(String(e))}
                      />
                      <p>Ex: R$ 1500,00</p>
                    </RentIptuCondominiumValueAreaContent>

                    <RentIptuCondominiumValueAreaContent>
                      <label>
                        Valor do Condomínio (R$) reais{" "}
                        {!!formErrors.condominium_value && (
                          <p>{formErrors.condominium_value}</p>
                        )}
                      </label>
                      <CurrencyInput
                        value={Number(condominiumValue)}
                        onValueChange={(e: number) =>
                          setCondominiumValue(String(e))
                        }
                      />
                      <p>Ex: R$ 1000,00</p>
                    </RentIptuCondominiumValueAreaContent>

                    <RentIptuCondominiumValueAreaContent>
                      <label>
                        Valor do IPTU (R$) reais{" "}
                        {!!formErrors.iptu_value && (
                          <p>{formErrors.iptu_value}</p>
                        )}
                      </label>
                      <CurrencyInput
                        value={Number(iptuValue)}
                        onValueChange={(e: number) => setIptuValue(String(e))}
                      />
                      <p>Ex: R$ 2500,00</p>
                    </RentIptuCondominiumValueAreaContent>

                    <RentIptuCondominiumValueAreaContent>
                      <label>
                        Taxa do lixo (R$) reais{" "}
                        {!!formErrors.garbage_tax && (
                          <p>{formErrors.garbage_tax}</p>
                        )}
                      </label>
                      <CurrencyInput
                        value={Number(garbageTax)}
                        onValueChange={(e: number) => setGarbageTax(String(e))}
                      />
                      <p>Ex: R$ 2500,00</p>
                    </RentIptuCondominiumValueAreaContent>

                    <RentIptuCondominiumValueAreaContent>
                      <label>
                        Área útil{" "}
                        {!!formErrors.area && <p>{formErrors.area}</p>}
                      </label>
                      <AreaInput
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                      />
                      <p>Em metros quadrados (m²)</p>
                    </RentIptuCondominiumValueAreaContent>
                  </RentIptuCondominiumValueAreaContainer>
                  <GuaranteesContainer>
                    <label>Garantias disponíveis:</label>
                    <div id="inputs-container">
                      {guarantees?.map((guarantee) => {
                        return (
                          <div id="input-container">
                            <input
                              id={guarantee.name}
                              type="checkbox"
                              onChange={onGuaranteeSelectedHandler}
                              value={guarantee.id}
                              checked={
                                propertyHasGuarantee(guarantee.id)
                                  ? true
                                  : false
                              }
                              key={guarantee.id}
                            />
                            <label
                              htmlFor={guarantee.name}
                              key={`lbl${guarantee.id}`}
                            >
                              {guarantee.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </GuaranteesContainer>
                  <AllowCatAndIsFunishedContainer>
                    <AllowCatAndIsFunishedContent>
                      <label>Mobiliado?</label>

                      <RadioContentToDescriptionForm>
                        <Radio
                          checked={!!isFunished}
                          name="selector-group30"
                          defaultChecked={!!isFunished}
                          onChange={() => setIsFunished(1)}
                        />
                        <p>Sim</p>

                        <Radio
                          checked={!isFunished}
                          name="selector-group30"
                          defaultChecked={!isFunished}
                          onChange={() => setIsFunished(0)}
                        />
                        <p>Não</p>
                      </RadioContentToDescriptionForm>
                    </AllowCatAndIsFunishedContent>

                    <AllowCatAndIsFunishedContent>
                      <label>Aceita Pets?</label>

                      <RadioContentToDescriptionForm>
                        <Radio
                          checked={!!allowPets}
                          name="selector-group31"
                          defaultChecked={!!allowPets}
                          onChange={() => setAllowPets(1)}
                        />
                        <p onClick={() => setAllowPets(1)}>Sim</p>

                        <Radio
                          checked={!allowPets}
                          name="selector-group31"
                          defaultChecked={!allowPets}
                          onChange={() => setAllowPets(0)}
                        />
                        <p onClick={() => setAllowPets(0)}>Não</p>
                      </RadioContentToDescriptionForm>
                    </AllowCatAndIsFunishedContent>

                    <AllowCatAndIsFunishedContent>
                      <label>Água inclusa</label>

                      <RadioContentToDescriptionForm>
                        <Radio
                          name="selector-group32"
                          checked={!!waterIncluded}
                          onChange={() => setWaterIncluded(1)}
                        />
                        <p onClick={() => setWaterIncluded(1)}>Sim</p>

                        <Radio
                          name="selector-group32"
                          checked={!waterIncluded}
                          onChange={() => setWaterIncluded(0)}
                        />
                        <p onClick={() => setWaterIncluded(0)}>Não</p>
                      </RadioContentToDescriptionForm>
                    </AllowCatAndIsFunishedContent>

                    <AllowCatAndIsFunishedContent>
                      <label>Gás incluso</label>

                      <RadioContentToDescriptionForm>
                        <Radio
                          name="selector-group33"
                          checked={!!gasIncluded}
                          onChange={() => setGasIncluded(1)}
                        />
                        <p onClick={() => setGasIncluded(1)}>Sim</p>

                        <Radio
                          name="selector-group33"
                          checked={!gasIncluded}
                          onChange={() => setGasIncluded(0)}
                        />
                        <p onClick={() => setGasIncluded(0)}>Não</p>
                      </RadioContentToDescriptionForm>
                    </AllowCatAndIsFunishedContent>
                    <AllowCatAndIsFunishedContent>
                      <label>Admin pela 7cantos</label>

                      <RadioContentToDescriptionForm>
                        <Radio
                          name="selector-group34"
                          checked={!!isAdm}
                          onChange={() => setIsAdm(1)}
                        />
                        <p onClick={() => setIsAdm(1)}>Sim</p>

                        <Radio
                          name="selector-group33"
                          checked={!isAdm}
                          onChange={() => setIsAdm(0)}
                        />
                        <p onClick={() => setIsAdm(0)}>Não</p>
                      </RadioContentToDescriptionForm>
                    </AllowCatAndIsFunishedContent>
                  </AllowCatAndIsFunishedContainer>
                  <p>Observação</p>
                  <textarea
                    maxLength={2000}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                  <ButtonsContainer>
                    <RegisterButton
                      type="button"
                      onClick={
                        !!loadingRequest
                          ? () => {}
                          : handleGoToCharacteristicForm
                      }
                    >
                      <p>{!!loadingRequest ? "Carregando..." : "Salvar"}</p>
                    </RegisterButton>
                    <PublishOnImovelWebButton
                      type="button"
                      onClick={handlePublishOnImovelWeb}
                    >
                      <p>Publicar no Imóvel Web</p>
                    </PublishOnImovelWebButton>
                    <PublishOnImovelWebButton
                      type="button"
                      onClick={handleShareLink}
                    >
                      <p>
                        {loadingSharedLink
                          ? "Gerando link..."
                          : "Gerar link compartilhável"}{" "}
                      </p>
                    </PublishOnImovelWebButton>
                    <NextButton
                      type="button"
                      onClick={() => {
                        setFormOptionSelected("map");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <FiArrowLeft size={18} color="#FFF" />
                      <p>Voltar</p>
                    </NextButton>
                    <NextButton
                      type="button"
                      onClick={() => {
                        setFormOptionSelected("characteristic");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <p>Próximo</p>
                      <FiArrowRight size={18} color="#FFF" />
                    </NextButton>
                  </ButtonsContainer>
                </DescriptionForm>
              )}

              {formOptionSelected === "characteristic" && (
                <CharacteristicFormContainer>
                  <CharacteristicContainer>
                    <CharacteristicContent>
                      <CharacteristicInitialContainer>
                        <h4>Acesso e área</h4>
                      </CharacteristicInitialContainer>

                      <CharacteristicRadiosContainer>
                        <RadioContainer>
                          <p>Perto de vias de acesso</p>

                          <RadioContent>
                            <Radio
                              name="selector-group3"
                              checked={!!isNearbyMajorRoads}
                              defaultChecked={!!isNearbyMajorRoads}
                              onChange={() => setIsNearbyMajorRoads(1)}
                            />
                            <p onClick={() => setIsNearbyMajorRoads(1)}>Sim</p>

                            <Radio
                              name="selector-group3"
                              checked={!isNearbyMajorRoads}
                              defaultChecked={!isNearbyMajorRoads}
                              onChange={() => setIsNearbyMajorRoads(0)}
                            />
                            <p onClick={() => setIsNearbyMajorRoads(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>Perto de shopping center</p>

                          <RadioContent>
                            <Radio
                              name="selector-group4"
                              checked={!!isNearbyMall}
                              defaultChecked={!!isNearbyMall}
                              onChange={() => setIsNearbyMall(1)}
                            />
                            <p onClick={() => setIsNearbyMall(1)}>Sim</p>

                            <Radio
                              name="selector-group4"
                              checked={!isNearbyMall}
                              defaultChecked={!isNearbyMall}
                              onChange={() => setIsNearbyMall(0)}
                            />
                            <p onClick={() => setIsNearbyMall(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>Perto de transporte público</p>

                          <RadioContent>
                            <Radio
                              name="selector-group5"
                              checked={!!isNearbyPublicTransport}
                              defaultChecked={!!isNearbyPublicTransport}
                              onChange={() => setIsNearbyPublicTransport(1)}
                            />
                            <p onClick={() => setIsNearbyPublicTransport(1)}>
                              Sim
                            </p>

                            <Radio
                              name="selector-group5"
                              checked={!isNearbyPublicTransport}
                              defaultChecked={!isNearbyPublicTransport}
                              onChange={() => setIsNearbyPublicTransport(0)}
                            />
                            <p onClick={() => setIsNearbyPublicTransport(0)}>
                              Não
                            </p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>Perto de Escolas</p>

                          <RadioContent>
                            <Radio
                              name="selector-group6"
                              checked={!!isNearbySchools}
                              defaultChecked={!!isNearbySchools}
                              onChange={() => setIsNearbySchools(1)}
                            />
                            <p onClick={() => setIsNearbySchools(1)}>Sim</p>

                            <Radio
                              name="selector-group6"
                              checked={!isNearbySchools}
                              defaultChecked={!isNearbySchools}
                              onChange={() => setIsNearbySchools(0)}
                            />
                            <p onClick={() => setIsNearbySchools(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>Perto de hospitais</p>

                          <RadioContent>
                            <Radio
                              name="selector-group7"
                              checked={!!isNearbyClinics}
                              defaultChecked={!!isNearbyClinics}
                              onChange={() => setIsNearbyClinics(1)}
                            />
                            <p onClick={() => setIsNearbyClinics(1)}>Sim</p>

                            <Radio
                              name="selector-group7"
                              checked={!isNearbyClinics}
                              defaultChecked={!isNearbyClinics}
                              onChange={() => setIsNearbyClinics(0)}
                            />
                            <p onClick={() => setIsNearbyClinics(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>
                      </CharacteristicRadiosContainer>
                    </CharacteristicContent>

                    <CharacteristicContent>
                      <CharacteristicInitialContainer>
                        <h4>Tem no imóvel</h4>
                      </CharacteristicInitialContainer>

                      <CharacteristicRadiosContainer>
                        <RadioContainer>
                          <p>Ar condicionado</p>

                          <RadioContent>
                            <Radio
                              name="selector-group8"
                              checked={!!hasAC}
                              defaultChecked={!!hasAC}
                              onChange={() => setHasAC(1)}
                            />
                            <p onClick={() => setHasAC(1)}>Sim</p>

                            <Radio
                              name="selector-group8"
                              checked={!hasAC}
                              defaultChecked={!hasAC}
                              onChange={() => setHasAC(0)}
                            />
                            <p onClick={() => setHasAC(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>Quintal</p>

                          <RadioContent>
                            <Radio
                              name="selector-group9"
                              checked={!!hasBackyard}
                              defaultChecked={!!hasBackyard}
                              onChange={() => setHasBackyard(1)}
                            />
                            <p onClick={() => setHasBackyard(1)}>Sim</p>

                            <Radio
                              name="selector-group9"
                              checked={!hasBackyard}
                              defaultChecked={!hasBackyard}
                              onChange={() => setHasBackyard(0)}
                            />
                            <p onClick={() => setHasBackyard(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>Varanda</p>

                          <RadioContent>
                            <Radio
                              name="selector-group10"
                              checked={!!hasPorch}
                              defaultChecked={!!hasPorch}
                              onChange={() => setHasPorch(1)}
                            />
                            <p onClick={() => setHasPorch(1)}>Sim</p>

                            <Radio
                              name="selector-group10"
                              checked={!hasPorch}
                              defaultChecked={!hasPorch}
                              onChange={() => setHasPorch(0)}
                            />
                            <p onClick={() => setHasPorch(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>Deposito</p>

                          <RadioContent>
                            <Radio
                              name="selector-group11"
                              checked={!!hasStorage}
                              defaultChecked={!!hasStorage}
                              onChange={() => setHasStorage(1)}
                            />
                            <p onClick={() => setHasStorage(1)}>Sim</p>

                            <Radio
                              name="selector-group11"
                              checked={!hasStorage}
                              defaultChecked={!hasStorage}
                              onChange={() => setHasStorage(0)}
                            />
                            <p onClick={() => setHasStorage(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>

                        <RadioContainer>
                          <p>TV a cabo</p>

                          <RadioContent>
                            <Radio
                              name="selector-group12"
                              checked={!!hasCableTV}
                              defaultChecked={!!hasCableTV}
                              onChange={() => setHasCableTV(1)}
                            />
                            <p onClick={() => setHasCableTV(1)}>Sim</p>

                            <Radio
                              name="selector-group12"
                              checked={!hasCableTV}
                              defaultChecked={!hasCableTV}
                              onChange={() => setHasCableTV(0)}
                            />
                            <p onClick={() => setHasCableTV(0)}>Não</p>
                          </RadioContent>
                        </RadioContainer>
                      </CharacteristicRadiosContainer>
                    </CharacteristicContent>
                  </CharacteristicContainer>

                  <BigCharacteristicContent>
                    <CharacteristicInitialContainer>
                      <h4>Diferenciais</h4>
                    </CharacteristicInitialContainer>

                    <CharacteristicRadiosContainer>
                      <RadioContainer>
                        <p>Churrasqueira</p>

                        <RadioContent>
                          <Radio
                            name="selector-group13"
                            checked={!!hasBBQ}
                            defaultChecked={!!hasBBQ}
                            onChange={() => setHasBBQ(1)}
                          />
                          <p onClick={() => setHasBBQ(1)}>Sim</p>

                          <Radio
                            name="selector-group13"
                            checked={!hasBBQ}
                            defaultChecked={!hasBBQ}
                            onChange={() => setHasBBQ(0)}
                          />
                          <p onClick={() => setHasBBQ(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Elevador</p>

                        <RadioContent>
                          <Radio
                            name="selector-group14"
                            checked={!!hasElevator}
                            defaultChecked={!!hasElevator}
                            onChange={() => setHasElevator(1)}
                          />
                          <p onClick={() => setHasElevator(1)}>Sim</p>

                          <Radio
                            name="selector-group14"
                            checked={!hasElevator}
                            defaultChecked={!hasElevator}
                            onChange={() => setHasElevator(0)}
                          />
                          <p onClick={() => setHasElevator(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Jardim</p>

                        <RadioContent>
                          <Radio
                            name="selector-group15"
                            checked={!!hasGarden}
                            defaultChecked={!!hasGarden}
                            onChange={() => setHasGarden(1)}
                          />
                          <p onClick={() => setHasGarden(1)}>Sim</p>

                          <Radio
                            name="selector-group15"
                            checked={!hasGarden}
                            defaultChecked={!hasGarden}
                            onChange={() => setHasGarden(0)}
                          />
                          <p onClick={() => setHasGarden(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Espaço Gourmet</p>

                        <RadioContent>
                          <Radio
                            name="selector-group16"
                            checked={!!hasGourmet}
                            defaultChecked={!!hasGourmet}
                            onChange={() => setHasGourmet(1)}
                          />
                          <p onClick={() => setHasGourmet(1)}>Sim</p>

                          <Radio
                            name="selector-group16"
                            checked={!hasGourmet}
                            defaultChecked={!hasGourmet}
                            onChange={() => setHasGourmet(0)}
                          />
                          <p onClick={() => setHasGourmet(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Academia</p>

                        <RadioContent>
                          <Radio
                            name="selector-group17"
                            checked={!!hasGym}
                            onChange={() => setHasGym(1)}
                          />
                          <p onClick={() => setHasGym(1)}>Sim</p>

                          <Radio
                            name="selector-group17"
                            checked={!hasGym}
                            defaultChecked={!hasGym}
                            onChange={() => setHasGym(0)}
                          />
                          <p onClick={() => setHasGym(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Salão de festas</p>

                        <RadioContent>
                          <Radio
                            name="selector-group18"
                            checked={!!hasParty}
                            defaultChecked={!!hasParty}
                            onChange={() => setHasParty(1)}
                          />
                          <p onClick={() => setHasParty(1)}>Sim</p>

                          <Radio
                            name="selector-group18"
                            checked={!hasParty}
                            defaultChecked={!hasParty}
                            onChange={() => setHasParty(0)}
                          />
                          <p onClick={() => setHasParty(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Playground</p>

                        <RadioContent>
                          <Radio
                            name="selector-group19"
                            checked={!!hasPlayground}
                            defaultChecked={!!hasPlayground}
                            onChange={() => setHasPlayground(1)}
                          />
                          <p onClick={() => setHasPlayground(1)}>Sim</p>

                          <Radio
                            name="selector-group19"
                            checked={!hasPlayground}
                            defaultChecked={!hasPlayground}
                            onChange={() => setHasPlayground(0)}
                          />
                          <p onClick={() => setHasPlayground(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Piscina</p>

                        <RadioContent>
                          <Radio
                            name="selector-group20"
                            checked={!!hasPool}
                            defaultChecked={!!hasPool}
                            onChange={() => setHasPool(1)}
                          />
                          <p onClick={() => setHasPool(1)}>Sim</p>

                          <Radio
                            name="selector-group20"
                            checked={!hasPool}
                            defaultChecked={!hasPool}
                            onChange={() => setHasPool(0)}
                          />
                          <p onClick={() => setHasPool(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Quadra poliesportiva</p>

                        <RadioContent>
                          <Radio
                            name="selector-group21"
                            checked={!!hasSports}
                            defaultChecked={!!hasSports}
                            onChange={() => setHasSports(1)}
                          />
                          <p onClick={() => setHasSports(1)}>Sim</p>

                          <Radio
                            name="selector-group21"
                            checked={!hasSports}
                            defaultChecked={!hasSports}
                            onChange={() => setHasSports(0)}
                          />
                          <p onClick={() => setHasSports(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Cinema em casa</p>

                        <RadioContent>
                          <Radio
                            name="selector-group22"
                            checked={!!hasHomeCinema}
                            defaultChecked={!!hasHomeCinema}
                            onChange={() => setHasHomeCinema(1)}
                          />
                          <p onClick={() => setHasHomeCinema(1)}>Sim</p>

                          <Radio
                            name="selector-group22"
                            checked={!hasHomeCinema}
                            defaultChecked={!hasHomeCinema}
                            onChange={() => setHasHomeCinema(0)}
                          />
                          <p onClick={() => setHasHomeCinema(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Quadra de tênis</p>

                        <RadioContent>
                          <Radio
                            name="selector-group23"
                            checked={!!hasTennisCourt}
                            defaultChecked={!!hasTennisCourt}
                            onChange={() => setHasTennisCourt(1)}
                          />
                          <p onClick={() => setHasTennisCourt(1)}>Sim</p>

                          <Radio
                            name="selector-group23"
                            checked={!hasTennisCourt}
                            defaultChecked={!hasTennisCourt}
                            onChange={() => setHasTennisCourt(0)}
                          />
                          <p onClick={() => setHasTennisCourt(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Sauna</p>

                        <RadioContent>
                          <Radio
                            name="selector-group24"
                            checked={!!hasSauna}
                            defaultChecked={!!hasSauna}
                            onChange={() => setHasSauna(1)}
                          />
                          <p onClick={() => setHasSauna(1)}>Sim</p>

                          <Radio
                            name="selector-group24"
                            checked={!hasSauna}
                            defaultChecked={!hasSauna}
                            onChange={() => setHasSauna(0)}
                          />
                          <p onClick={() => setHasSauna(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>SPA</p>

                        <RadioContent>
                          <Radio
                            name="selector-group25"
                            checked={!!hasSPA}
                            defaultChecked={!!hasSPA}
                            onChange={() => setHasSPA(1)}
                          />
                          <p onClick={() => setHasSPA(1)}>Sim</p>

                          <Radio
                            name="selector-group25"
                            checked={!hasSPA}
                            defaultChecked={!hasSPA}
                            onChange={() => setHasSPA(0)}
                          />
                          <p onClick={() => setHasSPA(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Vista-Mar</p>

                        <RadioContent>
                          <Radio
                            name="selector-group26"
                            checked={!!hasSeaView}
                            defaultChecked={!!hasSeaView}
                            onChange={() => setHasSeaView(1)}
                          />
                          <p onClick={() => setHasSeaView(1)}>Sim</p>

                          <Radio
                            name="selector-group26"
                            checked={!hasSeaView}
                            defaultChecked={!hasSeaView}
                            onChange={() => setHasSeaView(0)}
                          />
                          <p onClick={() => setHasSeaView(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Varanda gourmet</p>

                        <RadioContent>
                          <Radio
                            name="selector-group27"
                            checked={!!hasGourmetPorch}
                            defaultChecked={!!hasGourmetPorch}
                            onChange={() => setHasGourmetPorch(1)}
                          />
                          <p onClick={() => setHasGourmetPorch(1)}>Sim</p>

                          <Radio
                            name="selector-group27"
                            checked={!hasGourmetPorch}
                            defaultChecked={!hasGourmetPorch}
                            onChange={() => setHasGourmetPorch(0)}
                          />
                          <p onClick={() => setHasGourmetPorch(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Portaria 24 horas</p>

                        <RadioContent>
                          <Radio
                            name="selector-group28"
                            checked={!!has24hConcierge}
                            defaultChecked={!!has24hConcierge}
                            onChange={() => setHas24hConcierge(1)}
                          />
                          <p onClick={() => setHas24hConcierge(1)}>Sim</p>

                          <Radio
                            name="selector-group28"
                            checked={!has24hConcierge}
                            defaultChecked={!has24hConcierge}
                            onChange={() => setHas24hConcierge(0)}
                          />
                          <p onClick={() => setHas24hConcierge(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>

                      <RadioContainer>
                        <p>Salão de jogos</p>

                        <RadioContent>
                          <Radio
                            name="selector-group29"
                            checked={!!hasGameRoom}
                            defaultChecked={!!hasGameRoom}
                            onChange={() => setHasGameRoom(1)}
                          />
                          <p onClick={() => setHasGameRoom(1)}>Sim</p>

                          <Radio
                            name="selector-group29"
                            checked={!hasGameRoom}
                            defaultChecked={!hasGameRoom}
                            onChange={() => setHasGameRoom(0)}
                          />
                          <p onClick={() => setHasGameRoom(0)}>Não</p>
                        </RadioContent>
                      </RadioContainer>
                    </CharacteristicRadiosContainer>
                  </BigCharacteristicContent>

                  <ButtonsContainer>
                    <RegisterButton
                      type="button"
                      onClick={
                        !!loadingRequest ? () => {} : handleGoToPhotosForm
                      }
                    >
                      <p>{!!loadingRequest ? "Carregando..." : "Salvar"}</p>
                    </RegisterButton>
                    <NextButton
                      type="button"
                      onClick={() => {
                        setFormOptionSelected("description");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <FiArrowLeft size={18} color="#FFF" />
                      <p>Voltar</p>
                    </NextButton>
                    <NextButton
                      type="button"
                      onClick={() => {
                        setFormOptionSelected("photos");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <p>Próximo</p>
                      <FiArrowRight size={18} color="#FFF" />
                    </NextButton>
                  </ButtonsContainer>
                </CharacteristicFormContainer>
              )}

              {formOptionSelected === "photos" && (
                <PhotosForm>
                  <span>
                    * Você pode reorganizar as fotos arrastando para as novas
                    posições, depois clique em salvar
                  </span>
                  <br />
                  <span>
                    ** Após upload as fotos são salvas automaticamente
                  </span>

                  <DropZoneContainer {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!loadingPhotosRequest && (
                      <>
                        {!!isDragActive ? (
                          <p>Coloque a foto aqui...</p>
                        ) : (
                          <p>
                            <FiPaperclip size={24} color="#DD6435" /> ADICIONAR
                            FOTOS
                          </p>
                        )}
                      </>
                    )}

                    {!!loadingPhotosRequest && <p>Carregando...</p>}
                  </DropZoneContainer>

                  {!!state && (
                    <>
                      {(state.imagesWithError.length >= 1 ??
                        imageError.length >= 1) &&
                        imageError.map((image) => (
                          <p key={image.name}>
                            {!!image.name
                              ? `O arquivo ${image.name} não pode ser enviada, pois ${image.reason}`
                              : image.reason}
                          </p>
                        ))}

                      {state.imagesWithError.length >= 1 &&
                        state.imagesWithError.map((image) =>
                          imageError.length === 0 ? (
                            <p key={image.name}>
                              {!!image.name
                                ? `O arquivo ${image.name} não pode ser enviada, pois ${image.reason}`
                                : image.reason}
                            </p>
                          ) : (
                            <></>
                          )
                        )}
                    </>
                  )}

                  {!state && (
                    <>
                      {imageError.length >= 1 &&
                        imageError.map((image) => (
                          <p key={image.name}>
                            {!!image.name
                              ? `O arquivo ${image.name} não pode ser enviada, pois ${image.reason}`
                              : image.reason}
                          </p>
                        ))}
                    </>
                  )}

                  <PhotoContainer>
                    {photos
                      .sort((a, b) => a.order - b.order)
                      .map((photo) => (
                        <ImageBox
                          key={photo.id}
                          photo={photo}
                          boxNumber={photo.id}
                          isLoading={loadingRequestToRemovePhoto}
                          handleDrag={hadleDragImageBox}
                          handleDrop={handleDropImageBox}
                          handleRemovePhoto={() => handleRemovePhoto(photo.id)}
                        />
                      ))}
                  </PhotoContainer>

                  <ButtonsContainer>
                    <NextButton
                      type="button"
                      onClick={() => {
                        setFormOptionSelected("characteristic");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <FiArrowLeft size={18} color="#FFF" />
                      <p>Voltar</p>
                    </NextButton>

                    <RegisterButton
                      type="button"
                      onClick={!!loadingRequest ? () => {} : handleChangeOrders}
                    >
                      <p>{!!loadingRequest ? "Carregando..." : "REORDENAR"}</p>
                    </RegisterButton>
                  </ButtonsContainer>
                </PhotosForm>
              )}
            </EditPropertyContainer>
          </Content>
        )}
      </Layout>
    </Container>
  );
};

export default EditProperty;
