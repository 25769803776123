import styled from "styled-components"


export const Content = styled.div`
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 64px auto 10%;
    >h3{
        color: #666666;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 64px;
    }
`;

export const RadiosContainer = styled.div`
    padding: 24px;

    background: #F5F5F5;

    width: 40%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 32px;

    @media(max-width: 767px){
        padding: 16px;

        width: 80%;
    }
`;

export const RadioContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >p{
        margin-left: 4px;

        font-size: 15px;

        cursor: pointer;
    }

    @media(max-width: 767px){
        >p{
            font-size: 14px;
        }
    }
`;

export const CreateFAQForm = styled.div`
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    >label{
        color: #666666;
        font-weight: 500;
    }
    >p{
        margin-top: 96px;
        font-size: 12px;
    }
    >input{
        width: 100%;
        
        padding: 8px;
        border: 1px solid #B6B6B6;
        margin: 8px 0 24px;
    }
    .MuiInput-underline::before{
        border-bottom: 0;
    }
    .MuiInput-underline::after{
        border-bottom: 0;
    }
    .MuiFormControl-fullWidth{
        padding: 8px;
        border: 1px solid #B6B6B6;
        margin: 8px 0 24px;
    }
    .quill{
        width: 100%;
        height: 300px;
        margin-top: 8px;
    }

    .ql-editor{
        strong{
            font-weight: bold;
        }
    }

    @media(max-width: 767px){
        width: 80%;
    }
`;

export const CreateArticleForm = styled.div`
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    >label{
        color: #666666;
        font-weight: 500;
    }
    >p{
        margin-top: 96px;
        font-size: 12px;
    }
    >input{
        width: 100%;
        
        padding: 8px;
        border: 1px solid #B6B6B6;
        margin: 8px 0 24px;
    }
    .MuiInput-underline::before{
        border-bottom: 0;
    }
    .MuiInput-underline::after{
        border-bottom: 0;
    }
    .MuiFormControl-fullWidth{
        padding: 8px;
        border: 1px solid #B6B6B6;
        margin: 8px 0 24px;
    }
    .quill{
        width: 100%;
        height: 300px;
        margin-top: 8px;
    }

    .ql-editor{
        strong{
            font-weight: bold;
        }
    }

    @media(max-width: 767px){
        width: 80%;
    }
`;

export const CategoryLabelContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >label{
        color: #666666;
        font-weight: 500;
    }
    >button{
        color: #DD6435;
        font-size: 14px;
        font-weight: 500;
    }
`;

export const AnnexImageContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;

    >label{
        width: 35%;

        background: #6EA5FF;

        padding: 12px 0;

        cursor: pointer;

        >p{
            text-align: center;
            color: #FFF;

            font-weight: 600;
        }

        >input{
            display: none;
        }
    }

    >p{
        color: #919191;

        font-weight: 400;
        font-size: 14px;

        width: 63%;

        border: 1px solid #B6B6B6;

        padding: 12px 0 12px 16px;

        cursor: default;
    }
`;

export const TagsContainer = styled.form`
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 8px;
    border: 1px solid #B6B6B6;
    margin: 8px 0 24px;
    >input{
        border: 0;
        
        padding: 8px;
        margin-top: 8px;
    }
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    background: #6ea5ff;
    border-radius: 24px;
    margin-right: 8px;
    margin-top: 8px;
    >p{
        color: #FFF;
    }
    >button{
        margin-left: 8px;
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    >button{
        width: 40%;
        padding: 12px 0;
        font-weight: 500;
        color: #FFFFFF;
        background: #00BF33;
        border: 1px solid #00BF33;
        &:last-child{
            color: #666666;
            background: #FFFFFF;
            border: 1px solid #666666;
            margin-left: 8px;
        }
    }
`;