import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.response.use((response) => {
    if(response.data?.role === 'client'){
        localStorage.removeItem('@7Cantos:token');
        localStorage.removeItem('@7Cantos:user');
        localStorage.removeItem('@7Cantos:dateToExpires');
        localStorage.removeItem('@7Cantos:consultants');
    
        window.location.reload();
        window.location.href = `${window.location.origin}/`;
    };

    return response;
}, (err) => {
    if(!!err.response){
        if(err.response.status === 403){
            localStorage.removeItem('@7Cantos:token');
            localStorage.removeItem('@7Cantos:user');
            localStorage.removeItem('@7Cantos:dateToExpires');
            localStorage.removeItem('@7Cantos:consultants');
    
            window.location.reload();
            window.location.href = `${window.location.origin}/`;
        };
    }
    
    return Promise.reject(err);
});

export default api;