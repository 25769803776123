import React, { useState, useEffect, FormEvent } from 'react';

import Select from "react-select";

import Layout from '../../components/Layout';
import { Content, Form } from './styles';

import api from './../../services/api';
import refreshToken from "../../utils/refreshToken";

import { useAuth } from "../../hooks/auth";

interface IConsultants {
	id: number;
	first_name: string;
}
interface IConsultantsOptions {
	value?: number;
	label?: string;
}


export default function UpdateBulkConsultants() {

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const [currentConsultant, setCurrentConsultant] = useState<IConsultantsOptions>();
	const [newConsultant, setNewConsultant] = useState<IConsultantsOptions>();
	const [consultants, setConsultants] = useState<IConsultants[]>([]);
	const [consultantOptions, setConsultantOptions] = useState<IConsultantsOptions[]>([]);

	const { access_token, dateToExpires, user } = useAuth();

	useEffect(() => {
		loadAllConsultants();
	}, []);

	const loadAllConsultants = async () => {
		try {
			setLoading(true);
			const token = await refreshToken(dateToExpires, access_token);
			api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;
			const response = await api.get("/api/adm/users", {
				params: {
					role: "consultant",
				}
			});
			const consultants: IConsultants[] = response.data.data.consultants;
			setConsultants(consultants);

			setConsultantOptions(consultants.map((consultant) => ({
				label: consultant.first_name,
				value: consultant.id,
			  })));

			setLoading(false);
		} catch (e: any) {
			setLoading(false);
			setError("Ocorreu um erro ao carregar os consultores. Por favor, tente novamente!")
		}
	}

	const isValidForm = () => {
		setError("");
		setSuccess("");
		if (!currentConsultant || !newConsultant) {
			setError("Por favor, preencha os 2 campos obrigatórios!");
			return false;
		} else if (currentConsultant.value === newConsultant.value) {
			setError("Os consultores necessitam ser diferentes!");
			return false;
		} else {
			return true;
		}
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		
		if (!isValidForm()) return;

		setLoading(true);
		try {
			const data = {
				"original_consultant": currentConsultant?.value,
				"destination_consultant": newConsultant?.value
			};
			const response = await api.put('/api/adm/properties/change/consultants', data);

			if (response.status === 200) {
				setSuccess("Consultores alterados com sucesso!")
			} else {
				setError("Ocorreu um erro. Por favor, tente novamente!");
			}
			setLoading(false);
		} catch (err: any) {
			setError("Ocorreu um erro. Por favor, tente novamente!");
			setLoading(false);
		}
	};

	return (
		<Layout>
			<Content>
				<h2>ALTERAR CONSULTOR EM MASSA</h2>

				<p className='error'>{error}</p>
				<p className='success'>{success}</p>

				<Form onSubmit={handleSubmit}>
					<p>Consultor Atual*</p>
					<Select
                        value={currentConsultant}
                        options={consultantOptions}
                        placeholder={!!loading ? "Carregando" : ""}
                        noOptionsMessage={() =>
                          !!loading ? "Carregando" : ""
                        }
                        onChange={(e) => {
							setCurrentConsultant({ label: e?.label, value: e?.value})
                        }}
                      />

					<p>Novo Consultor*</p>
					<Select
                        value={newConsultant}
                        options={consultantOptions}
                        placeholder={!!loading ? "Carregando" : ""}
                        noOptionsMessage={() =>
                          !!loading ? "Carregando" : ""
                        }
                        onChange={(e) => {
							setNewConsultant({ label: e?.label, value: e?.value})
                        }}
                      />

					<button type="submit">{!!loading ? "Carregando" : "ATUALIZAR CONSULTORES"}</button>
				</Form>
			</Content>
		</Layout>
	);
};