import React, {useEffect} from 'react';

import { ToastMessage, useToast } from './../../../hooks/toast';

import { FiAlertCircle, FiXCircle, FiCheckCircle, FiInfo } from 'react-icons/fi';

import {Container} from './styles';

interface MessageProps{
	message: ToastMessage;
	style: object;
}

const icons = {
	info: <FiInfo size={24} />,
	success: <FiCheckCircle size={24} />,
	error: <FiAlertCircle size={24} />,
}

const Toast: React.FC<MessageProps> = ({message, style}) => {
	const { removeToast } = useToast();

	useEffect(() => {
		const timer = setTimeout(() => {
			removeToast(message.id);
		}, 3000);

		return () => {
			clearTimeout(timer);
		};
	}, [removeToast, message?.id] );

	return (
		<Container key={message.id} type={message.type} hasDescription={!!message.description} style={style}>
			{icons[message.type || 'info']}

				<div>
					<strong>{message.title}</strong>
					{message.description && (
						<p>{message.description}</p>
					)}
				</div>

				<button type="button" onClick={() => removeToast(message.id)}>
					<FiXCircle size={18} />
				</button>
		</Container>

		);
};

export default Toast;