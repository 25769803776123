import { Modal } from "react-responsive-modal";
import { FiX } from "react-icons/fi";

import {
  Content,
  TitleContainer,
  FormContainer,
  ButtonsContainer,
} from "./styles";
import { useForm } from "react-hook-form";
import MaskedInput from "react-text-mask";

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useCallback, useState } from "react";
import { useToast } from "../../hooks/toast";

import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationError";
import { useRefresh } from "../../hooks/refresh";
import { useAuth } from "../../hooks/auth";
import refreshToken from "../../utils/refreshToken";
import api from "../../services/api";

interface IModalPhotographerSchedule {
  open: boolean;
  dateToSchedule: string[];
  functionToCloseModalSchedule(): void;
}

interface IFormData {
  owner_name: string;
  keys_location: string;
  property_unity: string;
}

export default function ModalPhotographerSchedule(
  props: IModalPhotographerSchedule
) {
  const { register, handleSubmit } = useForm();

  const { addToast } = useToast();
  const { handleRefresh } = useRefresh();
  const { access_token, dateToExpires } = useAuth();

  const [googleMapsSearch, setGoogleMapsSearch] = useState("");

  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUF] = useState("");
  const [googleID, setGoogleID] = useState("");

  const [loadingRequest, setLoadingRequest] = useState(false);

  const hadleGetMoreInformationsAboutLocal = useCallback(
    async (address: string, place_id: string) => {
      try {
        const request = await geocodeByPlaceId(place_id);

        request[0].address_components.forEach((address) => {
          if (address.types.join().includes("route")) {
            setAddress(address.long_name);

            return;
          }

          if (address.types.join().includes("administrative_area_level_2")) {
            setCity(address.long_name);

            return;
          }

          if (address.types.join().includes("sublocality_level_1")) {
            setNeighborhood(address.long_name);

            return;
          }

          if (address.types.join().includes("administrative_area_level_1")) {
            setUF(address.short_name);

            return;
          }

          if (address.types.join().includes("street_number")) {
            setNumber(address.short_name);

            return;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const handleSchedule = useCallback(
    async (data: IFormData) => {
      setLoadingRequest(true);

      const dataToRequest = !!complement
        ? {
            date: `${props.dateToSchedule[0]} ${props.dateToSchedule[1]}`,
            photographer_id: props.dateToSchedule[2],
            property_address: address,
            property_number: number,
            property_city: city,
            property_uf: uf,
            property_neighborhood: neighborhood,
            property_complement: complement,
            owner_phone: phone,
            ...data,
            property_unity: data.property_unity
              ? data.property_unity
              : undefined,
          }
        : {
            date: `${props.dateToSchedule[0]} ${props.dateToSchedule[1]}`,
            photographer_id: props.dateToSchedule[2],
            property_address: address,
            property_number: number,
            property_city: city,
            property_uf: uf,
            property_neighborhood: neighborhood,
            owner_phone: phone,
            ...data,
            property_unity: data.property_unity
              ? data.property_unity
              : undefined,
          };

      try {
        // eslint-disable-next-line
        const phoneRegex = /\(?\d{2,}\)?[ -]?\d{1}?[ -]?\d{4}[\-\s]?\d{4}/g;

        const schema = Yup.object().shape({
          date: Yup.string().required("Campo obrigatório!"),
          photographer_id: Yup.string().required("Campo obrigatório!"),
          property_address: Yup.string().required("Campo obrigatório!"),
          property_number: Yup.string().required("Campo obrigatório!"),
          property_city: Yup.string().required("Campo obrigatório!"),
          property_uf: Yup.string().required("Campo obrigatório!"),
          property_neighborhood: Yup.string().required("Campo obrigatório!"),
          owner_name: Yup.string().required("Campo obrigatório!"),
          owner_phone: Yup.string()
            .required("Campo obrigatório!")
            .matches(phoneRegex, "Número de telefone inválido!"),
          keys_location: Yup.string().required("Campo obrigatório!"),
        });

        await schema.validate(dataToRequest, {
          abortEarly: false,
        });

        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.post(
          "/api/adm/photographer-schedule",
          dataToRequest
        );

        if (!!response.data.success) {
          addToast({
            title: "Agendamento concluído",
            type: "success",
            description: "",
          });

          handleRefresh();
          props.functionToCloseModalSchedule();
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          addToast({
            title: errors[Object.keys(errors)[0]],
            type: "info",
            description: "",
          });
        } else if (!!err.response.data.errors) {
          const errorsNumber = Object.keys(err.response.data.errors).length;

          for (let i = 0; i < errorsNumber; i++) {
            err.response.data.errors[
              Object.keys(err.response.data.errors)[i]
            ].forEach((error: string) => {
              addToast({
                title: error,
                type: "error",
              });
            });
          }
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        console.log(err);
      }

      setLoadingRequest(false);
    },
    [
      props,
      address,
      city,
      number,
      uf,
      complement,
      neighborhood,
      access_token,
      dateToExpires,
      phone,
      addToast,
      handleRefresh,
    ]
  );

  return (
    <Modal
      open={props.open}
      onClose={props.functionToCloseModalSchedule}
      styles={{
        modal: {
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100vh",
          overflow: "auto",
          margin: "auto",
          maxWidth: "600px",
        },
      }}
      classNames={{
        modal: "modalSchedule",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h3>Agendamento</h3>
        </TitleContainer>

        <hr />

        {/* <FormContainer onSubmit={handleText}>
                        <p>E-mail:</p>
                        <input 
                            value={!!loadingUsers ? 'Carregando...' : email}
                            onChange={e => setEmail(e.target.value)}
                        />

                        {email !== '' && users.length >= 1 ? (
                            <OutsideClickHandler onOutsideClick={() => {
                                setEmail('');
                                setUsers([]);
                            }}>
                                <div id="users">
                                    {users.map(user => (
                                        <button type="button" onClick={() => handleSelectUser(user.id)}>{user.first_name}</button>
                                    ))}
                                </div>
                            </OutsideClickHandler>
                        ) : (<></>)}
                        <button type="submit" style={{width: '0px', height: '0px'}}/>
                    </FormContainer> */}

        <FormContainer onSubmit={handleSubmit(handleSchedule)}>
          <p>Nome do Proprietário:</p>
          {/* <Autocomplete
                        id="combo-box-demo"
                        options={clientOptions}
                        getOptionLabel={(option) => option.label}
                        style={{ width: '100%' }}
                        noOptionsText="Sem opções"
                        onChange={(e, value) => handleSelectUser(value?.value)}
                        renderInput={(params) => <TextField {...params} label="" margin="normal" />}
                    /> */}
          <input name="owner_name" ref={register} />

          <p>Telefone do Proprietário:</p>
          <MaskedInput
            mask={[
              "(",
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <p>Endereço:</p>
          <GooglePlacesAutocomplete
            onLoadFailed={(err) => console.log(err)}
            autocompletionRequest={{
              componentRestrictions: { country: "br" },
              types: ["address"],
              location: {
                lat: -17.221666,
                lng: -46.875,
              },
              bounds: [
                {
                  lat: -17.221666,
                  lng: -46.875,
                },
                {
                  lat: -17.221666,
                  lng: -46.875,
                },
              ],
            }}
            selectProps={{
              inputValue: googleMapsSearch,
              loadingMessage(obj: { inputValue: string }) {
                return "Carregando...";
              },
              onInputChange(e) {
                setGoogleMapsSearch(e);
              },
              onChange(e) {
                hadleGetMoreInformationsAboutLocal(e?.label, e?.value.place_id);

                setGoogleMapsSearch(e?.label);
                setGoogleID(!!e?.value.place_id ? e?.value.place_id : googleID);
              },
              noOptionsMessage(obj: { inputValue: string }) {
                return "Sem local";
              },
              placeholder: "",
            }}
          />

          <p>Bairro:</p>
          <input
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
          />

          <p>Número:</p>
          <input value={number} onChange={(e) => setNumber(e.target.value)} />

          <p>Cidade:</p>
          <input value={city} onChange={(e) => setCity(e.target.value)} />

          <p>UF:</p>
          <input value={uf} onChange={(e) => setUF(e.target.value)} />

          <p>Complemento:</p>
          <input
            value={complement}
            onChange={(e) => setComplement(e.target.value)}
          />

          <p>Local das chaves:</p>
          <input name="keys_location" ref={register} />

          <p>Unidade:</p>
          <input name="property_unity" ref={register} />

          <hr />

          <ButtonsContainer>
            <button type={!!loadingRequest ? "button" : "submit"}>
              {!!loadingRequest ? "Carregando..." : "Agendar"}
            </button>
          </ButtonsContainer>
        </FormContainer>
      </Content>
    </Modal>
  );
}
