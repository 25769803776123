import React from 'react';

import { AuthProvider } from './auth';
import { ConsultantProvider } from './consultant';
import { RefreshProvider } from './refresh';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({children}) => {
	return(
		<AuthProvider>
			<ConsultantProvider>
				<ToastProvider>
					<RefreshProvider>
						{children}
					</RefreshProvider>
				</ToastProvider>
			</ConsultantProvider>
		</AuthProvider>
	);
};

export default AppProvider;