import { useEffect, useState } from "react";
import api from "../services/api";
import { useAuth } from "./auth";
import { useToast } from "./toast";
import refreshToken from "../utils/refreshToken";
import { Property } from "../global/types";

interface IUsers {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}

interface IConsultant {
  first_name: string;
  phone: string;
}

interface ISchedule {
  date: string;
}

interface IModalConsultData {
  client: IUsers;
  owner: IUsers;
  consultant: IConsultant;
  property: Property;
  schedule: ISchedule;
  video_call: number;
  schedule_id: string;
  properties: IScheduleProperty[];
}

interface IScheduleProperty {
  id: number;
  title: string;
}

const useModalFetch = (url: string) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const [fetchedData, setFetchedData] = useState({} as IModalConsultData);

  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const fetchedSchedule = await api.get(url);

        setFetchedData(fetchedSchedule.data.data);
      } catch (err: any) {
        console.log(err);
        setError(true);
        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
      setIsFetching(false);
    }
    fetchData();
  }, [access_token, addToast, dateToExpires, url]);

  return {
    isFetching,
    fetchedData,
    error,
  };
};

export default useModalFetch;
