import styled from "styled-components";

export const Content = styled.div`
    max-width: 50%;

    margin: 4% auto 10%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    .dropZone{
        width: 100%;

        margin: 64px 0;
    }

    >h1{
        font-weight: 700;
    }

    >h3{
        width: 60%;

        text-align: center;

        color: #6EA5FF;

        font-weight: 700;

        margin-top: 64px;
    }

    >p{
        color: #FF3838;

        margin-bottom: 16px;
    }
`;

export const PhotoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    border-bottom: 1px solid #B6B6B6;

    padding: 0 8px 8px;

    & + &{
        margin-top: 16px;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 35%;

    margin: 48px auto 0;
`;

export const ResetButton = styled.button`
    border: 2px solid #666666;

    width: 45%;

    padding: 8px 0;

    color: #666666;

    font-size: 18px;
    font-weight: 500;

    background: transparent;
`;

export const SaveButton = styled.button`
    border: 2px solid #00BF33;

    width: 45%;

    padding: 8px 0;

    color: #FFF;

    font-size: 18px;
    font-weight: 500;

    background: #00BF33;
`;