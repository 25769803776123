import { Radio } from "@material-ui/core";
import { RadioLabelWrapper } from "./styles";
import { ChangeEvent } from "react";

interface IProps {
  name: string;
  label: string;
  checked: boolean;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onClick: () => void;
}

function DescriptionRadio(props: IProps) {
  return (
    <RadioLabelWrapper>
      <Radio {...props} />
      <label onClick={props.onClick}>{props.label}</label>
    </RadioLabelWrapper>
  );
}

export default DescriptionRadio;
