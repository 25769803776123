import styled from "styled-components";

export const Container = styled.div`
    background-color: #f5f5f5;
    padding: 2rem 10rem;
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    background-color: #FFFFFF;
    border-radius: 1.5rem;

    >h2 {
        font-size: 2rem;
        font-weight: 700;
        color: #606060;
    }

    > p{
        font-size: 1rem;
        font-weight: 400;
        margin-top: 1em;
        color: #606060;
    }
`;

export const ButtonsContainer = styled.div`
    
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    margin-top: 5rem;

    > button {
        min-width: 30%; 
        border-radius: 2rem;
        font-size: 1rem;
        padding: 0.75em 0;
        color: #606060;
        border: 2px solid #B6B6B6;
        font-weight: 600;
        > span {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        >span.styled-btn {
            gap: 2%;
        }
    }

    >#forward-btn {
        background-color: #6EA6FF;
        font-weight: 700;
        color: #FFF;
        border: none;
    }

    >#last-btn:hover {
        color: #6da7ff;
        border: 2px solid #6da7ff;
        font-weight: 600;
        transition: 0.15s linear;
    }
    >#disable-btn {
        cursor: not-allowed;
    }
`;
