import React, { DragEvent, useState } from "react";
import {
  Wrapper,
  DropZoneWrapper,
  PhotoWrapper,
  RegisterButton,
} from "./styles";
import { ButtonsWrapper } from "../../styles";
import { FiPaperclip } from "react-icons/fi";
import FormOption from "../../Enum";
import ImageBox from "../../../../components/ImageBox/";

import { useAuth } from "../../../../hooks/auth";

import api from "../../../../services/api";

import refreshToken from "../../../../utils/refreshToken";

import { useDropzone } from "react-dropzone";

import { useHistory } from "react-router-dom";
import loadFile from "../../../../utils/loadFile";
import { useToast } from "../../../../hooks/toast";
import asyncForEach from "../../../../utils/asyncForEach";

interface IPhotos {
  id: number;
  order?: number;
  url_l: string;
  url_m: string;
  url_s: string;
}

interface IImageError {
  name: string;
  reason: string;
}

interface IProps {
  propertyID?: number;
}

function PhotosForm({ propertyID }: IProps) {
  const history = useHistory();

  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();

  const [loadingPhotosRequest, setLoadingPhotosRequest] = useState(false);

  const [photos, setPhotos] = useState<IPhotos[]>([]);
  const [dragId, setDragId] = useState<string>();

  const [imageError, setImageError] = useState<IImageError[]>([]);

  const formattedPhotos = photos.map((photo, index) => ({
    id: photo.id,
    order: index,
    url_l: photo.url_l,
    url_m: photo.url_m,
    url_s: photo.url_s,
  }));

  async function handleOnDrop(acceptedFiles: File[]) {
    let imagesWithError: IImageError[] = [];

    setLoadingPhotosRequest(true);

    if (
      acceptedFiles.length > 28 ||
      formattedPhotos.length === 28 ||
      formattedPhotos.length + acceptedFiles.length > 28
    ) {
      setImageError([{ name: "", reason: "O número máximo de fotos é 28" }]);
      setLoadingPhotosRequest(false);
      return;
    }

    const checkNames = acceptedFiles.find((acceptedFile) =>
      isNaN(
        Number(
          acceptedFile.name
            .toLocaleLowerCase()
            .replaceAll(".jpg", "")
            .replaceAll(".png", "")
        )
      )
    );

    if (!!checkNames) {
      setImageError([
        { name: "", reason: "O nome dos arquivos deve ser um número" },
      ]);

      return;
    }

    const sort = acceptedFiles.sort(
      (a, b) =>
        Number(
          a.name
            .toLocaleLowerCase()
            .replaceAll(".jpg", "")
            .replaceAll(".png", "")
        ) -
        Number(
          b.name
            .toLocaleLowerCase()
            .replaceAll(".jpg", "")
            .replaceAll(".png", "")
        )
    );

    await asyncForEach(sort, async (file, index) => {
      try {
        if (file.size > 4000000) {
          imagesWithError.push({
            name: file.name,
            reason: "é maior que 4mb",
          });

          return;
        }

        if (file.type !== "image/png" && file.type !== "image/jpeg") {
          imagesWithError.push({
            name: file.name,
            reason: "não e uma imagem png ou jpg",
          });

          return;
        }

        const imageBase64 = await loadFile(file);

        if (imageBase64.includes("data:image/jpeg;base64,")) {
          const imageBase64Formated = imageBase64.replaceAll(
            "data:image/jpeg;base64,",
            ""
          );

          const token = await refreshToken(dateToExpires, access_token);

          api.defaults.headers.authorization = `Bearer ${
            !!token ? token : access_token
          }`;

          const response = await api.post(
            `/api/adm/properties/${propertyID}/photos`,
            {
              order: index + 1,
              photo: imageBase64Formated,
            }
          );

          if (response.data.success) {
            setPhotos((prevValue) => [
              ...prevValue,
              {
                ...response.data.data,
                url_l: response.data.data.photo,
                url_m: response.data.data.photo,
                url_s: response.data.data.photo,
              },
            ]);

            if (index + 1 === acceptedFiles.length - imagesWithError.length) {
              setLoadingPhotosRequest(false);
              history.push({
                pathname: `/property/${propertyID}`,
                state: { isToShowPhotos: FormOption.Photos, imagesWithError },
              });

              addToast({
                title: "Imagens adicionadas!",
                type: "success",
              });
            }
          }

          return;
        } else if (imageBase64.includes("data:image/png;base64,")) {
          const imageBase64Formated = imageBase64.replaceAll(
            "data:image/png;base64,",
            ""
          );

          const token = await refreshToken(dateToExpires, access_token);

          api.defaults.headers.authorization = `Bearer ${
            !!token ? token : access_token
          }`;

          const response = await api.post(
            `/api/adm/properties/${propertyID}/photos`,
            {
              order: index + 1,
              photo: imageBase64Formated,
            }
          );

          if (response.data.success) {
            setPhotos((prevValue) => [
              ...prevValue,
              {
                ...response.data.data,
                url_l: response.data.data.photo,
                url_m: response.data.data.photo,
                url_s: response.data.data.photo,
              },
            ]);

            if (index + 1 === acceptedFiles.length - imagesWithError.length) {
              setLoadingPhotosRequest(false);
              history.push({
                pathname: `/property/${propertyID}`,
                state: { isToShowPhotos: FormOption.Photos, imagesWithError },
              });

              addToast({
                title: "Imagens adicionadas!",
                type: "success",
              });
            }
          }

          return;
        }
      } catch (err) {
        if (
          !!err.response.data.errors[
            Object.keys(err.response.data.errors)[0]
          ][0]
        ) {
          const errorsNumber = Object.keys(err.response.data.errors).length;

          for (let i = 0; i < errorsNumber; i++) {
            err.response.data.errors[
              Object.keys(err.response.data.errors)[i]
            ].forEach((error: string) => {
              addToast({
                title: error,
                type: "error",
              });
            });
          }
        }

        setLoadingPhotosRequest(false);
        console.log(err);
      }
    });

    setImageError(imagesWithError);
  }
  function hadleDragImageBox(ev: DragEvent<HTMLDivElement>) {
    setDragId(ev.currentTarget.id);
  }

  function handleDropImageBox(ev: DragEvent<HTMLDivElement>) {
    const dragPhoto = formattedPhotos.find(
      (photo) => String(photo.id) === dragId
    );
    const dropPhoto = formattedPhotos.find(
      (photo) => String(photo.id) === ev.currentTarget?.id
    );

    if (!!dragPhoto && !!dropPhoto) {
      const dragPhotoOrder = dragPhoto.order;
      const dropPhotoOrder = dropPhoto.order;

      const newPhotosState = formattedPhotos.map((photo) => {
        if (String(photo.id) === dragId) {
          photo.order = dropPhotoOrder;
        }
        if (String(photo.id) === ev.currentTarget.id) {
          photo.order = dragPhotoOrder;
        }
        return photo;
      });

      const orderPhotosState = newPhotosState.sort((a, b) => a.order - b.order);

      setPhotos(orderPhotosState);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (e) => handleOnDrop(e),
  });

  return (
    <Wrapper>
      <span>* Após upload as fotos são salvas automaticamente</span>

      <DropZoneWrapper {...getRootProps()}>
        <input {...getInputProps()} />

        {!loadingPhotosRequest && (
          <>
            {!!isDragActive ? (
              <p>Coloque a foto aqui...</p>
            ) : (
              <p>
                <FiPaperclip size={24} color="#DD6435" /> ADICIONAR FOTOS
              </p>
            )}
          </>
        )}

        {!!loadingPhotosRequest && <p>Carregando...</p>}
      </DropZoneWrapper>

      {imageError.length >= 1 &&
        imageError.map((image) => (
          <p key={image.name}>
            {!!image.name
              ? `O arquivo ${image.name} não pode ser enviada, pois ${image.reason}`
              : image.reason}
          </p>
        ))}

      <PhotoWrapper>
        {photos.map((photo) => (
          <ImageBox
            key={photo.id}
            photo={photo}
            boxNumber={photo.id}
            handleDrag={hadleDragImageBox}
            handleDrop={handleDropImageBox}
            handleRemovePhoto={() => {}}
          />
        ))}
      </PhotoWrapper>

      <ButtonsWrapper>
        <RegisterButton
          type="button"
          onClick={() => history.push(`/property/${propertyID}`)}
        >
          <p>CADASTRAR</p>
        </RegisterButton>
      </ButtonsWrapper>
    </Wrapper>
  );
}

export default PhotosForm;
