import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { useHistory } from 'react-router-dom';

import Layout from './../../components/Layout/';
import Loading from './../../components/Loading';

import api from './../../services/api';

import refreshToken from './../../utils/refreshToken';

import { useAuth } from './../../hooks/auth';
import { useToast } from './../../hooks/toast';

import {Container, 
        Content,
        ErrorMessageContainer,
        OptionToScheduleOrLock,
        OptionToLockOrConsult,
        SchedulesContainer,
        SchedulesContent,
        TDSchedules} from './styles';
import OutsideClickHandler from 'react-outside-click-handler';
import { useRefresh } from '../../hooks/refresh';
import ModalPhotographerSchedule from '../../components/ModalPhotographerSchedule';
import ModalPhotographScheduleConsult from '../../components/ModalPhotographScheduleConsult';
import ModalDemarcatePhotoghapherSchedule from '../../components/ModalDemarcatePhotoghapherSchedule';

interface IConsultants{
    id: number;
    first_name: string;
}

interface ISchedules{
    id: number;
    photographer_id: number;
    date: string;
    status: number;
}

interface IDateSelected{
    id: string;
    schedule_id: string;
}

interface IDataToShowInModalConsult{
    id: number;
    owner_name: string;
    keys_location: string;
    date: string;
    photographer_id: string;
    property_address: string;
    property_number: string;
    property_city: string;
    property_uf: string;
    property_neighborhood: string;
    property_complement: string;
    property_unity: string | null;
    owner_phone: string;
}

export default function PhotographerSchedule(){
    const history = useHistory();

    const { access_token, user, dateToExpires } = useAuth();
    const { addToast } = useToast();
    const { refresh } = useRefresh();

    const [businessHours, setBusinessHours] = useState<string[]>([]);
    const [photographers, setPhotographers] = useState<IConsultants[]>([]);
    const [dates, setDates] = useState<Date[]>([]);
    const [schedules, setSchedules] = useState<ISchedules[]>([]);

    const [dateSelected, setDateSelected] = useState<IDateSelected[]>([]);

    const [initialStatus, setInitialStatus] = useState<number>();
    const [schedule, setSchedule] = useState('');
    const [scheduleID, setScheduleID] = useState<number>();

    const [mouseIsDown, setMouseIsDown] = useState(false);

    const [localToOptionX, setLocalToOptionX] = useState<number>();
    const [localToOptionY, setLocalToOptionY] = useState<number>();

    const [openOption, setOpenOption] = useState(false);

    const [openModalSchedule, setOpenModalSchedule] = useState(false);

    const [loadingSchedules, setLoadingSchedules] = useState(true);

    const [loadApiError, setLoadApiError] = useState('');

    const [dataToShowInModalConsult, setDataToShowInModalConsult] = useState({} as IDataToShowInModalConsult);
    const [openModalConsult, setOpenModalConsult] = useState(false);

    const [loadingModalConsult, setLoadingModalConsult] = useState(false);
    const [loadingModalSchedule, setLoadingModalSchedule] = useState(false);

    const [openModalToDemarcateSchedule, setOpenModalToDemarcateSchedule] = useState(false);

    useEffect(() => {
        async function loadApi(){
            try{
                api.defaults.headers.authorization = `Bearer ${access_token}`;

                const response = await api.get('/api/adm/photographer-schedule');

                const formatDates = response.data.data.dates.map((date: string) => {
                    const formatDate = new Date(Number(date.substring(6, 10)), Number(date.substring(3, 5)) - 1, Number(date.substring(0, 2)));

                    return(formatDate);
                });

                setBusinessHours(response.data.data.business_hours);
                setPhotographers(Array.isArray(response.data.data.photographers) ? response.data.data.photographers : [response.data.data.photographers]);
                setDates(formatDates);
                setSchedules(response.data.data.schedules);

                setLoadingSchedules(false);
            } catch(err){
                if(err.response.status === 403){
                    setLoadApiError(err.response.data.message);

                    setLoadingSchedules(true);
                }

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }

                setLoadApiError(err.response.data.message);

                setLoadingSchedules(true);
            }
        }

        if(!!user){
            loadApi();
        } else{
            history.push('/')
        }

        // eslint-disable-next-line
    }, [dateToExpires, access_token, user, history, refresh]);

    const formatData = useMemo(() => {
        const buildData = photographers.map(photographer => {
            const formatDates = dates.map((date) => {
                const finalData = businessHours.map(hour => {
                    const findSchedules = schedules.find(schedule => (schedule.date === `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${hour}:00` && schedule.photographer_id === photographer.id))

                    return (!!findSchedules ? {
                        date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}|${hour}:00|${photographer.id}`,
                        status: findSchedules.status,
                        schedule_id: !!findSchedules ? findSchedules.id : 0,
                        photographer_id: photographer.id,
                        id: uuidv4(),
                    } : {
                        date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}|${hour}:00|${photographer.id}`,
                        status: 0,
                        photographer_id: photographer.id,
                        id: uuidv4(),
                    });
                });

                return finalData;
            });

            return ({
                photographer_id: photographer.id,
                photographer_name: photographer.first_name,
                dates: formatDates
            })
        });

        return buildData;
    }, [dates, photographers, businessHours, schedules]);

    const selectDate = useCallback((id: string, status: number, event: React.MouseEvent) => {
        setLocalToOptionX(event.pageX+10);
        setLocalToOptionY(event.pageY+10);

        const findData = formatData.find((data) => {
            return data.dates.find((date) => {
                return date.find(dateFinded => dateFinded.id === id);
            })
        });

        const checkID = findData?.dates.find(date => {
            return date.find(dateFinded => dateFinded.id === id);
        });

        const checkData = checkID?.find(data => (data.id === id));

        if(!!checkData && status === initialStatus){
            const checkIfAlreadyIsSelect = dateSelected.find(idFinded => idFinded.id === id);

            if(!!checkIfAlreadyIsSelect){
                const removeId = dateSelected.filter(idFinded => idFinded.id !== id);

                return setDateSelected(removeId);
            }

            const objectToSave = {
                id: checkData.id,
                schedule_id: checkData.date
            }

            setDateSelected([...dateSelected, objectToSave]);
        }

        if(!!checkData && status !== initialStatus){
            setDateSelected([]);

            const objectToSave = {
                id: checkData.id,
                schedule_id: checkData.date
            }

            setDateSelected([objectToSave]);
        }

        setOpenOption(true);
    }, [formatData, dateSelected, initialStatus]);

    const selectDateMouseOver = useCallback((id: string, status: number, event: React.MouseEvent) => {
        if(!!mouseIsDown && status === initialStatus){
            setLocalToOptionX(event.pageX+10);
            setLocalToOptionY(event.pageY+10);

            if(!!openOption){
                setOpenOption(false);
            }

            const findData = formatData.find((data) => {
                return data.dates.find((date) => {
                    return date.find(dateFinded => dateFinded.id === id);
                })
            });
    
            const checkID = findData?.dates.find(date => {
                return date.find(dateFinded => dateFinded.id === id);
            });
    
            const checkData = checkID?.find(data => (data.id === id));
    
            if(!!checkData){
                const checkIfAlreadyIsSelect = dateSelected.find(idFinded => idFinded.id === id);
                
                if(!!checkIfAlreadyIsSelect){
                    const removeId = dateSelected.filter(idFinded => idFinded.id !== id);
    
                    return setDateSelected(removeId);
                }

                const objectToSave = {
                    id: checkData.id,
                    schedule_id: checkData.date
                }
    
                setDateSelected([...dateSelected, objectToSave]);
            }
        }
    }, [mouseIsDown, formatData, dateSelected, openOption, initialStatus]);

    const handleOpenModalConsult = useCallback(async() => {
        setLoadingModalConsult(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.get(`/api/adm/photographer-schedule/${scheduleID}`);

            setDataToShowInModalConsult(response.data.data);

            if(!!response.data.success && !!response.data.data){
                setOpenModalConsult(true);
            }

            setLoadingModalConsult(false);
        } catch(err){
            console.log(err);

            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }

            setLoadingModalConsult(false);
        }
    }, [scheduleID, access_token, dateToExpires, addToast]);

    const handleOpenModalSchedule = useCallback(async() => {
        setLoadingModalSchedule(true);

        try{
           /*  const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.get(`/api/adm/schedule/panel`);

            setDataToShowInModalSchedule(response.data.data);

            if(!!response.data.success && !!response.data.data){
                setOpenModalSchedule(true);
            } */

            setOpenModalSchedule(true);
        } catch(err){
            console.log(err);

            if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }
        }

        setLoadingModalSchedule(false);
    }, [addToast]);

    /* const handleCheckWhereMouseClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.log({x: event.clientX, y: event.clientY});
        //console.log(window.innerWidth);
        console.log(window.innerHeight);

        if(!!openOption && !mouseIsDown && event.clientX <= window.innerWidth * 0.17 || !!openOption && !mouseIsDown && event.clientX >= window.innerWidth * 0.82){
            setDateSelected([]);
        }

        else if(!!openOption && !mouseIsDown && event.clientY <= window.innerHeight * 0.22){
            setDateSelected([]);
        }
    }, [openOption, mouseIsDown, window.innerWidth]); */

    return(
        <>
        {!!loadingSchedules ? (<Loading />) : (
            <Container onMouseUp={() => {
                setMouseIsDown(false);
                setOpenOption(true);
                }}  
            >
            <Layout>
                
                    <Content>
                        <>
                        {!!loadApiError && (
                            <ErrorMessageContainer>
                                <h2>{loadApiError}</h2>
                            </ErrorMessageContainer>
                        )}

                        {!loadApiError && (
                            <>
                            <OutsideClickHandler onOutsideClick={() => setDateSelected(prevValue => !!openOption && !mouseIsDown ? [] : [...prevValue])}>
                                {!!openOption && !mouseIsDown && initialStatus === 0 && dateSelected.length > 0 && user?.role !== 'photographer' ? (
                                    <OptionToScheduleOrLock x={localToOptionX} y={localToOptionY}>
                                        <button onClick={handleOpenModalSchedule}>
                                            <p>{!!loadingModalSchedule ? "Carregando..." : "Agendar fotografia"}</p>
                                        </button>
                                    </OptionToScheduleOrLock>
                                ) : (<></>)}

                                {!!openOption && !mouseIsDown && initialStatus === 1 && dateSelected.length > 0 ? (
                                    <OptionToLockOrConsult x={localToOptionX} y={localToOptionY}>
                                        <button onClick={handleOpenModalConsult}>
                                            <p>{!!loadingModalConsult ? 'Carregando...' : 'Consultar agendamento'}</p>
                                        </button>

                                        {user?.role !== 'photographer' && (
                                            <button onClick={() => {
                                                setOpenModalToDemarcateSchedule(true); 
                                            }}>
                                                <p>Desmarcar visita</p>
                                            </button>
                                        )}
                                    </OptionToLockOrConsult>
                                ) : (<></>)}
                            </OutsideClickHandler>

                                {!!openModalConsult && (
                                    <ModalPhotographScheduleConsult open={openModalConsult} functionToClose={() => setOpenModalConsult(false)} dataToShow={dataToShowInModalConsult}/>
                                )}

                                {!!openModalSchedule && (
                                    <ModalPhotographerSchedule open={openModalSchedule} functionToCloseModalSchedule={() => setOpenModalSchedule(false)} dateToSchedule={schedule.split('|')}/>
                                )}

                                <ModalDemarcatePhotoghapherSchedule 
                                    open={openModalToDemarcateSchedule} 
                                    scheduleID={scheduleID}
                                    functionToCloseModal={() => setOpenModalToDemarcateSchedule(false)}
                                />

                                <SchedulesContainer>
                                    <h3>Agenda de Fotográfos</h3>

                                    {dates.map((date, index) => (
                                    <SchedulesContent key={date.getDate()} cellSpacing="0" cellPadding="0">
                                            <thead>
                                                <tr>
                                                    <th className="th-day">
                                                        {date.getDay() === 0 && (
                                                            <p>Domingo - {`${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`}</p>
                                                        )}

                                                        {date.getDay() === 1 && (
                                                            <p>Segunda - {`${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`}</p>
                                                        )}

                                                        {date.getDay() === 2 && (
                                                            <p>Terça - {`${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`}</p>
                                                        )}

                                                        {date.getDay() === 3 && (
                                                            <p>Quarta - {`${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`}</p>
                                                        )}

                                                        {date.getDay() === 4 && (
                                                            <p>Quinta - {`${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`}</p>
                                                        )}

                                                        {date.getDay() === 5 && (
                                                            <p>Sexta - {`${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`}</p>
                                                        )}

                                                        {date.getDay() === 6 && (
                                                            <p>Sábado - {`${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`}</p>
                                                        )}
                                                    </th>
                                                    {businessHours.map(hour => (
                                                        <th key={hour} className="th-hour">{hour}</th>
                                                    ))}
                                                </tr>
                                            </thead>

                                            {formatData.map((data) => (
                                                <tbody key={data.photographer_id}>
                                                <tr>
                                                    <td className="td-consultant"><p>{data.photographer_name}</p></td>
                                                    {data.dates[index].map((dateFinded) => (
                                                            <TDSchedules 
                                                                key={dateFinded.id} 
                                                                status={dateFinded.status} 
                                                                isSelected={dateSelected.findIndex(idFinded => idFinded.id === dateFinded.id)}
                                                                onMouseDown={(event) => {
                                                                    setInitialStatus(dateFinded.status);
                                                                    selectDate(dateFinded.id, dateFinded.status, event);
                                                                    setSchedule(`${dateFinded.date}`);
                                                                    setScheduleID(dateFinded.schedule_id);
                                                                    setMouseIsDown(true);
                                                                }}
                                                                onMouseOver={(event) => selectDateMouseOver(dateFinded.id, dateFinded.status, event)}/>
                                                    ))}
                                                </tr>
                                            </tbody>
                                            ))}
                                        </SchedulesContent> 
                                    ))}
                                </SchedulesContainer>
                            </>
                        )}
                        </>
                    </Content>
            </Layout>
        </Container>
        )}
        </>
    );
}