import styled, { css } from 'styled-components';

interface ITDSchedules{
    status: number;
    isSelected: number;
}

interface IOption{
    x: number | undefined;
    y: number | undefined;
}

export const Container = styled.div`

`;

export const Content = styled.div`
    max-width: 1200px;

    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    @media(max-width: 767px){
        max-width: 300px;
    }
`;

export const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 75vh;

    >h2{
        width: 60%;

        text-align: center;

        color: #ff5252;
    }
`;

export const OptionToScheduleOrLock = styled.div<IOption>`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    position: absolute;
    z-index: 2;

    padding: 16px;

    background-color: #F9F9F9;
    border: 0.5px solid #bcbfc2;

    left: ${props => `${props.x}px`};
    top: ${props => `${props.y}px`};

    >button{
        display: flex;
        align-items: center;

        background-color: transparent;
        border: 0;

        >p{
            margin-left: 12px;

            color: #C31010;

            font-weight: 500;
        }

        & + button{
            margin-top: 12px;
        }

        &:first-child{
            p{
                color: #00BF33;
            }
        }
    }
`;

export const OptionToLockOrConsult = styled.div<IOption>`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;

    position: absolute;
    z-index: 2;

    padding: 16px;

    background-color: #F9F9F9;
    border: 0.5px solid #bcbfc2;
    border-radius: 4px;

    left: ${props => `${props.x}px`};
    top: ${props => `${props.y}px`};

    >button{
        display: flex;
        align-items: center;

        background-color: transparent;
        border: 0;

        >p{
            margin-left: 12px;

            color: #BF0000;

            font-weight: 500;
        }

        & + button{
            margin-top: 12px;
        }

        &:first-child{
            p{
                color: #0218A2;
            }
        }
    }
`;

export const SchedulesContainer = styled.div`
    padding: 24px;

    border-radius: 4px;

    margin-top: 24px;

    h3{
        font-size: 35px;
        font-weight: bold;

        text-align: center;

        color: #666666;
    }

    @media(max-width: 767px){
        width: 120%;

        padding: 24px 12px;
    }
`;

export const SchedulesContent = styled.table`
    max-width: 978px;

    overflow: auto;

    border: 0;

    margin-top: 24px;

    border-spacing: 10px;

    td{
        user-select: none;

        &::selection{
            background: none;
        }

        &::-moz-selection{
            background: none;
        }
    }

    .th-day{
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;

        background-color: #6ea5ff;

        width: 200px;

        padding: 12px 0;

        color: #FFF;

        >p{
            font-size: 14px;
            font-weight: 500;
        }
    }

    .th-hour{
        padding: 12px 29px;

        background-color: #9b9b9b;

        border: 0;

        font-size: 14px;
        font-weight: 500;
        color: #FFF;

        text-align: end;
    }

    .td-consultant{
        text-align: start;

        font-size: 14px;
        color: #666666;

        padding: 12px 0 12px 16px;

        background: #F9F9F9;

        >p{
            font-weight: 500;
        }
    }

    @media(max-width: 767px){
        display: block;

        width: 100%;
    }
`;

export const TDSchedules = styled.td<ITDSchedules>`
    ${props => props.status === 0 && css`
        background-color: ${props.isSelected >= 0 ? "#dee2e6" : "#FFFFFF"};
        border: 1px solid #D7D7D7;
    `}

    ${props => props.status === 1 && css`
        background-color: ${props.isSelected >= 0 ? "#dee2e6" : "#72e88d"};
    `}
    
    width: 100%;
    height: 100%;

    padding: 24px;
`;