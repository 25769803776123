import {Content, 
        PhotoContainer,
        ButtonsContainer,
        ResetButton,
        SaveButton} from "./styles";

import Layout from './../../components/Layout';
import { DropZoneContainer } from "../EditProperty/styles";
import { FileRejection, useDropzone } from "react-dropzone";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { FiPaperclip, FiTrash2 } from "react-icons/fi";

import { useHistory, useLocation } from 'react-router-dom';
import asyncForEach from "../../utils/asyncForEach";
import refreshToken from "../../utils/refreshToken";

import imageCompression from 'browser-image-compression';
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";

interface IDataToShow{
    id: number;
    owner_name: string;
    keys_location: string;
    date: string;
    photographer_id: string;
    property_address: string;
    property_number: string;
    property_city: string;
    property_uf: string;
    property_neighborhood: string;
    property_complement: string;
    property_unity: string | null;
    owner_phone: string;
}

interface IState{
    dataToShow: IDataToShow;
}

interface IFiles extends Blob{
	name: string;
}

export default function PhotographerUploadImage(){
    const { state } = useLocation<IState>();

    const history = useHistory();

    const { access_token, dateToExpires } = useAuth();
    const { addToast } = useToast();

    const [loadingPhotosRequest, setLoadingPhotosRequest] = useState(false);
    const [loadingFinishRequest, setLoadingFinishRequest] = useState(false);

    const [files, setFiles] = useState<IFiles[]>([]);

	const [errorFileExists, setErrorFileExists] = useState('');
	const [errorInFileSize, setErrorInFileSize] = useState('');

    useEffect(() => {
        if(!state){
            history.push("/photographers-schedule");
        }
    }, [state, history]);

    const handleOnDrop = useCallback(async(acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if(fileRejections.length >= 1){
            setLoadingPhotosRequest(false);

            return;
        }

		setErrorInFileSize('');	
		setErrorFileExists('');

		try{
			if(acceptedFiles){
				setLoadingPhotosRequest(true);

				const checkFileExists = files.some(fileFinded => {
					return acceptedFiles.map(file => file.name).indexOf(fileFinded.name) >= 0;
				});

				if(!!checkFileExists){
					setLoadingPhotosRequest(false);
					
					setErrorFileExists('Arquivos com o mesmo nome não são permitidos');

					return;
				}

				await asyncForEach(acceptedFiles, async(file, index) => {
					const options = {
						maxSizeMB: 4,
						maxWidthOrHeight: 1920,
						useWebWorker: true
					};
	
					const imageFile = file;
	
					const compressedFile = await imageCompression(imageFile, options);
	
					if(compressedFile.size <= 4000000){
						const data = new FormData();
	
						data.append('photo', compressedFile);
                        data.append('property_address', `${state.dataToShow.property_address} ${state.dataToShow.property_number} ${state.dataToShow.property_neighborhood} ${state.dataToShow.property_unity ? state.dataToShow.property_unity : ''}`);

                        const token = await refreshToken(dateToExpires, access_token);

						api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;
						const response = await api.post('/api/adm/photographer/photos', data);
	
						if(!!response.data.success){
							setFiles(prevValue => [...prevValue, imageFile]);

							if(index + 1 === acceptedFiles.length){
								setLoadingPhotosRequest(false);
							}
						}
	
						return;
					} else{
						setErrorInFileSize('Arquivo muito grande. Por favor, envie um arquivo de até 4mb.');
						setLoadingPhotosRequest(false);
					}
				});
			}	
		} catch(err){
			setLoadingPhotosRequest(false);
		}
    }, [access_token, dateToExpires, state, files]);

    const handleDeletePhoto = useCallback(async(file_name: string) => {
		try{
			const updateFiles = files.filter(file => (file.name !== file_name));
			setFiles(updateFiles);
		} catch(err){
			console.log(err);
		}
	// eslint-disable-next-line
	}, [files]);

    const handleFinishUpload = useCallback(async() => {
        setLoadingFinishRequest(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;
            const response = await api.put(`/api/adm/photographer-schedule/${state.dataToShow.id}/finish`);

            if(!!response.data.success){
                addToast({
                    title: 'Imagens enviadas!',
                    type: 'success'
                });

                history.push("/photographers-schedule");
            }
        } catch(err){
            if(!!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]){
                const errorsNumber = Object.keys(err.response.data.errors).length;

                for(let i = 0; i < errorsNumber; i++){
                    err.response.data.errors[Object.keys(err.response.data.errors)[i]].forEach((error: string) => {
                        addToast({
                            title: error,
                            type: 'error',
                        }); 
                    })
                }
            };

           if(!!err.response?.data.message){
                addToast({
                    title: err.response.data.message,
                    type: 'error'
                });
            }

            console.log(err);
        }

        setLoadingFinishRequest(false);
    }, [addToast, history, access_token, dateToExpires, state.dataToShow.id]);

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({onDrop: (e, fileRejections) => handleOnDrop(e, fileRejections), accept: 'image/jpeg, image/png, image/jpg'});

    return(
        <Layout>
            <Content>
                <h1>Upload de fotos</h1>

                <h3>{state.dataToShow.property_address}, {state.dataToShow.property_neighborhood}, {state.dataToShow.property_number}, {state.dataToShow.property_city} - {state.dataToShow.property_uf}</h3>

                <DropZoneContainer {...getRootProps()} className="dropZone">
                    <input {...getInputProps()}/>

                    {!loadingPhotosRequest && (
                        <>
                            {!!isDragActive ? <p>Coloque a foto aqui...</p> : <p><FiPaperclip size={24} color="#DD6435"/> ADICIONAR FOTOS</p>}
                        </>
                    )}
                                        
                    {!!loadingPhotosRequest && (
                        <p>Carregando...</p>
                    )}
                </DropZoneContainer>

                {!!errorFileExists && (
                    <p>{errorFileExists}</p>
                )}

                {!!errorInFileSize && (
                    <p>{errorInFileSize}</p>
                )}

                {fileRejections.map(({ file, errors }) => (
                    <>
                        {errors.map(error => (
                            <p>{file.name} - {error.message === 'File type must be image/jpeg, image/png, image/jpg' ? 'Arquivo deve ser .jpg ou .png' : error.message}</p>
                        ))}
                    </>
                ))}

                {files.map(file => (
                    <PhotoContainer key={file.name}>
                        <p>{file.name}</p>

                        <button onClick={() => handleDeletePhoto(file.name)}><FiTrash2 color="#E00000" size={24}/></button>
                    </PhotoContainer>
                ))}

                <ButtonsContainer>
                    <ResetButton>LIMPAR</ResetButton>
                    <SaveButton onClick={loadingFinishRequest ? () => {} : handleFinishUpload}>{loadingFinishRequest ? "CARREGANDO..." : "SALVAR"}</SaveButton>
                </ButtonsContainer>
            </Content>
        </Layout>
    );
}