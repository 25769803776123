import styled from "styled-components";

export const CharacteristicWrapper = styled.div`
  width: 210px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  margin-left: 28px;

  > p {
    font-size: 14px;
    font-weight: 500;

    color: #666666;
  }
`;

export const RadiosWrapper = styled.div`
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 6px 0 18px;

  background: #f9f9f9;

  padding: 12px 0;

  > p {
    margin: 0 8px;

    cursor: pointer;
  }
`;

