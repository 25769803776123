import styled from "styled-components";

export const CharacteristicFormContainer = styled.form`
  width: 100%;

  background-color: #fff;

  padding: 24px;

  > h3 {
    color: #000;

    font-size: 28px;

    text-align: center;
  }
`;

export const CharacteristicContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const CharacteristicContent = styled.div`
  width: 48%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  margin-top: 24px;

  & + div {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const BigCharacteristicContent = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  margin-top: 24px;
`;

export const CharacteristicInitialContainer = styled.div`
  width: 100%;

  padding: 18px;

  font-size: 22px;
  font-weight: 500;

  color: #666666;

  text-align: center;
`;

export const CategoryWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-wrap: wrap;

  background: #f9f9f9;

  padding: 24px 18px 8px;
`;
