import styled from "styled-components";

export const Form = styled.form`
  width: 100%;

  background-color: #fff;

  padding: 24px;

  > p {
    margin: 24px 0 12px;

    font-weight: 500;

    color: #666666;
  }

  .p-example {
    font-size: 12px;
    font-weight: 400;

    color: #666666;

    margin: 12px 0 48px;
  }

  .css-2b097c-container {
    width: 100%;
  }

  .css-yk16xz-control {
    border: 0;
    border-bottom: 1px solid #b6b6b6;

    border-radius: 0;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  @media (max-width: 767px) {
    .p-example {
      font-size: 10px;
    }
  }
`;

export const FormDataWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  background-color: #f9f9f9;

  margin-top: 24px;

  padding: 40px 0;

  position: relative;

  > label {
    width: 45%;

    padding: 8px 0;

    background: #dd6435;

    font-weight: 500;
    color: #ffffff;

    text-align: center;

    position: absolute;
    top: -16px;
    left: 50;
  }

  @media (max-width: 767px) {
    > label {
      width: 90%;

      font-size: 12px;
    }
  }
`;

export const FormDataFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 6px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const FormDataField = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  > label {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    font-size: 14px;
    font-weight: 500;

    color: #666666;

    margin-bottom: 6px;

    > p {
      margin-left: 6px;

      font-size: 10px;
      font-weight: bold;
    }
  }

  > input {
    width: 100%;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    color: #666666;

    background: transparent;

    padding: 8px;
  }

  & + div {
    margin-left: 18px;
  }

  @media (max-width: 767px) {
    & + div {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;