import { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { useAuth } from "../../hooks/auth";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import {
  Container,
  ErrorMessageContainer,
  GuaranteesTable,
  TableChangeContainer,
} from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineDelete,
  MdOutlineEdit,
} from "react-icons/md";
import refreshToken from "../../utils/refreshToken";
import ModalConfirmRemoveGuarantee from "../../components/ModalConfirmRemoveGuarantee";
import { useRefresh } from "../../hooks/refresh";

interface IGuarantee {
  id: number;
  name: string;
  description: string;
  documents: string[];
  contracts_model: string[];
}

const ShowGuarantees = () => {
  const history = useHistory();
  const { access_token, user, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const { refresh, handleRefresh } = useRefresh();
  const [guarantees, setGuarantees] = useState<IGuarantee[]>([]);
  const [loadingGuarantees, setLoadingGuarantees] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [loadApiError, setLoadApiError] = useState("");
  const [openModalConfirmRemoveGuarantee, setOpenModalConfirmRemoveGuarantee] =
    useState(false);
  const [
    loadingApiRequestToRemoveGuarantee,
    setLoadingApiRequestToRemoveGuarantee,
  ] = useState(false);
  const [guaranteeIdToRemove, setGuaranteeIdToRemove] = useState(0);

  const GUARANTEES_PER_PAGE = 10;

  useEffect(() => {
    async function loadApi() {
      try {
        api.defaults.headers.authorization = `Bearer ${access_token}`;

        const response = await api.get("/api/adm/guarantees");
        const fetchedGuarantees = response.data.data;

        setGuarantees(fetchedGuarantees);
        const integerNumOfPages = Math.floor(
          fetchedGuarantees.length / GUARANTEES_PER_PAGE
        );
        const remainder = fetchedGuarantees.length % GUARANTEES_PER_PAGE;
        setNumberOfPages(
          integerNumOfPages === 0
            ? 1
            : integerNumOfPages + (remainder === 0 ? 0 : 1)
        );
        setLoadingGuarantees(false);
      } catch (err: any) {
        if (err?.response?.status === 403) {
          setLoadApiError(err?.response?.data?.message);

          setLoadingGuarantees(true);
        }

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }

        setLoadApiError(err.response.data.message);

        setLoadingGuarantees(true);
      }
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }

    // eslint-disable-next-line
  }, [dateToExpires, access_token, user, history]);

  const onBackward = () => {
    if (currentPage !== 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const onForward = () => {
    if (currentPage !== numberOfPages)
      setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleEditGuarantee = useCallback(
    (guarantee) => {
      history.push("/create-guarantee", { ...guarantee });
    },
    [history]
  );

  const handleRemoveGuarantee = useCallback(async () => {
    setLoadingApiRequestToRemoveGuarantee(true);

    try {
      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.delete(
        `/api/adm/guarantees/${guaranteeIdToRemove}`
      );

      if (!!response.data.success) {
        addToast({
          type: "success",
          title: "Garantia removida",
        });

        handleRefresh();
        setOpenModalConfirmRemoveGuarantee(false);
      }
    } catch (err) {
      console.log(err);
    }

    setLoadingApiRequestToRemoveGuarantee(false);
  }, [
    dateToExpires,
    access_token,
    addToast,
    handleRefresh,
    guaranteeIdToRemove,
  ]);

  const getContent = (pageGuarantees: IGuarantee[], activePage: number) => {
    let content = [];

    for (
      let i = (activePage - 1) * GUARANTEES_PER_PAGE;
      i < GUARANTEES_PER_PAGE * activePage;
      i++
    )
      if (i < pageGuarantees.length)
        content.push(
          <>
            <div className="row table-item first-on-row">
              <span>{pageGuarantees[i].name}</span>
            </div>
            <div className="row table-item">
              <span>{pageGuarantees[i].description}</span>
            </div>
            <div className="row table-item create-file-icon">
              <span onClick={() => handleEditGuarantee(pageGuarantees[i])}>
                <MdOutlineEdit color="0219a3" size={24} />
              </span>
            </div>
            <div className="row table-item create-file-icon">
              <span
                onClick={() => {
                  setOpenModalConfirmRemoveGuarantee(true);
                  setGuaranteeIdToRemove(pageGuarantees[i].id);
                }}
              >
                <MdOutlineDelete color="e00000" size={24} />
              </span>
            </div>
          </>
        );

    return content;
  };

  const getNavigationFooter = (activePage: number) => {
    let content = [];

    for (let i = 1; i <= numberOfPages; i++)
      content.push(
        <span
          id={activePage === i ? "current-page" : ""}
          onClick={() => {
            setCurrentPage(i);
          }}
        >
          {i}
        </span>
      );

    return content;
  };

  if (loadingGuarantees) return <Loading />;

  return (
    <Container>
      {!!loadApiError && (
        <ErrorMessageContainer>
          <h2>{loadApiError}</h2>
        </ErrorMessageContainer>
      )}
      <ModalConfirmRemoveGuarantee
        open={openModalConfirmRemoveGuarantee}
        isLoading={loadingApiRequestToRemoveGuarantee}
        functionToRemoveGuarantee={handleRemoveGuarantee}
        functionToCloseModal={() => setOpenModalConfirmRemoveGuarantee(false)}
      />
      {!loadApiError && (
        <>
          <Header />
          <h2>Garantias Cadastradas</h2>
          <GuaranteesTable>
            <div className="header table-item first-on-row">
              <span>Nome</span>
            </div>
            <div className="header table-item  description-col">
              <span>Descrição</span>
            </div>
            <div className="header table-item delete-header">
              <span>Editar</span>
            </div>
            <div className="header table-item delete-header">
              <span>Deletar</span>
            </div>
            {getContent(guarantees, currentPage)}
          </GuaranteesTable>
          <TableChangeContainer>
            <MdChevronLeft
              size={20}
              onClick={onBackward}
              className={currentPage === 1 ? "disable" : ""}
            />

            {getNavigationFooter(currentPage)}

            <MdChevronRight
              size={20}
              onClick={onForward}
              className={currentPage === numberOfPages ? "disable" : ""}
            />
          </TableChangeContainer>
          <Footer />
        </>
      )}
    </Container>
  );
};

export default ShowGuarantees;
