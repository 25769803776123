import React, { createContext, useState, useCallback, useContext } from 'react';

interface IConsultant{
    id: number;
    first_name: string;
}

interface IContext{
	consultantsSaved: IConsultant[];
	handleAddConsultants(consultants: IConsultant[]): void;
    handleRemoveConsultants(): void;
}

const ConsultantContext = createContext<IContext>({} as IContext);

const ConsultantProvider: React.FC = ({ children }) => {
	const [consultantsSaved, setConsultantsSaved] = useState<IConsultant[]>(() => {
		const consultants = localStorage.getItem('@7Cantos:consultants');

		if(consultants){
			return  JSON.parse(consultants);
		}

		return [];
	});

	const handleAddConsultants = useCallback((consultants: IConsultant[]) => {
		localStorage.setItem('@7Cantos:consultants', JSON.stringify(consultants));
		setConsultantsSaved(consultants);
	}, []);

    const handleRemoveConsultants = useCallback(() => {
		localStorage.removeItem('@7Cantos:consultants');
		setConsultantsSaved([]);
	}, []);

	return(
		<ConsultantContext.Provider value={{consultantsSaved, handleAddConsultants, handleRemoveConsultants}}>
			{children}
		</ConsultantContext.Provider>
	);
};

function useConsultant():IContext{
	const { consultantsSaved, handleAddConsultants, handleRemoveConsultants } = useContext(ConsultantContext);

	return { consultantsSaved, handleAddConsultants, handleRemoveConsultants };
}

export {ConsultantProvider, useConsultant};