import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  > label {
    margin-bottom: 8px;

    font-weight: 500;
    color: #666666;

    > p {
      font-size: 10px;
      font-weight: bold;
    }
  }

  > input {
    border: 1px solid #04c5ff;
    border-radius: 4px;

    padding: 8px;

    &:focus-within {
      border: 3px solid rgba(4, 197, 255, 0.3);
    }
  }

  .css-2b097c-container {
    width: 213px;
  }

  .css-yk16xz-control {
    border: 0;
    border-bottom: 1px solid #b6b6b6;

    border-radius: 0;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  @media (max-width: 767px) {
    width: 100%;

    > label {
      margin: 16px 0 0;
    }

    .css-2b097c-container {
      width: 100%;
    }
  }
`;
