import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
`;

export const Form = styled.form`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;

  > div.MuiInputBase-root {
    margin-top: 0.75rem;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
    border-radius: 0.8em;
    border: 1px solid #b6b6b6;
    > .MuiInputBase-input {
      padding: 0;
    }
    > svg {
      padding: 0 0.25rem 0 0;
      box-sizing: content-box;
    }
    > svg.MuiSelect-iconOpen {
      padding: 0 0 0 0.25rem;
    }
  }

  > div.MuiInputBase-root::before {
    display: none;
  }
  > div.MuiInputBase-root::after {
    display: none;
  }

  > label {
    font-weight: 600;
    font-size: 1rem;
    color: #606060;
    margin-top: 0.5rem;
  }

  > label#name-lbl {
    margin-top: 2rem;
  }

  > input {
    margin-top: 0.75rem;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
    border-radius: 0.8em;
    border: 1px solid #b6b6b6;
  }

  > div:not(#checkbox-container):not(.MuiInputBase-root) {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border: 1px solid #b6b6b6;
    max-height: 5rem;
    overflow: auto;
    align-items: start;
    padding: 0.25rem;
    margin: 0.25rem 0.25rem;

    > button:hover {
      background: #eeeeee;
      transition: linear 0.2s;
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 1%;
  margin: 1rem 0;

  > label {
    color: #606060;
    font-weight: 400;
    font-size: 0.9rem;
  }
`;

export const FormButton = styled.button`
  background-color: #6da7ff;
  font-weight: 700;
  color: #fff;
  min-width: 33%;
  border-radius: 2rem;
  font-size: 1rem;
  padding: 0.75em 0;
  margin: 3rem auto 1rem;
  > span#styled-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3%;
  }
`;
