import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useRouteMatch } from "react-router";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import ModalShowClients from "../../components/ModalShowClients";
import { useAuth } from "../../hooks/auth";
import { useRefresh } from "../../hooks/refresh";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import formatValue from "../../utils/formatValue";
import refreshToken from "../../utils/refreshToken";

import {Content,
        OffersContainer,
        LocalContainer,
        LocalContent,
        PropertyDatasContainer,
        OfferAuthorNameAndOptionContainer,
        OfferContent,
        OfferDataContainer,
        OffersStatusContainer,
        ButtonsContainer,
        AcceptOfferButton,
        CancelOfferButton} from "./styles";

interface IParams{
    offer_id: string;
}

interface IAddress{
    number: string;
    address: string;
    city: string;
    neighborhood: string;
    uf: string;
}

interface IUser{
    email: string;
    phone: string;
    first_name: string;
}

interface IAuthor{
    id: number;
    first_name: string;
}

interface IOffer{
    client_id: number;
    id: number;
    initial_offer_id: null;
    author: IAuthor;
    offer_value: string;
    owner_id: number;
    property_id: number;
    requirements: string;    
    status: number;
    updated_at: string;
    user_id: number;
}

interface IApiData{
    property_photo: string;
    property_title: string;
    property_values: {
        rent: string;
        condominium: string;
        iptu: string;
    }
    owner: IUser;
    client: IUser;
    address: IAddress;
    offers: IOffer[];
}

export default function SeeOffer(){
    const { access_token, dateToExpires, user } = useAuth();
    const { addToast } = useToast();
    const { handleRefresh, refresh } = useRefresh();
 
    const { params } = useRouteMatch<IParams>();
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const [apiData, setApiData] = useState({} as IApiData);

    const [openModalShowClients, setOpenModalShowClients] = useState(false);

    const [loadingAcceptOfferRequest, setLoadingAcceptOfferRequest] = useState(false);
    const [loadingCancelOfferRequest, setLoadingCancelOfferRequest] = useState(false);

    useEffect(() => {
        async function loadApi(){
            try{
                const token = await refreshToken(dateToExpires, access_token);

                api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

                const response = await api.get(`/api/adm/offers/${params.offer_id}`);

                setApiData(response.data.data);
                setLoading(false);
            } catch(err){
                if(err.response.statusText === 'Not Found'){
                    addToast({
                        title: 'Página indisponível',
                        type: 'error'
                    });

                    history.goBack();
                };

                if(!!err.response.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });

                    history.goBack();
                }

                console.log(err);
            }
        }

        if(!!user){
            loadApi();
        } else{
            history.push('/');
        }
        // eslint-disable-next-line
    }, [access_token, dateToExpires, params.offer_id, user, history, refresh]);

    const totalValue = useMemo(() => {
        return formatValue(Number(apiData.property_values?.rent) + Number(apiData.property_values?.iptu) + Number(apiData.property_values?.condominium));
    }, [apiData.property_values]);

    const handleAcceptOffer = useCallback(async(offer_id: number) => {
        setLoadingAcceptOfferRequest(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.put(`/api/adm/offers/${offer_id}`, {
                status: 1
            });

            if(!!response.data.success){
                setLoadingAcceptOfferRequest(false);

                addToast({
                    title: 'Proposta aceita!',
                    type: 'success'
                });

                handleRefresh();
            }
        } catch(err){
            setLoadingAcceptOfferRequest(false);

            console.log(err);
        }
    }, [dateToExpires, access_token, addToast, handleRefresh])

    const handleCancelOffer = useCallback(async(offer_id: number) => {
        setLoadingCancelOfferRequest(true);

        try{
            const token = await refreshToken(dateToExpires, access_token);

            api.defaults.headers.authorization = `Bearer ${!!token ? token : access_token}`;

            const response = await api.put(`/api/adm/offers/${offer_id}`, {
                status: 4
            });

            if(!!response.data.success){
                setLoadingCancelOfferRequest(false);
                
                addToast({
                    title: 'Proposta cancelada!',
                    type: 'success'
                });

                handleRefresh();
            }
        } catch(err){
            console.log(err.response);



            setLoadingCancelOfferRequest(false);
        }
    }, [dateToExpires, access_token, addToast, handleRefresh])

    return(
        <Layout>
            {!!loading && (
                <Loading />
            )}

            {!loading && (
                <Content>
                    <h2>Negociação</h2>

                    <OffersContainer>
                        <LocalContainer>
                            <LocalContent>
                                <img src={apiData.property_photo} alt="propertyImg"/>

                                <PropertyDatasContainer>
                                    <p>{apiData.property_title}</p>
                                    <p>{apiData.address.address},{apiData.address.number},{apiData.address.neighborhood},{apiData.address.city}-{apiData.address.uf}</p>
                                    <p>Aluguel: {formatValue(Number(apiData.property_values?.rent))} - Total: {totalValue}</p>
                                </PropertyDatasContainer>
                            </LocalContent>

                            <OfferAuthorNameAndOptionContainer>
                                <div><p>Autor da proposta:</p> <p>{apiData.client.first_name}</p></div>

                                <button onClick={() => setOpenModalShowClients(true)}>
                                    <p>DADOS DO CLIENTE</p>
                                </button>
                            </OfferAuthorNameAndOptionContainer>
                        </LocalContainer>

                        {apiData.offers.map(offer => (
                            <OfferContent key={offer.id}>
                                <OfferDataContainer>
                                    <p>Valor da Proposta: {formatValue(Number(offer.offer_value))}</p>
                                    {!!offer.requirements && (<p>Solicitações: {offer.requirements}</p>)}
                                
                                    <span>Realizado em {new Date(offer.updated_at).toLocaleString('pt-BR')}</span>
                                </OfferDataContainer>

                                {offer.status === 0 && (
                                    <ButtonsContainer>
                                        <AcceptOfferButton onClick={!!loadingAcceptOfferRequest ? () => {} : () => handleAcceptOffer(offer.id)}>{!!loadingAcceptOfferRequest ? "Carregando..." : "ACEITAR PROPOSTA"}</AcceptOfferButton>
                                        <CancelOfferButton onClick={!!loadingCancelOfferRequest ? () => {} : () => handleCancelOffer(offer.id)}>{!!loadingCancelOfferRequest ? "Carregando..." : "CANCELAR PROPOSTA"}</CancelOfferButton>
                                    </ButtonsContainer>
                                )}

                                {offer.status === 1 && (
                                    <OffersStatusContainer status={offer.status}>PROPOSTA ACEITA</OffersStatusContainer>
                                )}

                                {offer.status === 2 && (
                                    <OffersStatusContainer status={offer.status}>CONTRAPROSPOSTA REALIZADA</OffersStatusContainer>
                                )}

                                {offer.status === 3 && (
                                    <OffersStatusContainer status={offer.status}>PROPOSTA ENCERRADA</OffersStatusContainer>
                                )}

                                {offer.status === 4 && (
                                    <OffersStatusContainer status={offer.status}>PROPOSTA CANCELADA</OffersStatusContainer>
                                )}
                            </OfferContent>
                        ))}
                    </OffersContainer>

                    <ModalShowClients open={openModalShowClients} functionToClose={() => setOpenModalShowClients(false)} owner={apiData.owner} client={apiData.client}/>
                </Content>
            )}
        </Layout>
    );
}