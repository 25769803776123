import { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import api from "../../../../../services/api";
import { useAuth } from "../../../../../hooks/auth";
import { useToast } from "../../../../../hooks/toast";
import refreshToken from "../../../../../utils/refreshToken";
import { useHistory } from "react-router-dom";

interface IGuarantee {
  id: number;
  name: string;
  documents: string[];
  description: string;
}

interface IProps {
  selectedGuarantees: number[];
  onChange: (event: any) => void;
}

function isChecked(selectedGuarantees: number[], currentGuarantee: number) {
  const hasGuarantee = selectedGuarantees.find(
    (guarantee) => guarantee === currentGuarantee
  );
  return hasGuarantee ? true : false;
}

function GuaranteesSection({ onChange, selectedGuarantees }: IProps) {
  const { access_token, dateToExpires, user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);
  const [fetchedGuarantees, setFetchedGuarantees] = useState<IGuarantee[]>([]);

  useEffect(() => {
    async function loadGuarantees() {
      setIsFetching(true);
      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get("/api/adm/guarantees");

        setFetchedGuarantees(response.data.data);
      } catch (err) {
        console.log(err);

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
      setIsFetching(false);
    }

    if (!!user) {
      loadGuarantees();
    } else {
      history.push("/");
    }
  }, [dateToExpires, access_token, user, history]);

  return (
    <Wrapper>
      <label>Garantias disponíveis:</label>
      <div id="inputs-container">
        {isFetching && "Carregando..."}
        {!isFetching &&
          fetchedGuarantees?.map((guarantee) => {
            return (
              <div id="input-container" key={guarantee.id}>
                <input
                  checked={isChecked(selectedGuarantees, guarantee.id)}
                  id={guarantee.name}
                  type="checkbox"
                  onChange={onChange}
                  value={guarantee.id}
                />
                <label htmlFor={guarantee.name}>{guarantee.name}</label>
              </div>
            );
          })}
      </div>
    </Wrapper>
  );
}

export default GuaranteesSection;
