import { useCallback, useEffect, useMemo, useState } from "react";
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import refreshToken from "../../utils/refreshToken";

import {Content,
        OffersTable,
        OffersTableTD,
        LastTableTD,
        PagesContainer,
        PageOptionPrevious,
        PageOption,
        PageOptionLatter} from './styles';

interface IAuthor{
    id: number;
    first_name: string;
}

interface IOffers{
    id: number;
    author: IAuthor;
    property_id: number;
    user_id: number;
    status: number;
    updated_at: string;
}

interface IParams{
    page: string;
}

export default function Offers(){
    const history = useHistory();
    const { params } = useRouteMatch<IParams>();

    const { dateToExpires, access_token, user } = useAuth();
    const { addToast } = useToast();

    const [offers, setOffers] = useState<IOffers[]>([]);
    const [lastPage, setLastPage] = useState<number>(0);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function loadApi(){
            try{
                const token = await refreshToken(dateToExpires, access_token);

                api.defaults.headers.authotization = `Bearer ${!!token ? token : access_token}`;

                const response = await api.get('/api/adm/offers', {
                    params:{
                        page: Number(params.page)
                    }
                });

                setOffers(response.data.data.data);
                setLastPage(response.data.data.last_page);

                setLoading(false);
            } catch(err){
                console.log(err);

                if(!!err.response?.data.message){
                    addToast({
                        title: err.response.data.message,
                        type: 'error'
                    });
                }
            }
        }

        if(!!user){
            loadApi();
        } else{
            history.push('/');
        }

        // eslint-disable-next-line
    }, [user, access_token, dateToExpires, history, params.page]);

    const pagesNumber = useMemo(() => {
        const pagesNumberArray = [];

        for(let i = 1; i <= lastPage; i++){
            pagesNumberArray.push(i);
        }

        if(pagesNumberArray.length > 5 && Number(params.page) < 5){
            pagesNumberArray.length = 5;

            const pagesNumberArrayFormated = [...pagesNumberArray ];

            return pagesNumberArrayFormated;
        }

        if(pagesNumberArray.length > 5 && Number(params.page) >= 5 && Number(params.page) < pagesNumberArray.length - 3){
            return ([pagesNumberArray[Number(params.page) - 4], pagesNumberArray[Number(params.page) - 3], pagesNumberArray[Number(params.page) - 2], pagesNumberArray[Number(params.page) - 1], pagesNumberArray[Number(params.page)], pagesNumberArray[Number(params.page) + 1], pagesNumberArray[Number(params.page) + 2]]);
        }

        if(pagesNumberArray.length > 5 && Number(params.page) >= pagesNumberArray.length -4){
            return ([pagesNumberArray[pagesNumberArray.length - 5], pagesNumberArray[pagesNumberArray.length - 4], pagesNumberArray[pagesNumberArray.length - 3], pagesNumberArray[pagesNumberArray.length - 2], pagesNumberArray[pagesNumberArray.length - 1]]);
        }

        return pagesNumberArray;
    }, [lastPage, params.page]);

    const handleChangePagesNumber = useCallback((page: number) => {
        history.push(`/offers/${page}`);

        window.location.reload();
    }, [history]);

    return(
        <Layout>
            {!!loading && (<Loading />)}
            {!loading && (
                <Content>
                    <h2>Propostas em Andamento</h2>

                    <OffersTable>
                        <thead>
                            <tr>
                                <th>Imóvel</th>
                                <th>Autor da proposta</th>
                                <th>Última Atualização</th>
                                <th>Status/Visualizar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offers.map((offer) => (
                                <tr key={offer.id}>
                                    <OffersTableTD id="td-id">{offer.property_id}</OffersTableTD>
                                    <OffersTableTD>{offer.author.first_name}</OffersTableTD>
                                    <OffersTableTD>{new Date(offer.updated_at).toLocaleString('pt-br')}</OffersTableTD>
                                    <LastTableTD status={offer.status}>
                                        {offer.status === 0 && (
                                            <Link to={`/see-offer/${String(offer.id)}`}>ACOMPANHAR PROPOSTA</Link>
                                        )}
                                        {offer.status === 1 && (
                                            <Link to={`/see-offer/${String(offer.id)}`}>PROPOSTA ACEITA</Link>
                                        )}
                                        {offer.status === 2 && (
                                            <Link to={`/see-offer/${String(offer.id)}`}>ACOMPANHAR PROPOSTA</Link>
                                        )}
                                        {offer.status === 3 && (
                                            <Link to={`/see-offer/${String(offer.id)}`}>PROPOSTA ACEITA</Link>
                                        )}
                                        {offer.status === 4 && (
                                            <Link to={`/see-offer/${String(offer.id)}`}>PROPOSTA CANCELADA</Link>
                                        )}
                                    </LastTableTD>
                                </tr>
                            ))}
                        </tbody>
                    </OffersTable>

                    <PagesContainer>
                        <PageOptionPrevious>
                            {Number(params.page) !== 1 ? (<Link to={`/offers/${1}`} onClick={() => handleChangePagesNumber(1)}><FiChevronsLeft color="#666666" size={24}/></Link>) : (<FiChevronsLeft color="#666666" size={24}/>)}
                        </PageOptionPrevious>

                        <PageOptionPrevious>
                            {Number(params.page) !== 1 ? (<Link to={`/offers/${Number(params.page) - 1}`} onClick={() => handleChangePagesNumber(Number(params.page) - 1)}><FiChevronLeft color="#666666" size={24}/></Link>) : (<FiChevronLeft color="#666666" size={24}/>)}
                        </PageOptionPrevious>

                        {pagesNumber.map(page => (
                            <PageOption key={page} isSelected={Number(params.page) === page}>
                                {Number.isInteger(page) ? (<Link to={`/offers/${page}`} onClick={() => handleChangePagesNumber(Number(page))}>{page}</Link>) : (<p>{page}</p>)}
                            </PageOption>
                        ))}

                        <PageOptionLatter>
                            {Number(params.page) !== (pagesNumber[pagesNumber.length - 1]) ? (<Link to={`/offers/${Number(params.page) + 1}`} onClick={() => handleChangePagesNumber(Number(params.page) + 1)}><FiChevronRight color="#666666" size={24}/></Link>) : (<FiChevronRight color="#666666" size={24}/>)}
                        </PageOptionLatter>

                        <PageOptionLatter>
                            {Number(params.page) !== (pagesNumber[pagesNumber.length - 1]) ? (<Link to={`/offers/${Number(lastPage)}`} onClick={() => handleChangePagesNumber(Number(lastPage))}><FiChevronsRight color="#666666" size={24}/></Link>) : (<FiChevronsRight color="#666666" size={24}/>)}
                        </PageOptionLatter>
                    </PagesContainer>
                </Content>
            )}
        </Layout>
    );
}