import styled, { css } from "styled-components";
import ScheduleType from "./ScheduleType";

interface ITDSchedules {
  status: number;
  isSelected: number;
}

interface IOption {
  x: number | undefined;
  y: number | undefined;
}

interface ICoordinate {
  coordinate: IOption;
}

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1200px;

  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 75vh;

  > h2 {
    width: 60%;

    text-align: center;

    color: #ff5252;
  }
`;

export const OptionToUnlock = styled.button<ICoordinate>`
  display: flex;
  align-items: center;
  box-shadow: -2px 3px 3px rgb(0 0 0 / 20%);

  position: absolute;
  z-index: 2;

  background-color: #f9f9f9;
  border: 0.5px solid #bcbfc2;

  left: ${(props) => `${props.coordinate.x}px`};
  top: ${(props) => `${props.coordinate.y}px`};

  padding: 16px;

  > p {
    margin-left: 12px;

    color: #00bf33;

    font-weight: 500;
  }
`;

export const OptionToScheduleOrLock = styled.div<ICoordinate>`
  position: absolute;
  z-index: 2;
  box-shadow: -2px 3px 3px rgb(0 0 0 / 20%);

  background-color: #f9f9f9;
  border: 0.5px solid #bcbfc2;

  left: ${(props) => `${props.coordinate.x}px`};
  top: ${(props) => `${props.coordinate.y}px`};

  > p {
    color: hsl(252 4% 66%);
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid hsl(252 4% 66%);
    font-weight: 500;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;

    > button {
      background-color: transparent;
      border: 0;
      color: hsl(30 0% 38%);
    }
  }
`;

export const OptionToLockOrConsult = styled.div<ICoordinate>`
  position: absolute;
  z-index: 2;
  box-shadow: -2px 3px 3px rgb(0 0 0 / 20%);

  background-color: #f9f9f9;
  border-radius: 4px;

  left: ${(props) => `${props.coordinate.x}px`};
  top: ${(props) => `${props.coordinate.y}px`};

  .option-date {
    border-bottom: 1px solid hsl(252 4% 66%);
    font-weight: 500;
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;

    > button {
      background-color: transparent;
      border: 0;
  
      color: hsl(30 0% 38%);
    }
  }

  .client-tag {
    border-top: 1px solid hsl(252 4% 66%);
  }
 
  > p {
    color: hsl(252 4% 66%);
    width: 100%;
    padding: 1rem;
  }
`;

export const SchedulesContainer = styled.div`
  padding: 16px;

  border-radius: 4px;

  margin-top: 24px;
  width: 100%;
  h3 {
    font-size: 35px;
    font-weight: bold;

    text-align: left;

    color: #666666;
  }

  .bottom-line {
    margin-top: 24px;
    width: 10%;
    height: 3px;
    background-color: #dd6434;
    border-radius: 1px;
    @media (max-width: 767px) {
      width: 30%;
    }

  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 24px 12px;
  }
`;

export const SchedulesContent = styled.div`
  max-width: 1260px;
  margin-top: 36px;

  overflow: auto;

  .table-hide {
    display: none;
    transition: height 0.5s;
  }
  
  .table-show {
    width: 100%;
    margin: 24px 0 12px;
    border-radius: 16px;
    overflow: hidden;
    @media(max-width: 767px){ 
      overflow: auto;
    }
  }

  thead {
    display: block;
    width: 100%;

    tr {
      display: flex;
      @media(max-width: 767px){
        display: block;
      }
    }
    th {
      display: inline-block;
    }
    .th-city {
      background: #dd6434;
      color: #ffffff;

      text-align: center;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;

      padding: 16px 0;
      width: 14%;
      height: 48px;
      margin-right: 1%;

      @media(max-width: 767px){
        position: sticky;
        left: 0;
        width: 22.5%;
        white-space: wrap;
      }
    }
    .th-hour {
      padding: 12px 29px;
      background-color: #9b9b9b;
      height: 48px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      padding: 16px 0;
      width: 8.5%;

      @media(max-width: 767px){
        width: 12.9%;

      }
    }
  }

  tbody {
    width: 100%;
    display: block;

    tr {
      margin-top: 12px;
      display: block;
      
      .td-consultant {
        text-align: center;
    
        width: 14%;
        height: 3rem;
    
        font-size: 14px;
        color: #666666;
    
        padding: 16px 4px;
        background: #f9f9f9;
        font-weight: 500; 
  
        @media(max-width: 767px){
          position: sticky;
          left: 0;
          width: 22.5%;
          padding: 4px;
          >p {
            display: flex;
            height: 100%;
            align-items: center;
            white-space: wrap;
            justify-content: center;
          }
        }
      }
      @media (min-width: 767px) {
        &:last-of-type {
          > td:last-of-type {
            border-radius: 0 0 1rem 0;
          }
        } 
      }
    }
    
    td {
      display: inline-block;
      user-select: none;
  
      &::selection {
        background: none;
      }
  
      &::-moz-selection {
        background: none;
      }
    }
  }

  @media (max-width: 767px) {
    white-space: nowrap;
  }
`;

export const WeekdayTitle = styled.div`

  background-color: #6ea5ff;
  color: #ffffff;

  cursor: pointer;

  display: flex;

  padding: 12px 0;

  border-radius: 16px;

  > h4 {
    margin: 0 auto;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }

  > svg {
    align-items: center;
    font-size: 20px;
    text-align: right;
    margin-right: 16px;
  }

  @media(max-width: 767px){
    position: sticky;
    left: 0;
  }
`;

export const TDSchedules = styled.td<ITDSchedules>`
  ${(props) =>
    props.status === ScheduleType.LOCKED &&
    css`
      border: 2px solid ${
        props.isSelected >= 0 ? "#dee2e6" : "hsl(360deg 100% 45%)"
      };
      >svg {
        color: ${props.isSelected >= 0 ? "#dee2e6" : "hsl(360deg 100% 45%)"}
      }
    `}

  ${(props) =>
    props.status === ScheduleType.UNLOCKED &&
    css`
      background-color: ${props.isSelected >= 0 ? "#dee2e6" : "#FFFFFF"};
      border: 1.5px solid #d7d7d7;
    `}

  ${(props) =>
    props.status === ScheduleType.SCHEDULED &&
    css`
    border: 2px solid ${
      props.isSelected >= 0 ? "#dee2e6" : "hsl(112deg 99% 28%)"
    };
    >svg {
      color: ${props.isSelected >= 0 ? "#dee2e6" : "hsl(112deg 99% 28%)"}
    }
  `}
    
    width: 7.6%;
    margin-left: 1%;
    height: 48px;

    >svg {
      display: block;
      margin: auto;
      pointer-events: none;
      margin-top: 10px;
    }

    @media(max-width: 767px){
      width: 12%;
      height: 3rem;
    }
`;
